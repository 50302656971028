import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges, ViewChild
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../../router.module';
import {UserDataService} from '../../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {AggiuntaPiatto} from "../../aggiunta-piatto/aggiunta-piatto";
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {CommonService} from "../../../shared/services/firestore/common.service";
import {Group} from "../../menu/menu";
import * as Chart from "chart.js";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";



@Component({
  selector: 'app-categorie',
  templateUrl: './report-incassi.html',
  styleUrls: ['./report-incassi.css']
})
  export class ReportIncassi implements OnInit{


  modalOptions = { backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {} };

  sezioneNovita = 1;
  dataSourceDipendenti2= new MatTableDataSource<any | Group>();

  public dataSource = new MatTableDataSource<any | Group>([]);



  @ViewChild(MatPaginator) paginatorDipendenti2: MatPaginator;
  @ViewChild(MatSort) sortDipendenti2: MatSort;


  _alldata: any[];

  columns: any[];
  displayedColumns: string[];

  groupByColumns: string[] = [];


  displayedColumnsDipendenti2: string[] = [];


  display = 'none';

  closeResult = '';

  temp = [
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      messaggio1: 'Stato Ordine',
      messaggio2: 'In Corso',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    }
  ];

  nome = 'Totale';

  nome2 = 'Mancia per Mike';


  data = '23-01-2021 19.30';

  luogo = 'Rimini ,';

  prezzo = '26.00';

  prezzo2 = '2.60';

  percentuale = 0;

  percentuale2 = 10;

  user;

  ristorante;

  ifCarrello;

  carrelloTmp;

  carrello;

  numeroElementi = 0;

  tot = 0;

  isOver = false;


  memoLink = 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png';

  scontrini = [


    {
      link: 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png',
      nome: 'Aggiungi Ingriedente',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png',
      nome: 'Import cvs',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png',
      nome: 'Import json',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png',
      nome: 'Import xml',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    }
  ];

  ristoranti: { nome: string, descrizione: string, link: string }[] = [
    { nome: 'menu', descrizione: 'Available', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' },
    { nome: 'menu', descrizione: 'Ready', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' },
    { nome: 'menu', descrizione: 'Started', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' }
  ];

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];


  totali =

    {
      'Elettronici': '42.000,00€',
      'Contanti': '14.000,00€',
    }


  costiOperativi = [
    {
      categoria: 'Totale',
      menu: [
        {
          categoria: 'Totale',
          descrizione: 'Totale fatturato',
          id: '',
          nome: 'Totali',
          prezzo: '999',
          transazioni: 999,
          quantita: 20,
        },
      ]
    },
    {
      categoria: 'Elettronici',
      menu: [
        {
          categoria: 'Elettronici',
          descrizione: 'POS Cassa 1',
          id: '',
          nome: 'POS Cassa 1',
          prezzo: '7.000,00€',
          transazioni: 200,

          quantita: 20,
        },{
          categoria: 'Elettronici',
          descrizione: 'POS Cassa 2',
          id: '',
          nome: 'POS Cassa 2',
          prezzo: '7.000,00€',
          transazioni: 189,

          quantita: 20,
        },{
          categoria: 'Elettronici',
          descrizione: 'POS Cassa 3',
          id: '',
          nome: 'POS Cassa 3',
          prezzo: '7.000,00€',
          transazioni: 123,

          quantita: 20,
        },{
          categoria: 'Elettronici',
          descrizione: 'POS Totem 1',
          id: '',
          nome: 'POS Totem 1',
          prezzo: '7.000,00€',
          transazioni: 497,

          quantita: 20,
        },{
          categoria: 'Elettronici',
          descrizione: 'POS Totem 2',
          id: '',
          nome: 'POS Totem 2',
          prezzo: '7.000,00€',
          transazioni: 380,

          quantita: 20,
        },{
          categoria: 'Elettronici',
          descrizione: 'POS Totem 3',
          id: '',
          nome: 'POS Totem 3',
          prezzo: '7.000,00€',
          transazioni: 514,

          quantita: 20,
        },
      ]
    },
    {
      categoria: 'Contanti',
      menu: [
        {
          categoria: 'Contanti',
          descrizione: 'Cassa 1',
          id: '',
          nome: 'Cassa 1',
          prezzo: '3.500,00€',
          transazioni: 113,
          quantita: 20,
        },
        {
          categoria: 'Contanti',
          descrizione: 'Cassa 2',
          id: '',
          nome: 'Cassa 2',
          prezzo: '3.500,00€',
          transazioni: 143,
          quantita: 20,
        },
        {
          categoria: 'Contanti',
          descrizione: 'Cassa 3',
          id: '',
          nome: 'Cassa 3',
          prezzo: '3.500,00€',
          transazioni: 89,
          quantita: 20,
        },
        {
          categoria: 'Contanti',
          descrizione: 'Totem 1',
          id: '',
          nome: 'Totem 1',
          prezzo: '3.500,00€',
          transazioni: 437,
          quantita: 20,
        }
      ]
    }
  ]


  sommaTotaleValore(valore: string)
  {
    if(this.costiOperativi !== undefined) {


      if(valore !== '999') {

        if(valore !== undefined)
        {
          return valore.replace(".", '').replace(",", '.').slice(0, -1) + '€'
        }



      }
      else
      {
        let initialValue = 0;
        let sumWithInitial = this.costiOperativi[1].menu.reduce(
          (accumulator, currentValue) => accumulator + +currentValue.prezzo.replace(".", '').replace(",", '.').slice(0, -1),
          initialValue,
        )
        initialValue = 0;
        sumWithInitial = sumWithInitial + this.costiOperativi[2].menu.reduce(
          (accumulator, currentValue) => accumulator + +currentValue.prezzo.replace(".", '').replace(",", '.').slice(0, -1),
          initialValue,
        )

        return sumWithInitial.toFixed(2).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`)+'€'
      }
    }
  }


  sommaTotaleValor2(valore: string)
  {
    if(this.costiOperativi !== undefined) {


      if(valore !== '999') {

        if(valore !== undefined)
        {
          return valore.replace(".", '').replace(",", '.').slice(0, -1) + '€'
        }



      }
      else
      {
        let initialValue = 0;
        let sumWithInitial = this.costiOperativi[1].menu.reduce(
          (accumulator, currentValue) => accumulator + +currentValue.prezzo.replace(".", '').replace(",", '.').slice(0, -1),
          initialValue,
        )
        initialValue = 0;
        sumWithInitial = sumWithInitial + this.costiOperativi[2].menu.reduce(
          (accumulator, currentValue) => accumulator + +currentValue.prezzo.replace(".", '').replace(",", '.').slice(0, -1),
          initialValue,
        )

        return sumWithInitial.toFixed(2)
      }
    }
  }



  sommaTotaleValoreCategoria(i: any)
  {
    if(i == 0)
    {
      let initialValue = 0;

      return this.costiOperativi[1].menu.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.prezzo.replace(".", '').replace(",", '.').slice(0, -1),
        initialValue,
      );
    }
    else
    {
      let initialValue = 0;
      return this.costiOperativi[2].menu.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.prezzo.replace(".", '').replace(",", '.').slice(0, -1),
        initialValue,
      );
    }
  }

  sommaTotale()
  {
    if(this.costiOperativi !== undefined) {

      let initialValue = 0;
      let sumWithInitial = this.costiOperativi[1].menu.reduce(
        (accumulator, currentValue) => accumulator + currentValue.transazioni,
        initialValue,
      )

      initialValue = 0;
      sumWithInitial = sumWithInitial + this.costiOperativi[2].menu.reduce(
        (accumulator, currentValue) => accumulator + currentValue.transazioni,
        initialValue,
      )

      return sumWithInitial;
    }


  }

  scontrinoMedio(i: number)
  {
    let array = this.costiOperativi[0].menu
    array = array.concat(this.costiOperativi[1].menu)
    array = array.concat(this.costiOperativi[2].menu)

    if(i == 1 )
    {
      return '100%'
    }
    else
    {
      if(i<10)
      {
        return ( +array[i-2].prezzo.replace(".", '').replace(",", '.').slice(0, -1) * 100)/ +this.sommaTotaleValor2('999').slice(0, -1)+'%'
      }
      else
      {
        return ( +array[i-3].prezzo.replace(".", '').replace(",", '.').slice(0, -1) * 100)/ +this.sommaTotaleValor2('999').slice(0, -1)+'%'
      }
    }
  }


  scontrinoMedio2(i: number)
  {
    let array = this.costiOperativi[0].menu
    array = array.concat(this.costiOperativi[1].menu)
    array = array.concat(this.costiOperativi[2].menu)

    if(i == 1 )
    {
      //return ( +array[0].prezzo.replace(".", '').replace(",", '.').slice(0, -1) / +array[i-2].transazioni).toFixed(2)+'€'
    }
    else
    {
      if(i<10)
      {
        return ( +array[i-2].prezzo.replace(".", '').replace(",", '.').slice(0, -1) / +array[i-2].transazioni).toFixed(2)+'€'
      }
      else
      {
        return ( +array[i-3].prezzo.replace(".", '').replace(",", '.').slice(0, -1) / +array[i-3].transazioni).toFixed(2)+'€'
      }
    }
  }

  percentualeCategoria(i: number)
  {
    let array = this.costiOperativi[0].menu
    array = array.concat(this.costiOperativi[1].menu)
    array = array.concat(this.costiOperativi[2].menu)

    if(i == 1 )
    {
      return '100%'
    }
    else
    {
      if(i<10)
      {
        return (( +array[i-2].prezzo.replace(".", '').replace(",", '.').slice(0, -1) * 100)/ +this.sommaTotaleValoreCategoria(0)).toFixed(2) +'%'
      }
      else
      {
        return (( +array[i-3].prezzo.replace(".", '').replace(",", '.').slice(0, -1) * 100)/ +this.sommaTotaleValoreCategoria(1)).toFixed(2) +'%'
      }
    }
  }

  idPieChart = ''
  idChart = ''

  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog,
    public commonService: CommonService,     private dataServiceGenerico: DataServiceGenerico

  ){

    let current = new Date();
    let timestamp = current.getTime();
    let psw = timestamp.toString();
    this.idPieChart = '1pie' + psw;
    this.idChart = '1chart' + psw;


    this.columns = [{
      field: 'nome'
    }, {
      field: 'transazioni'
    }, {
      field: 'percentuale totale'
    }, {
      field: 'percentuale categoria'
    }, {
      field: 'scontrino medio'
    },{
      field: 'prezzo'
    }];
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['categoria'];


    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');

  }



  openDialog(): any {
    const modalRef = this.dialog.open(AggiuntaPiatto, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
    });

  }

  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }
  immagini = {}

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }

  ngOnInit(): void {

    this.immagini = this.dataServiceGenerico.getImmagini()



    this._alldata = [];
    this.costiOperativi.forEach((item, index) => {
      item['id'] = index + 1;
      item.menu.forEach(piatto => {
        piatto['categoria'] = item['categoria'];
        this._alldata.push(piatto)
      })
    });
    console.log("magazzino: ", this.menu)

    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    this.dataSource.filter = performance.now().toString();


    console.log('firebase: ', firebase);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveCarrello();
        this.userService.emitter.subscribe(
          () => {
            this.retriveCarrello();
          }
        );
      } else {
        // No user is signed in.
      }
    });

  }

  ngAfterViewInit() {
    this.createChart0();
  }

  menu = [];
  mostraImport = false;
  mostraExport = false;
  mostraCategorie = false;

  idRistorante = '';
  retriveCarrello(): void {

    console.log(firebase.auth().currentUser);
    console.log(firebase.auth().currentUser.uid);


    this.commonService.getRistorante().subscribe(
      (data) =>
      {
        console.log("ciaociao; ", data)
        if(data !== undefined)
        {
          this.idRistorante = data[0].hasOwnProperty('id')? data[0]['id']: '';

          this._alldata = [];
          this.costiOperativi.forEach((item, index) => {
            item['id'] = index + 1;
            item.menu.forEach(piatto => {
              piatto['categoria'] = item['categoria'];
              this._alldata.push(piatto)
            })
          });

          //this.menu = data[0].hasOwnProperty('menu')? data[0]['menu']: [];
          console.log("data: ", data[0]['menu'])
          console.log("magazzino: ", this.menu)

          this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
          this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
          this.dataSource.filter = performance.now().toString();


        }
      }
    );


    this.userService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
      data => {
        if (data.data() !== undefined) {

          this.user = data.data();

          console.log('menuCard: ', this.carrello);
          console.log('ho trovato2: ', data.data());

          this.carrelloTmp = data.data().carrello;

          console.log(this.carrelloTmp);

          this.calcolaTotale();


        }
      }
    );
  }


  salvaCategorie()
  {
    console.log("ristorante: ", this.menu)
    let newMenu = {
      menu: this.menu
    }
    console.log("ristorant2: ", newMenu)

    this.commonService.updateDati(this.idRistorante, newMenu)
  }

  salvaMenu()
  {
    console.log("ristorante: ", this.dataSource.data)
    let newDataSource = this.dataSource.data.filter(element => !element.hasOwnProperty('expanded'))
    console.log("ristorant2: ", newDataSource)

    let dataSourceDaSavlvare = [];
    newDataSource.forEach(element => {
      if(dataSourceDaSavlvare.some(elemento => elemento.categoria == element.categoria))
      {
        dataSourceDaSavlvare[dataSourceDaSavlvare.findIndex(elemento => elemento.categoria == element.categoria)].menu.push(element)
      }
      else
      {
        dataSourceDaSavlvare.push(
          {
            categoria: element.categoria,
            menu: [element]
          }
        )
      }
    })

    console.log("datasourcedasalvare: ", dataSourceDaSavlvare)


    let menuDaSalvare = {
      menu: dataSourceDaSavlvare
    }



    this.commonService.updateDati(this.idRistorante, menuDaSalvare)
  }

  calcolaTotale(): void {

    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {

          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);


    });
  }


  calcolaMancia(): number {


    return this.tot / 10;
  }

  calcolaPrezzo(piatto: any): number {

    let prezzoPiatto = piatto.prezzo;

    if (piatto.opzioni) {
      piatto.opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }
    return prezzoPiatto;

  }




  groupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, true);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  checkGroupByColumn(field, add ) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if ( add ) {
        this.groupByColumns.push(field);
      }
    }
  }

  unGroupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, false);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  // below is for grid row grouping
  customFilterPredicate(data: any | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter(
      row => {
        if (!(row instanceof Group)) {
          return false;
        }
        let match = true;
        this.groupByColumns.forEach(column => {
          if (!row[column] || !data[column] || row[column] !== data[column]) {
            match = false;
          }
        });
        return match;
      }
    );

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.dataSource.filter = performance.now().toString();  // bug here need to fix
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map(
        row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }
      ),
      JSON.stringify);

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }


  public chart: any;
  public pieChart: any = {};

  dataGrafici = {
    labels: ['Elettronici', 'Contanti'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [75, 25],
        backgroundColor: [
          '#F40027',
          '#000000',
          '#f1ebeb',
        ],
        hoverOffset: 4,
      },
    ],
  };

  config = {
  type: 'bar',
  data: [],
  options: {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true
      }
    }
  }
  };

  createChart0() {

    console.log("create chart")
    this.pieChart = new Chart(this.idPieChart, {
      type: 'doughnut',
      data: this.dataGrafici,
      options: {
      legend: {
        display: true,
        position: 'bottom'
      },
      cutoutPercentage: 75,
    }
    });

    this.chart = new Chart (this.idChart, {
      type: 'bar',
      data: this.dataStacked,
      options: {
        legend: {
          display: true,
          position: 'bottom'
        },
        responsive: true,
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
              color: "rgb(0,0,0)",
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              drawOnChartArea: false,
              color: "rgb(0,0,0)"
            }
          }]
        }
      }
    });
  }


  dataStacked = {
    labels: ['Elettronici', 'Contanti'],
    datasets: [
      {
        label: 'POS Cassa 1',
        data: [7000, 0],
        backgroundColor: '#F40027',
      },
      {
        label: 'POS Cassa 2',
        data: [7000, 0],
        backgroundColor: '#FF6666',
      },
      {
        label: 'POS Cassa 3',
        data: [7000, 0],
        backgroundColor: '#000000',
      },
      {
        label: 'POS Totem 1',
        data: [7000, 0],
        backgroundColor: '#CCCCCC',
      },
      {
        label: 'POS Totem 2',
        data: [7000, 0],
        backgroundColor: '#333333',
      },
      {
        label: 'POS Totem 3',
        data: [7000, 0],
        backgroundColor: '#f1ebeb',
      },


      {
        label: 'Cassa 1',
        data: [0, 3500],
        backgroundColor: '#f1ebeb',
      },
      {
        label: 'Cassa 2',
        data: [0, 3500],
        backgroundColor: '#f1ebeb',
      },
      {
        label: 'Cassa 3',
        data: [0, 3500],
        backgroundColor: '#f1ebeb',
      },
      {
        label: 'Totem 1',
        data: [0, 3500],
        backgroundColor: '#f1ebeb',
      },
    ]
  }


  leggiGroup(group: any)
  {
    console.log("group: ", group)
  }

}
