import { EventEmitter, Inject, Injectable, Optional } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import axios from 'axios';
import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

let ipcRenderer;
if (typeof window !== 'undefined' && window.require) {
  const electron = window.require('electron');
  ipcRenderer = electron.ipcRenderer;
}

@Injectable({
  providedIn: 'any'
})
export class UserDataService {
  private dbPath = '/users';
  private offlineMode: boolean = false;

  emitter = new EventEmitter();

  tutorialsRef: AngularFirestoreCollection<any[]>;

  constructor(private db: AngularFirestore,
              @Inject('ristorante') @Optional() private ristorante?: string,
              @Inject('raccolta') @Optional() private raccolta?: string) {
    this.tutorialsRef = db.collection(this.dbPath);

    db.collection(this.dbPath).valueChanges().subscribe((userData) => {

      this.emitter.emit();
    });

    if (ipcRenderer) {
      ipcRenderer.on('offline-mode', (event, mode) => {
        this.offlineMode = mode;
        console.log(`Offline mode set to: ${this.offlineMode}`);
      });
    }
  }

  private async getMongoData(endpoint: string) {
    try {
      const response = await axios.get(`http://localhost:3005/backup/${endpoint}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching data from MongoDB:`, error);
      throw error;
    }
  }

  private async postMongoData(endpoint: string, data: any) {
    try {
      const response = await axios.post(`http://localhost:3005/backup/${endpoint}`, data);
      return response.data;
    } catch (error) {
      console.error(`Error posting data to MongoDB:`, error);
      throw error;
    }
  }

  private async putMongoData(endpoint: string, data: any) {
    try {
      const response = await axios.put(`http://localhost:3005/backup/${endpoint}`, data);
      return response.data;
    } catch (error) {
      console.error(`Error putting data to MongoDB:`, error);
      throw error;
    }
  }

  private async deleteMongoData(endpoint: string) {
    try {
      const response = await axios.delete(`http://localhost:3005/backup/${endpoint}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting data from MongoDB:`, error);
      throw error;
    }
  }

  getAllOnline(): Observable<any[]> {
    return this.tutorialsRef.valueChanges();
  }

  getAllOffline(): Observable<any[]> {
    return from(this.getMongoData('users')).pipe(switchMap(data => of(data)));
  }

  getDoc(id: string): AngularFirestoreDocument<any> {
    if (this.offlineMode) {
      const document: any = {
        valueChanges: () => from(this.getMongoData('users')).pipe(
          switchMap(users => of(users.find(user => user._id === id)))
        )
      };
      return document;
    } else {
      return this.tutorialsRef.doc(id);
    }
  }

  getCollection(id: string): AngularFirestoreDocument<any> {
    return this.getDoc(id);
  }

  create(tutorial: any, id: string): Promise<void> {
    if (this.offlineMode) {
      return this.postMongoData(`users/${id}`, tutorial);
    } else {
      return this.tutorialsRef.doc(id).set({ ...tutorial });
    }
  }

  update(id: string, data: any): Promise<void> {
    if (this.offlineMode) {
      return this.putMongoData(`users/${id}`, data);
    } else {
      return this.tutorialsRef.doc(id).update(data);
    }
  }

  delete(id: string): Promise<void> {
    if (this.offlineMode) {
      return this.deleteMongoData(`users/${id}`);
    } else {
      return this.tutorialsRef.doc(id).delete();
    }
  }
}
