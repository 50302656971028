// pager-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-pager-dialog',
    styles: [`
        .conferma-button {
            width: 40%;
            border: none;
            border-radius: 20px;
            padding: 17px 10px;
            background-color: #36bfa3;
            font-size: 17px;
            font-weight: 600;
            text-align: center;
            margin-top: 30px;
            cursor: pointer;
            color: white;
        }

        .conferma-button:hover {
            background-color: #24937c;
        }

        .annulla-button {
            width: 40%;
            border-radius: 20px;
            padding: 17px 10px;
            border: #ff0000b3;
            font-size: 17px;
            font-weight: 600;
            text-align: center;
            margin-top: 30px;
            cursor: pointer;
        }

        .annulla-button:hover {
            background-color: #ff0000b3;
        }

    `],
    template: `
    <div class="p-6">
      <h2 class="text-xl font-bold mb-4" style="display: flex; justify-content: center">Assegna Pager</h2>
      
      <div class="mb-4" style="display: flex; justify-content: center">
        <label class="block text-sm font-medium mb-2" style="padding: 5px 0px;">Numero Pager</label>
        <input 
          type="number" 
          [(ngModel)]="pagerNumber" 
          class="w-full p-2 border rounded-lg"
          style=" padding: 5px 5px; margin-left: 15px; width: 30%;"
          min="1"
          max="999">
      </div>
      
      <div  style="display: flex; justify-content: space-between">
        <button 
          class="annulla-button"
          (click)="onCancel()">
          Annulla
        </button>
        <button 
          class="conferma-button"
          
          (click)="onConfirm()"
          [disabled]="!pagerNumber">
          Conferma
        </button>
      </div>
    </div>
  `
})
export class PagerDialogComponent {
    pagerNumber: number;

    constructor(
        public dialogRef: MatDialogRef<PagerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    onConfirm(): void {
        if (this.pagerNumber) {
            this.dialogRef.close(this.pagerNumber);
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}