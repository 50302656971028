<div class="row">
    <div class="column">
        <div class="back-container">
            <div class="back-icon">
                <categorieCard class="col-12 col-md-12 menuItem"
                               [link]="'assets/icons/icons8-back-arrow-96.png'"
                               [border]="'#e55933'"
                               (click)="indietro();">
                </categorieCard>
            </div>
            <div class="receipt-card">
                <receiptOrderCard1
                        [titolo]="getTitoloOrdine()"
                        [stato]="currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? 'Pagato': 'Non Pagato': 'Non Pagato'"
                        [fonte]="currentOrder?.fonte">
                </receiptOrderCard1>
            </div>
        </div>

        <div class="container">
            <p class="order-details">Dettagli Ordinazione: <b>{{getElapsedTime(currentOrder?.data, currentOrder?.closureOrderTime)}}</b></p>

            <!--div class="row1">
                <div class="slider" [style.background-color]="portata == 0? 'rgba(181,181,181,0.2)': 'transparent'" (click)="portata = 0">
                    <div>
                        <div>1</div>
                    </div>
                </div>
                <div class="slider" [style.background-color]="portata == 1? 'rgba(181,181,181,0.2)': 'transparent'" (click)="portata = 1">
                    <div>
                        <div>2</div>
                    </div>
                </div>
                <div class="slider" [style.background-color]="portata == 2? 'rgba(181,181,181,0.2)': 'transparent'" (click)="portata = 2">
                    <div>
                        <div>3</div>
                    </div>
                </div>
                <div class="slider" [style.background-color]="portata == 3? 'rgba(181,181,181,0.2)': 'transparent'" (click)="portata = 3">
                    <div>
                        <div>4</div>
                    </div>
                </div>
                <div class="slider" [style.background-color]="portata == 4? 'rgba(181,181,181,0.2)': 'transparent'" (click)="portata = 4">
                    <div>
                        <div>Tutto</div>
                    </div>
                </div>
            </div-->

            <div class="order-items-container">
                <div class="ciaociao" *ngFor="let piatto of getPropertiesPortata(currentOrder?.carrello); let last = last; let first = first">
                    <menuDetailCard-bar
                            [nome]="currentOrder?.carrello[piatto]['title']"
                            [ingredienti]="currentOrder?.carrello[piatto]['category']"
                            [prezzo]="getPrezzoPiatto(currentOrder?.carrello[piatto])"
                            [quantita]="currentOrder?.carrello[piatto].quantita"
                            [id]="currentOrder.ordine"
                            [pagato]="currentOrder.statoPagato"
                            [carrello]="true"
                            [last]="last"
                            [first]="first"
                            [lenght]="getProperties(currentOrder?.carrello).length"
                            [link]="currentOrder?.carrello[piatto].image_link"
                            [aggiunte]="currentOrder?.carrello[piatto].aggiunte"
                            [rimozioni]="currentOrder?.carrello[piatto].rimozioni"
                            [nota]="currentOrder?.carrello[piatto].note"
                            [variants]="currentOrder?.carrello[piatto].variants"
                            [isPaidQuantity]="currentOrder?.carrello[piatto].isPaidQuantity"
                            (quantitaOut)="handleEvent($event, currentOrder?.carrello[piatto], piatto)"
                            (deleteItem)="handleDeleteItem(piatto)"
                            [hasRequiredVariants]="hasRequiredVariants(currentOrder?.carrello[piatto])"
                            (openVariants)="apriVarianti(currentOrder?.carrello[piatto], piatto)"
                            (checkLastProduct)="checkIfLastProduct(piatto, $event, variabile_eliminazione)">
                    </menuDetailCard-bar>
                </div>
            </div>
        </div>

        <div class="container2NotScrolled">
            <div>
                <p class="nota" *ngIf="currentOrder?.nota"><b>Nota:</b>{{currentOrder?.nota}}</p>
                <p class="nota" *ngIf="currentOrder?.motivazione"><b>Motivo eliminazione:</b>{{currentOrder?.motivazione}}</p>

                <div class="divider"></div>

                <div class="total">Totale:  {{calcolaTotale().toFixed(2)}}€</div>
            </div>

            <div class="actions">
                <categorieCard class="menuItem action-card" *ngIf="currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? false: true: true"
                               [nome]="'Aggiungi nota'"
                               [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2YPWsVQRSG149GBb/+gKVYW5rCwtbKQlGw0H8hyDYWam/wNyzBC/O8cz+8hKt7tbKxC4FUfoEQiI1gRN0wMBcuMYTNruPODfPCsLNTHM6zc87MOZtlSQsi4LWkNeCuG24ewfurA4NIqmIcWQuQGHZirTVIFomUQJR2JIiUQkuHPLQkTVveBdMaNqeLAFLWsFkGB+lKSiBKOxJEiiDZ/zqBFvXUKhuAlMFBupISiNKOBJEiSPZUa80rJbtSsoeR0j2iVGtVIWotYAW49f9iMjYpMpDBYHAReA6sA98kfZb0whhzvaqqIwsBIuk+8NP79EvSpqTfc6Foogex1t4A/nh/Hks659aHw+F54CHwwz0PDAK8nXVwIebz6vf7p4Gv3pcn2R6y1l4ZjUansobHbxlqvjuknA/AF2PMyayJYggtYNWDPG1spEsQ4F7NluC7y5WYQd7VAQGeRR9axpilmQ+TyeR4Y0MxgRRFcaKxoZQj/0AcphxxkvR+v8uwlmIAAR55Pz4URXGskZGWv3xaDWDV+3BB0rZfz/fys9frnZV0ab+v8aZLGEmXPUw+q3qBB+Px+Ixbd6U7cBXYAO5ksSvP86OSlndBfpK0NXu31t7u2s/aknTNtbu+odoGPgIvjTE33YW5Ax46bBsi/MF9AAAAAElFTkSuQmCC'"
                               altezza="65"
                               (click)="aggiungiNota()">
                </categorieCard>

                <!--categorieCard class="menuItem action-card" *ngIf="currentOrder?.statoPagato !== undefined ? currentOrder?.statoPagato == 0 ? false : true : true"
                               [nome]="'Preconto'"
                               [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2YPWsVQRSG149GBb/+gKVYW5rCwtbKQlGw0H8hyDYWam/wNyzBC/O8cz+8hKt7tbKxC4FUfoEQiI1gRN0wMBcuMYTNruPODfPCsLNTHM6zc87MOZtlSQsi4LWkNeCuG24ewfurA4NIqmIcWQuQGHZirTVIFomUQJR2JIiUQkuHPLQkTVveBdMaNqeLAFLWsFkGB+lKSiBKOxJEiiDZ/zqBFvXUKhuAlMFBupISiNKOBJEiSPZUa80rJbtSsoeR0j2iVGtVIWotYAW49f9iMjYpMpDBYHAReA6sA98kfZb0whhzvaqqIwsBIuk+8NP79EvSpqTfc6Foogex1t4A/nh/Hks659aHw+F54CHwwz0PDAK8nXVwIebz6vf7p4Gv3pcn2R6y1l4ZjUansobHbxlqvjuknA/AF2PMyayJYggtYNWDPG1spEsQ4F7NluC7y5WYQd7VAQGeRR9axpilmQ+TyeR4Y0MxgRRFcaKxoZQj/0AcphxxkvR+v8uwlmIAAR55Pz4URXGskZGWv3xaDWDV+3BB0rZfz/fys9frnZV0ab+v8aZLGEmXPUw+q3qBB+Px+Ixbd6U7cBXYAO5ksSvP86OSlndBfpK0NXu31t7u2s/aknTNtbu+odoGPgIvjTE33YW5Ax46bBsi/MF9AAAAAElFTkSuQmCC'"
                               altezza="65"
                               (click)="printReceipt('nonAprire')">
                </categorieCard-->

                <!--categorieCard class="menuItem action-card"
                               [nome]="'Invia Comanda'"
                               [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2YPWsVQRSG149GBb/+gKVYW5rCwtbKQlGw0H8hyDYWam/wNyzBC/O8cz+8hKt7tbKxC4FUfoEQiI1gRN0wMBcuMYTNruPODfPCsLNTHM6zc87MOZtlSQsi4LWkNeCuG24ewfurA4NIqmIcWQuQGHZirTVIFomUQJR2JIiUQkuHPLQkTVveBdMaNqeLAFLWsFkGB+lKSiBKOxJEiiDZ/zqBFvXUKhuAlMFBupISiNKOBJEiSPZUa80rJbtSsoeR0j2iVGtVIWotYAW49f9iMjYpMpDBYHAReA6sA98kfZb0whhzvaqqIwsBIuk+8NP79EvSpqTfc6Foogex1t4A/nh/Hks659aHw+F54CHwwz0PDAK8nXVwIebz6vf7p4Gv3pcn2R6y1l4ZjUansobHbxlqvjuknA/AF2PMyayJYggtYNWDPG1spEsQ4F7NluC7y5WYQd7VAQGeRR9axpilmQ+TyeR4Y0MxgRRFcaKxoZQj/0AcphxxkvR+v8uwlmIAAR55Pz4URXGskZGWv3xaDWDV+3BB0rZfz/fys9frnZV0ab+v8aZLGEmXPUw+q3qBB+Px+Ixbd6U7cBXYAO5ksSvP86OSlndBfpK0NXu31t7u2s/aknTNtbu+odoGPgIvjTE33YW5Ax46bBsi/MF9AAAAAElFTkSuQmCC'"
                               altezza="65"
                               (click)="testComande()">
                </categorieCard-->

                <categorieCard class=" menuItem action-card" *ngIf="!currentOrder?.pager"
                               [nome]="'Assegna Pager'"
                               [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ficons8-circle-100.png?alt=media&token=44602582-f81c-4452-9f5d-06b816574eab'"
                               altezza="65"
                               (click)="assignPager()">
                </categorieCard>

                <categorieCard class=" menuItem action-card" *ngIf="currentOrder?.pager"
                               [nome]="'Chiama pager: '+currentOrder.pager"
                               [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ficons8-circle-100.png?alt=media&token=44602582-f81c-4452-9f5d-06b816574eab'"
                               altezza="65">
                </categorieCard>

                <categorieCard class=" menuItem action-card" *ngIf="currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? false: true: true"
                               [nome]="'Fidelity'"
                               [link]="'assets/icons/icons8-loyalty-90.png'"
                               border="#cda434"
                               altezza="65"
                               (click)="openDialogFidelity()">
                </categorieCard>

                <categorieCard class=" menuItem action-card" *ngIf="currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? false: true: true"
                               [nome]="'Apri cassetto'"
                               [link]="'assets/images/icons8-drawer-100 (1).png'"
                               altezza="65"
                               (click)="apricassetto()">
                </categorieCard>

                <!--categorieCard class=" menuItem action-card" *ngIf="camerieri[getIndex()]?.statoPagato !== undefined? camerieri[getIndex()].statoPagato == 0? false: true: true"
                               [nome]="'Fidelity'"
                               [link]="'assets/icons/icons8-loyalty-90.png'"
                               [bg]="'rgb(52, 152, 219)'"
                               altezza="65"
                               (click)="openDialogFidelity()">
                </categorieCard-->

                <categorieCard
                        class=" menuItem action-card"
                        *ngIf="currentOrder?.statoPagato !== undefined ? currentOrder?.statoPagato == 0 ? false : true : true"
                        [bg]="'rgba(255,0,0,0.8)'"
                        (click)="eliminaOrdine(currentOrder?.ordine, variabile_eliminazione)"
                        [nome]="'Elimina'"
                        [link]="'assets/icons/icons8-delete-96.png'"
                        altezza="65">
                </categorieCard>

                <categorieCard class="menuItem action-card" *ngIf="currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? false: true: true"
                               [nome]="'Pagamento'"
                               [link]="'assets/icons/icons8-cash-100.png'"
                               [bg]="'#34bfa3'"
                               altezza="65"
                               (click)="openDialogPagamento()">
                </categorieCard>

                <categorieCard class="menuItem action-card" *ngIf="!(currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? false: true: true)"
                               [nome]="'Fatturazione'"
                               [link]="'assets/icons/icons8-cash-100.png'"
                               [bg]="'#34bfa3'"
                               altezza="65"
                               (click)="openDialogFatturazione()">
                </categorieCard>
            </div>
        </div>

        <!--div class="container2NotScrolled">
          <div>
            <dettaglioScontrinoFinaleCard [nome]="this.nome2" [luogo]="this.luogo" [costo]="calcolaMancia()" [percentuale]="this.percentuale2"
            style="border-bottom-right-radius: 20px; border-bottom-left-radius: 20px"></dettaglioScontrinoFinaleCard>
          </div>
        </div-->

    </div>

    <div class="column2">
        <div class="categories-container">
            <div class="category-item" [class.active]="sezione == -1" (click)="sezione = -1">
                <div class="category-content">
                    <img class="category-icon" src="assets/icons/icons8-heart-100.png" alt="Favorites">
                    <div class="category-title">Preferiti</div>
                </div>
            </div>

            <ng-container *ngFor="let element of menu; let i = index">
                <div class="category-item" [class.active]="sezione == i" (click)="sezione = i" *ngIf="hasCategoryItems(element.menu)">
                    <div class="category-content">
                        <img class="category-icon" [src]="imagePaths[element.menu[0]['title']] | async" [alt]="element.menu[0]['category']">
                        <div class="category-title">{{element.menu[0]['category']}}</div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="menu-items-container" *ngIf="sezione > -1">
            <div class="menu-items" >
            <div *ngFor="let piatto of getFilteredMenuItems(menu[sezione]?.menu), let i = index, trackBy: trackByFn">
                <div class="menu-item" [class.selected]="getQuantita(piatto) > 0" (click)="updateOrdine(piatto);">
                    <div class="quantity-badge" *ngIf="getQuantita(piatto) > 0">{{getQuantita(piatto)}}</div>
                    <div class="menu-image-container">
                        <img [src]="imagePaths[piatto.title] | async" class="menu-item-image">
                    </div>
                    <div class="menu-item-details">
                        <span class="menu-item-title">{{piatto.title}}</span>
                        <div class="menu-item-price">
                            <div class="price-tag">{{getPriceFromPriceList(piatto)}}€</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div class="menu-items-container" *ngIf="sezione == -1">
            <div class="menu-items" >
                <div *ngFor="let piatto of user?.preferiti, let i = index">
                    <div class="menu-item" [class.selected]="getQuantita(piatto) > 0" (click)="updateOrdine(piatto);">
                        <div class="quantity-badge" *ngIf="getQuantita(piatto) > 0">{{getQuantita(piatto)}}</div>
                        <div class="menu-image-container">
                            <img [src]="imagePaths[piatto.title] | async" class="menu-item-image">
                        </div>
                        <div class="menu-item-details">
                            <span class="menu-item-title">{{piatto.title}}</span>
                            <div class="menu-item-price">
                                <div class="price-tag">{{(+piatto.price).toFixed(2)}}€</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-numeric-keypad #keypad (valueEntered)="onKeypadValue($event)" [reset]="resetCounter"></app-numeric-keypad>
    </div>
</div>