<div class="dashboard-container">
    <!-- Stats Grid -->
    <div class="stats-grid">
        <div class="stats-section">
            <!-- Revenue Stats -->
            <div class="stat-card">
                <h3 class="stat-title">Fatturato Mese Precedente</h3>
                <p class="stat-value">€{{monthlyStats.lastMonth | number:'1.2-2'}}</p>
            </div>

            <div class="stat-card">
                <h3 class="stat-title">Fatturato Mese Attuale</h3>
                <p class="stat-value">€{{monthlyStats.currentMonth | number:'1.2-2'}}</p>
                <div class="trend-indicator" [class.positive]="monthlyStats.percentageChange >= 0" [class.negative]="monthlyStats.percentageChange < 0">
                    <i class="material-icons">
                        {{monthlyStats.percentageChange >= 0 ? 'trending_up' : 'trending_down'}}
                    </i>
                    <span>{{monthlyStats.percentageChange | number:'1.1-1'}}%</span>
                </div>
            </div>

            <div class="stat-card">
                <h3 class="stat-title">Proiezione Mensile</h3>
                <p class="stat-value">€{{monthlyStats.projected | number:'1.2-2'}}</p>
            </div>
        </div>

        <!-- Promo Card -->
        <div class="promo-card">
            <div class="promo-content">
                <h3>Scopri le Nuove Funzionalità</h3>
                <p>Ottimizza il tuo business con strumenti avanzati</p>
                <button class="promo-button" (click)="this.router.navigate(['/stats'])">Scopri di più</button>
            </div>
        </div>
    </div>

    <!-- Chart and Notifications Grid -->
    <div class="chart-notifications-grid">
        <!-- Chart Card -->
        <div class="chart-card">
            <h3 class="section-title">Andamento Fatturato</h3>
            <div class="chart-container">
                <canvas #revenueChart></canvas>
            </div>
        </div>

        <!-- Notifications Section -->
        <div class="notifications-section">
            <h2 class="section-title">Notifiche</h2>
            <div class="notifications-list">
                <!-- Single Notification -->
                <div class="notification-item">
                    <div class="notification-avatar warning">
                        <i class="material-icons">warning</i>
                    </div>
                    <div class="notification-content">
                        <h3>Scadenza Prodotti</h3>
                        <span class="notification-meta">@inventory</span>
                    </div>
                    <div class="notification-indicator warning">!</div>
                </div>

                <div class="notification-item">
                    <div class="notification-avatar info">
                        <i class="material-icons">info</i>
                    </div>
                    <div class="notification-content">
                        <h3>Suggerimento Menu</h3>
                        <span class="notification-meta">@menu</span>
                    </div>
                    <div class="notification-indicator info">i</div>
                </div>

                <div class="notification-item">
                    <div class="notification-avatar alert">
                        <i class="material-icons">notification_important</i>
                    </div>
                    <div class="notification-content">
                        <h3>Alert Magazzino</h3>
                        <span class="notification-meta">@storage</span>
                    </div>
                    <div class="notification-indicator alert">!</div>
                </div>

                <button class="view-more">
                    Scopri di più
                    <i class="material-icons">chevron_right</i>
                </button>
            </div>
        </div>
    </div>

    <!-- Channels Section -->
    <div class="channels-section">
        <div class="channels-layout">
            <!-- Left side info -->
            <div class="channels-info">
                <h2>Top prodotti</h2>
                <p>I <strong>prodotti più venduti</strong> dell'anno in corso.</p>
            </div>

            <!-- Right side cards -->
            <div class="channels-grid">
                <!-- Product Cards -->
                <div class="channel-card" *ngFor="let product of topProducts">
                    <div class="channel-icon">
                        <img [src]="product.image_link || 'assets/images/placeholder.png'" [alt]="product.title">
                    </div>
                    <div class="channel-content">
                        <span class="channel-name">{{product.title}}</span>
                        <span class="channel-handle">€{{product.revenue | number:'1.2-2'}}</span>
                        <div class="channel-stats positive">
                            {{product.quantity}}x venduti
                        </div>
                    </div>
                </div>

                <!-- Loading State -->
                <div class="loading-state" *ngIf="loading">
                    <div class="spinner"></div>
                </div>

                <!-- Error State -->
                <div class="error-state" *ngIf="error">
                    <p>Non è stato possibile caricare i dati</p>
                </div>

                <!-- Full Stats Card -->
                <div class="full-stats-card" (click)="this.router.navigate(['/productstats'])">
                    <span style="text-align: center;">Statistiche Complete</span>
                    <i class="material-icons">arrow_forward</i>
                </div>
            </div>
        </div>
    </div>

    <!-- AI Suggestion -->
    <!--div class="ai-card">
        <div class="ai-header">
            <div class="ai-icon">
                <i class="material-icons">lightbulb</i>
            </div>
            <h3>Suggerimento AI</h3>
        </div>
        <p class="ai-content">
            Crea un menu combinato Caffè + Croissant per aumentare l'upselling.
            Basandoci sui dati, questa combinazione potrebbe incrementare le vendite del 15%.
        </p>
    </div-->
</div>