import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

/**
 * This component allows dynamic background, border, height, and content management.
 * The image will be centered horizontally if 'nome' is empty.
 */
@Component({
  selector: 'categorieCard',
  styles: [`
    .card-block {
      width: 100%;
      margin: 0;
      display: inline-flex;
      background: white;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      align-items: center;
      font-weight: 500;
      color: black;
      border-radius: 20px;
      border: solid 1px #e3e2e2;
    }

    .card-icon {
      background-size: 5% auto;
      height: 40px;
      width: 40px;
      display: inline-block;
      transform: scale(0.7);
    }

    .icon-filtered {
      filter: brightness(0) invert(1);
    }

    .icon-centered {
      position: relative;
      left: 5%;
      transform: translateX(-50%);
    }

    .icon-offset {
      position: relative;
      margin-left: 10px;
    }

    .card-text {
      padding: 0 10px;
      max-width: 80%;
      font-family: 'Inter', sans-serif;
      font-weight: 500;
    }
  `],
  template: `
    <div class="card-block"
         [ngStyle]="{
           'border': bg ? 'solid 1px ' + bg : (border ? 'solid 1px ' + border : null),
           'background-color': bg ? bg : null,
           'height': altezza ? altezza+'px' : '65px',
           'justify-content': (nome && nome !== '') ? 'left' : 'center'
         }">
      <img class="card-icon"
           [ngClass]="{
             'icon-filtered': bg,
             'icon-offset': nome && nome !== ''
           }"
           [src]="link">
      <div *ngIf="nome && nome !== ''"
           class="card-text"
           [ngStyle]="{'color': bg ? 'white' : (border ? border : 'black')}">
        {{ nome }}
      </div>
    </div>
  `
})
export class CategorieCard {
  @Input() nome: string;
  @Input() link: string;
  @Input() border: string;
  @Input() bg: string;
  @Input() altezza: string;  // Nuovo input per l'altezza dinamica



  constructor(private router: Router) {

  }

  clicco(): void {
    console.log('Clicked on', this.nome);
  }

  logout(): void {
    this.router.navigateByUrl('login');
  }
}
