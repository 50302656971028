import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit} from '@angular/core';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import firebase from 'firebase/app';

@Component({
  selector: 'edit-magazzino',
  templateUrl: './edit-magazzino.html',
  styleUrls: ['./edit-magazzino.css']
})
export class EditMagazzino implements OnChanges, OnInit {
  @Input() item: any;
  @Output() itemUpdated = new EventEmitter<any>();
  @Output() itemDeleted = new EventEmitter<void>();

  // Form fields
  Titolo: string = '';
  Categoria: string = '';
  selectedImage: string = '';
  Prezzo: string = '';
  Quantita: string = '';
  Fornitore: string = '';
  CodiceFornitore: string = '';
  CodiceProdottoInterno: string = '';
  expiryDate: string = '';
  isGenericoChecked= false;
  isIngredienteChecked = false;
  UM: string = '';
  nonGenericProducts: any[] = [];
  prodottiAssociati: string[] = [];

  // Modal states
  aggiunta: boolean = true;
  inventario: boolean = false;
  spreco: boolean = false;
  trasferimento: boolean = false;

  // Packaging levels
  levels: { quantita: string, um: string, barCode: string }[] = [
    { quantita: '', um: '', barCode: '' },
    { quantita: '', um: '', barCode: '' },
    { quantita: '', um: '', barCode: '' },
    { quantita: '', um: '', barCode: '' }
  ];

  // Level visibility flags
  isLvl1: boolean = true;
  isLvl2: boolean = false;
  isLvl3: boolean = false;
  isLvl4: boolean = false;

  maxLvl: number = 1; // Default a 1 livello

  // Categories array
  categories: string[] = [];

  constructor(
      private dataService: DataServiceGenerico,
      private snackBar: MatSnackBar
  ) {
    this.fetchCategories();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && changes.item.currentValue) {
      this.initializeFormData(changes.item.currentValue);
    }
  }

  ngOnInit() {
    this.fetchNonGenericProducts();

  }

  private fetchNonGenericProducts() {
    const restaurantName = this.dataService.getRistorante();
    firebase.firestore()
        .collection('magazzino_test')
        .doc(restaurantName)
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            const items = data?.items || [];

            // Filtra solo i prodotti non generici
            this.nonGenericProducts = items.filter(item => !item.generico);
          }
        });
  }

  private fetchCategories() {
    const restaurantName = this.dataService.getRistorante();
    firebase.firestore()
        .collection('magazzino_test')
        .doc(restaurantName)
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            const items = data?.items || [];
            const categoriesSet = new Set<string>();

            items.forEach(item => {
              if (item.Categoria) {
                categoriesSet.add(item.Categoria);
              }
            });

            this.categories = Array.from(categoriesSet);
          }
        });
  }

  onMenuChangeLevel1() {
    this.maxLvl = 1;
    this.isLvl1 = true;
    this.isLvl2 = false;
    this.isLvl3 = false;
    this.isLvl4 = false;

    // Assicurati che l'oggetto per il primo livello esista
    this.levels[0] = this.levels[0] || { quantita: '', um: '', barCode: '' };

    // Resetta i livelli successivi
    for (let i = 1; i < 4; i++) {
      this.levels[i] = { quantita: '', um: '', barCode: '' };
    }
  }

  onMenuChangeLevel2() {
    this.maxLvl = 2;
    this.isLvl1 = false;
    this.isLvl2 = true;
    this.isLvl3 = false;
    this.isLvl4 = false;

    // Assicurati che gli oggetti per i primi due livelli esistano
    for (let i = 0; i < 2; i++) {
      this.levels[i] = this.levels[i] || { quantita: '', um: '', barCode: '' };
    }

    // Resetta i livelli successivi
    for (let i = 2; i < 4; i++) {
      this.levels[i] = { quantita: '', um: '', barCode: '' };
    }
  }

  onMenuChangeLevel3() {
    this.maxLvl = 3;
    this.isLvl1 = false;
    this.isLvl2 = false;
    this.isLvl3 = true;
    this.isLvl4 = false;

    // Assicurati che gli oggetti per i primi tre livelli esistano
    for (let i = 0; i < 3; i++) {
      this.levels[i] = this.levels[i] || { quantita: '', um: '', barCode: '' };
    }

    // Resetta l'ultimo livello
    this.levels[3] = { quantita: '', um: '', barCode: '' };
  }

  onMenuChangeLevel4() {
    this.maxLvl = 4;
    this.isLvl1 = false;
    this.isLvl2 = false;
    this.isLvl3 = false;
    this.isLvl4 = true;

    // Assicurati che tutti e quattro i livelli esistano
    for (let i = 0; i < 4; i++) {
      this.levels[i] = this.levels[i] || { quantita: '', um: '', barCode: '' };
    }
  }

  private initializeFormData(itemData: any) {
    this.Titolo = itemData.Titolo || '';
    this.Categoria = itemData.Categoria || '';
    this.selectedImage = itemData.image_link || '';
    this.Prezzo = itemData.Prezzo || '';
    this.Quantita = itemData.Quantita || '';
    this.Fornitore = itemData.Fornitore || '';
    this.CodiceFornitore = itemData.CodiceFornitore || '';
    this.CodiceProdottoInterno = itemData.CodiceProdottoInterno || '';
    this.isIngredienteChecked = itemData.ingrediente || false;
    this.isGenericoChecked = itemData.generico || false;
    this.expiryDate = itemData.Scadenza || '';
    this.UM = itemData.UM || '';

    // Carica i prodotti associati se il prodotto è generico
    this.prodottiAssociati = itemData.prodottiAssociati || [];

    // Initialize packaging levels
    if (itemData.lvl) {
      this.levels = itemData.lvl.map((level, index) => ({
        quantita: level.quantita || '',
        um: level.um || '',
        barCode: level.barCode || ''
      }));
    }

    // Set level visibility based on maxLvl
    const maxLvl = itemData.maxLvl || 1;
    this.isLvl1 = maxLvl >= 1;
    this.isLvl2 = maxLvl >= 2;
    this.isLvl3 = maxLvl >= 3;
    this.isLvl4 = maxLvl >= 4;
  }


  getMaxLevel(): number {
    if (this.isLvl4) return 4;
    if (this.isLvl3) return 3;
    if (this.isLvl2) return 2;
    return 1;
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const storageRef = firebase.storage().ref();
      const filePath = `immaginiMagazzino/${Date.now()}_${file.name}`;

      storageRef.child(filePath).put(file)
          .then(snapshot => snapshot.ref.getDownloadURL())
          .then(url => {
            this.selectedImage = url;
            this.snackBar.open('Immagine caricata con successo', 'Chiudi', { duration: 2000 });
          })
          .catch(error => {
            console.error('Error uploading image:', error);
            this.snackBar.open('Errore nel caricamento dell\'immagine', 'Chiudi', { duration: 3000 });
          });
    }
  }

  removeImage() {
    this.selectedImage = '';
  }

  calculateNewQuantity(currentQuantity: string, addedQuantity: string, operation: string): number {
    const current = parseInt(currentQuantity || '0', 10);
    const added = parseInt(addedQuantity || '0', 10);

    switch (operation) {
      case 'aggiunta':
        return current + added;
      case 'spreco':
      case 'trasferimento':
        return current - added;
      case 'inventario':
        return added;
      default:
        return current;
    }
  }

  saveChanges() {
    if (!this.Titolo) {
      this.snackBar.open('Il nome del prodotto è obbligatorio', 'Chiudi', { duration: 3000 });
      return;
    }

    const operationType = this.aggiunta ? 'aggiunta' :
        this.spreco ? 'spreco' :
            this.inventario ? 'inventario' :
                this.trasferimento ? 'trasferimento' : 'aggiunta';

    let totalQuantityChange = 0;
    const maxLevel = this.getMaxLevel();

    // Calculate quantity changes based on levels
    for (let i = 0; i < maxLevel; i++) {
      const levelQuantity = parseInt(this.levels[i].quantita || '0', 10);
      if (i === 0) {
        totalQuantityChange = levelQuantity;
      } else {
        totalQuantityChange *= levelQuantity;
      }
    }

    const updatedItem = {
      id: this.item?.id || firebase.firestore().collection('temporary').doc().id,
      Titolo: this.Titolo,
      Categoria: this.Categoria,
      image_link: this.selectedImage,
      Prezzo: this.Prezzo,
      Quantita: this.calculateNewQuantity(
          this.item?.Quantita || '0',
          totalQuantityChange.toString(),
          operationType
      ).toString(),
      Fornitore: this.Fornitore,
      CodiceFornitore: this.CodiceFornitore,
      Scadenza: this.expiryDate,
      CodiceProdottoInterno: this.CodiceProdottoInterno,
      generico: this.isGenericoChecked,
      ingrediente: this.isIngredienteChecked,
      UM: this.UM,
      lvl: this.levels.slice(0, maxLevel),
      maxLvl: this.maxLvl,
      // Aggiungi i prodotti associati solo se il prodotto è generico
      prodottiAssociati: this.isGenericoChecked ? this.prodottiAssociati : [],
      cronologia: [
        ...(this.item?.cronologia || []),
        {
          data: new Date().toLocaleString(),
          quantitaPrec: this.item?.Quantita || '0',
          quantitaAgg: totalQuantityChange.toString(),
          lvl: this.levels.slice(0, maxLevel),
          modalita: operationType
        }
      ]
    };

    const restaurantName = this.dataService.getRistorante();
    const magazzinoRef = firebase.firestore()
        .collection('magazzino_test')
        .doc(restaurantName);

    magazzinoRef.get().then(doc => {
      if (doc.exists) {
        const items = doc.data()?.items || [];
        let updatedItems: any[];

        if (this.item?.id) {
          updatedItems = items.map(item =>
              item.id === this.item.id ? updatedItem : item
          );
        } else {
          updatedItems = [...items, updatedItem];
        }

        return magazzinoRef.set({
          items: updatedItems,
          ristorante: restaurantName
        })
            .then(() => {
              this.snackBar.open('Modifiche salvate con successo', 'Chiudi', { duration: 2000 });
              this.itemUpdated.emit(updatedItem);
            });
      } else {
        return magazzinoRef.set({
          items: [updatedItem],
          ristorante: restaurantName
        })
            .then(() => {
              this.snackBar.open('Elemento creato con successo', 'Chiudi', { duration: 2000 });
              this.itemUpdated.emit(updatedItem);
            });
      }
    })
        .catch(error => {
          console.error('Error saving changes:', error);
          this.snackBar.open('Errore nel salvataggio delle modifiche', 'Chiudi', { duration: 3000 });
        });
  }

  deleteItem() {
    if (!this.item?.id) {
      this.itemDeleted.emit();
      return;
    }

    const restaurantName = this.dataService.getRistorante();
    const magazzinoRef = firebase.firestore()
        .collection('magazzino_test')
        .doc(restaurantName);

    magazzinoRef.get().then(doc => {
      if (doc.exists) {
        const items = doc.data()?.items || [];
        const updatedItems = items.filter(item => item.id !== this.item.id);

        magazzinoRef.update({ items: updatedItems })
            .then(() => {
              this.snackBar.open('Elemento eliminato con successo', 'Chiudi', { duration: 2000 });
              this.itemDeleted.emit();
            })
            .catch(error => {
              console.error('Error deleting item:', error);
              this.snackBar.open('Errore nell\'eliminazione dell\'elemento', 'Chiudi', { duration: 3000 });
            });
      }
    });
  }
}