<div class="row" style="height: 95vh; width:calc(60vw); margin-top: 15px; border-radius: 20px; overflow-y: scroll">
    <!-- Sezione Abilita Fidelity -->
    <div style="">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Fidelity</div>
        <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <form [formGroup]="fidelityForm">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="">
                            <label class="container">Permetti fidelity
                                <input type="checkbox" formControlName="fidelityEnabled">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);" (click)="saveFidelitySettings()">
                    Salva Modifiche
                </div>
            </form>
        </div>
    </div>

    <!-- Sezione Punti -->
    <div style="">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Punti</div>
        <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <form [formGroup]="fidelityForm">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                            <label class="font-bold text-gray-700 text-heading-sm">Punti per ogni euro speso</label>
                            <input type="number" formControlName="puntiPerEuro">
                        </div>
                    </div>
                </div>

                <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);" (click)="saveFidelitySettings()">
                    Salva Modifiche
                </div>
            </form>
        </div>
    </div>

    <!-- Sezione Sconti -->
    <div style="margin-bottom: 100px">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Sconti</div>
        <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
                <div style="display: flex; gap: 10px; margin: 0 1%;">
                    <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                   (click)="addSconto()"
                                   [nome]="'Aggiungi Sconto'"
                                   altezza="50"
                                   [link]="'assets/icons/icons8-add-90.png'">
                    </categorieCard>
                    <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                   (click)="addAccountSconto()"
                                   [nome]="'Sconto Account'"
                                   altezza="50"
                                   [link]="'assets/icons/icons8-add-90.png'">
                    </categorieCard>
                </div>
            </div>

            <div class='table-responsive' style="margin-top: 20px; margin-left: 1%; width: 98%; overflow-y: scroll; border-radius: 20px; border: 1px solid #e3e2e2;">
                <table mat-table id="tabellaDipendenti2" matSortActive="nome" [dataSource]="sconti" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">
                    <ng-container matColumnDef="nome" style="width: 25%">
                        <th mat-header-cell *matHeaderCellDef> Nome </th>
                        <td mat-cell *matCellDef="let sconto"> {{sconto.nome}} </td>
                    </ng-container>

                    <ng-container matColumnDef="punti" style="width: 15%">
                        <th mat-header-cell *matHeaderCellDef> Punti </th>
                        <td mat-cell *matCellDef="let sconto"> {{sconto.punti}} </td>
                    </ng-container>

                    <ng-container matColumnDef="sconto" style="width: 20%">
                        <th mat-header-cell *matHeaderCellDef> Premio </th>
                        <td mat-cell *matCellDef="let sconto">
                            {{sconto.hasOwnProperty('prodotto') ? sconto.prodotto.join(', ') : sconto.sconto}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="validita" style="width: 30%">
                        <th mat-header-cell *matHeaderCellDef> Validità </th>
                        <td mat-cell *matCellDef="let sconto"> {{getValidityText(sconto)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="edit" style="width: 10%">
                        <th mat-header-cell *matHeaderCellDef> Edit </th>
                        <td mat-cell *matCellDef="let sconto">
                            <div style="width: 30px; margin-left: 10px">
                                <button class="action-button" (click)="openDialog(sconto)">
                                    <img [src]="'assets/icons/icons8-edit-90.png'" alt="Azione" style="max-width: 15px; max-height: 15px;">
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </table>
            </div>
        </div>
    </div>


    <div style="margin-bottom: 100px">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Sconti Predefiniti</div>
        <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
                <div style="display: flex; gap: 10px; margin: 0 1%;">
                    <categorieCard class="col-12 col-md-12 menuItem"
                                   style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                   (click)="addPredefinedSconto()"
                                   [nome]="'Aggiungi Sconto Predefinito'"
                                   altezza="50"
                                   [link]="'assets/icons/icons8-add-90.png'">
                    </categorieCard>
                </div>
            </div>

            <div class='table-responsive' style="margin-top: 20px; margin-left: 1%; width: 98%; overflow-y: scroll; border-radius: 20px; border: 1px solid #e3e2e2;">
                <table mat-table [dataSource]="predefinedSconti" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">
                    <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef> Nome </th>
                        <td mat-cell *matCellDef="let sconto"> {{sconto.nome}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef> Tipo </th>
                        <td mat-cell *matCellDef="let sconto"> {{sconto.tipo === 'percentage' ? 'Percentuale' : 'Valore'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="valore">
                        <th mat-header-cell *matHeaderCellDef> Valore </th>
                        <td mat-cell *matCellDef="let sconto"> {{sconto.tipo === 'percentage' ? sconto.valore + '%' : sconto.valore + '€'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef> Edit </th>
                        <td mat-cell *matCellDef="let sconto">
                            <div style="width: 30px; margin-left: 10px">
                                <button class="action-button" (click)="openPredefinedDialog(sconto)">
                                    <img [src]="'assets/icons/icons8-edit-90.png'" alt="Azione" style="max-width: 15px; max-height: 15px;">
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="predefinedDisplayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: predefinedDisplayedColumns;"></mat-row>
                </table>
            </div>
        </div>
    </div>

    <!-- Sezione Invia Comunicazioni -->
    <div style="margin-bottom: 100px">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Invia Comunicazioni</div>
        <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <form [formGroup]="communicationForm" (ngSubmit)="saveCommunication()" style="padding: 20px;">
                <div style="width: 100%; display: flex; gap: 10px; margin-bottom: 20px;">
                    <div *ngFor="let category of categories"
                         style="flex: 1; padding: 15px; border-radius: 10px; cursor: pointer;"
                         [style.background-color]="communicationForm.get('category')?.value === category.id ? 'rgb(0, 125, 104)' : '#f7f9fb'"
                         [style.color]="communicationForm.get('category')?.value === category.id ? 'white' : 'black'"
                         (click)="communicationForm.patchValue({category: category.id})">
                        <div style="text-align: center; font-weight: 500;">{{ category.name }}</div>
                    </div>
                </div>

                <textarea
                        formControlName="text"
                        placeholder="Scrivi il testo della comunicazione..."
                        style="width: 94%; height: 300px; margin-left: 1%; border: 1px solid #d2d9e0; background-color: #f7f9fb; border-radius: 10px; padding: 15px; resize: none;">
            </textarea>

                <div style="display: flex; justify-content: flex-end; margin-top: 20px;">
                    <button type="submit"
                            [disabled]="communicationForm.invalid"
                            style="font-size: 14px; text-align: center; border-radius: 7px; font-weight: 700; padding: 15px 30px; color: white; background-color: rgb(0 125 104); border: none; cursor: pointer;"
                            [style.opacity]="communicationForm.invalid ? '0.7' : '1'">
                        Invia Comunicazione
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>