<div class="settings-container">
    <div class="settings-header">
        <h2>{{item?.id ? 'Modifica Prodotto' : 'Nuovo Prodotto'}}</h2>
        <div class="header-actions">
            <button class="icon-button delete" *ngIf="item?.id" (click)="deleteItem()" title="Elimina"
                    style="border: 1px solid rgb(255 102 94); color: rgb(255 102 94)">
                elimina
            </button>
            <button class="icon-button delete" *ngIf="!item?.id" (click)="deleteItem()" title="Elimina"
                    style="border: 1px solid rgb(255 102 94); color: rgb(255 102 94)">
                Annulla
            </button>
            <button class="icon-button save" (click)="saveChanges()" title="Salva"
                    style="background-color: rgb(52 152 219); border: 1px solid rgb(52 152 219); color: white">
                salva
            </button>
        </div>
    </div>

    <div class="settings-content">

        <!-- Product Details Section -->
        <div class="settings-section">
            <div class="section-header">
                <h3>Dettagli Prodotto</h3>
            </div>

            <div class="settings-row">
                <span class="setting-label">È un prodotto generico?</span>
                <div class="checkbox_item citem_1" style="width: 30px;">
                    <label class="checkbox_wrap">
                        <input type="checkbox" name="checkbox" class="checkbox_inp" [(ngModel)]="isGenericoChecked">
                        <span class="checkbox_mark"></span>
                    </label>
                </div>
            </div>

            <div class="settings-row">
                <span class="setting-label">È un ingrediente?</span>
                <div class="checkbox_item citem_1" style="width: 30px;">
                    <label class="checkbox_wrap">
                        <input type="checkbox" name="checkbox" class="checkbox_inp" [(ngModel)]="isIngredienteChecked">
                        <span class="checkbox_mark"></span>
                    </label>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Nome Prodotto</div>
                <div class="setting-field">
                    <input [(ngModel)]="Titolo" placeholder="Nome prodotto" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Categoria</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="Categoria" class="custom-input">
                        <mat-option *ngFor="let category of categories" [value]="category">
                            {{category}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="settings-row" *ngIf="isGenericoChecked">
                <div class="setting-label">Prodotti Associati</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="prodottiAssociati" multiple class="custom-input">
                        <mat-option *ngFor="let product of nonGenericProducts" [value]="product.id">
                            {{product.Titolo}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Prezzo</div>
                <div class="setting-field">
                    <input type="number" [(ngModel)]="Prezzo" placeholder="0.00" class="custom-input" step="0.01">
                </div>
            </div>

            <!--div class="settings-row">
                <div class="setting-label">Unità di Misura</div>
                <div class="setting-field">
                    <input [(ngModel)]="UM" placeholder="UM" class="custom-input">
                </div>
            </div-->

            <div class="settings-row">
                <div class="setting-label">Codice Prodotto Interno</div>
                <div class="setting-field">
                    <input [(ngModel)]="CodiceProdottoInterno" placeholder="Codice Prodotto Interno" class="custom-input">
                </div>
            </div>

            <div class="settings-row" *ngIf="!isGenericoChecked">
                <div class="setting-label">Fornitore</div>
                <div class="setting-field">
                    <input [(ngModel)]="Fornitore" placeholder="Nome fornitore" class="custom-input">
                </div>
            </div>

            <div class="settings-row" *ngIf="!isGenericoChecked">
                <div class="setting-label">Codice Fornitore</div>
                <div class="setting-field">
                    <input [(ngModel)]="CodiceFornitore" placeholder="Codice fornitore" class="custom-input">
                </div>
            </div>

            <div class="settings-row" >
                <div class="setting-label">Immagine</div>
                <div class="setting-field image-upload">
                    <img *ngIf="selectedImage" [src]="selectedImage" alt="Selected Image" class="preview-image">
                    <div class="upload-actions">
                        <button class="action-button" *ngIf="!selectedImage" (click)="fileInput.click()">
                            Scegli immagine
                        </button>
                        <button class="elimina-button" *ngIf="selectedImage" (click)="removeImage()">
                            Elimina immagine
                        </button>
                        <input #fileInput type="file" (change)="onFileSelected($event)" style="display: none">
                    </div>
                </div>
            </div>


        </div>

        <!-- Packaging Levels Section -->
        <!-- Livelli di Imballaggio -->
        <div class="custom-fieldset" *ngIf="isGenericoChecked !== true">
            <div class="section-header">
                <h3>Livelli di imballaggio</h3>
            </div>
            <div class="actions-container">
                <div class="action-item">
                    <span>1</span>
                    <div class="checkbox-wrapper-26" style="width: 30px;">
                        <input
                                type="checkbox"
                                [id]="'checkbox-lvl'"
                                [checked]="isLvl1"
                                (click)="onMenuChangeLevel1()"
                        >
                        <label [for]="'checkbox-lvl'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>

                <div class="action-item">
                    <span>2</span>
                    <div class="checkbox-wrapper-26" style="width: 30px;">
                        <input
                                type="checkbox"
                                [id]="'checkbox-lvl2'"
                                [checked]="isLvl2"
                                (click)="onMenuChangeLevel2()"
                        >
                        <label [for]="'checkbox-lvl2'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>

                <div class="action-item">
                    <span>3</span>
                    <div class="checkbox-wrapper-26" style="width: 30px;">
                        <input
                                type="checkbox"
                                [id]="'checkbox-lvl3'"
                                [checked]="isLvl3"
                                (click)="onMenuChangeLevel3()"
                        >
                        <label [for]="'checkbox-lvl3'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>

                <div class="action-item">
                    <span>4</span>
                    <div class="checkbox-wrapper-26" style="width: 30px;">
                        <input
                                type="checkbox"
                                [id]="'checkbox-lvl4'"
                                [checked]="isLvl4"
                                (click)="onMenuChangeLevel4()"
                        >
                        <label [for]="'checkbox-lvl4'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- Primo Livello -->
        <div class="settings-row" *ngIf="(isLvl1 || isLvl2 || isLvl3 || isLvl4) && isGenericoChecked !== true">
            <div class="setting-label">Primo Livello</div>
            <div class="setting-field">
                <div class="chart-container" style="display: flex;     justify-content: space-around;">
                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">Quantità</div>
                        <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[0].quantita">
                    </div>

                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">UM</div>
                        <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[0].um">
                    </div>

                </div>
            </div>
        </div>

        <!-- Secondo Livello -->
        <div class="settings-row" *ngIf="(isLvl2 || isLvl3 || isLvl4) && isGenericoChecked !== true">
            <div class="setting-label">Secondo Livello</div>
            <div class="setting-field">
                <div class="chart-container" style="display: flex;     justify-content: space-around;">
                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">Quantità</div>
                        <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[1].quantita">
                    </div>

                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">UM</div>
                        <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[1].um">
                    </div>


                </div>
            </div>
        </div>

        <!-- Terzo Livello -->
        <div class="settings-row" *ngIf="(isLvl3 || isLvl4) && isGenericoChecked !== true">
            <div class="setting-label">Terzo Livello</div>
            <div class="setting-field">
                <div class="chart-container" style="display: flex;     justify-content: space-around;">
                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">Quantità</div>
                        <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[2].quantita">
                    </div>

                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">UM</div>
                        <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[2].um">
                    </div>


                </div>
            </div>
        </div>

        <!-- Quarto Livello -->
        <div class="settings-row" *ngIf="isLvl4 && isGenericoChecked !== true">
            <div class="setting-label">Quarto Livello</div>
            <div class="setting-field">
                <div class="chart-container" style="display: flex;     justify-content: space-around;">
                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">Quantità</div>
                        <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[3].quantita">
                    </div>

                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">UM</div>
                        <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[3].um">
                    </div>

                </div>
            </div>
        </div>

        <!-- Expiry Date Section
        <div class="settings-section">
            <div class="section-header">
                <h3>Data di Scadenza</h3>
            </div>
            <div class="settings-row">
                <div class="setting-field">
                    <input type="date" [(ngModel)]="expiryDate" class="custom-input">
                </div>
            </div>
        </div>
                -->
    </div>
</div>