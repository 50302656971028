<!-- Main Container -->
<div class="kitchen-container">
    <!-- Header -->
    <div class="kitchen-header">
        <div class="header-item">
            <strong>{{ currentTime | date:'HH:mm' }}</strong>
        </div>
        <div class="header-item center">
            <strong>Completamento {{ getCompletionPercentage() }}%</strong>
        </div>
        <div class="header-item right">
            <strong>Tempo Medio {{ getElapsedTime() }}</strong>
        </div>
    </div>

    <!-- Filters Panel -->
    <div *ngIf="showFilters" class="filters-panel">
        <div class="filters-grid">
            <!-- Print Destinations Filter -->
            <div class="filter-section">
                <h3>Destinazione Stampa</h3>
                <div class="filter-options">
                    <div *ngFor="let dest of printDestinations"
                         [class.active]="selectedDestinazioni.includes(dest)"
                         class="filter-option"
                         (click)="toggleDestinazione(dest)">
                        {{ dest }}
                    </div>
                </div>
            </div>

            <!-- Categories Filter -->
            <div class="filter-section">
                <h3>Categorie</h3>
                <div class="filter-options">
                    <div *ngFor="let cat of uniqueCategories"
                         [class.active]="selectedCategories.includes(cat)"
                         class="filter-option"
                         (click)="toggleCategory(cat)">
                        {{ cat }}
                    </div>
                </div>
            </div>
        </div>

        <!-- Active Filters -->
        <div *ngIf="hasActiveFilters()" class="active-filters">
            <h4>Filtri attivi:</h4>
            <div class="active-filters-list">
                <div *ngFor="let dest of selectedDestinazioni" class="active-filter">
                    {{ dest }}
                    <span class="remove-filter" (click)="toggleDestinazione(dest)">×</span>
                </div>
                <div *ngFor="let cat of selectedCategories" class="active-filter">
                    {{ cat }}
                    <span class="remove-filter" (click)="toggleCategory(cat)">×</span>
                </div>
            </div>
            <button class="clear-filters" (click)="clearFilters()">Rimuovi Filtri</button>
        </div>
    </div>

    <!-- Orders Grid -->
    <div class="orders-grid">
        <div *ngFor="let colonna of getColonneOrdinate()" class="orders-column">
            <div *ngFor="let ordine of colonna"
                 class="order-card"
                 [class.completed]="ordine.statoPagato === 3"
                 (click)="completeOrder(ordine)">

                <!-- Order Header -->
                <div class="order-header"
                     [ngClass]="{
               'counter': ordine.selectedPriceListType === 'counter',
               'table': ordine.selectedPriceListType === 'table',
               'takeaway': ordine.selectedPriceListType === 'takeaway'
             }">
                    <div class="order-header-text">
                        <span>Ordine #{{ordine.ordine}}</span>
                        <span class="order-type-badge">
              {{getOrderTypeLabel(ordine.selectedPriceListType)}}
                            <span *ngIf="ordine.tavolo">- Tavolo {{ordine.tavolo}}</span>
            </span>
                    </div>
                    <div class="order-time">
                        {{getOrderTime(ordine.data)}}
                    </div>
                </div>

                <!-- Order Content -->
                <div class="order-content">
                    <!-- Products List -->
                    <div *ngFor="let prodottoId of ordine.ordineInserimento" class="product-item">
            <span class="product-quantity">
              {{ordine.carrello[prodottoId].quantita}}×
            </span>
                        <div class="product-details">
                            <div class="product-name">
                                {{ordine.carrello[prodottoId].title}}
                            </div>
                            <!-- Variants -->
                            <div *ngIf="ordine.carrello[prodottoId].variants" class="product-variants">
                                <div *ngFor="let variant of ordine.carrello[prodottoId].variants">
                                    → {{variant.name}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Order Notes -->
                    <div *ngIf="ordine.nota" class="order-notes">
                        <div class="order-notes-title">Note:</div>
                        <div class="order-notes-content">{{ordine.nota}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Control Buttons -->
    <div class="control-buttons">
        <div class="control-button primary" (click)="toggleVisualizzazione()">
            {{ mostraTutti ? 'Visualizza ordini da evadere' : 'Visualizza tutti gli ordini' }}
        </div>
        <div class="control-button secondary" (click)="toggleFilters()">
            {{ showFilters ? 'Nascondi Filtri' : 'Mostra Filtri' }}
        </div>
    </div>
</div>