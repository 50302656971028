import {
  Component,
  OnInit,
  OnDestroy,
  NgZone
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Importazioni RxJS
import {Subject, Subscription} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Importazioni Material e Dialog
import { MatDialog } from '@angular/material/dialog';

// Importazioni Firebase
import firebase from 'firebase';

// Importazioni componenti di dialogo
import { SelezionaVarianti } from '../../seleziona-varianti/seleziona-varianti';
import { DialogPagamento } from '../../dialogPagamento/dialogPagamento';
import { Fidelity } from '../../fidelity/fidelity';
import { Fatturazione } from '../../fatturazione/fatturazione';
import { DeleteOrderDialogComponent } from '../../../component/delete-order-dialog/delete-order-dialog.component';
import { PagerDialogComponent } from '../../PagerDialogComponent/pagerDialogComponent.component';
import { AddNoteDialogComponent } from '../../addNoteDialog/addNoteDialog.component';

// Importazioni servizi
import { UserDataService } from '../../../shared/services/firestore/userData.service';
import { DataServiceGenerico } from '../../../shared/services/data-service/data.service';
import { CommonService } from '../../../shared/services/firestore/common.service';
import { IpcService } from '../../../shared/services/ipc.service';
import { PosTransactionService } from '../../../shared/services/pos-transaction.service';
import {SelezionaVariantiSingoloComponent} from "../../seleziona-varianti-singolo/seleziona-varianti-singolo.component";

@Component({
  selector: 'cassa-ordine',
  templateUrl: './cassa-ordine.html',
  styleUrls: ['./cassa-ordine.css']
})
export class CassaOrdine implements OnInit, OnDestroy {
  // Variabili di stato principali
  selectedPriceList: string = '';
  selectedPrice: number | null = null;
  selectedQuantity: number | null = null;
  resetCounter = 0;
  variabile_eliminazione = false;
  variabile_singolo = true;


  // Gestione ordine
  currentOrder: any;
  portata = 4;
  sezione = 0;
  menu: any[] = [];
  menuBello: any[] = [];
  imagePaths: { [key: string]: Promise<string> } = {};
  user: any;

  // Gestione varianti
  private allVariants: any[] = [];

  // Gestione del ciclo di vita
  private destroy$ = new Subject<void>();
  private posTransactionCleanup: (() => void) | null = null;
  private authUnsubscribe: (() => void) | null = null;
  private subscriptions: Subscription[] = [];

  // Configurazione modale
  modalOptions = {
    backdrop: true,
    keyboard: false,
    focus: true,
    show: false,
    scroll: true,
    ignoreBackdropClick: false,
    class: 'app-modal-window',
    containerClass: '',
    animated: true,
    data: {}
  };

  constructor(
      private router: Router,
      private userService: UserDataService,
      public dialog: MatDialog,
      private commonService: CommonService,
      private dataServiceGenerico: DataServiceGenerico,
      private ipcService: IpcService,
      private posTransactionService: PosTransactionService,
      private route: ActivatedRoute,
      private ngZone: NgZone
  ) {}

  // Metodo di cleanup del componente
  ngOnDestroy(): void {

    this.subscriptions.forEach(sub => sub.unsubscribe());

    this.destroy$.next();
    this.destroy$.complete();

    if (this.posTransactionCleanup) {
      this.posTransactionCleanup();
    }

    if (this.authUnsubscribe) {
      this.authUnsubscribe();
    }
  }

  // Inizializzazione del componente
  async ngOnInit(): Promise<void> {

    this.subscriptions.push(
        this.userService.emitter.pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => {
          this.retriveMenu();
        })
    );

    this.loadVariants();
    this.ipcService.openSecondaryDisplay();
    this.subscribeToOrder();

    // Configurazione dei listener per le transazioni
    this.posTransactionCleanup = this.posTransactionService.onTransactionResult((event, response) => {
      if (response.status === 'success') {
        this.currentOrder.statoPagato = 0;
        this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      }
    });

    // Listener per l'autenticazione Firebase
    this.authUnsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.retriveMenu();
        this.userService.emitter.pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => {
          this.retriveMenu();
        });
      }
    });

    // Selezione del price list
    try {
      this.selectedPriceList = await this.ipcService.getSelectedPriceList();
    } catch (error) {
      this.selectedPriceList = '5Q1cRLWC2uvW8w0doJQ7';
    }
  }

  // Sottoscrizione all'ordine corrente
  private subscribeToOrder(): void {
    const orderId = this.route.snapshot.paramMap.get('id');
    if (orderId) {
      this.commonService.getOrderById(orderId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(order => {
            this.currentOrder = order.payload.data() || {};
            this.currentOrder.carrello = this.currentOrder.carrello || {};
            this.currentOrder.ordineInserimento = this.currentOrder.ordineInserimento || [];

            this.ipcService.updateSecondaryDisplay(this.currentOrder);
          });
    }
  }

  // Caricamento delle varianti
  private async loadVariants() {
    const db = firebase.firestore();
    const idRistorante = await this.dataServiceGenerico.getCurrentRestaurantId();
    const restaurantName = this.dataServiceGenerico.getRistorante();
    const variantiRef = db.collection('varianti').doc(restaurantName);

    variantiRef.get().then(doc => {
      if (doc.exists) {
        this.allVariants = doc.data()?.variants || [];
      }
    });
  }

  // Recupero del menu
  private async retriveMenu(): Promise<void> {
    const db = firebase.firestore();
    const idRistorante = await this.dataServiceGenerico.getCurrentRestaurantId();
    const restaurantName = this.dataServiceGenerico.getRistorante();
    const defaultImageUrl = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FFillApp%20solo%20logo%20grigio.png?alt=media&token=6105b54c-35c8-4c16-818c-2cb5548e9f94';

    db.collection('price_lists').doc(restaurantName).get().then(priceListDoc => {
      const priceListData = priceListDoc.data();
      const selectedList = priceListData?.priceLists?.find(list => list.id === this.selectedPriceList);
      const orderCategories = selectedList?.orderCategories || [];
      const orderProducts = selectedList?.orderProducts || [];

      const menuRef = db.collection('menu_test').doc(restaurantName);
      menuRef.get().then(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.menuBello = data.items.filter(elemento => elemento.disponibilita !== false);

          // Gestione delle immagini
          this.menuBello.forEach(elemento => {
            if (!elemento.hasOwnProperty('image_link') || elemento.image_link?.length === 0) {
              elemento.image_link = defaultImageUrl;
            }
          });

          // Preparazione delle categorie
          const categorie = this.filterUniqueCategories(this.menuBello);
          this.menu = orderCategories.length > 0 ? orderCategories : categorie;
          this.menu = this.menu.map(categoria => ({
            testo: categoria,
            menu: this.menuBello.filter(prodotto => prodotto.category === categoria)
          }));

          // Caricamento dei preferiti utente
          this.userService.getDoc(firebase.auth().currentUser.uid).get()
              .pipe(takeUntil(this.destroy$))
              .subscribe(data => {
                if (data.data() !== undefined) {
                  this.user = data.data();
                  if (this.user.hasOwnProperty('preferiti')) {
                    this.user.preferiti = this.user.preferiti.filter(preferito =>
                        this.menuBello.some(piatto => piatto.title === preferito.title)
                    );
                  }
                }
              });

          this.initializeImagePaths();
        }
      });
    });
  }

  // Inizializzazione dei path delle immagini
  private initializeImagePaths() {
    this.menu.forEach(categoria => {
      categoria.menu.forEach(item => {
        this.imagePaths[item.title] = this.ipcService.getLocalImage(item.image_link);
      });
    });
  }

  // Filtraggio delle categorie univoche
  private filterUniqueCategories(menuItems) {
    const seenCategories = new Set();
    return menuItems
        .filter(item => {
          if (seenCategories.has(item.category)) {
            return false;
          } else {
            seenCategories.add(item.category);
            return true;
          }
        })
        .map(x => x.category)
        .filter(element => element !== undefined);
  }

  // Input della tastiera numerica
  onKeypadValue(event: {type: 'price' | 'quantity', value: number}) {
    if (event.type === 'price') {
      this.selectedPrice = event.value;
      this.selectedQuantity = null;
    } else {
      this.selectedQuantity = event.value;
      this.selectedPrice = null;
    }
  }

  // Calcolo del prezzo di un piatto
  getPrezzoPiatto(piatto) {
    const priceListPrice = piatto?.prezzo;
    const basePrice = priceListPrice !== undefined ? priceListPrice : (piatto?.prezzo || piatto?.price || 0);

    if(piatto?.hasOwnProperty('aggiunte')) {
      return basePrice + piatto?.aggiunte.length;
    }
    return basePrice;
  }

  // Verifica delle varianti obbligatorie
  hasRequiredVariants(piatto: any): boolean {
    if (!piatto?.variantIds || piatto.variantIds.length === 0) {
      return false;
    }

    if (!this.allVariants) {
      return false;
    }

    const piattoVariants = this.allVariants.filter(v => piatto.variantIds.includes(v.id));
    return piattoVariants.some(v => v.isRequired);
  }

  // Apertura dialogo delle varianti
  apriVarianti(piatto, productKey?: string) {
    const isEdit = productKey !== undefined;

    if (piatto.variantIds != undefined && piatto.variantIds.length > 0) {
      this.openDialog(
          piatto,
          this.currentOrder,
          this.portata,
          productKey,
          isEdit
      );
    }
  }

  // Apertura dialogo generico
  openDialog(piatto?: any, currentOrder?: any, portata?: any, productKey?: string, isEdit: boolean = false, seleziona_singolo: boolean= true): any {
    if (piatto !== undefined) {
      this.modalOptions.data = {
        dataKey: piatto,
        height: '70vh',
        isEdit: isEdit,
        productKey: productKey
      }
    }

    if(seleziona_singolo){
      const modalRef = this.dialog.open(SelezionaVariantiSingoloComponent, this.modalOptions);
      modalRef.componentInstance.user = this.user;
      modalRef.componentInstance.currentOrder = currentOrder;
      modalRef.componentInstance.portata = portata;
      modalRef.componentInstance.piattoRicevuto = piatto;
      return modalRef;
    }else {
      const modalRef = this.dialog.open(SelezionaVarianti, this.modalOptions);
      modalRef.componentInstance.user = this.user;
      modalRef.componentInstance.currentOrder = currentOrder;
      modalRef.componentInstance.portata = portata;
      modalRef.componentInstance.piattoRicevuto = piatto;
      return modalRef;
    }

  }

  // Apertura dialogo pagamento
  openDialogPagamento(): any {
    let filteredCarrello = {};

    if (this.currentOrder?.carrello) {
      Object.keys(this.currentOrder.carrello).forEach(key => {
        const keyWithoutSpaces = key.replace(/ /g, '');
        const item = this.currentOrder.carrello[key];

        const paidQuantity = item.isPaidQuantity || 0;
        const remainingQuantity = item.quantita - paidQuantity;

        if (remainingQuantity > 0) {
          filteredCarrello[keyWithoutSpaces] = {
            ...item,
            quantita: remainingQuantity,
            originalQuantity: item.quantita,
            isPaidQuantity: paidQuantity
          };
        }
      });
    }

    this.modalOptions.data = this.currentOrder.ordine;
    const modalRef = this.dialog.open(DialogPagamento, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.camerieri = this.currentOrder;
    modalRef.componentInstance.carrello = filteredCarrello;

    return modalRef;
  }

  // Apertura dialogo Fidelity
  async openDialogFidelity(): Promise<void> {
    const idRistorante = await this.dataServiceGenerico.getCurrentRestaurantId();
    const dialogRef = this.dialog.open(Fidelity, {
      panelClass: 'custom-dialog-container',
      data: {
        restaurantId: this.dataServiceGenerico.getRistorante()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.user) {
        this.currentOrder.userId = result.user.id;

        if (result.carrelloItem) {
          const itemId = result.carrelloItem.id.replace(/ /g,'');
          this.currentOrder.carrello[itemId] = result.carrelloItem;
          this.currentOrder.ordineInserimento.push(itemId);
        }

        this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      }
    });
  }

  // Calcolo della quantità di un prodotto
  getQuantita(prodotto: any) {
    if (!this.currentOrder?.carrello) {
      return 0;
    }

    const baseName = prodotto.title.replace(/ /g, '');
    let totalQuantity = 0;

    Object.keys(this.currentOrder.carrello).forEach(key => {
      if (key === baseName || key.startsWith(`${baseName}_`)) {
        totalQuantity += this.currentOrder.carrello[key].quantita;
      }
    });

    return totalQuantity;
  }

  // Gestione dell'eliminazione di un elemento
  handleDeleteItem(piatto: string) {
    if (this.currentOrder?.statoPagato !== undefined ? this.currentOrder.statoPagato != 0 : true) {
      if (this.currentOrder?.carrello[piatto]) {
        delete this.currentOrder.carrello[piatto];

        if (this.currentOrder.ordineInserimento) {
          this.currentOrder.ordineInserimento = this.currentOrder.ordineInserimento.filter(
              item => item !== piatto
          );
        }

        this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
        this.ipcService.updateSecondaryDisplay(this.currentOrder);
      }
    }
  }

// Gestione dell'evento di modifica quantità
  handleEvent(event: number, piatto: any, piatto2?: string) {
    if(this.currentOrder?.statoPagato !== undefined ? this.currentOrder.statoPagato != 0 : true) {
      const nomePiatto = piatto2 || '';
      this.currentOrder.carrello[nomePiatto].quantita = event;

      if (this.currentOrder.carrello[nomePiatto].quantita == 0) {
        delete this.currentOrder.carrello[nomePiatto];
        this.currentOrder['ordineInserimento'] = this.currentOrder['ordineInserimento'].filter(item => item !== nomePiatto);
      }
    }
    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
    this.ipcService.updateSecondaryDisplay(this.currentOrder);
  }

// Aggiornamento dell'ordine
  async updateOrdine(piatto) {
    const shouldUseKeypadPrice = this.selectedPrice !== null;
    const shouldUseKeypadQuantity = this.selectedQuantity !== null;

    const priceListPrices = piatto.priceListPrices?.[this.selectedPriceList];
    let basePrice = shouldUseKeypadPrice ? this.selectedPrice :
        (priceListPrices?.counter?.price || +piatto.price);
    let vatId = priceListPrices?.counter?.vatId || '2';
    const quantity = shouldUseKeypadQuantity ? this.selectedQuantity : 1;

    if(piatto.variantIds?.length > 0) {
      const db = firebase.firestore();
      const idRistorante = await this.dataServiceGenerico.getCurrentRestaurantId();
      const restaurantName = this.dataServiceGenerico.getRistorante();
      const variantiRef = db.collection('varianti').doc(restaurantName);

      variantiRef.get().then(doc => {
        if (doc.exists) {
          const allVariants = doc.data()?.variants || [];
          const piattoVariants = allVariants.filter(v => piatto.variantIds.includes(v.id));
          const hasRequiredVariants = piattoVariants.some(v => v.isRequired);

          if (hasRequiredVariants) {
            this.apriVarianti(piatto);
          } else {
            this.addToCartWithoutVariants(piatto, basePrice, vatId);
          }
        }
      });
    } else {
      this.addToCartWithoutVariants(piatto, basePrice, vatId);
    }
    this.ipcService.updateSecondaryDisplay(this.currentOrder);
  }

// Aggiunta al carrello senza varianti
  private addToCartWithoutVariants(piatto, basePrice, vatId) {
    const nome = piatto.title.replace(/ /g,'');
    const quantity = this.selectedQuantity || 1;
    const priceListType = this.currentOrder?.selectedPriceListType || 'counter';

    const uniqueId = this.selectedPrice ?
        `${nome}_p${this.selectedPrice}` :
        nome;

    if (!this.currentOrder.carrello) {
      this.currentOrder.carrello = {};
    }
    if (!this.currentOrder.ordineInserimento) {
      this.currentOrder.ordineInserimento = [];
    }

    const priceListData = piatto.priceListPrices?.[this.selectedPriceList]?.[priceListType];
    const finalPrice = this.selectedPrice || (priceListData?.price ?? basePrice);
    const finalVatId = priceListData?.vatId ?? vatId;

    if (this.currentOrder.carrello[uniqueId]) {
      this.currentOrder.carrello[uniqueId].quantita += quantity;
    } else {
      this.currentOrder.carrello[uniqueId] = {
        ...piatto,
        quantita: quantity,
        price: finalPrice,
        prezzo: finalPrice,
        portata: this.portata == 4 ? 0 : this.portata,
        vatId: finalVatId
      };
      this.currentOrder.ordineInserimento.push(uniqueId);
    }

    this.selectedPrice = null;
    this.selectedQuantity = null;
    this.resetCounter++;

    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
  }

// Metodi di utilità per ottenere proprietà
  getProperties(object: any) {
    return object !== undefined ? Object.keys(object) : [];
  }

// Ottenere proprietà per portata
  getPropertiesPortata(object: any) {
    if (object !== undefined && this.currentOrder?.ordineInserimento) {
      return this.currentOrder.ordineInserimento.filter((piatto) => {
        const piattoObj = this.currentOrder?.carrello[piatto];
        return piattoObj.hasOwnProperty('portata')
            ? piattoObj['portata'] == this.portata || this.portata == 4
            : this.portata == 4;
      });
    }
    return [];
  }

// Ottenere il titolo dell'ordine
  getTitoloOrdine() {
    return this.currentOrder?.displayName;
  }

// Calcolo del tempo trascorso
  getElapsedTime(orderDate: string, closureOrderTime?: string): string {
    if (!orderDate) return '00:00:00';

    const startTime = new Date(parseInt(orderDate));
    const endTime = closureOrderTime ? new Date(parseInt(closureOrderTime)) : new Date();

    const diff = endTime.getTime() - startTime.getTime();

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

// Verifica dell'ultimo prodotto
  checkIfLastProduct(piatto: string, isFullDelete: boolean, eliminazione = false) {
    let totalItems = 0;

    Object.values(this.currentOrder.carrello).forEach(() => {
      totalItems += 1;
    });

    if (totalItems === 1) {
      if (eliminazione) {
        const dialogRef = this.dialog.open(DeleteOrderDialogComponent, {
          width: '400px',
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.currentOrder.motivazione = result;
            this.handleEvent(0, this.currentOrder.carrello[piatto], piatto);
          }
        });
      } else {
        this.handleEvent(0, this.currentOrder.carrello[piatto], piatto);
      }
    } else {
      if (isFullDelete) {
        this.handleEvent(0, this.currentOrder.carrello[piatto], piatto);
      } else {
        this.handleEvent(this.currentOrder.carrello[piatto].quantita - 1, this.currentOrder.carrello[piatto], piatto);
      }
    }
  }

// Assegnazione pager
  assignPager(): void {
    const dialogRef = this.dialog.open(PagerDialogComponent, {
      width: '400px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && this.currentOrder) {
        this.ngZone.run(() => {
          this.currentOrder = {
            ...this.currentOrder,
            pager: result
          };

          this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
        });
      }
    });
  }

// Verifica se un elemento è stato pagato
  isPaidItem(piatto: string): boolean {
    if (!this.currentOrder?.paidItems) return false;
    return this.currentOrder.paidItems.some(item => item.id === piatto);
  }

// Calcolo del totale
  calcolaTotale() {
    let totale = 0;
    if (this.currentOrder?.carrello) {
      Object.values(this.currentOrder.carrello).forEach((item: any) => {
        totale += this.getPrezzoPiatto(item) * item.quantita;
      });
    }
    return totale;
  }

// Metodi per il menu e i prezzi
  getPriceFromPriceList(piatto: any): string {
    if (!piatto.priceListPrices || !piatto.priceListPrices[this.selectedPriceList]) {
      return (+piatto.price || 0).toFixed(2);
    }

    const priceListType = this.currentOrder?.selectedPriceListType || 'counter';
    const priceData = piatto.priceListPrices[this.selectedPriceList][priceListType];

    if (!priceData || typeof priceData.price !== 'number') {
      return (+piatto.price || 0).toFixed(2);
    }

    return priceData.price.toFixed(2);
  }

// Verifica se un piatto è nel price list
  isPiattoInPriceList(piatto: any): boolean {
    return piatto.priceListPrices &&
        piatto.priceListPrices[this.selectedPriceList] &&
        piatto.priceListPrices[this.selectedPriceList].counter &&
        piatto.priceListPrices[this.selectedPriceList].counter.price > 0;
  }

// Filtraggio degli elementi del menu
  getFilteredMenuItems(categoryMenu: any[]): any[] {
    if (!categoryMenu) return [];
    return categoryMenu.filter(piatto => this.isPiattoInPriceList(piatto));
  }

// Verifica se una categoria ha elementi
  hasCategoryItems(categoryMenu: any[]): boolean {
    return this.getFilteredMenuItems(categoryMenu).length > 0;
  }

  // Indietro alla schermata precedente
  indietro() {
    this.ipcService.updateSecondaryDisplay(undefined);
    this.router.navigate(['/cassa'], { fragment: 'nonav' });
  }

  // Aggiunta nota all'ordine
  aggiungiNota(): void {
    const dialogRef = this.dialog.open(AddNoteDialogComponent, {
      width: '400px',
      data: { existingNote: this.currentOrder?.nota }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.currentOrder.nota = result;
        this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      }
    });
  }

  // Apertura cassetto
  apricassetto(): void {
    this.ipcService.printReceipt('', 'apriSolo', 'rch');
  }

  // Eliminazione dell'ordine
  eliminaOrdine(ordine: any, motivazione = false) {
    if (motivazione) {
      const dialogRef = this.dialog.open(DeleteOrderDialogComponent, {
        width: '400px',
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          const orderToDelete = {
            ...this.currentOrder,
            statoPagato: -1,
            motivazione: result,
            dataEliminazione: new Date().toISOString()
          };

          try {
            await this.commonService.updateOrdine(this.currentOrder.id, orderToDelete);
            this.router.navigate(['/cassa'], { fragment: 'nonav' });
          } catch (error) {
            // Gestione dell'errore
          }
        }
      });
    } else {
      try {
        const orderToDelete = {
          ...this.currentOrder,
          statoPagato: -1,
          motivazione: 'nessuna motivazione richiesta',
          dataEliminazione: new Date().toISOString()
        };

        this.commonService.updateOrdine(this.currentOrder.id, orderToDelete);
        this.router.navigate(['/cassa'], { fragment: 'nonav' });
      } catch (error) {
        // Gestione dell'errore
      }
    }
  }

  // Apertura dialogo fatturazione
  openDialogFatturazione(piatto?: any): any {
    if (piatto !== undefined) {
      this.modalOptions.data = {
        dataKey: piatto
      }
    }

    const modalRef = this.dialog.open(Fatturazione, this.modalOptions);

    return modalRef;
  }

  // Metodo per stampare lo scontrino
  printReceipt(cassetto: string) {
    let stringa = [];

    this.getProperties(this.currentOrder?.carrello)?.forEach(
        key => stringa.push(this.currentOrder?.carrello[key])
    );

    const scontrino = this.stampaScontrino(stringa);
    this.ipcService.printReceipt(scontrino, cassetto);

    if (cassetto != 'apriSolo' && cassetto != 'nonAprire') {
      this.currentOrder.statoPagato = 0;
    }
    if (cassetto == 'nonAprire') {
      this.currentOrder.statoPagato = 1;
    }

    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
  }

  // Metodo per generare lo scontrino
  stampaScontrino(menu: any[]): string {
    const intestazione = 'F.S. LA VALLETTA\n' +
        'di Vilson Coku & C. S.n.C.\n' +
        'Via Partinico n.2\n' +
        '47814 - Igea Marina - RN\n' +
        'IT04428530408\n' +
        'Tel. 0541330860\n \n                   PRECONTO\n \n';

    const header = 'Qta  Prodotto                     IVA   Prezzo\n';
    const ivaRate = 0.10;
    const maxLength = 28;
    const spaceBetweenFields = ' '.repeat(2);

    let totale = 0;

    const scontrino = menu.map(item => {
      const totalePrezzo = item.quantita * this.getPrezzoPiatto(item);
      totale += totalePrezzo;
      const prodotto = item.title.length > maxLength
          ? item.title.slice(0, maxLength)
          : item.title.padEnd(maxLength);
      const quantita = item.quantita.toString().padStart(3);
      const prezzo = totalePrezzo.toFixed(2).padStart(6);
      const ivaField = (ivaRate * 100).toFixed(0).padStart(3) + '%';

      return `${quantita}${spaceBetweenFields}${prodotto}${spaceBetweenFields}${ivaField}${spaceBetweenFields}${prezzo}`;
    }).join('\n');

    const iva = totale * ivaRate;
    const subtotale = totale - iva;

    const footer = `
      -------------------------------

  * SUBTOTALE${subtotale.toFixed(2).padStart(34)}
  * IVA${iva.toFixed(2).padStart(40)}
  * TOTALE${totale.toFixed(2).padStart(37)}

      -------------------------------
      
      
      
         GRAZIE ED ARRIVEDERCI
  `;

    return header + scontrino + footer;
  }

}