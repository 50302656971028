// delete-order-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-order-dialog',
    template: `
    <h2 mat-dialog-title>Elimina Ordine</h2>
    <div mat-dialog-content>
      <p>Inserisci la motivazione dell'eliminazione:</p>
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Motivazione</mat-label>
        <textarea matInput 
                  [(ngModel)]="motivazione" 
                  rows="4"
                  placeholder="Inserisci qui la motivazione..."></textarea>
      </mat-form-field>
    </div>
    <div mat-dialog-actions style="justify-content: flex-end; margin-top: 20px;">
      <button mat-button (click)="onNoClick()">Annulla</button>
      <button mat-button 
              color="warn" 
              [disabled]="!motivazione?.trim()"
              (click)="onConfirm()">
        Elimina
      </button>
    </div>
  `,
    styles: [`
    mat-form-field {
      width: 100%;
    }
  `]
})
export class DeleteOrderDialogComponent {
    motivazione: string = '';

    constructor(
        public dialogRef: MatDialogRef<DeleteOrderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    onConfirm(): void {
        this.dialogRef.close(this.motivazione);
    }
}