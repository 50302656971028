<div class="row">



  <div class="column">


    <div class="container menu" id="menuListaPiatti">
      <div style="pointer-events: auto; margin-top: 0px">

        <div class="noScrollBarr" style="padding-top: 0%; padding-left: 0px; padding: 10px; max-height: 90vh; overflow-x: visible; overflow-y: scroll">

          <div class="container" style="padding-top: 0px; ">


              <div class="orders-layout">
                  <!-- Lista ordini (colonna sinistra) -->
                  <div class="orders-list">
                      <div class="order-card" *ngFor="let ordine of ordiniMagazzino"
                           (click)="openOrderDetail(ordine)">
                          <ordini-card-magazzino
                                  [stato]="ordine.stato"
                                  [totale]="calcolaTotaleOrdine(ordine)"
                                  [fonte]="ordine.fonte"
                                  [nome]="ordine.ristorante"
                                  [numero]="ordine.idOrdine"
                                  [bloccato]="ordine.bloccato"
                                  [data]="ordine.data"
                                  [selected]="selectedOrder?.idOrdine === ordine.idOrdine">
                          </ordini-card-magazzino>
                      </div>
                  </div>

                  <!-- Dettaglio ordine (colonna destra) -->
                  <div class="order-detail" *ngIf="selectedOrder">
                      <div class="detail-header">
                          <h3>Ordine #{{selectedOrder.idOrdine}}</h3>
                          <div class="status-tag" [ngClass]="{
          'status-paid': selectedOrder.stato === 'confermato',
          'status-partial': selectedOrder.stato === 'confermato_parziale',
          'status-processing': selectedOrder.scheduledTime !== null,
          'status-unpaid': !selectedOrder.stato || selectedOrder.stato === 'in_attesa'
        }">
                              {{getOrderStatus()}}
                          </div>
                      </div>

                      <div class="detail-content">
                          <div class="info-section">
                              <div class="info-row">
                                  <span class="info-label">Ristorante:</span>
                                  <span class="info-value">{{selectedOrder.ristorante}}</span>
                              </div>
                              <div class="info-row">
                                  <span class="info-label">Data:</span>
                                  <span class="info-value">{{selectedOrder.data?.toDate() | date:'dd/MM/yyyy HH:mm'}}</span>
                              </div>
                          </div>

                          <div class="products-section">
                              <h4>Prodotti Ordinati</h4>
                              <div class="product-list">
                                  <div class="product-item" *ngFor="let item of selectedOrder.carrelloMagazzino">
                    <span class="quantity">
                        <ng-container *ngIf="!isConfirmingPartial">
                            <div *ngIf="selectedOrder.stato === 'confermato_parziale'" class="quantity-confirmed">
                                <span class="original-quantity"><s>{{item.Quantita}}x</s></span>
                                <span class="received-quantity">{{partialQuantities[item.Titolo]}}x</span>
                                <span class="um">{{item.UM}}</span>
                            </div>
                            <div *ngIf="selectedOrder.stato !== 'confermato_parziale'">
                                {{item.Quantita}}x {{item.UM}}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isConfirmingPartial">
                            <div class="quantity-input">
                                <input type="number"
                                       [(ngModel)]="partialQuantities[item.Titolo]"
                                       [max]="item.Quantita"
                                       min="0"
                                       class="form-control">
                                <span class="um">{{item.UM}}</span>
                            </div>
                            <div class="quantity-original">
                                Ordinati: {{item.Quantita}}
                            </div>
                        </ng-container>
                    </span>
                                      <div class="product-info">
                                          <div class="product-name">{{item.Titolo}}</div>
                                          <div class="product-supplier">{{item.Fornitore}} - {{item.Categoria}}</div>
                                          <div class="product-code" *ngIf="item.CodiceFornitore">Cod: {{item.CodiceFornitore}}</div>
                                      </div>
                                      <div class="product-details">
                                          <div class="expiry-date" *ngIf="item.Scadenza">Scad: {{item.Scadenza}}</div>
                                          <span class="price">{{(parseFloat(item.Prezzo)).toFixed(2)}} €</span>
                                      </div>
                                  </div>
                              </div>

                              <div class="total-row">
                                  <span>Totale Ordine</span>
                                  <span class="total-amount">{{calcolaTotaleOrdine(selectedOrder)}} €</span>
                              </div>

                              <!-- Pulsanti di conferma - mostrati solo se l'ordine non è stato ancora confermato -->
                              <!-- Pulsanti di conferma -->
                              <div class="confirmation-buttons" *ngIf="(!selectedOrder.stato || selectedOrder.stato === 'in_attesa') && !selectedOrder.scheduledTime">
                                  <ng-container *ngIf="!isConfirmingPartial">
                                      <button class="btn btn-success confirm-btn" (click)="confirmFullOrder()">
                                          Conferma Ordine Completo
                                      </button>
                                      <button class="btn btn-warning partial-btn" (click)="startPartialConfirmation()">
                                          Conferma Parziale
                                      </button>
                                  </ng-container>

                                  <ng-container *ngIf="isConfirmingPartial">
                                      <div class="partial-confirmation">
                                          <button class="btn btn-primary confirm-partial-btn" (click)="confirmPartialOrder()">
                                              Conferma Quantità Ricevute
                                          </button>
                                          <button class="btn btn-secondary cancel-btn" (click)="cancelPartialConfirmation()">
                                              Annulla
                                          </button>
                                      </div>
                                  </ng-container>
                              </div>


                          </div>
                      </div>
                  </div>

              </div>

          </div>

        </div>

      </div>
    </div>


  </div>



  <div class="column1">

    <div class="row" style="margin-top: 8%">

        <!-- immagini -->

    </div>


    <p style="width: 75%; text-align: left; max-width: 95%; display: inline-block;  border: none; font-weight: bold; font-size: 22px; padding-left: 0px; margin: 0px; margin-top:15px; margin-left: 0%; white-space: normal; word-break: normal; word-wrap: break-word;  overflow-wrap: break-word; hyphens: auto; position: sticky; ">Periodo</p>


    <div style="background-color: white; border: 1px solid rgba(181,181,181,0.3); border-radius: 20px; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; display: grid; margin-top: 15px">
      <div style="display: flex; cursor: pointer" (click)="filtraPagato()">
        <div >
          <img *ngIf="filtroStato != 0" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-empty-96.png?alt=media&token=55f2a274-4848-457d-96ab-697e620b22e5" style="display: grid; place-items: center; width: 30px">
          <img *ngIf="filtroStato == 0" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-96.png?alt=media&token=860666ad-6d37-4457-be44-18b5b442d2ae" style="display: grid; place-items: center; width: 30px">
        </div>
        <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: first; margin-left: 20px" [ngStyle]="{'color': filtroStato === 0 ? '#E55933' : 'black'}">Ultima Settimana</div>
      </div>
      <div style=" margin-left:40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin-top: 10px; margin-bottom: 10px"></div>
      <div style="display: flex; cursor: pointer" (click)="filtraNonPagato()">
        <div>
          <img *ngIf="filtroStato != 1" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-empty-96.png?alt=media&token=55f2a274-4848-457d-96ab-697e620b22e5" style="display: grid; place-items: center; width: 30px">
          <img *ngIf="filtroStato == 1" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-96.png?alt=media&token=860666ad-6d37-4457-be44-18b5b442d2ae" style="display: grid; place-items: center; width: 30px">
        </div>
        <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: first; margin-left: 20px" [ngStyle]="{'color': filtroStato === 1 ? '#E55933' : 'black'}">Ultimo mese</div>
      </div>
      <div style=" margin-left:40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin-top: 10px; margin-bottom: 10px"></div>
      <div style="display: flex; cursor: pointer" (click)="filtraStatoTutto()">
        <div>
          <img *ngIf="filtroStato != 2" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-empty-96.png?alt=media&token=55f2a274-4848-457d-96ab-697e620b22e5" style="display: grid; place-items: center; width: 30px">
          <img *ngIf="filtroStato == 2" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-96.png?alt=media&token=860666ad-6d37-4457-be44-18b5b442d2ae" style="display: grid; place-items: center; width: 30px">
        </div>
        <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: first; margin-left: 20px" [ngStyle]="{'color': filtroStato === 2 ? '#E55933' : 'black'}">Ultimo Anno</div>
      </div>

      <div style=" margin-left:40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin-top: 10px; margin-bottom: 10px"></div>



      <div style="display: flex; cursor: pointer" (click)="filtraStatoTutto()">

        <mat-form-field appearance="fill" style="margin-top: -10px !important; margin-left: -15px; display: flex; align-items: center;">
          <mat-datepicker-toggle matPrefix [for]="picker" ></mat-datepicker-toggle>

          <mat-label style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: start; "
                     [ngStyle]="{'color': filtroStato === 3 ? '#E55933' : 'black'}">
            Periodo Personalizzato
          </mat-label>

          <mat-date-range-input [rangePicker]="picker" style="margin-left: 20px">
            <input matStartDate placeholder="Start date">
            <input matEndDate placeholder="End date">
          </mat-date-range-input>

          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

      </div>
    </div>





  </div>

</div>
