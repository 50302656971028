import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserDataService } from '../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { AggiuntaPiatto } from "../aggiunta-piatto/aggiunta-piatto";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CommonService } from "../../shared/services/firestore/common.service";
import * as Papa from 'papaparse';
import {EditMagazzino} from "../edit-magazzino/edit-magazzino";
import {EditCategories} from "../edit-categorie/edit-categories";
import { ChangeDetectorRef } from '@angular/core';
import {InventoryComponent} from "../inventoryComponent/inventory.component";
import {map} from "rxjs/operators";
import {EditInventario} from "../edit-inventario/edit-inventario";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {AddOrdineMagazzino} from "../add-ordine-magazzino/add-ordine-magazzino";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ordini-magazzino',
  templateUrl: './carrelloMagazzino.html',
  styleUrls: ['./carrelloMagazzino.css']
})
export class CarrelloMagazzino implements OnInit {
  dataSourceDipendenti2 = new MatTableDataSource<any | Group>();
  public dataSource = new MatTableDataSource<any | Group>([]);

  @ViewChild(MatPaginator) paginatorDipendenti2: MatPaginator;
  @ViewChild(MatSort) sortDipendenti2: MatSort;

  _alldata: any[];
  menu = [];
  columns: any[];
  displayedColumns: string[];
  groupByColumns: string[] = [];
  displayedColumnsDipendenti2: string[] = [];

  modalOptions = {
    backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true
  };

  display = 'none';
  closeResult = '';
  nome = 'Totale';
  nome2 = 'Mancia per Mike';
  luogo = 'Rimini ,';
  prezzo = '26.00';
  prezzo2 = '2.60';
  percentuale = 0;
  percentuale2 = 10;
  user;
  ristorante;
  ifCarrello;
  carrelloTmp;
  carrello;
  numeroElementi = 0;
  tot = 0;
  lista = 0;

  idRistorante = '';
  totalCartPrice: number = 0;
  isViewMode: boolean = false;

  constructor(
      private router: Router,
      private userService: UserDataService,
      private _location: Location,
      private modalService: NgbModal,
      public dialog: MatDialog,
      private cdRef: ChangeDetectorRef,
      public commonService: CommonService,
      public dataService: DataServiceGenerico,
      public dataServiceGenerico: DataServiceGenerico,
      private dialogRef: MatDialogRef<CarrelloMagazzino>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.columns = [{
      field: 'Titolo'
    }, {
      field: 'UM'
    }, {
      field: 'Prezzo'
    }, {
      field: 'Rimuovi'
    }];
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['Categoria'];
  }

  openDialogAdd(row:any): any {
    const dialogRef = this.dialog.open(AddOrdineMagazzino, {
      data: row
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSyncMenuChange(row: any) {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const warehouseRef = db.collection('magazzino_test').doc(restaurantName);

    const newSyncMenuValue = row.SyncMenu === '1' ? '0' : '1';

    const updatedItems = this._alldata.map(item => {
      if (item.id === row.id) {
        return { ...item, SyncMenu: newSyncMenuValue };
      }
      return item;
    });

    warehouseRef.update({ items: updatedItems })
        .then(() => {
          console.log('SyncMenu aggiornato con successo su Firestore!');
          row.SyncMenu = newSyncMenuValue;
          this.cdRef.detectChanges();
        })
        .catch(error => {
          console.error('Errore durante l\'aggiornamento di SyncMenu su Firestore:', error);
        });
  }

  immagini = {}

  ngOnInit(): void {
    console.log("data: ", this.data)
    if (this.data && this.data !== "") {
      this.isViewMode = true;
      this.fetchOrderData(this.data['orderId']);
      // Modifica le colonne in base a isViewMode
      this.columns = [{
        field: 'Titolo'
      }, {
        field: 'UM'
      }, {
        field: 'Prezzo'
      }];
    } else {
      this.fetchCarrelloData();
      // Quando non è in modalità visualizzazione, includi la colonna Aggiungi
      this.columns = [{
        field: 'Titolo'
      }, {
        field: 'UM'
      }, {
        field: 'Prezzo'
      }, {
        field: 'Rimuovi'
      }];
    }
    // Aggiorna displayedColumns dopo aver modificato columns
    this.displayedColumns = this.columns.map(column => column.field);
  }

  fetchOrderData(orderId: string): void {
    const db = firebase.firestore();
    const orderRef = db.collection('ordini_magazzino_test').doc(orderId);

    orderRef.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.carrelloMagazzino || [];
        this.dataSource.data = items;
        this.calculateTotalCartPrice();
        console.log('Order items:', items);
      } else {
        console.error('No order found with ID:', orderId);
      }
    }).catch((error) => {
      console.error('Error retrieving order data:', error);
    });
  }

  fetchCarrelloData(): void {
    const db = firebase.firestore();
    const cartRef = db.collection('ristoranti').doc(this.dataService.getRistorante1());

    cartRef.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.carrelloMagazzino || [];
        this.dataSource.data = items;
        this.calculateTotalCartPrice();
        console.log('Items in cart:', items);
      } else {
        console.error('No document found in carrello_test');
      }
    }).catch((error) => {
      console.error('Error retrieving carrello_test data:', error);
    });
  }

  calculateTotalCartPrice(): void {
    this.totalCartPrice = this.dataSource.data.reduce((total, item) => {
      const price = parseFloat(item.Prezzo) || 0;
      return total + price;
    }, 0);
  }

  filterValue = ''
  applyFilter(filterValue: string): void {
    this.filterValue = filterValue
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeItem(itemId: string): void {
    if (this.isViewMode) return; // Don't allow removal in view mode

    const db = firebase.firestore();
    const cartRef = db.collection('ristoranti').doc(this.dataService.getRistorante1());

    cartRef.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.carrelloMagazzino || [];
        const updatedItems = items.filter((item: any) => item.id !== itemId);

        cartRef.set({ carrelloMagazzino: updatedItems }, {merge: true})
            .then(() => {
              console.log('Item removed from cart successfully!');
              this.fetchCarrelloData();
            })
            .catch((error) => {
              console.error('Error removing item from Firestore cart:', error);
            });
      } else {
        console.error('No document found in carrello_test');
      }
    }).catch((error) => {
      console.error('Error retrieving carrello_test data for removal:', error);
    });
  }

  shouldShowSaveButton(): boolean {
    return !this.isViewMode;
  }

  shouldShowRemoveButton(): boolean {
    return !this.isViewMode;
  }

  async saveOrder(): Promise<void> {
    if (this.isViewMode) return; // Don't allow saving in view mode

    const db = firebase.firestore();
    const cartRef = db.collection('ristoranti').doc(this.dataService.getRistorante1());
    const ordiniRef = db.collection('ordini_magazzino_test');

    try {
      const doc = await cartRef.get();

      if (!doc.exists) {
        console.error('No document found in ristoranti');
        return;
      }

      const data = doc.data();
      const carrelloItems = data?.carrelloMagazzino || [];

      if (carrelloItems.length === 0) {
        console.log('Il carrello è vuoto');
        return;
      }

      const nuovoOrdineRef = ordiniRef.doc();
      const nuovoIdOrdine = nuovoOrdineRef.id;

      const ordine = {
        data: firebase.firestore.Timestamp.now(),
        carrelloMagazzino: carrelloItems,
        idOrdine: nuovoIdOrdine,
        ristorante: this.dataService.getRistorante1()
      };

      await nuovoOrdineRef.set(ordine);
      await cartRef.set({ carrelloMagazzino: [] }, { merge: true });

      console.log('Ordine salvato con successo!');
      this.fetchCarrelloData();

    } catch (error) {
      console.error('Errore durante il salvataggio dell\'ordine:', error);
    }
  }

  filtraRicerca(data: any, filter: any)
  {
    const searchStr = Object.keys(data).reduce((currentTerm, key) => {
      return currentTerm + (data[key] ? data[key].toString().toLowerCase() : '');
    }, '');
    return searchStr.indexOf(filter) !== -1;
  }

  groupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, true);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  unGroupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, false);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  checkGroupByColumn(field, add) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if (add) {
        this.groupByColumns.push(field);
      }
    }
  }

  groupHeaderClick(row: Group) {
    console.log("row: ", row)
    row.expanded = !row.expanded;

    this.dataSource.data.forEach(item => {
      if (item == row) {
        if (item instanceof Group) {
          this.toggleChildGroupVisibility(item);
        }
      }
    });

    this.dataSource.filter = performance.now().toString();
  }

  toggleChildGroupVisibility(group: Group) {
    console.log("toggleVisibility: ", group)
    this.dataSource.data.forEach(item => {
      if (item.Categoria === group.Categoria) {
        if (item instanceof Group) {
        }else
        {
          item.visible = group.expanded;
        }
      }
    });
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    const result = this.getSublevel(data, 0, groupByColumns, rootGroup);
    result.forEach(item => {
      if (item instanceof Group && item.level === 1) {
        item.visible = true;
      }
    });

    console.log("result: ", result)
    return result;
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
        data?.map(row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          result.expanded = parent.expanded;
          result.visible = false;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }),
        JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups?.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }
  uniqueBy(a, key) {
    const seen = {};
    return a?.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index: number, item: any): boolean {
    return item instanceof Group;
  }
}

class Group {
  level: number = 0;
  parent: Group | null = null;
  expanded: boolean = true;
  visible: boolean = true;
  totalCounts: number = 0;
  [key: string]: any;

  constructor() {
    this.level = 0;
    this.parent = null;
    this.expanded = true;
    this.visible = false;
    this.totalCounts = 0;
  }
}