<div class="row" style="height: 95vh; {{this.larghezza}} margin-top: 15px; border-radius: 20px;">

  <div class="row1" style="float: left; width: 100%; height: 65px; margin-top: 20px; border-radius: 20px; background-color: white; border: solid 1px #e3e2e2;  ">


    <div *ngFor="let element of menu; let i = index" class="slider" style="float: left; cursor: pointer; height: 55px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 60px; font-weight: 500; display: flex; align-items: center;" [style.background-color]="sezione == i? 'rgba(181,181,181,0.2)': 'transparent'" [style.width]="width() + '%'"  (click)="sezione = i" >

      <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%; position: relative; ">
        <img class="store"
             style="width: 40px !important; height: 40px !important; max-height: 40px !important; max-width: 40px !important; background-size: 100% auto;"
             src="{{element.menu[0]['image_link']}}"
             onerror="if (this.src != 'error.jpg') this.src = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a';">
      </div>


    </div>


  </div>


  <div style="overflow-y: auto; float: left; width: 100%; height: 80vh; border-radius: 30px; background-color: transparent; padding-top: 10px; " *ngIf="menu.hasOwnProperty(sezione)">

    <div *ngFor="let piatto of menu[sezione]['menu'], let i = index" >

      <div style="position: relative; float: left; cursor: pointer; width: 23%; border-radius: 20px; background-color: white; margin-left: 1%; border: 1px solid #e3e2e2; text-align: left; margin-top: 10px; display: flex; flex-direction: column; align-items: center;" [style.min-height]="piatto['descrizione'] !== '' ? '200px' : '180px'" (click)="setPreferito(piatto)">

        <img [src]="piatto.image_link" style="height: 100px; margin-top: 15px; background-color: transparent;">

        <span style="width: calc(100% - 20px); background-color: transparent; margin-top: 5px; text-align: center; margin-left: 10px; font-weight: 700; margin-right: 10px; font-size: 11px">{{piatto.title}}</span>
        <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; margin-top: auto; padding-bottom: 10px;">
          <div style="padding-left: 10px; ">
            <div style="background-color: transparent; font-size: small; border-radius: 12px; color: white; font-weight: bold; aspect-ratio: 1/1; padding: 3px 6px;">
              <img *ngIf="!preferito(piatto)" style="height: 30px; aspect-ratio: 1/1" src="assets/icons/image-removebg-preview.png">
                <img *ngIf="preferito(piatto)" style="height: 30px; aspect-ratio: 1/1" src="assets/icons/image-removebg-preview-filled.png">
            </div>
          </div>
          <span style="background-color: transparent; font-size: small; font-weight: bold; padding-right: 10px; text-align: right;">{{(+piatto.price).toFixed(2)}}€</span>
        </div>
      </div>
    </div>

  </div>



</div>


