import {Component, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import { retry, catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import firebase from 'firebase/app';
import { Group } from "../magazzino/magazzino";

export interface InventoryRow {
  id: string;
  Titolo: string;
  Categoria: string;
  Quantita: string;
  UM: string;
  lvl: LevelData[];
  maxLvl: number;
  isEditRow?: boolean;
  newQuantities?: string[];
  quantityTotals?: {
    livello1: string;
    livello2: string;
    livello3: string;
    livello4: string;
  };
  cronologia?: Array<{
    data: string;
    quantitaPrec: string;
    quantitaAgg: string;
    lvl: LevelData[];
    modalita: string;
  }>;
}


interface LevelData {
  quantita: string;
  um: string;
  barCode: string;
}

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<any | Group>;
  _alldata: any[];
  displayedColumns = ['Titolo', 'livello1', 'livello2', 'livello3', 'livello4'];
  groupByColumns: string[] = ['Categoria'];
  operationMode: 'aggiunta' | 'scarico' | 'scarti' | 'trasferimento' = 'aggiunta';
  isLoading = false;
  errorMessage = '';

  selectedItemForHistory: any = null;
  isHistoryVisible = false;
  private dataSubscription: () => void;



  constructor(
      private dataService: DataServiceGenerico,
      private commonService: CommonService,
      private cdr: ChangeDetectorRef
  ) {
    this.dataSource = new MatTableDataSource<any | Group>([]);
    this.displayedColumns = ['Titolo', 'livello1', 'livello2', 'livello3', 'livello4'];
    // Non serve aggiungere 'groupHeader' a displayedColumns
  }

  ngOnInit(): void {
    this.loadInventoryData();
    this.subscribeToDataChanges();
  }




  showHistory(item: any) {
    this.selectedItemForHistory = item;
    // Aggiusta il layout della tabella
    this.isHistoryVisible = true;
  }

  private subscribeToDataChanges() {
    const magazzinoRef = firebase.firestore()
        .collection('magazzino_test')
        .doc(this.dataService.getRistorante1());

    // L'onSnapshot restituisce una funzione di unsubscribe
    this.dataSubscription = magazzinoRef.onSnapshot(doc => {
      if (doc.exists) {
        const data = doc.data();
        if (data && data.items) {
          this._alldata = this.transformData(data.items);
          this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);

          if (this.selectedItemForHistory) {
            const updatedItem = this._alldata.find(
                item => !item.isEditRow && item.id === this.selectedItemForHistory.id
            );
            if (updatedItem) {
              this.selectedItemForHistory = updatedItem;
            }
          }

          this.cdr.detectChanges();
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription(); // Chiama direttamente la funzione invece di .unsubscribe()
    }
  }

  private loadInventoryData(): void {
    this.isLoading = true;
    this.commonService.getMagazzino(this.dataService.getRistorante1())
        .pipe(
            retry(3),
            catchError(error => {
              this.errorMessage = 'Errore nel caricamento dei dati';
              this.isLoading = false;
              console.error('Error loading data:', error);
              return EMPTY;
            })
        )
        .subscribe({
          next: (response) => {
            if (response && response.items) {
              this._alldata = this.transformData(response.items);
              this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
              this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
              this.dataSource.filter = performance.now().toString();
              this.isLoading = false;
            }
          },
          error: (error) => {
            this.errorMessage = 'Errore nel caricamento dei dati';
            this.isLoading = false;
            console.error('Error in subscription:', error);
          }
        });
  }

  private calculateLevelTotal(item: any, levelIndex: number): string {
    if (!item.lvl || !item.lvl[levelIndex]) return '';

    let total = parseInt(item.lvl[levelIndex].quantita || '0', 10);
    if (item.cronologia) {
      item.cronologia.forEach(log => {
        if (log.lvl && log.lvl[levelIndex]) {
          const changeQty = parseInt(log.lvl[levelIndex].quantita || '0', 10);
          switch(log.modalita) {
            case 'aggiunta':
              total += changeQty;
              break;
            case 'scarico':
            case 'scarti':
            case 'trasferimento':
              total -= changeQty;
              break;
            case 'inventario':
              total = changeQty;
              break;
          }
        }
      });
    }

    return total > 0 ? `${total} ${item.lvl[levelIndex].um}` : '';
  }

  private calculateTotalQuantities(item: any): any {
    return {
      livello1: this.calculateLevelTotal(item, 0),
      livello2: this.calculateLevelTotal(item, 1),
      livello3: this.calculateLevelTotal(item, 2),
      livello4: this.calculateLevelTotal(item, 3)
    };
  }

  private transformData(items: any[]): InventoryRow[] {
    const transformedData: InventoryRow[] = [];

    items.forEach(item => {
      // View row with current totals
      transformedData.push({
        ...item,
        isEditRow: false,
        quantityTotals: this.calculateTotalQuantities(item)
      });

      // Edit row
      transformedData.push({
        ...item,
        isEditRow: true,
        newQuantities: new Array(item.maxLvl || 4).fill('0')
      });
    });

    return transformedData;
  }

  // Group handling methods
  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) return data;

    const groups = this.uniqueBy(
        data.map(row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }),
        JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter(item => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.dataSource.filter = performance.now().toString();
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter(row => {
      if (!(row instanceof Group)) return false;
      let match = true;
      this.groupByColumns.forEach(column => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) return true;
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  onOperationModeChange(mode: 'aggiunta' | 'scarico' | 'scarti' | 'trasferimento'): void {
    this.operationMode = mode;
  }

  onQuantityChange(row: InventoryRow, levelIndex: number, value: string): void {
    if (row.isEditRow && row.newQuantities) {
      row.newQuantities[levelIndex] = value;
    }
  }

  async saveChanges(): Promise<void> {
    try {
      this.isLoading = true;
      const db = firebase.firestore();
      const restaurantName = this.dataService.getRistorante1();
      const magazzinoRef = db.collection('magazzino_test').doc(restaurantName);

      const editRows = this._alldata.filter(row => row.isEditRow);
      const updates = editRows.map(async (row) => {
        if (!row.newQuantities?.some(q => parseInt(q) !== 0)) {
          return null;
        }

        const currentRow = this._alldata.find(r => !r.isEditRow && r.id === row.id);
        if (!currentRow) return null;

        const updateData = {
          id: row.id,
          lvl: row.lvl.map((level, idx) => ({
            ...level,
            quantita: row.newQuantities[idx] || '0'
          })),
          cronologia: [
            ...(currentRow.cronologia || []),
            {
              data: new Date().toLocaleString('it-IT', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              }),
              quantitaPrec: JSON.stringify(currentRow.quantityTotals),
              quantitaAgg: JSON.stringify(row.newQuantities),
              lvl: row.lvl.map((level, idx) => ({
                quantita: row.newQuantities[idx] || '0',
                um: level.um,
                barCode: level.barCode
              })),
              modalita: this.operationMode
            }
          ]
        };

        return updateData;
      });

      const validUpdates = (await Promise.all(updates)).filter(update => update !== null);

      if (validUpdates.length > 0) {
        const doc = await magazzinoRef.get();
        if (doc.exists) {
          const data = doc.data();
          const items = data?.items || [];

          const updatedItems = items.map(item => {
            const update = validUpdates.find(u => u?.id === item.id);
            if (update) {
              return { ...item, ...update };
            }
            return item;
          });

          await magazzinoRef.update({ items: updatedItems });
          this.loadInventoryData();
        }
      }

      this.isLoading = false;
    } catch (error) {
      console.error('Error saving changes:', error);
      this.errorMessage = 'Errore durante il salvataggio';
      this.isLoading = false;
    }
  }
}