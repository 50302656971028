import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener, Inject, OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoriesModel} from '../../shared/models/menu/categories.model';
import {newArray} from '@angular/compiler/src/util';
import {MenuService} from '../../shared/services/firestore/menu.service';
import {map} from 'rxjs/operators';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';
import {User} from '../../shared/services/user';
import firebase from 'firebase/app';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";

@Component({
  selector: 'app-fidelity',
  template: `
    <div class="dialog-container">
      <!-- Header -->
      <div class="dialog-header">
        <div class="header-content">
          <div class="title">
            {{ utenteSelezionato ? 'Dettagli Cliente' : 'Seleziona Cliente' }}
          </div>
          <button mat-icon-button (click)="chiudi()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>

      <!-- Content -->
      <div class="dialog-content">
        <!-- Ricerca Utenti -->
        <div *ngIf="!utenteSelezionato" class="search-section">
          <mat-form-field appearance="outline" class="search-field">
            <mat-label>Cerca cliente</mat-label>
            <input matInput [(ngModel)]="testo" (ngModelChange)="ricerca()" placeholder="Nome, email o telefono...">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>

          <div class="users-list">
            <div *ngFor="let utente of utentiFilter"
                 class="user-card"
                 (click)="selezionaUtente(utente)">
              <div class="user-info">
                <div class="user-avatar">
                  {{ utente.displayName?.charAt(0).toUpperCase() || 'U' }}
                </div>
                <div class="user-details">
                  <div class="user-name">{{ utente.displayName || 'Utente' }}</div>
                  <div class="user-email">{{ utente.email }}</div>
                </div>
              </div>
              <div class="user-points">
                <div class="points-badge">
                  <mat-icon>stars</mat-icon>
                  {{ utente.punti || 0 }} pt
                </div>
              </div>
            </div>

            <div *ngIf="utentiFilter.length === 0 && testo" class="no-results">
              <mat-icon>search_off</mat-icon>
              <div>Nessun risultato trovato</div>
            </div>
          </div>
        </div>

        <!-- Dettaglio Utente e Offerte -->
        <div *ngIf="utenteSelezionato" class="user-detail-section">
          <!-- Info Utente -->
          <div class="selected-user-info">
            <div class="user-avatar large">
              {{ utenteSelezionato.displayName?.charAt(0).toUpperCase() || 'U' }}
            </div>
            <div class="user-main-info">
              <div class="user-name">{{ utenteSelezionato.displayName }}</div>
              <div class="user-contacts">
                <span class="user-email">{{ utenteSelezionato.email }}</span>
                <span *ngIf="utenteSelezionato.telefono" class="user-phone">
                  · {{ utenteSelezionato.telefono }}
                </span>
              </div>
            </div>
            <div class="user-points-detail">
              <div class="points-value">{{ utenteSelezionato.punti || 0 }}</div>
              <div class="points-label">Punti</div>
            </div>
          </div>

          <!-- Lista Offerte -->
          <div class="offers-section">
            <div class="section-title">Offerte Disponibili</div>
            <div class="offers-grid">
              <div *ngFor="let offerta of offerte"
                   class="offer-card"
                   [class.selected]="selectedOffer === offerta"
                   (click)="selectOffer(offerta)">
                <img [src]="offerta.image_link" [alt]="offerta.nome" class="offer-image">
                <div class="offer-content">
                  <div class="offer-name">{{ offerta.nome }}</div>
                  <div class="offer-details">
                    <div class="offer-discount" *ngIf="offerta.sconto">
                      -{{ offerta.sconto }}%
                    </div>
                    <div class="offer-points" *ngIf="offerta.punti">
                      {{ offerta.punti }} pt
                    </div>
                    <div class="offer-price" *ngIf="offerta.prezzo">
                      {{ offerta.prezzo }}€
                    </div>
                  </div>
                </div>
                <div class="offer-type-badge" *ngIf="offerta.personale">
                  Personale
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="dialog-footer" *ngIf="utenteSelezionato">
        <button mat-stroked-button color="primary" (click)="utenteSelezionato = undefined">
          <mat-icon>arrow_back</mat-icon>
          Cambia Cliente
        </button>
        <button mat-flat-button color="primary"
                (click)="conferma()">
          Conferma
          <mat-icon>check</mat-icon>
        </button>
      </div>
    </div>
  `,
  styles: [`
    .dialog-container {
      display: flex;
      flex-direction: column;
      height: 600px;
      width: 800px;
      background: white;
      border-radius: 24px;
      overflow: hidden;
    }

    .dialog-header {
      padding: 16px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
    }

    .dialog-content {
      flex: 1;
      overflow-y: auto;
      padding: 24px;
    }

    .search-section {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .search-field {
      width: 100%;
    }

    .users-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .user-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      border-radius: 12px;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.12);
      cursor: pointer;
      transition: all 0.2s;
    }

    .user-card:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    .user-info {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .user-avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: #F94F4F;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
    }

    .user-avatar.large {
      width: 56px;
      height: 56px;
      border-radius: 28px;
      font-size: 24px;
    }

    .user-details {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .user-name {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
    }

    .user-email {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }

    .points-badge {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 4px 12px;
      background: #FFF4E5;
      color: #F59E0B;
      border-radius: 16px;
      font-weight: 500;
    }

    .selected-user-info {
      display: flex;
      align-items: center;
      gap: 24px;
      padding: 24px;
      background: #F5F5F5;
      border-radius: 16px;
      margin-bottom: 24px;
    }

    .user-main-info {
      flex: 1;
    }

    .user-contacts {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 4px;
    }

    .user-points-detail {
      text-align: center;
    }

    .points-value {
      font-size: 24px;
      font-weight: 600;
      color: #F94F4F;
    }

    .points-label {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }

    .offers-section {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .section-title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
    }

    .offers-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 16px;
    }

    .offer-card {
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 12px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      transition: all 0.2s;
    }

    .offer-card.selected {
      border-color: #F94F4F;
      box-shadow: 0 2px 8px rgba(249, 79, 79, 0.15);
    }

    .offer-image {
      width: 100%;
      height: 120px;
      object-fit: cover;
    }

    .offer-content {
      padding: 12px;
    }

    .offer-name {
      font-weight: 500;
      margin-bottom: 4px;
    }

    .offer-details {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 8px;
    }

    .offer-discount {
      background: #FFB800;
      color: black;
      padding: 2px 8px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
    }

    .offer-price {
      color: #B91D73;
      font-weight: 600;
      font-size: 16px;
    }

    .offer-type-badge {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 4px 8px;
      background: #34BFA3;
      color: white;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 500;
    }

    .dialog-footer {
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }

    .no-results {
      text-align: center;
      padding: 32px;
      color: rgba(0, 0, 0, 0.6);
    }
  `]
})
export class Fidelity implements OnDestroy {
  testo = '';
  nuovoUtente = false;
  utenteSelezionato: any;
  utentiFilter: any[] = [];
  selectedOffer: any;
  private restaurantId: string;

  offerte: any[] = [];

  constructor(
      public dialogRef: MatDialogRef<Fidelity>,
      private dataService: DataServiceGenerico,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.initComponent();
  }

  async initComponent() {
    this.restaurantId = await this.dataService.getRistorante();
    this.ricerca();
  }

  async ricerca() {
    if (!this.restaurantId) return;

    try {
      const querySnapshot = await firebase.firestore()
          .collection('users')
          .where('ristorantiFidelity', 'array-contains', this.restaurantId)
          .get();

      const utenti = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      this.utentiFilter = utenti.filter(utente =>
          this.testo.trim() === '' ||
          Object.keys(utente).some(k =>
              typeof utente[k] === 'string' &&
              utente[k].toLowerCase().includes(this.testo.toLowerCase())
          )
      );
    } catch (error) {
      console.error('Errore nel recupero degli utenti:', error);
      this.utentiFilter = [];
    }
  }

  selezionaUtente(utente: any) {
    this.utenteSelezionato = utente;
    this.selectedOffer = null; // Reset selezione offerta
    this.loadOfferte(); // Carica le offerte per l'utente
  }

  async loadOfferte() {
    try {
      const scountsSnapshot = await firebase.firestore()
          .collection('sconti')
          .where('ristoranteId', '==', this.restaurantId)
          .get();

      const restaurantDoc = await firebase.firestore()
          .collection('ristoranti')
          .doc(this.restaurantId)
          .get();

      const restaurantData = restaurantDoc.data() || {};

      this.offerte = scountsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          nome: data.nome || 'Offerta',
          punti: data.punti,
          personale: data.isAccountDiscount || false,
          image_link: data.imageUrl || 'https://picsum.photos/200',
          sconto: data.sconto,
          prezzo: data.prezzo,
          prodotto: data.prodotto || null,
          restaurant: {
            nome: restaurantData.nome,
            logo: restaurantData.logo,
          }
        };
      });
    } catch (error) {
      console.error('Errore nel caricamento delle offerte:', error);
      this.offerte = [];
    }
  }

  selectOffer(offerta: any) {
    this.selectedOffer = offerta;
  }

  conferma() {
    // Se non c'è un'offerta selezionata, ritorna solo l'utente
    if (!this.selectedOffer) {
      this.dialogRef.close({
        user: this.utenteSelezionato,
        offer: null
      });
      return;
    }

    // Prepara l'oggetto da aggiungere al carrello
    let carrelloItem;

    if (this.selectedOffer.personale) {
      // Se è uno sconto personale
      carrelloItem = {
        id: this.selectedOffer.id,
        image_link: this.selectedOffer.image_link,
        title: this.selectedOffer.nome,
        prezzo: -this.selectedOffer.sconto,
        price: -this.selectedOffer.sconto,
        tipo: true,
        quantita: 1,
        punti: this.selectedOffer.punti,
        sconto: true
      };
    } else {
      // Se è un prodotto scontato
      const prezzoScontato = 0;
      if (this.selectedOffer.prodotto) {

        carrelloItem = {
          id: this.selectedOffer.id,
          image_link: this.selectedOffer.imageUrl,
          title: this.selectedOffer.prodotto[0],
          prezzo: -this.selectedOffer.sconto,
          price: -this.selectedOffer.sconto,
          quantita: 1,
          punti: this.selectedOffer.punti
        };
      }else
      {
        carrelloItem = {
          id: this.selectedOffer.id,
          image_link: this.selectedOffer.imageUrl,
          title: this.selectedOffer.nome,
          prezzo: prezzoScontato,
          price: prezzoScontato,
          quantita: 1,
          punti: this.selectedOffer.punti,
          sconto: true

        };
      }
    }

    // Calcola i nuovi punti dell'utente
    const puntiRimanenti = this.utenteSelezionato.punti - this.selectedOffer.punti;

    // Aggiorna i punti dell'utente nel database
    firebase.firestore()
        .collection('users')
        .doc(this.utenteSelezionato.id)
        .update({
          punti: puntiRimanenti
        });

    // Chiudi il dialog con utente, offerta e item da aggiungere al carrello
    this.dialogRef.close({
      user: this.utenteSelezionato,
      offer: this.selectedOffer,
      carrelloItem: carrelloItem
    });
  }

  chiudi() {
    this.dialogRef.close();
  }

  ngOnDestroy() {}
}