import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {CommonService} from "../../shared/services/firestore/common.service";
import firebase from 'firebase/app';
import {Router} from "@angular/router";

interface MonthlyStats {
    lastMonth: number;
    currentMonth: number;
    projected: number;
    percentageChange: number;
}

interface TopPerformer {
    id: number;
    name: string;
    revenue: number;
    totalYearRevenue: number;
    percentage: number;
}

interface ChannelStat {
    name: string;
    username: string;
    icon: string;
    growth: number;
    orders: number;
}

interface TopProduct {
    title: string;
    image_link: string;
    quantity: number;
    revenue: number;
    percentage: number;
}

@Component({
    selector: 'app-analytics-dashboard',
    templateUrl: './analytics-dashboard.component.html',
    styleUrls: ['./analytics-dashboard.component.css']
})
export class AnalyticsDashboardComponent implements OnInit, AfterViewInit {
    @ViewChild('revenueChart') revenueChartCanvas: ElementRef;

    monthlyStats: MonthlyStats = {
        lastMonth: 0,
        currentMonth: 0,
        projected: 0,
        percentageChange: 0
    };

    topProducts: TopProduct[] = [];
    totalYearRevenue: number = 0;
    loading = true;
    error = false;

    monthlyRevenueData: number[] = new Array(12).fill(0);
    months = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
    private chart: Chart;


    aiSuggestion = {
        title: "Suggerimento AI",
        content: "Crea un menu combinato Caffè + Croissant per aumentare l'upselling. Basandoci sui dati, questa combinazione potrebbe incrementare le vendite del 15%."
    };


    constructor( public router: Router,
                 private commonService: CommonService,
                private dataService: DataServiceGenerico) {}

    ngOnInit(): void {
        this.loadData();
    }

    ngAfterViewInit(): void {
        this.initChart();
    }

    private calculateMonthlyStats(orders: any[]) {
        const now = new Date();
        const currentMonth = now.getMonth();
        const lastMonth = currentMonth - 1 >= 0 ? currentMonth - 1 : 11;
        const currentYear = now.getFullYear();
        const lastMonthYear = currentMonth - 1 >= 0 ? currentYear : currentYear - 1;

        let lastMonthRevenue = 0;
        let currentMonthRevenue = 0;

        orders.forEach(order => {
            const orderDate = new Date(parseInt(order.data));
            const orderMonth = orderDate.getMonth();
            const orderYear = orderDate.getFullYear();

            // Calcola il fatturato per ordine
            let orderRevenue = 0;
            if (order.carrello) {
                Object.values(order.carrello).forEach((item: any) => {
                    const price = typeof item.prezzo === 'number' ? item.prezzo : parseFloat(item.prezzo || '0');
                    const quantity = typeof item.quantita === 'number' ? item.quantita : parseInt(item.quantita || '0');
                    orderRevenue += price * quantity;
                });
            }

            // Assegna il fatturato al mese corretto
            if (orderYear === currentYear && orderMonth === currentMonth) {
                currentMonthRevenue += orderRevenue;
            } else if (orderYear === lastMonthYear && orderMonth === lastMonth) {
                lastMonthRevenue += orderRevenue;
            }
        });

        // Calcola la proiezione per il mese corrente
        const dayOfMonth = now.getDate();
        const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
        const projected = (currentMonthRevenue / dayOfMonth) * daysInMonth;

        // Calcola la variazione percentuale
        const percentageChange = lastMonthRevenue > 0
            ? ((currentMonthRevenue - lastMonthRevenue) / lastMonthRevenue) * 100
            : 0;

        this.monthlyStats = {
            lastMonth: lastMonthRevenue,
            currentMonth: currentMonthRevenue,
            projected: projected,
            percentageChange: percentageChange
        };
    }

    private calculateMonthlyRevenue(orders: any[]) {
        // Reset array
        this.monthlyRevenueData = new Array(12).fill(0);

        orders.forEach(order => {
            if (!order.data || !order.carrello) return;

            const orderDate = new Date(parseInt(order.data));
            const month = orderDate.getMonth();

            // Calcola il fatturato per questo ordine
            let orderRevenue = 0;
            Object.values(order.carrello).forEach((item: any) => {
                const price = typeof item.prezzo === 'number' ? item.prezzo : parseFloat(item.prezzo || '0');
                const quantity = typeof item.quantita === 'number' ? item.quantita : parseInt(item.quantita || '0');
                orderRevenue += price * quantity;
            });

            // Aggiungi al mese corrispondente
            this.monthlyRevenueData[month] += orderRevenue;
        });
    }



    private async loadData() {
        try {
            const restaurantId = await this.dataService.getCurrentRestaurantId()
            const restaurantName = await this.dataService.getRistorante();

            // Carica tutti gli ordini
            const ordersSnapshot = await firebase.firestore()
                .collection('ordini')
                .where('ristorante', '==', restaurantName)
                .get();

            const orders = ordersSnapshot.docs.map(doc => doc.data());

            // Calcola le statistiche mensili
            this.calculateMonthlyStats(orders);
            // Calcola il fatturato mensile per il grafico
            this.calculateMonthlyRevenue(orders);

            // Se il grafico esiste già, aggiornalo
            if (this.chart) {
                this.chart.data.datasets[0].data = this.monthlyRevenueData;
                this.chart.update();
            }

            // Calcola i prodotti più venduti
            this.loadTopProducts();

        } catch (error) {
            console.error('Errore nel caricamento dei dati:', error);
            this.loading = false;
            this.error = true;
        }
    }

    private async loadTopProducts() {
        try {
            const restaurantID = await this.dataService.getCurrentRestaurantId();
            const restaurantName = await this.dataService.getRistorante();

            // Carica tutti gli ordini da Firestore
            const ordersSnapshot = await firebase.firestore()
                .collection('ordini')
                .where('ristorante', '==', restaurantName)
                .get();

            console.log('aaaa',restaurantName);
            console.log('bbbb',ordersSnapshot);

            const productMetrics: { [key: string]: TopProduct } = {};

            // Calcola le metriche per ogni prodotto
            ordersSnapshot.docs.forEach(doc => {
                const order = doc.data();

                if (order.carrello) {
                    Object.values(order.carrello).forEach((item: any) => {
                        if (!productMetrics[item.title]) {
                            productMetrics[item.title] = {
                                title: item.title,
                                image_link: item.image_link || '',
                                quantity: 0,
                                revenue: 0,
                                percentage: 0
                            };
                        }

                        const price = typeof item.prezzo === 'number' ? item.prezzo : parseFloat(item.prezzo || '0');
                        const quantity = typeof item.quantita === 'number' ? item.quantita : parseInt(item.quantita || '0');

                        productMetrics[item.title].quantity += quantity;
                        productMetrics[item.title].revenue += price * quantity;
                        this.totalYearRevenue += price * quantity;
                    });
                }
            });

            // Converti in array e ordina per fatturato
            this.topProducts = Object.values(productMetrics)
                .sort((a, b) => b.revenue - a.revenue)
                .slice(0, 4);

            // Calcola le percentuali sul fatturato totale
            this.topProducts.forEach(product => {
                product.percentage = (product.revenue / this.totalYearRevenue) * 100;
            });

            this.loading = false;
        } catch (error) {
            console.error('Errore nel caricamento dei top prodotti:', error);
            this.loading = false;
            this.error = true;
        }
    }

    private initChart(): void {
        if (!this.revenueChartCanvas) return;

        const ctx = this.revenueChartCanvas.nativeElement.getContext('2d');

        // Crea il gradiente per lo sfondo
        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, 'rgba(79, 70, 229, 0.4)');
        gradient.addColorStop(1, 'rgba(129, 140, 248, 0.1)');

        this.chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: this.months,
                datasets: [{
                    label: 'Fatturato Mensile',
                    data: this.monthlyRevenueData,
                    borderColor: '#4F46E5',
                    tension: 0.4,
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 6,
                    pointHoverBackgroundColor: '#FFFFFF',
                    pointHoverBorderColor: '#4F46E5',
                    pointHoverBorderWidth: 2,
                    fill: true,
                    backgroundColor: gradient
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            font: {
                                family: 'Inter',
                                size: 12
                            },
                            color: '#64748b'
                        }
                    },
                    y: {
                        grid: {
                            borderDash: [8, 4],
                            color: 'rgba(148, 163, 184, 0.1)'
                        },
                        ticks: {
                            font: {
                                family: 'Inter',
                                size: 12
                            },
                            color: '#64748b',
                            callback: (value) => '€' + value.toLocaleString()
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        backgroundColor: '#FFFFFF',
                        titleColor: '#1E293B',
                        bodyColor: '#1E293B',
                        borderColor: '#E2E8F0',
                        borderWidth: 1,
                        padding: 12,
                        displayColors: false,
                        callbacks: {
                            label: (context) => '€' + context.raw.toLocaleString()
                        }
                    }
                }
            }
        });
    }

    protected readonly navigator = navigator;
}