import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmDialogData {
    title: string;
    confirmText: string;
    cancelText: string;
    isRestore?: boolean;
}

@Component({
    selector: 'app-confirm-dialog',
    template: `
    <div class="dialog-container">
      <h2 mat-dialog-title>{{data.title}}</h2>
      <div mat-dialog-actions>
        <button class="confirm-button" [class.restore]="data.isRestore" (click)="onConfirm()">
          {{data.confirmText}}
        </button>
        <button class="cancel-button" (click)="onCancel()">
          {{data.cancelText}}
        </button>
      </div>
    </div>
  `,
    styles: [`
    .dialog-container {
      padding: 20px;
      min-width: 300px;
    }

    h2 {
      margin: 0 0 20px 0;
      font-size: 1.5rem;
      font-weight: 500;
      color: #333;
      text-align: center;
    }

    mat-dialog-actions {
      display: flex;
      justify-content: center;
      gap: 16px;
      margin: 0;
      padding: 0;
    }

    button {
      padding: 10px 24px;
      border: none;
      border-radius: 8px;
      font-weight: 500;
      font-size: 1rem;
      cursor: pointer;
      transition: transform 0.2s ease, opacity 0.2s ease;
    }

    button:hover {
      transform: translateY(-1px);
      opacity: 0.9;
    }

    .confirm-button {
      background-color: #4CAF50;
      color: white;
    }

    .confirm-button.restore {
      background-color: #2196F3;
    }

    .cancel-button {
      background-color: #f5f5f5;
      color: #333;
    }
  `]
})
export class ConfirmDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
    ) {}

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }
}