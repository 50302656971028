import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { DataServiceGenerico } from '../../../shared/services/data-service/data.service';
import { AddRestaurantDialogComponent } from './dialog/add-restaurant-dialog';
import { AddGroupDialogComponent } from './dialog/add-group-dialog';
import {AddWorkstationDialogComponent} from "./dialog/app-add-workstation-dialog";

@Component({
  selector: 'impostazioni-ristoranti',
  templateUrl: './impostazioniRistoranti.html',
  styleUrls: ['./impostazioniRistoranti.css']
})
export class ImpostazioniRistoranti implements OnInit {
  displayedColumnsRistoranti: string[] = ['nome', 'piva', 'email', 'permessi'];
  displayedColumnsGruppi: string[] = ['nome', 'ristoranti'];
  displayedColumnsWorkstations: string[] = ['nome', 'tipo', 'ristorante', 'listino', 'actions'];

  ristoranti = [];
  gruppi = [];
  workstations: any[] = [];
  restaurants: any[] = [];

  permessi = [
    'Menu',
    'Magazzino',
    'Report',
    'Preferiti',
    'Totem',
    'Gestione fidelity',
    'Fidelity',
    'Sconti'
  ];

  constructor(
      public dialog: MatDialog,
      private dataServiceGenerico: DataServiceGenerico
  ) {}

  ngOnInit(): void {
    // Subscribe to available restaurants from DataService
    this.dataServiceGenerico.availableRestaurants$.subscribe(restaurants => {
      this.restaurants = restaurants;
      this.ristoranti = restaurants;

      console.log("ristorantiii: ", this.ristoranti)

      // Load workstations when restaurants are loaded
      if (this.restaurants.length > 0) {
        this.loadWorkstations();
      }
    });

    // Initial load of restaurants
    //this.dataServiceGenerico.loadAvailableRestaurants();
  }

  async openAddRestaurantDialog(): Promise<void> {
    const dialogRef = this.dialog.open(AddRestaurantDialogComponent, {
      width: '500px',
      height: '80vh',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const db = firebase.firestore();
          const currentUser = firebase.auth().currentUser;

          const userQuery = await db
              .collection('users')
              .where('email', '==', result.email)
              .get();

          let adminUid;
          let secondaryApp;

          if (userQuery.empty) {
            secondaryApp = firebase.initializeApp({
              apiKey: "AIzaSyBYzhvaoK3MPFvrKSkEqTe8HTiLwcd-Pzo",
              authDomain: "deweats.firebaseapp.com",
              databaseURL: "https://deweats-default-rtdb.europe-west1.firebasedatabase.app",
              projectId: "deweats",
              storageBucket: "deweats.appspot.com",
              messagingSenderId: "157089671742",
              appId: "1:157089671742:web:899765e7a64cf907e03f6f",
              measurementId: "G-KCGE5JVRDK"
            }, 'Secondary');

            const userCredential = await secondaryApp.auth().createUserWithEmailAndPassword(
                result.email,
                result.password
            );

            adminUid = userCredential.user.uid;

            await db.collection('users').doc(adminUid).set({
              email: result.email,
              role: 'admin'
            });
          } else {
            adminUid = userQuery.docs[0].id;
          }

          const restaurantRef = await db.collection('ristoranti').add({
            nome: result.nome,
            piva: result.piva,
            email: result.email,
            admin: adminUid,
            permessi: [],
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: currentUser.uid
          });


          const menuRef = await db.collection('menu_test').doc(restaurantRef.id).set(
              {
                items: [],
                ristorante: restaurantRef.id
              }
          )

          restaurantRef.update(
              {
                selectedMenu: restaurantRef.id,
              }
          )

          const groupQuery = await db
              .collection('gruppi')
              .where('admin', '==', currentUser.uid)
              .limit(1)
              .get();

          if (!groupQuery.empty) {
            const groupDoc = groupQuery.docs[0];
            await groupDoc.ref.update({
              ristoranti: firebase.firestore.FieldValue.arrayUnion(restaurantRef.id)
            });
          }

          if (secondaryApp) {
            await secondaryApp.delete();
          }

        } catch (error) {
          console.error('Error creating restaurant:', error);
        }
      }
    });
  }

  async openAddGroupDialog(): Promise<void> {
    const dialogRef = this.dialog.open(AddGroupDialogComponent, {
      width: '500px',
      height: '80vh',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const db = firebase.firestore();
          const currentUser = firebase.auth().currentUser;

          const groupQuery = await db
              .collection('gruppi')
              .where('admin', '==', currentUser.uid)
              .limit(1)
              .get();

          if (!groupQuery.empty) {
            const groupDoc = groupQuery.docs[0];
            const currentGroups = groupDoc.data().gruppi || [];

            await groupDoc.ref.update({
              gruppi: [...currentGroups, {
                nomeGruppo: result.nome,
                ristoranti: []
              }]
            });
          }
        } catch (error) {
          console.error('Error creating group:', error);
        }
      }
    });
  }

  async updateGroupRestaurants(group: any): Promise<void> {
    try {
      const db = firebase.firestore();
      const currentUser = firebase.auth().currentUser;

      const groupQuery = await db
          .collection('gruppi')
          .where('admin', '==', currentUser.uid)
          .limit(1)
          .get();

      if (!groupQuery.empty) {
        const groupDoc = groupQuery.docs[0];
        const currentGroups = groupDoc.data().gruppi || [];

        const updatedGroups = currentGroups.map(g =>
            g.nomeGruppo === group.nomeGruppo
                ? {
                  ...g,
                  ristoranti: group.ristoranti.map(r => r.id)
                }
                : g
        );

        await groupDoc.ref.update({
          gruppi: updatedGroups,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });
      }
    } catch (error) {
      console.error('Error updating group restaurants:', error);
    }
  }

  async updateRestaurantPermissions(restaurant: any): Promise<void> {
    try {
      const db = firebase.firestore();
      await db.collection('ristoranti').doc(restaurant.id).update({
        permessi: restaurant.permessi,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
    } catch (error) {
      console.error('Error updating permissions:', error);
    }
  }

  compareObjects(obj1: any, obj2: any): boolean {
    if (!obj1 || !obj2) return false;
    return obj1.id === obj2.id;
  }

  private loadWorkstations() {
    const db = firebase.firestore();
    const restaurantIds = this.restaurants.map(r => r.id);

    if (restaurantIds.length === 0) {
      this.workstations = [];
      return;
    }

    db.collection('workstations')
        .where('restaurantId', 'in', restaurantIds)
        .onSnapshot(snapshot => {
          this.workstations = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
        }, error => {
          console.error('Error loading workstations:', error);
          this.workstations = [];
        });
  }

  getRistoranteNome(restaurantId: string): string {
    const restaurant = this.restaurants.find(r => r.id === restaurantId);
    return restaurant ? restaurant.nome : '';
  }

  async openAddWorkstationDialog(): Promise<void> {
    const dialogRef = this.dialog.open(AddWorkstationDialogComponent, {
      width: '500px',
      height: '80vh',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const db = firebase.firestore();
          await db.collection('workstations').add({
            name: result.name,
            type: result.type,
            restaurantId: result.restaurantId,
            priceListId: result.priceListId,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          });
        } catch (error) {
          console.error('Error creating workstation:', error);
        }
      }
    });
  }

  editWorkstation(workstation: any): void {
    const dialogRef = this.dialog.open(AddWorkstationDialogComponent, {
      width: '500px',
      height: '80vh',
      disableClose: true,
      data: workstation
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const db = firebase.firestore();
          await db.collection('workstations').doc(workstation.id).update({
            name: result.name,
            type: result.type,
            restaurantId: result.restaurantId,
            priceListId: result.priceListId,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          });
        } catch (error) {
          console.error('Error updating workstation:', error);
        }
      }
    });
  }
  async deleteWorkstation(workstation: any): Promise<void> {
    if (confirm('Sei sicuro di voler eliminare questa postazione?')) {
      try {
        const db = firebase.firestore();
        await db.collection('workstations').doc(workstation.id).delete();
      } catch (error) {
        console.error('Error deleting workstation:', error);
      }
    }
  }
}