import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserDataService } from '../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { AggiuntaPiatto } from "../aggiunta-piatto/aggiunta-piatto";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CommonService } from "../../shared/services/firestore/common.service";
import * as Papa from 'papaparse';
import {EditMagazzino} from "../edit-magazzino/edit-magazzino";
import {EditCategories} from "../edit-categorie/edit-categories";
import { ChangeDetectorRef } from '@angular/core';
import {InventoryComponent} from "../inventoryComponent/inventory.component";
import {map} from "rxjs/operators";
import {EditInventario} from "../edit-inventario/edit-inventario";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {AddOrdineMagazzino} from "../add-ordine-magazzino/add-ordine-magazzino";
import {CarrelloMagazzino} from "../carrelloMagazzino/carrelloMagazzino";


@Component({
  selector: 'app-ordini-magazzino-test',
  templateUrl: './ordiniMagazzino.html',
  styleUrls: ['./ordiniMagazzino.css']
})
export class OrdiniMagazzino implements OnInit, OnDestroy {

  dataSourceDipendenti2 = new MatTableDataSource<any | Group>();
  public dataSource = new MatTableDataSource<any | Group>([]);

  @ViewChild(MatPaginator) paginatorDipendenti2: MatPaginator;
  @ViewChild(MatSort) sortDipendenti2: MatSort;

  _alldata: any[];
  menu = [];
  columns: any[];
  displayedColumns: string[];
  groupByColumns: string[] = [];
  displayedColumnsDipendenti2: string[] = [];

  modalOptions = {
    backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {}
  };


  display = 'none';
  closeResult = '';
  nome = 'Totale';
  nome2 = 'Mancia per Mike';
  data = '23-01-2021 19.30';
  luogo = 'Rimini ,';
  prezzo = '26.00';
  prezzo2 = '2.60';
  percentuale = 0;
  percentuale2 = 10;
  user;
  ristorante;
  ifCarrello;
  carrelloTmp;
  carrello;
  numeroElementi = 0;
  tot = 0;
  lista = 0;
  cartItems: Set<string> = new Set();
  totalOrderAmount = 0;

  idRistorante = '';

  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog, private cdRef: ChangeDetectorRef,
    public commonService: CommonService, public dataService: DataServiceGenerico, public dataServiceGenerico: DataServiceGenerico
  ) {
    this.columns = [{
      field: 'Titolo'
    }, {
      field: 'UM'
    }, {
      field: 'Prezzo'
    }, {
      field: 'Aggiungi'
    }];
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['Categoria'];
  }

  openDialog(row:any): any {
    const dialogRef = this.dialog.open(EditMagazzino, {
      data: row // Passa i dati della riga al dialog
    });
    //modalRef.componentInstance.user = this.user;

  }
  unsubscribe;

  selezione = 'Carrello';

  getTabStyle(tab: string) {
    const isSelected = this.selezione === tab;
    return {
      'font-weight': isSelected ? 'bold' : '580',
      'color': isSelected ? 'black' : '#969696',
      'display': 'flex',
      'align-items': 'center',
      'height': 'calc(100% - 2px)',
      'border-bottom': isSelected ? '2px solid black' : '2px solid white',
      'padding': '0px 10px',
      'cursor': 'pointer'
    };
  }

  async fetchCartItems() {

      let restaurantId = await this.dataService.getCurrentRestaurantId();
      const cartRef = firebase.firestore()
          .collection('ristoranti')
          .doc(restaurantId);

      // Creare uno snapshot listener invece di una singola query
      this.unsubscribe = cartRef.onSnapshot((doc) => {
        if (doc.exists) {
          console.log('carrelloMagazzino: ', doc.data());
          const items = doc.data()?.carrelloMagazzino || [];
          this.cartItems = new Set(items.map((item: any) => item.id));

          // Calcolare il totale dell'ordine
          this.totalOrderAmount = items.reduce(
              (total: number, item: any) => total + parseFloat(item.Prezzo || 0),
              0
          );

          this.cdRef.detectChanges();
        } else {
          console.log('Il documento non esiste');
          this.cartItems = new Set();
          this.totalOrderAmount = 0;
          this.cdRef.detectChanges();
        }
      }, (error) => {
        console.error('Errore nel monitoraggio del carrello:', error);
      });

  }

  ngOnDestroy(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  openDialogAdd(row:any): any {
    const dialogRef = this.dialog.open(AddOrdineMagazzino, {
      data: row // Passa i dati della riga al dialog
    });
    //modalRef.componentInstance.user = this.user;

  }

  openDialogRiepilogo(): any {
    const dialogRef = this.dialog.open(CarrelloMagazzino, {
      data: '', // Passa i dati della riga al dialog
      width: '80vw',
      maxWidth: '80vw'
    });
    //modalRef.componentInstance.user = this.user;

  }

  openDialogCategorie(row: any): void {
    this.dialog.open(EditCategories, {
      data: {
        collectionType: 'magazzino_test'  // O 'magazzino_test' a seconda del caso
      }
    });
  }

  openDialogInventario(row: any): void {
    this.dialog.open(EditInventario, {
      data: {
        collectionType: 'magazzino_test'  // O 'magazzino_test' a seconda del caso
      }
    });
  }

  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }

  onSyncMenuChange(row: any) {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const warehouseRef = db.collection('magazzino_test').doc(restaurantName);

    // Toggle the SyncMenu value
    const newSyncMenuValue = row.SyncMenu === '1' ? '0' : '1';

    // Trova l'elemento da aggiornare e imposta il nuovo valore di SyncMenu
    const updatedItems = this._alldata.map(item => {
      if (item.id === row.id) {
        return { ...item, SyncMenu: newSyncMenuValue };
      }
      return item;
    });

    // Aggiorna l'array items nel documento su Firestore
    warehouseRef.update({ items: updatedItems })
      .then(() => {
        console.log('SyncMenu aggiornato con successo su Firestore!');
        // Aggiorna la visualizzazione locale
        row.SyncMenu = newSyncMenuValue;
        this.cdRef.detectChanges();
      })
      .catch(error => {
        console.error('Errore durante l\'aggiornamento di SyncMenu su Firestore:', error);
      });
  }
  immagini = {}

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }

  ngOnInit(): void {

    this.immagini = this.dataServiceGenerico.getImmagini()
    console.log('firebase: ', firebase);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.retriveCarrello();
        this.userService.emitter.subscribe(
          () => {
            this.retriveCarrello();
          }
        );
      } else {
        // No user is signed in.
      }
    });

    this.fetchCartItems();
  }

  triggerFileInput() {
    document.getElementById('fileInput').click();
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        Papa.parse(csvData as string, {
          header: true,
          delimiter: ';',
          complete: (result) => {
            const processedData = this.processCSVData(result.data);
            console.log(processedData);
            this.saveWarehouseToFirestore(processedData);
          }
        });
      };
      reader.readAsText(file);
    }
  }

  processCSVData(data: any[]) {
    return data;
  }

  saveWarehouseToFirestore(warehouseItems: any[]) {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const warehouseRef = db.collection('magazzino_test').doc(restaurantName);

    const updatedWarehouseItems = [];

    warehouseItems.forEach(item => {
      const newDocRef = db.collection('magazzino_test').doc().collection(restaurantName).doc();
      item.id = newDocRef.id;
      updatedWarehouseItems.push(item);
    });

    warehouseRef.set({ items: updatedWarehouseItems })
      .then(() => {
        console.log('Magazzino caricato con successo su Firestore con ID generati!');
        this.retriveCarrello();
      })
      .catch((error) => {
        console.error('Errore durante il caricamento del magazzino su Firestore:', error);
      });
  }

  retriveCarrello(): void {
    this.commonService.getMagazzino(this.dataService.getRistorante1()).subscribe({
      next: (response) => {
        console.log("Menu Tabella: ", response);
        if (response && response.items) {
          this._alldata = response.items;

          this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
          this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
          this.dataSource.filter = performance.now().toString();
        } else {
          console.error('No items found in the response');
          this._alldata = [];
          this.dataSource.data = [];
        }
      },
      error: (error) => {
        console.error('Error retrieving warehouse data:', error);
        this._alldata = [];
        this.dataSource.data = [];
      }
    });
  }




  salvaCategorie() {
    console.log("ristorante: ", this.menu);
    let newMenu = {
      magazzino: this.menu,
      menu: this.menu,
    }
    this.commonService.updateDati(this.idRistorante, newMenu);
  }

  salvaMenu() {
    console.log("ristorante: ", this.dataSource.data);
    let newDataSource = this.dataSource.data.filter(element => !element.hasOwnProperty('expanded'));
    console.log("ristorant2: ", newDataSource);

    let dataSourceDaSavlvare = [];
    newDataSource.forEach(element => {
      if (dataSourceDaSavlvare.some(elemento => elemento.categoria == element.categoria)) {
        dataSourceDaSavlvare[dataSourceDaSavlvare.findIndex(elemento => elemento.categoria == element.categoria)].menu.push(element);
      } else {
        dataSourceDaSavlvare.push({
          categoria: element.categoria,
          menu: [element]
        });
      }
    });

    console.log("datasourcedasalvare: ", dataSourceDaSavlvare);

    let menuDaSalvare = {
      magazzino: dataSourceDaSavlvare,
      menu: this.menu,
    };

    this.commonService.updateDati(this.idRistorante, menuDaSalvare);
  }

  calcolaTotale(): void {
    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {
          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);
    });
  }

  calcolaMancia(): number {
    return this.tot / 10;
  }

  calcolaPrezzo(piatto: any): number {
    let prezzoPiatto = piatto.prezzo;

    if (piatto.opzioni) {
      piatto.opzioni.forEach(opzione => {
        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }
    return prezzoPiatto;
  }




  filterValue = ''
  applyFilter(filterValue: string): void {
    this.filterValue = filterValue
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {


    //console.log("data: ", data);
    //console.log("filter: ", filter)
    // @ts-ignore
    if(!(data instanceof Group) && (!isNaN(parseFloat(filter)) && !isNaN(filter - 0)))
    {
      if(!data.hasOwnProperty('visible'))
      {
        return true;
      }
      else {
        return data.visible
      }
    }
    // @ts-ignore
    if(!(data instanceof Group) && (isNaN(parseFloat(filter)) && isNaN(filter - 0)))
    {
      return this.filtraRicerca(data, filter)
    }



    // @ts-ignore
    if ((data instanceof Group) && this.filterValue == '') {
      return true;
    }
    // @ts-ignore
    if ((data instanceof Group) && this.filterValue !== '') {
      //console.log("datasource: ", this.dataSource)
      const hasVisibleChild = this.dataSource.filteredData.some(item => {
        if(!(item instanceof Group))
        {
          console.log("item: ", item)
          return (item.Categoria == data.Categoria)
        }
        else {
          return false;
        }
      });
      //console.log("data: ", data)
      data.visible = hasVisibleChild;
      //console.log("data: ", data)

      console.log("data: ", data)
      return hasVisibleChild;
    }


  }

  filtraRicerca(data: any, filter: any)
  {
    const searchStr = Object.keys(data).reduce((currentTerm, key) => {
      return currentTerm + (data[key] ? data[key].toString().toLowerCase() : '');
    }, '');
    return searchStr.indexOf(filter) !== -1;
  }

  groupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, true);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  unGroupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, false);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  checkGroupByColumn(field, add) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if (add) {
        this.groupByColumns.push(field);
      }
    }
  }

  groupHeaderClick(row: Group) {

    console.log("row: ", row)
    row.expanded = !row.expanded;

    // Nasconde o mostra le righe figlie basandosi sullo stato del gruppo
    this.dataSource.data.forEach(item => {
      //let parent = (item.categoria == row.categoria) && item.hasOwnProperty('parent')
      if (item == row) {
        //item.visible = row.expanded;
        if (item instanceof Group) {
          this.toggleChildGroupVisibility(item);
        }
      }
    });

    this.dataSource.filter = performance.now().toString();
  }

  toggleChildGroupVisibility(group: Group) {

    console.log("toggleVisibility: ", group)
    this.dataSource.data.forEach(item => {
      if (item.Categoria === group.Categoria) {
        if (item instanceof Group) {
        }else
        {
          item.visible = group.expanded;
        }
      }
    });
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    const result = this.getSublevel(data, 0, groupByColumns, rootGroup);
    // Imposta la visibilità iniziale solo per i gruppi principali
    result.forEach(item => {
      if (item instanceof Group && item.level === 1) {
        item.visible = true;
      }
    });

    /*
    let primaCategoria = new Group()
    primaCategoria.categoria= 'Risultati';
    primaCategoria.expanded =  false;
    primaCategoria.totalCounts= 0;
    primaCategoria.visible= true;

    result.unshift(primaCategoria)

     */


    console.log("result: ", result)
    return result;
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data?.map(row => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        result.expanded = parent.expanded;
        result.visible = false; // Nascondi i gruppi figlio per default
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups?.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a?.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index: number, item: any): boolean {
    return item instanceof Group;
  }


}



class Group {
  level: number = 0;
  parent: Group | null = null;
  expanded: boolean = true;
  visible: boolean = true;
  totalCounts: number = 0;
  [key: string]: any;
  constructor() {
    this.level = 0;
    this.parent = null;
    this.expanded = true;
    this.visible = false; // Per default i gruppi sono nascosti
    this.totalCounts = 0;
  }
}
