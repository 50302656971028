import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-add-note-dialog',
    template: `
    <h2 mat-dialog-title>Aggiungi Nota</h2>
    <div mat-dialog-content>
      <p>Inserisci una nota per l'ordine:</p>
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Nota</mat-label>
        <textarea matInput 
                  [(ngModel)]="nota" 
                  rows="4"
                  placeholder="Inserisci qui la nota..."></textarea>
      </mat-form-field>
    </div>
    <div mat-dialog-actions style="justify-content: flex-end; margin-top: 20px;">
      <button mat-button (click)="onNoClick()">Annulla</button>
      <button mat-button 
              color="primary" 
              [disabled]="!nota?.trim()"
              (click)="onConfirm()">
        Salva
      </button>
    </div>
  `,
    styles: [`
    mat-form-field {
      width: 100%;
    }
  `]
})
export class AddNoteDialogComponent {
    nota: string = '';

    constructor(
        public dialogRef: MatDialogRef<AddNoteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // If there's an existing note, populate it
        if (data && data.existingNote) {
            this.nota = data.existingNote;
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onConfirm(): void {
        this.dialogRef.close(this.nota);
    }
}