<!-- cronologia.component.html -->
<div class="cronologia-container">
  <div class="cronologia-header">
    <h3>Cronologia movimenti</h3>
    <div class="product-info">
      <div class="product-title">{{ item?.Titolo }}</div>
      <div class="product-code">N° {{ item?.id }}</div>
    </div>
  </div>

    <div style="
    height: 90%;
    overflow-y: auto;">
        <div class="timeline">
            <div class="timeline-item" *ngFor="let movimento of cronologiaInvertita">
                <div class="timeline-dot"
                     [style.background-color]="getTagColor(movimento.modalita)"
                     [style.border-color]="getTagColor(movimento.modalita)">
                </div>
                <div class="timeline-content">
                    <div class="timeline-header">
                        <div class="tag"
                             [style.background-color]="getTagColor(movimento.modalita)"
                             [style.color]="getTagTextColor(movimento.modalita)">
                            {{ movimento.modalita | titlecase }}
                        </div>
                        <div class="date">{{ movimento.data }}</div>
                    </div>
                    <div class="movement-details">
                        <div class="quantity">
                            <ng-container *ngFor="let level of movimento.lvl; let i = index">
                                <div *ngIf="level.quantita !== '0'" class="level-quantity">
                                    {{ level.quantita }} {{ level.um }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="prev-quantity">Quantità precedente: {{ formatPreviousQuantity(movimento.quantitaPrec) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>