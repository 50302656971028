import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'edit-predefined-sconti',
    templateUrl: './edit-predefined-sconti.component.html',
    styleUrls: ['./edit-predefined-sconti.component.css']
})
export class EditPredefinedSconti implements OnInit {
    scontoForm: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<EditPredefinedSconti>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.initForm();
    }

    private initForm(): void {
        this.scontoForm = this.fb.group({
            nome: ['', Validators.required],
            tipo: ['percentage', Validators.required],
            valore: ['', [Validators.required, Validators.min(0)]]
        });
    }

    ngOnInit(): void {
        if (this.data && Object.keys(this.data).length > 0) {
            this.scontoForm.patchValue({
                nome: this.data.nome || '',
                tipo: this.data.tipo || 'percentage',
                valore: this.data.valore || ''
            });
        }
    }

    saveSconto() {
        if (this.scontoForm.invalid) return;
        this.dialogRef.close(this.scontoForm.value);
    }

    deleteSconto() {
        if (confirm('Sei sicuro di voler eliminare questo sconto?')) {
            this.dialogRef.close({ delete: true });
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }
}