import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject, Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../../router.module';
import {UserDataService} from '../../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";


@Component({
  selector: 'preferiti',
  templateUrl: './preferiti.html',
  styleUrls: ['./preferiti.css']
})
  export class Preferiti implements OnInit{
  @Input() larghezza: string | '';


  dragPosition = {x: 253, y: 244};

  dragPosition2 = {x: 253, y: 244};


  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog, private ipcService: IpcService, private posTransactionService: PosTransactionService,
    private dataServiceGenerico: DataServiceGenerico
  ){

    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');


  }

  dropped(e)
  {
    console.log("droppato: ", e)
    console.log("droppato 2: ", this.dragPosition)

  }

  user = {}

  preferito(piatto)
  {
    if(this.user.hasOwnProperty('preferiti'))
    {
      let index = this.user['preferiti'].findIndex( p => p.title == piatto.title)
      return index > -1
    }
    else
    {
      return false;
    }
  }

  setPreferito(piatto)
  {
    if(this.user.hasOwnProperty('preferiti'))
    {
      let index = this.user['preferiti'].findIndex( p => p.title == piatto.title)
      if(index > -1)
      {
        this.user['preferiti'].splice(index, 1);
        //delete this.user['preferiti'][piatto.title.replace(/ /g,'')];
      }
      else
      {
        this.user['preferiti'].push(piatto)
      }
    }
    else
    {
      this.user['preferiti'] = []
      this.user['preferiti'].push(piatto)
    }

    console.log("preferiti: ", this.user)
    this.userService.update(this.user['uid'], this.user)

  }


  ngOnInit(): void {

    this.user = this.dataServiceGenerico.getUser()
    console.log("this.user: ", this.user)

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveMenu();
        this.userService.emitter.subscribe(
          () => {
            this.retriveMenu();
          }
        );
      } else {
        // No user is signed in.
      }
    });

  }


  menuBello = []

  categorie = []
  menu = []
  sezione = 0;

  retriveMenu(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataServiceGenerico.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        this.menuBello = data.items; // Recupero e assegno i dati del menu

        this.menuBello = this.menuBello.filter(elemento => elemento.hasOwnProperty('image_link') && elemento.image_link?.length > 0)
        this.categorie = this.filterUniqueCategories(this.menuBello); // Filtro le categorie uniche

        console.log('Menu recuperato da Firebase:', this.menuBello);
        console.log('Menu recuperato da Firebasen 2:', this.categorie);

        this.menu = []

        this.categorie.forEach(categoria => this.menu.push({
          testo: categoria,
          menu: []
        }))

        this.menu.forEach((categoria, i) =>
          {
            this.menuBello.forEach((prodotto) => {
              if(categoria.testo == prodotto.category)
              {
                this.menu[i].menu.push(prodotto)
              }
            })
          }
        )

        console.log('Menu recuperato da Firebasen 3:', this.menu);


      } else {
        console.log('Nessun documento trovato su Firebase.');
      }
    }).catch(error => {
      console.error('Errore nel recupero del menu da Firebase:', error);
    });
  }


  filterUniqueCategories(menuItems) {
    const seenCategories = new Set();
    return menuItems.filter(item => {
      if (seenCategories.has(item.category)) {
        return false;
      } else {
        seenCategories.add(item.category);
        return true;
      }
    }).map(x => x.category).filter(function( element ) {
      return element !== undefined;
    });
  }


  width()
  {
    return (100/(this.categorie.length))
  }



}
