import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../../router.module';
import {UserDataService} from '../../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {AggiuntaPiatto} from "../../aggiunta-piatto/aggiunta-piatto";
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthService} from "../../../shared/services/auth.service";


@Component({
  selector: 'app-impostazioni',
  templateUrl: './impostazioni.html',
  styleUrls: ['./impostazioni.css']
})
  export class Impostazioni implements OnInit{

  pagina = 0;

  dragPosition = {x: 253, y: 244};

  dragPosition2 = {x: 253, y: 244};

  form: FormGroup;


  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog, private ipcService: IpcService, private posTransactionService: PosTransactionService,
    private fb: FormBuilder, private authService: AuthService
  ){

    this.form = this.fb.group({
      // ... definizione dei controlli del form
    });
    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');


  }

  dropped(e)
  {
    console.log("droppato: ", e)
    console.log("droppato 2: ", this.dragPosition)

  }

  logout() {
    this.authService.SignOut();
    this.router.navigateByUrl('login');
  }

  ngOnInit(): void {
  }




}
