<div class="dialog-container">
    <div class="sticky-container">
        <div class="save-button" *ngIf="data?.url">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Elimina Immagine"
                 (click)="deleteImage()">
        </div>
        <div class="save-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Salva Immagine"
                 (click)="saveImage()"
                 [class.disabled]="isLoading">
        </div>
        <div class="close-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Chiudi"
                 (click)="closeDialog()">
        </div>
    </div>

    <div class="dialog-content">
        <form [formGroup]="imageForm">
            <div class="card-section">
                <div class="card-container">
                    <div class="form-content">
                        <!-- Nome immagine -->
                        <div class="form-group">
                            <label class="form-label">Nome Immagine</label>
                            <input formControlName="name"
                                   placeholder="Nome immagine"
                                   class="custom-input"
                                   [class.error]="imageForm.get('name')?.invalid && imageForm.get('name')?.touched">
                            <div class="error-message" *ngIf="imageForm.get('name')?.invalid && imageForm.get('name')?.touched">
                                {{ getErrorMessage('name') }}
                            </div>
                        </div>

                        <!-- Durata -->
                        <div class="form-group">
                            <label class="form-label">Durata (secondi)</label>
                            <input type="number"
                                   formControlName="duration"
                                   min="1"
                                   max="60"
                                   class="custom-input"
                                   [class.error]="imageForm.get('duration')?.invalid && imageForm.get('duration')?.touched">
                            <div class="error-message" *ngIf="imageForm.get('duration')?.invalid && imageForm.get('duration')?.touched">
                                {{ getErrorMessage('duration') }}
                            </div>
                        </div>

                        <!-- Checkbox clickable -->
                        <div class="form-group">
                            <label class="checkbox-container">Immagine cliccabile
                                <input type="checkbox" formControlName="isClickable">
                                <span class="checkmark"></span>
                            </label>
                        </div>

                        <!-- Conditional fields for clickable images -->
                        <ng-container *ngIf="imageForm.get('isClickable')?.value">
                            <div class="form-group">
                                <label class="checkbox-container">È uno sconto
                                    <input type="checkbox" formControlName="isDiscount">
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                            <div class="form-group" *ngIf="imageForm.get('isDiscount')?.value">
                                <label class="form-label">Seleziona Sconto</label>
                                <select formControlName="discountId" class="custom-input">
                                    <option value="">Seleziona uno sconto</option>
                                    <option *ngFor="let discount of discounts" [value]="discount.id">
                                        {{discount.nome}}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group" *ngIf="!imageForm.get('isDiscount')?.value">
                                <label class="form-label">Seleziona Prodotto</label>
                                <select formControlName="linkedItemId" class="custom-input">
                                    <option value="">Seleziona un prodotto</option>
                                    <option *ngFor="let item of menuItems" [value]="item.id">
                                        {{item.title}}
                                    </option>
                                </select>
                            </div>
                        </ng-container>

                        <!-- File upload -->
                        <div class="form-group">
                            <label class="form-label">Immagine</label>
                            <input type="file"
                                   accept="image/*"
                                   (change)="onFileSelected($event)"
                                   class="file-input">

                            <div class="preview-container" *ngIf="previewUrl">
                                <img [src]="previewUrl"
                                     class="image-preview"
                                     alt="Preview">
                            </div>

                            <div *ngIf="uploadProgress > 0" class="progress-bar">
                                <div class="progress" [style.width.%]="uploadProgress"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>