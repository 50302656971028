import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Subscription } from 'rxjs';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-user-selection',
  template: `
    <div class="row" style="height: 95vh; width:calc(60vw); margin-top: 15px; border-radius: 20px; overflow-y: scroll; margin-left: 50px">
      <div style="margin-bottom: 100px">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Seleziona Utente</div>
        <div style="width: 100%; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px; padding: 20px;">
          <div style="display: grid; grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); gap: 20px; padding: 10px;">
            <div *ngFor="let user of users"
                 (click)="selectUser(user)"
                 [ngClass]="{
                   'user-card': true,
                   'selected-user': isSelectedUser(user)
                 }"
                 style="aspect-ratio: 1; cursor: pointer; padding: 20px; border: 1px solid #e3e2e2; border-radius: 20px; display: flex; flex-direction: column; align-items: center; justify-content: center; background-color: white; transition: all 0.2s ease;">
              <div class="text-center" style="width: 100%;">
                <div class="text-lg" style="margin-bottom: 10px; font-size: 18px;">{{user.username}}</div>
                <div class="text-gray-700" style="font-weight: 700; font-size: 14px;">{{user.ruolo}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Password Dialog Template -->
    <ng-template #passwordDialogTpl>
      <div style="background-color: white; border-radius: 20px; padding: 2.5rem;">
        <div style="height: 50px; line-height: 50px; font-weight: 700; margin-bottom: 20px;">Inserisci Password</div>

        <div class="flex flex-col gap-2 flex-1" style="margin-bottom: 20px;">
          <input type="password"
                 [(ngModel)]="password"
                 class="custom-input"
                 [ngClass]="{'error-input': showPasswordError}"
                 placeholder="Password">
          <div *ngIf="showPasswordError" class="error-message">
            Password non corretta
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end; gap: 10px;">
          <div style="font-size: 14px; text-align: center; border-radius: 7px; font-weight: 700; padding: 15px; color: #666; background-color: #f1f1f1; cursor: pointer;"
               (click)="dialogRef.close(null)">
            Annulla
          </div>
          <div style="font-size: 14px; text-align: center; border-radius: 7px; font-weight: 700; padding: 15px; color: white; background-color: rgb(0 125 104); cursor: pointer;"
               (click)="confirmPassword()">
            Conferma
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styles: [`
    .user-card:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .selected-user {
      border: 2px solid rgb(0 125 104) !important;
      background-color: rgba(0, 125, 104, 0.05) !important;
    }
    .custom-input {
      background-color: #f7f9fb;
      border: 1px solid #d2d9e0 !important;
      border-radius: 10px;
      padding: 0.5rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5rem;
      width: 90%;
    }
    .custom-input:focus {
      outline-color: rgb(0 125 104);
      border-color: rgb(0 125 104) !important;
    }
    .error-input {
      border-color: #dc3545 !important;
      background-color: #fff8f8;
    }
    .error-message {
      color: #dc3545;
      font-size: 0.875rem;
      margin-top: 0.25rem;
    }
    .text-gray-700 {
      color: rgb(63 72 81);
    }
  `]
})
export class UserSelectionComponent implements OnInit, OnDestroy {
  @ViewChild('passwordDialogTpl') passwordDialog!: TemplateRef<any>;

  users: any[] = [];
  password: string = '';
  private userSubscription: Subscription;
  dialogRef: MatDialogRef<any>;
  selectedUser: any;
  showPasswordError: boolean = false;
  currentUser: any;

  constructor(
      private dialog: MatDialog,
      private dataService: DataServiceGenerico,
      private router: Router,
      private dataServiceGenerico: DataServiceGenerico
  ) {}

  ngOnInit(): void {

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Prima carica i ristoranti disponibili
          await this.dataServiceGenerico.loadAvailableRestaurants();

          // Ora che abbiamo il ristorante, possiamo sottoscriverci ai tavoli
          const ristoranteId = this.dataService.getRistorante1();
          if (ristoranteId) {

            this.loadUsers();
            this.currentUser = this.dataService.getUserLocale();
          } else {
            console.error('ID ristorante non disponibile');
          }
        } catch (error) {
          console.error('Errore durante l\'inizializzazione:', error);
        }
      }
    });



  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  isSelectedUser(user: any): boolean {
    return this.currentUser && this.currentUser.username === user.username;
  }

  private loadUsers(): void {
    const db = firebase.firestore();
    const ristoranteId = this.dataService.getRistorante1();

    if (!ristoranteId) {
      console.error('ID ristorante non disponibile');
      return;
    }

    this.userSubscription = new Subscription();

    const docListener = db.collection('ristoranti')
        .doc(ristoranteId)
        .onSnapshot(
            (docSnapshot) => {
              if (docSnapshot.exists) {
                const data = docSnapshot.data();
                if (data && data.organico) {
                  this.users = data.organico;
                }
              }
            },
            (error) => {
              console.error('Errore nel caricamento degli utenti:', error);
            }
        );

    this.userSubscription.add(() => docListener());
  }

  selectUser(user: any): void {
    this.selectedUser = user;

    if (user.passwordCassa) {
      this.showPasswordError = false;
      this.password = '';
      this.dialogRef = this.dialog.open(this.passwordDialog, {
        width: '400px',
        disableClose: true
      });
    } else {
      this.confirmUserSelection(user);
    }
  }

  confirmPassword(): void {
    if (this.selectedUser.passwordCassa === this.password) {
      this.dialogRef.close();
      this.confirmUserSelection(this.selectedUser);
    } else {
      this.showPasswordError = true;
      this.password = '';
    }
  }

  private confirmUserSelection(user: any): void {
    this.dataService.setUserLocale(user);
    console.log("user settato: ", user)
    this.router.navigate(['/cassa'], { fragment: 'nonav' });
  }
}