import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";
import {NotificationService} from "../stampanti/notificationService/notification.service";

interface RevenueData {
    [key: string]: number;
}

@Component({
    selector: 'app-revenue',
    template: `
        <div class="flex flex-col" style="width: 50vw; max-height: 90vh; overflow: scroll">
            <div class="column shadow">
                <div class="flex flex-col gap-2">
                    <h2 class="text-gray-700 font-bold text-heading-sm p-4">Fatturato Mensile</h2>

                    <form [formGroup]="revenueForm" (ngSubmit)="onSubmit()">
                        <div class="flex flex-col gap-4 p-4">
                            <div *ngFor="let month of months" class="flex gap-4 items-center">
                                <label [for]="month.toLowerCase()" class="text-gray-700 font-bold text-heading-sm w-24">
                                    {{month}}
                                </label>
                                <input
                                        [id]="month.toLowerCase()"
                                        type="number"
                                        [formControlName]="month.toLowerCase()"
                                        (input)="calculateMonthlyPercentage(month.toLowerCase())"
                                        class="flex-1 search"
                                        placeholder="0.00"
                                >
                                <div class="sb-example-1">
                                    <input
                                            type="text"
                                            [value]="getFormattedPercentage(month.toLowerCase())"
                                            readonly
                                            class="searchTerm"
                                    >
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="shadow" style="margin-top: 20px;">
                <div class="flex flex-col gap-2 p-4">
                    <h2 class="text-gray-700 font-bold text-heading-sm">Riepilogo</h2>

                    <div class="flex flex-col gap-4 mt-4">
                        <div class="flex justify-between items-center">
                            <span class="text-gray-700 font-bold">Fatturato Totale:</span>
                            <span>{{totalRevenue | currency:'EUR':'symbol':'1.2-2'}}</span>
                        </div>
                        <div class="flex justify-between items-center">
                            <span class="text-gray-700 font-bold">Commissioni:</span>
                            <span>{{totalRevenue * 0.1 | currency:'EUR':'symbol':'1.2-2'}}</span>
                        </div>
                    </div>

                    <button
                            type="submit"
                            [disabled]="revenueForm.invalid || isLoading"
                            style="background-color: rgb(0 125 104)"
                            class="text-white px-4 py-2 rounded-lg mt-4 w-full"
                            (click)="onSubmit()"
                    >
                        Salva
                    </button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./revenue.component.css']
})
export class RevenueComponent implements OnInit {
    revenueForm: FormGroup;
    months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
        'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    totalRevenue = 0;
    monthlyPercentages: {[key: string]: number} = {};
    isLoading = false;

    constructor(
        private fb: FormBuilder,
        private dataServiceGenerico: DataServiceGenerico,
        private notificationService: NotificationService
    ) {
        this.initForm();
    }

    async ngOnInit() {
        if (this.dataServiceGenerico.getRistorante1() === '') {
            await this.dataServiceGenerico.loadSelectedMenuFromFirebase();
        }
        await this.loadData();
    }

    private initForm() {
        const group: any = {};
        this.months.forEach(month => {
            group[month.toLowerCase()] = ['', [Validators.min(0)]];
        });
        this.revenueForm = this.fb.group(group);
    }

    private async saveData() {
        const restaurantId = this.dataServiceGenerico.getRistorante1();
        if (!restaurantId) throw new Error('No restaurant ID found');

        const db = firebase.firestore();
        const revenueData = {
            restaurantId: restaurantId,
            revenue: this.revenueForm.value,
            total: this.totalRevenue,
            percentage: this.totalRevenue * 0.1,
            year: 2024,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        };

        await db.collection('revenues').doc().set(revenueData);
    }

    async onSubmit() {
        if (this.revenueForm.invalid) {
            Object.keys(this.revenueForm.controls).forEach(key => {
                const control = this.revenueForm.get(key);
                if (control?.invalid) control.markAsTouched();
            });
            return;
        }

        this.isLoading = true;
        try {
            await this.saveData();
            this.notificationService.show('Fatturato salvato con successo', 'success');
        } catch (error) {
            console.error('Error saving revenue data:', error);
            this.notificationService.show('Errore durante il salvataggio dei dati', 'error');
        } finally {
            this.isLoading = false;
        }
    }

    private async loadData() {
        try {
            const restaurantId = this.dataServiceGenerico.getRistorante1();
            if (!restaurantId) return;

            const db = firebase.firestore();
            const snapshot = await db.collection('revenues')
                .where('restaurantId', '==', restaurantId)
                .where('year', '==', 2024)
                .orderBy('updatedAt', 'desc')
                .limit(1)
                .get();

            if (!snapshot.empty) {
                const data = snapshot.docs[0].data();
                this.months.forEach(month => {
                    const lowercaseMonth = month.toLowerCase();
                    if (data.revenue[lowercaseMonth]) {
                        this.revenueForm.patchValue({
                            [lowercaseMonth]: data.revenue[lowercaseMonth]
                        });
                        this.calculateMonthlyPercentage(lowercaseMonth);
                    }
                });
            }
        } catch (error) {
            console.error('Error loading revenue data:', error);
            this.notificationService.show('Errore durante il caricamento dei dati', 'error');
        }
    }
    calculateMonthlyPercentage(month: string) {
        const value = parseFloat(this.revenueForm.get(month)?.value) || 0;
        this.monthlyPercentages[month] = value * 0.1;
        this.calculateTotal();
    }

    getFormattedPercentage(month: string): string {
        return new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
        }).format(this.monthlyPercentages[month] || 0);
    }

    calculateTotal() {
        this.totalRevenue = <number>Object.values(this.revenueForm.value)
            .reduce((acc: number, val: string) => acc + (parseFloat(val) || 0), 0);
    }

}