import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Inject
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from "@angular/material/table";
import {UserDataService} from "../../shared/services/firestore/userData.service";
import {Location} from "@angular/common";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CommonService} from "../../shared/services/firestore/common.service";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";

@Component({
  selector: 'inventoryComponent',
  templateUrl: './edit-inventario.html',
  styleUrls: ['./edit-inventario.css'],
})
export class EditInventario implements OnInit, OnDestroy {



  public dataSource = new MatTableDataSource<any | Group>([]);
  _alldata: any[];
  groupByColumns: string[] = [];
  columns: any[];
  displayedColumns: string[];





  isMenuChecked = false;
  isSingleChecked = false;
  isDisponibileChecked = false;
  ristorante = 'menu';

  categories: string[] = [];
  ingredients: string[] = [];
  allergens: string[] = [];

  // Variabili per gestire i valori dei campi
  Titolo: string = '';
  id: string = '';
  selectedCategoria: string = '';
  selectedImage: string = '';
  selectedIngredients: string[] = [];
  selectedAllergens: string[] = [];
  price: string = '';
  quantity: string = '';
  supplierCode: string = '';
  supplier: string = '';
  expiryDate: string = '';
  um: string = '';
  maxLvl: number = 1; // Default a 1 livello

  // Variabili per i livelli di imballaggio
  levels: { quantita: string, um: string, barCode: string }[] = [
    {quantita: '', um: '', barCode: ''}, // Primo livello
    {quantita: '', um: '', barCode: ''}, // Secondo livello
    {quantita: '', um: '', barCode: ''}, // Terzo livello
    {quantita: '', um: '', barCode: ''}  // Quarto livello
  ];

  // Variabili per la cronologia
  cronologia: any[] = [];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  isLvl1 = true;
  isLvl2 = false;
  isLvl3 = false;
  isLvl4 = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<EditInventario>,
    private dataService: DataServiceGenerico,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private userService: UserDataService, public dialog: MatDialog,
    public commonService: CommonService
  ) {
    this.route.params.subscribe(params => {
      if (params.ristorante) {
        this.ristorante = 'menu';
      }
    });


    this.columns = [{
      field: 'Titolo'
    }, {
      field: 'UM'
    }, {
      field: 'Fornitore'
    }, {
      field: 'Quantita'
    }
    ];
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['Categoria'];
  }

  ngOnInit(): void {
    this.fetchCategoriesFromFirestore();


    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.retriveCarrello();
        this.userService.emitter.subscribe(
          () => {
            this.retriveCarrello();
          }
        );
      } else {
        // No user is signed in.
      }
    });


    if (this.data) {
      // Popola i campi con i dati passati
      this.id = this.data.id || '';
      this.Titolo = this.data.Titolo || '';
      this.selectedCategoria = this.data.Categoria || '';
      this.selectedImage = this.data.image_link || '';
      this.supplierCode = this.data.CodiceFornitore || '';
      this.supplier = this.data.Fornitore || '';
      this.quantity = this.data.Quantita || '';
      this.price = this.data.Prezzo || '';
      this.expiryDate = this.data.Scadenza || '';
      this.um = this.data.UM || '';
      this.maxLvl = this.data.maxLvl || 1;

      // Inizializza i livelli con quantità zero
      this.levels = this.data.lvl?.map((level) => ({...level, quantita: '0'})) || [
        {quantita: '0', um: '', barCode: ''},
        {quantita: '0', um: '', barCode: ''},
        {quantita: '0', um: '', barCode: ''},
        {quantita: '0', um: '', barCode: ''}
      ];

      this.isMenuChecked = this.data.menu;
      this.isSingleChecked = this.data.single;
      this.isDisponibileChecked = this.data.disponibilita;

      // Imposta i livelli in base a maxLvl
      this.isLvl1 = this.maxLvl >= 1;
      this.isLvl2 = this.maxLvl >= 2;
      this.isLvl3 = this.maxLvl >= 3;
      this.isLvl4 = this.maxLvl >= 4;

      this.cdRef.detectChanges();
    }
  }


  ngOnDestroy(): any {
    this.passBack();
  }

  fetchCategoriesFromFirestore(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const collectionRef = db.collection('magazzino_test').doc(restaurantName);

    collectionRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        const categoriesSet = new Set<string>();
        const ingredientsSet = new Set<string>();

        items.forEach(item => {
          if (item.Categoria) {
            categoriesSet.add(item.Categoria);
          }
          if (item.Titolo) {
            ingredientsSet.add(item.Titolo); // Usa i titoli dei prodotti come ingredienti
          }
        });

        this.categories = Array.from(categoriesSet);
        this.ingredients = Array.from(ingredientsSet);
        this.cdRef.detectChanges();
      }
    }).catch(error => {
      console.error('Error fetching data from Firestore:', error);
    });
  }

  onMenuChange(event: any) {
    this.isMenuChecked = event.target.checked;
  }

  onMenuChangeLevel1(event: any) {
    this.isLvl1 = event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl1 ? 1 : 1;  // Aggiorna maxLvl
  }

  onMenuChangeLevel2(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl2 ? 2 : 1;  // Aggiorna maxLvl
  }

  onMenuChangeLevel3(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl3 ? 3 : 2;  // Aggiorna maxLvl
  }

  onMenuChangeLevel4(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = event.target.checked;
    this.maxLvl = this.isLvl4 ? 4 : 3;  // Aggiorna maxLvl
  }


  aggiunta = true;
  spreco = false;
  trasferimento = false;

  onModalitaChangeAggiunta(event: any) {
    this.aggiunta = true;
    this.spreco = false;
    this.trasferimento = false;
  }

  onModalitaChangeSpreco(event: any) {
    this.aggiunta = false;
    this.spreco = true;
    this.trasferimento = false;
  }

  onModalitaChangeTrasferimento(event: any) {
    this.aggiunta = false;
    this.spreco = false;
    this.trasferimento = true;
  }

  closeDialog() {
    this.passBack();
    this.dialogRef.close();
  }

  removeImage(levelIndex: number) {
    this.levels[levelIndex].barCode = '';
    this.cdRef.detectChanges();
  }

  onFileSelected(event: any, levelIndex: number) {
    const file = event.target.files[0];
    if (file) {
      this.uploadImageToFirebase(file, levelIndex);
    }
  }

  uploadImageToFirebase(file: File, levelIndex: number) {
    const storageRef = firebase.storage().ref();
    const filePath = `immaginiMenu/${file.name}`;
    const uploadTask = storageRef.child(filePath).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error('Error uploading image:', error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.levels[levelIndex].barCode = downloadURL;
          this.cdRef.detectChanges();
        });
      }
    );
  }

  saveChangesToFirestore(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const magazzinoRef = db.collection('magazzino_test').doc(restaurantName);

    magazzinoRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        if (this.id) {
          // Trova l'elemento esistente
          const currentItem = items.find(item => item.id === this.id);

          if (currentItem) {
            // Calcola la quantità totale da aggiungere basata sui livelli
            let quantitaAggiunta = 0;

            if (this.maxLvl >= 1) {
              quantitaAggiunta += parseInt(this.levels[0].quantita || '0', 10) * parseInt(this.data.lvl[0].quantita || '0', 10);
            }
            if (this.maxLvl >= 2) {
              quantitaAggiunta += parseInt(this.levels[1].quantita || '0', 10) * parseInt(this.data.lvl[1].quantita || '0', 10);
            }
            if (this.maxLvl >= 3) {
              quantitaAggiunta += parseInt(this.levels[2].quantita || '0', 10) * parseInt(this.data.lvl[2].quantita || '0', 10) * parseInt(this.data.lvl[1].quantita || '0', 10);
            }
            if (this.maxLvl === 4) {
              quantitaAggiunta += parseInt(this.levels[3].quantita || '0', 10) * parseInt(this.data.lvl[3].quantita || '0', 10) * parseInt(this.data.lvl[2].quantita || '0', 10) * parseInt(this.data.lvl[1].quantita || '0', 10);
            }

            let newQuantity = 0;
            let modalita = ''
            if (this.aggiunta == true) {
              modalita = 'aggiunta'
              newQuantity = parseInt(currentItem.Quantita || '0', 10) + quantitaAggiunta;
            }
            if (this.spreco == true) {
              modalita = 'spreco'

              newQuantity = parseInt(currentItem.Quantita || '0', 10) - quantitaAggiunta;
            }
            if (this.trasferimento == true) {
              modalita = 'trasferimento'

              newQuantity = parseInt(currentItem.Quantita || '0', 10) - quantitaAggiunta;
            }

            const updatedItem = {
              ...currentItem,
              Quantita: newQuantity.toString(),
              Scadenza: this.expiryDate,
              cronologia: [
                ...(currentItem.cronologia || []),
                {
                  data: new Date().toLocaleString(),
                  quantitaPrec: currentItem.Quantita,
                  quantitaAgg: quantitaAggiunta.toString(),
                  lvl: this.levels.slice(0, this.maxLvl),
                  modalita: modalita
                }
              ]
            };

            const updatedItems = items.map(item =>
              item.id === this.id ? updatedItem : item
            );

            magazzinoRef.update({items: updatedItems})
              .then(() => {
                console.log('Quantità aggiornata con successo su Firestore!');
              })
              .catch((error) => {
                console.error('Errore durante l\'aggiornamento della quantità su Firestore: ', error);
              });
          } else {
            console.error('Elemento non trovato');
          }
        } else {
          console.error('ID del prodotto mancante.');
        }
      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }


  deleteItemFromFirestore(): void {
    if (!this.id) {
      console.error('ID mancante, impossibile eliminare l\'elemento.');
      return;
    }

    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const magazzinoRef = db.collection('magazzino_test').doc(restaurantName);

    magazzinoRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        const updatedItems = items.filter(item => item.id !== this.id);

        magazzinoRef.update({
          items: updatedItems
        })
          .then(() => {
            console.log('Elemento eliminato con successo da Firestore!');
            this.dialogRef.close();
          })
          .catch((error) => {
            console.error('Errore durante l\'eliminazione dell\'elemento su Firestore: ', error);
          });

      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }

  passBack() {
    // Potresti voler restituire dati al chiamante, ad esempio:
    // this.passEntry.emit({ ... });
  }

  filterValue = ''

  applyFilter(filterValue: string): void {
    this.filterValue = filterValue
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {


    //console.log("data: ", data);
    //console.log("filter: ", filter)
    // @ts-ignore
    if (!(data instanceof Group) && (!isNaN(parseFloat(filter)) && !isNaN(filter - 0))) {
      if (!data.hasOwnProperty('visible')) {
        return true;
      } else {
        return data.visible
      }
    }
    // @ts-ignore
    if (!(data instanceof Group) && (isNaN(parseFloat(filter)) && isNaN(filter - 0))) {
      return this.filtraRicerca(data, filter)
    }


    // @ts-ignore
    if ((data instanceof Group) && this.filterValue == '') {
      return true;
    }
    // @ts-ignore
    if ((data instanceof Group) && this.filterValue !== '') {
      //console.log("datasource: ", this.dataSource)
      const hasVisibleChild = this.dataSource.filteredData.some(item => {
        if (!(item instanceof Group)) {
          console.log("item: ", item)
          return (item.Categoria == data.Categoria)
        } else {
          return false;
        }
      });
      //console.log("data: ", data)
      data.visible = hasVisibleChild;
      //console.log("data: ", data)

      console.log("data: ", data)
      return hasVisibleChild;
    }


  }

  filtraRicerca(data: any, filter: any) {
    const searchStr = Object.keys(data).reduce((currentTerm, key) => {
      return currentTerm + (data[key] ? data[key].toString().toLowerCase() : '');
    }, '');
    return searchStr.indexOf(filter) !== -1;
  }

  groupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, true);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  unGroupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, false);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  checkGroupByColumn(field, add) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if (add) {
        this.groupByColumns.push(field);
      }
    }
  }

  groupHeaderClick(row: Group) {

    console.log("row: ", row)
    row.expanded = !row.expanded;

    // Nasconde o mostra le righe figlie basandosi sullo stato del gruppo
    this.dataSource.data.forEach(item => {
      //let parent = (item.categoria == row.categoria) && item.hasOwnProperty('parent')
      if (item == row) {
        //item.visible = row.expanded;
        if (item instanceof Group) {
          this.toggleChildGroupVisibility(item);
        }
      }
    });

    this.dataSource.filter = performance.now().toString();
  }

  toggleChildGroupVisibility(group: Group) {

    console.log("toggleVisibility: ", group)
    this.dataSource.data.forEach(item => {
      if (item.Categoria === group.Categoria) {
        if (item instanceof Group) {
        } else {
          item.visible = group.expanded;
        }
      }
    });
  }



  retriveCarrello(): void {

    this.commonService.getMagazzino(this.dataService.getRistorante()).subscribe(
      (data) =>
      {
        console.log("Menu Tabella; ", data[0].items)
        if(data !== undefined)
        {
          this._alldata = [];
          this._alldata = data[0].items

          this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
          this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
          this.dataSource.filter = performance.now().toString();

        }
      }
    );
  }




  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    const result = this.getSublevel(data, 0, groupByColumns, rootGroup);
    // Imposta la visibilità iniziale solo per i gruppi principali
    result.forEach(item => {
      if (item instanceof Group && item.level === 1) {
        item.visible = true;
      }
    });

    /*
    let primaCategoria = new Group()
    primaCategoria.categoria= 'Risultati';
    primaCategoria.expanded =  false;
    primaCategoria.totalCounts= 0;
    primaCategoria.visible= true;

    result.unshift(primaCategoria)

     */


    console.log("result: ", result)
    return result;
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map(row => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        result.expanded = parent.expanded;
        result.visible = false; // Nascondi i gruppi figlio per default
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index: number, item: any): boolean {
    return item instanceof Group;
  }


}



class Group {
  level: number = 0;
  parent: Group | null = null;
  expanded: boolean = true;
  visible: boolean = true;
  totalCounts: number = 0;
  [key: string]: any;
  constructor() {
    this.level = 0;
    this.parent = null;
    this.expanded = true;
    this.visible = false; // Per default i gruppi sono nascosti
    this.totalCounts = 0;
  }
}

