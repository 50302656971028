import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {UserDataService} from "../../../shared/services/firestore/userData.service";
import {NotificationService} from "./notificationService/notification.service";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";
import firebase from 'firebase/app';
import 'firebase/firestore';

interface Printer {
  name: string;
  ipAddress: string;
}

interface Workstation {
  id: string;
  name: string;
  printers: Printer[];
  type: string;
}

@Component({
  selector: 'stampanti',
  templateUrl: './stampanti.html',
  styleUrls: ['./stampanti.css']
})
export class Stampanti implements OnInit {
  workstations: any[] = [];
  isLoading = false;

  constructor(
      private userService: UserDataService,
      private dataServiceGenerico: DataServiceGenerico,
      private notificationService: NotificationService
  ) {}

  async ngOnInit() {
    await this.loadWorkstations();
  }

  async loadWorkstations() {
    try {
      const db = firebase.firestore();
      const snapshot = await db.collection('workstations').get();
      this.workstations = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          printers: data.printers || [{ name: 'fiscale', ipAddress: '' }]
        };
      });
    } catch (error) {
      console.error('Error loading workstations:', error);
    }
  }

  getWorkstationPrinters(workstation: any) {
    return workstation.printers || [];
  }

  addPrinter(workstation: any) {
    if (!workstation.printers) {
      workstation.printers = [];
    }
    workstation.printers.push({ name: '', ipAddress: '' });
  }

  removePrinter(workstation: any, index: number) {
    workstation.printers.splice(index, 1);
  }

  isPrinterNameInvalid(workstationId: string, index: number): boolean {
    const workstation = this.workstations.find(w => w.id === workstationId);
    return !workstation.printers[index].name;
  }

  isPrinterIpInvalid(workstationId: string, index: number): boolean {
    const workstation = this.workstations.find(w => w.id === workstationId);
    const ipAddress = workstation.printers[index].ipAddress;
    const ipPattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
    return !ipPattern.test(ipAddress);
  }

  isValid(): boolean {
    return this.workstations.every(workstation =>
        workstation.printers?.every(printer =>
            printer.name &&
            /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(printer.ipAddress)
        )
    );
  }

  async savePrinters() {
    if (!this.isValid()) {
      return;
    }

    this.isLoading = true;
    try {
      const db = firebase.firestore();
      const batch = db.batch();

      this.workstations.forEach(workstation => {
        const docRef = db.collection('workstations').doc(workstation.id);
        batch.update(docRef, { printers: workstation.printers });
      });

      await batch.commit();
      this.notificationService.show('Stampanti salvate con successo', 'success');
    } catch (error) {
      console.error('Error saving printers:', error);
      this.notificationService.show('Errore durante il salvataggio delle stampanti', 'error');
    } finally {
      this.isLoading = false;
    }
  }
}