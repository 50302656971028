import {
    Component,
    ElementRef,
    HostListener,
    ViewChild,
    AfterViewInit,
    Renderer2,
    EventEmitter,
    Output,
    Input, SimpleChanges, OnChanges
} from '@angular/core';

@Component({
    selector: 'app-numeric-keypad',
    template: `
        <div class="keypad-container"
             #keypad
             [style.bottom.px]="bottomPosition"
             tabindex="0"
             (keydown)="onKeyDown($event)">
            <div class="keypad-handle"
                 (mousedown)="onDragStart($event)"
                 (touchstart)="onDragStart($event)"></div>
            <div class="keypad-content">
                <div class="keypad-left">
                    <div class="keypad-display">{{ formattedDisplay }}</div>
                    <div class="keypad-grid">
                        <button *ngFor="let num of numbers"
                                (click)="onButtonClick(num)"
                                class="keypad-button"
                                [class.operator-button]="isOperator(num)">
                            {{ num }}
                        </button>
                    </div>
                </div>
                <div class="keypad-right">
                    <button class="clear-button" (click)="clearDisplay()">C</button>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .keypad-container {
            position: fixed;
            right: 20px;
            width: 400px;
            background-color: white;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border: 1px solid #e3e2e2;
            font-family: 'Inter', sans-serif;
            touch-action: none;
            outline: none;
            z-index: 1000;
            transition: bottom 0.1s ease;
        }

        .keypad-handle {
            height: 20px;
            background-color: #ff4444;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            cursor: ns-resize;
            touch-action: none;
            user-select: none;
        }

        .keypad-content {
            display: flex;
            padding: 15px;
            gap: 15px;
        }

        .keypad-left {
            flex: 3;
        }

        .keypad-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .keypad-display {
            text-align: right;
            font-size: 24px;
            padding: 15px;
            background-color: #f5f5f5;
            border: 1px solid #e3e2e2;
            margin-bottom: 10px;
            border-radius: 10px;
            font-family: 'Inter', sans-serif;
            font-weight: 500;
        }

        .keypad-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
        }

        .keypad-button {
            font-size: 20px;
            padding: 20px 15px;
            background-color: white;
            border: 1px solid #e3e2e2;
            border-radius: 10px;
            cursor: pointer;
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            touch-action: manipulation;
        }

        .operator-button {
            background-color: #f5f5f5;
            font-weight: 600;
        }

        .clear-button {
            background-color: #ff4444;
            color: white;
            border: none;
            padding: 20px 15px;
            font-weight: 600;
            font-size: 20px;
            border-radius: 10px;
            font-family: 'Inter', sans-serif;
            cursor: pointer;
            touch-action: manipulation;
        }
    `]
})
export class NumericKeypadComponent implements AfterViewInit, OnChanges {
    @ViewChild('keypad') keypad: ElementRef;
    @Output() valueEntered = new EventEmitter<{type: 'price' | 'quantity', value: number}>();
    @Input() reset: number = 0; // Contatore per il reset



    ngOnChanges(changes: SimpleChanges) {
        if (changes['reset'] && !changes['reset'].firstChange) {
            this.clearDisplay();
        }
    }


    numbers = ['7', '8', '9', '4', '5', '6', '1', '2', '3', '.', '0', 'x'];
    display = '0';
    bottomPosition = -280;
    isMultiplier = false;
    previousValue = '';
    hasDecimal = false;

    private isDragging = false;
    private startY = 0;
    private startBottom = 0;
    private keypadHeight: number;

    constructor(private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.keypadHeight = this.keypad.nativeElement.offsetHeight;
        this.bottomPosition = -(this.keypadHeight - 20);
    }

    get formattedDisplay(): string {
        if (this.isMultiplier) {
            return `x${this.display}`;
        }

        // Mostra il valore semplice senza formattazione quando stiamo inserendo
        return `€ ${this.display}`;
    }

    onButtonClick(value: string) {
        if (value === 'x') {
            if (!this.isMultiplier && this.display !== '0') {
                this.isMultiplier = true;
                this.previousValue = this.display;
                this.display = this.previousValue;
                this.hasDecimal = false;
                // Qui emettiamo il valore come quantità (intero)
                this.valueEntered.emit({
                    type: 'quantity',
                    value: parseInt(this.display)
                });
            }
            return;
        }

        if (value === '.') {
            if (!this.hasDecimal) {
                this.hasDecimal = true;
                if (this.display === '0') {
                    this.display = '0.';
                } else {
                    this.display += '.';
                }
            }
            return;
        }

        // Gestione numeri
        if (this.display === '0' && !this.hasDecimal) {
            this.display = value;
        } else {
            // Controlla decimali
            if (this.hasDecimal) {
                const decimalPart = this.display.split('.')[1];
                if (decimalPart && decimalPart.length >= 2) {
                    return; // Non permettere più di 2 decimali
                }
            }
            this.display += value;
        }

        // Emettiamo il valore come prezzo con due decimali SOLO quando non siamo in modalità moltiplicatore
        if (!this.isMultiplier) {
            let numericValue = parseFloat(this.display);
            if (!this.hasDecimal) {
                numericValue = parseInt(this.display);
            }
            this.valueEntered.emit({
                type: 'price',
                value: numericValue
            });
        }
    }

    clearDisplay() {
        this.display = '0';
        this.isMultiplier = false;
        this.hasDecimal = false;
        this.previousValue = '';
    }

    isOperator(value: string): boolean {
        return value === 'x' || value === '.';
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (/^[0-9]$/.test(event.key)) {
            this.onButtonClick(event.key);
        } else if (event.key === '.') {
            this.onButtonClick('.');
        } else if (event.key === 'x') {
            this.onButtonClick('x');
        } else if (event.key === 'Escape') {
            this.clearDisplay();
        }
        event.preventDefault();
    }

    onDragStart(event: MouseEvent | TouchEvent) {
        this.isDragging = true;
        this.startY = this.getEventY(event);
        this.startBottom = this.bottomPosition;
        event.preventDefault();

        if (event instanceof MouseEvent) {
            this.renderer.listen('document', 'mousemove', this.onDragMove.bind(this));
            this.renderer.listen('document', 'mouseup', this.onDragEnd.bind(this));
        } else {
            this.renderer.listen('document', 'touchmove', this.onDragMove.bind(this));
            this.renderer.listen('document', 'touchend', this.onDragEnd.bind(this));
        }
    }

    onDragMove(event: MouseEvent | TouchEvent) {
        if (this.isDragging) {
            const currentY = this.getEventY(event);
            const deltaY = this.startY - currentY;
            let newBottom = this.startBottom + deltaY;
            newBottom = Math.min(0, Math.max(-(this.keypadHeight - 20), newBottom));
            this.bottomPosition = newBottom;
        }
    }

    onDragEnd() {
        this.isDragging = false;
        this.renderer.destroy();
    }

    private getEventY(event: MouseEvent | TouchEvent): number {
        return event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
    }




}