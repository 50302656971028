import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 *
 * card per lo stato dell'ordine nella home
 */
@Component({
  selector: 'receiptOrderCard1',
  styles: [`
    .receipt-card {
      width: 100%;
      height: 65px;
      background: white;
      border: 1px solid #e3e2e2;
      border-radius: 20px;
      display: flex;
      align-items: center;
    }

    .title-section {
      display: flex;
      align-items: center;
      width: 50%;
      padding-left: 20px;
    }

    .title-text {
      font-size: 16px;
      font-weight: 500;
      color: #333;
    }

    .status-section {
      display: flex;
      width: 50%;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
    }

    .tag-container {
      display: grid;
      place-items: center;
    }

    .status-badge {
      padding: 0 12px;
      height: 32px;
      border-radius: 8px;
      display: grid;
      place-items: center;
      font-size: 11px;
      font-weight: 500;
      margin-right: 20px;
    }

    .status-paid {
      background-color: rgba(0, 255, 0, 0.3);
      color: rgba(14, 184, 14, 0.7);
    }

    .status-unpaid {
      background-color: rgba(255, 0, 0, 0.5);
      color: rgba(255, 0, 0, 0.7);
    }
  `],
  template: `
    <div class="receipt-card">
      <div class="title-section">
        <div class="title-text">{{titolo}}</div>
      </div>

      <div class="status-section">
        <div class="tag-container">
          <tagComponent [fonte]="fonte"></tagComponent>
        </div>

        <div class="status-badge"
             [class.status-paid]="stato === 'Pagato'"
             [class.status-unpaid]="stato === 'Non Pagato'">
          {{stato}}
        </div>
      </div>
    </div>
  `
})
export class cardDettaglioScontrino {


  @Input() titolo ;
  @Input() stato ;
  @Input() fonte ;
  @Input() link ;
  @Input() costo ;

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  /**
   * Simulate logout
   */


}
