<div class="row">
    <div class="content-wrapper">
        <div class="header-container">
            <div class="title">Immagini Pubblicitarie</div>
            <button type="button" class="add-button" (click)="addImage()">
                Aggiungi Immagine
            </button>
        </div>

        <div class="table-responsive">
            <table mat-table [dataSource]="images" class="mat-elevation-z8">
                <!-- Nome Column -->
                <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let image">{{image.name}}</td>
                </ng-container>

                <!-- Durata Column -->
                <ng-container matColumnDef="durata">
                    <th mat-header-cell *matHeaderCellDef>Durata (secondi)</th>
                    <td mat-cell *matCellDef="let image">{{image.duration}}</td>
                </ng-container>

                <!-- Preview Column -->
                <ng-container matColumnDef="preview">
                    <th mat-header-cell *matHeaderCellDef>Anteprima</th>
                    <td mat-cell *matCellDef="let image">
                        <img [src]="image.url"
                             alt="Preview"
                             style="height: 50px; width: 50px; object-fit: cover; border-radius: 8px;">
                    </td>
                </ng-container>

                <!-- Edit Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let image">
                        <button class="action-button" (click)="editImage(image)">
                            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-edit-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
                                 alt="Edit"
                                 class="action-icon">
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>