import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


interface DailyReceiptData {
    count: number;
    lastUpdated: firebase.firestore.Timestamp | firebase.firestore.FieldValue;
}

interface RistoranteData extends DocumentData {
    dailyReceipts?: {
        [date: string]: DailyReceiptData;
    };
    // altri campi del ristorante
}

@Injectable({
    providedIn: 'root'
})
export class DailyReceiptService {
    constructor(private firestore: AngularFirestore) {}

    async getNextReceiptNumber(restaurantId: string): Promise<number> {
        const today = new Date();
        const dateString = today.toISOString().split('T')[0];
        const ristoranteRef = this.firestore.collection('ristoranti').doc<RistoranteData>(restaurantId);

        return this.firestore.firestore.runTransaction(async (transaction) => {
            const doc = await transaction.get(ristoranteRef.ref);
            const currentData = doc.data() as RistoranteData || {};
            const dailyReceipts = currentData?.dailyReceipts || {};
            const currentCount = dailyReceipts[dateString]?.count || 0;
            const newNumber = currentCount + 1;

            transaction.set(ristoranteRef.ref, {
                dailyReceipts: {
                    ...dailyReceipts,
                    [dateString]: {
                        count: newNumber,
                        lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
                    }
                }
            }, { merge: true });

            return newNumber;
        });
    }
}