import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import firebase from 'firebase/app';
import { EMPTY } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { pipe } from 'rxjs';

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

@Component({
  selector: 'app-magazzino',
  templateUrl: './magazzino.html',
  styleUrls: ['./magazzino.css']
})
export class Magazzino implements OnInit {
  dataSource = new MatTableDataSource<any | Group>([]);
  _alldata: any[];
  columns: any[];
  displayedColumns: string[];
  groupByColumns: string[] = [];
  selectedItem: any = null;
  selezione = 'Magazzino';

  constructor(
      private dataService: DataServiceGenerico,
      private commonService: CommonService,
      private cdr: ChangeDetectorRef
  ) {
    this.columns = [
      { field: 'Titolo' },
      { field: 'UM' },
      { field: 'Fornitore' },
      { field: 'Prezzo' },
      { field: 'Quantità' }
    ];
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['Categoria'];
  }

  ngOnInit(): void {
    this.dataService.ensureInitialized().then(() => {
      this.loadData();
    });
  }

  private loadData(): void {
    this.commonService.getMagazzino(this.dataService.getRistorante1())
        .pipe(
            // Riprova fino a 3 volte in caso di errore
            retry(3),
            // Gestisci gli errori
            catchError(error => {
              console.error('Error loading data:', error);
              this._alldata = [];
              this.dataSource.data = [];
              return EMPTY;
            })
        )
        .subscribe({
          next: (response) => {
            if (response && response.items) {
              console.log('Data loaded successfully:', response);
              this._alldata = response.items;
              this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
              this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
              this.dataSource.filter = performance.now().toString();
            }
          }
        });
  }


  getTabStyle(tab: string) {
    const isSelected = this.selezione === tab;
    return {
      'font-weight': isSelected ? 'bold' : '580',
      'color': isSelected ? 'black' : '#969696',
      'display': 'flex',
      'align-items': 'center',
      'height': 'calc(100% - 2px)',
      'border-bottom': isSelected ? '2px solid black' : '2px solid white',
      'padding': '0px 10px',
      'cursor': 'pointer'
    };
  }

  selectItem(item: any) {
    this.selectedItem = item;
    this.cdr.detectChanges();
  }

  onItemUpdate(updatedItem: any) {
    if (this.selectedItem) {
      const index = this._alldata.findIndex(item => item.id === updatedItem.id);
      if (index !== -1) {
        this._alldata[index] = updatedItem;
        this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
      }
    }
  }

  onItemDeleted() {
    this.selectedItem = null;
    this.selezione = 'Magazzino';
    this.cdr.detectChanges();
  }

  aggiungiProdotto() {
    this.selectedItem = {
      id: null,
      Titolo: '',
      Categoria: '',
      image_link: '',
      Prezzo: '',
      Quantita: '0',
      Fornitore: '',
      CodiceFornitore: '',
      Scadenza: '',
      CodiceProdottoInterno: '',
      generico: false,
      ingrediente: false,
      UM: '',
      lvl: [
        { quantita: '', um: '', barCode: '' },
        { quantita: '', um: '', barCode: '' },
        { quantita: '', um: '', barCode: '' },
        { quantita: '', um: '', barCode: '' }
      ],
      maxLvl: 1
    };
    this.selezione = 'Magazzino';
    this.cdr.detectChanges();
  }

  // Group handling methods
  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.dataSource.filter = performance.now().toString();
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter(row => {
      if (!(row instanceof Group)) return false;
      let match = true;
      this.groupByColumns.forEach(column => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) return true;
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) return data;

    const groups = this.uniqueBy(
        data.map(row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }),
        JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter(item => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }
}