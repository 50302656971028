import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataServiceGenerico } from '../../shared/services/data-service/data.service';
import firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subscription, Observable } from 'rxjs';

interface PriceListSettings {
    counter: { vatId: string };
    table: { vatId: string };
    takeaway: { vatId: string };
}

interface PriceList {
    id: string;
    name: string;
    settings: PriceListSettings;
    orderProducts?: string[];  // Array of product IDs in display order
    orderCategories?: string[]; // Array of category names in display order
}

interface MenuItem {
    id: string;
    title: string;
    category: string;
    image_link?: string;
    counter_price?: number;
    counter_vat?: string;
    table_price?: number;
    table_vat?: string;
    takeaway_price?: number;
    takeaway_vat?: string;
    priceListPrices?: {
        [listId: string]: {
            counter: { price: number; vatId: string; },
            table: { price: number; vatId: string; },
            takeaway: { price: number; vatId: string; }
        }
    };
}

interface CategoryProducts {
    [key: string]: MenuItem[];
}

class Group {
    level = 0;
    parent: Group;
    expanded = true;
    totalCounts = 0;
    category?: string;
    get visible(): boolean {
        return !this.parent || (this.parent.visible && this.parent.expanded);
    }
}

@Component({
    selector: 'app-price-lists',
    templateUrl: './price-lists.component.html',
    styleUrls: ['./price-lists.component.css']
})
export class PriceListsComponent implements OnInit {
    priceLists: PriceList[] = [];
    selectedList: PriceList | null = null;
    editingList: PriceList | null = null;
    isDirty = false;
    isDragDisabled = false;

    // Table configuration
    dataSource = new MatTableDataSource<MenuItem | Group>([]);
    _alldata: MenuItem[] = [];
    columns = [
        { field: 'image' },
        { field: 'title' },
        { field: 'counter_price' },
        { field: 'counter_vat' },
        { field: 'table_price' },
        { field: 'table_vat' },
        { field: 'takeaway_price' },
        { field: 'takeaway_vat' }
    ];
    displayedColumns: string[] = this.columns.map(col => col.field);
    groupByColumns: string[] = ['category'];

    private priceListsSubscription: Subscription;
    private menuItemsSubscription: Subscription;

    // Drag and drop
    categories: string[] = [];
    productsByCategory: CategoryProducts = {};

    constructor(
        private dataService: DataServiceGenerico,
        private cdRef: ChangeDetectorRef,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.loadPriceLists();
    }

    ngOnDestroy() {
        // Clean up subscriptions
        if (this.priceListsSubscription) {
            this.priceListsSubscription.unsubscribe();
        }
        if (this.menuItemsSubscription) {
            this.menuItemsSubscription.unsubscribe();
        }
    }

    loadPriceLists() {
        const db = firebase.firestore();
        const restaurantName = this.dataService.getRistorante();
        const priceListsRef = db.collection('price_lists').doc(restaurantName);

        this.priceListsSubscription = new Observable<firebase.firestore.DocumentSnapshot>(subscriber => {
            return priceListsRef.onSnapshot(
                (doc) => subscriber.next(doc),
                (error) => subscriber.error(error)
            );
        }).subscribe({
            next: (doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    this.priceLists = data?.priceLists || [];

                    if (this.selectedList) {
                        const updatedSelectedList = this.priceLists.find(list => list.id === this.selectedList.id);
                        if (updatedSelectedList) {
                            this.selectedList = updatedSelectedList;
                        }
                    }

                    this.cdRef.detectChanges();
                }
            },
            error: (error) => {
                console.error('Error loading price lists:', error);
                this.snackBar.open('Errore nel caricamento dei listini', 'Chiudi', { duration: 3000 });
            }
        });
    }

    toggleDragLock() {
        this.isDragDisabled = !this.isDragDisabled;
        this.cdRef.detectChanges();
    }

    sortAlphabetically() {
        if (!this.selectedList) return;

        // Sort only products within each category, keeping category order unchanged
        Object.keys(this.productsByCategory).forEach(category => {
            this.productsByCategory[category].sort((a, b) =>
                a.title.localeCompare(b.title, undefined, {
                    sensitivity: 'base',  // ignora maiuscole/minuscole
                    numeric: true         // gestisce correttamente i numeri nei titoli
                })
            );
        });

        // Update the data source
        this.updateDataSource();

        // Save the new order
        this.saveOrder();

        this.snackBar.open('Prodotti ordinati alfabeticamente', 'Chiudi', {
            duration: 2000
        });
    }

    selectList(list: PriceList) {
        this.selectedList = list;
        this.loadMenuItems();
    }

    loadMenuItems() {
        if (!this.selectedList) return;

        const db = firebase.firestore();
        const restaurantName = this.dataService.getRistorante();
        const menuRef = db.collection('menu_test').doc(restaurantName);

        if (this.menuItemsSubscription) {
            this.menuItemsSubscription.unsubscribe();
        }

        this.menuItemsSubscription = new Observable<firebase.firestore.DocumentSnapshot>(subscriber => {
            return menuRef.onSnapshot(
                (doc) => subscriber.next(doc),
                (error) => subscriber.error(error)
            );
        }).subscribe({
            next: (doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    const items = data?.items || [];

                    this._alldata = items.filter(item =>
                        item.priceListPrices && item.priceListPrices[this.selectedList.id]
                    ).map(item => ({
                        ...item,
                        counter_price: item.priceListPrices[this.selectedList.id].counter.price,
                        counter_vat: item.priceListPrices[this.selectedList.id].counter.vatId,
                        table_price: item.priceListPrices[this.selectedList.id].table.price,
                        table_vat: item.priceListPrices[this.selectedList.id].table.vatId,
                        takeaway_price: item.priceListPrices[this.selectedList.id].takeaway.price,
                        takeaway_vat: item.priceListPrices[this.selectedList.id].takeaway.vatId
                    }));

                    this.categories = [...new Set(this._alldata.map(item => item.category))];
                    this.productsByCategory = {};

                    if (this.selectedList.orderCategories) {
                        this.categories = this.sortArrayByOrder(
                            this.categories,
                            this.selectedList.orderCategories
                        );
                    }

                    this.categories.forEach(category => {
                        let productsInCategory = this._alldata.filter(item => item.category === category);
                        if (this.selectedList.orderProducts) {
                            productsInCategory = this.sortArrayByOrder(
                                productsInCategory,
                                this.selectedList.orderProducts,
                                'id'
                            );
                        }
                        this.productsByCategory[category] = productsInCategory;
                    });

                    this.updateDataSource();
                    this.cdRef.detectChanges();
                }
            },
            error: (error) => {
                console.error('Error loading menu items:', error);
                this.snackBar.open('Errore nel caricamento dei prodotti', 'Chiudi', { duration: 3000 });
            }
        });
    }


    private sortArrayByOrder<T>(items: T[], orderArray: string[], idField: string = null): T[] {
        const orderMap = new Map(orderArray.map((id, index) => [id, index]));
        return [...items].sort((a, b) => {
            const aId = idField ? a[idField] : a;
            const bId = idField ? b[idField] : b;
            const aIndex = orderMap.has(aId) ? orderMap.get(aId) : Infinity;
            const bIndex = orderMap.has(bId) ? orderMap.get(bId) : Infinity;
            return aIndex - bIndex;
        });
    }

    // Nel component aggiungere questo metodo:
    removeProductFromList(item: MenuItem, event: Event) {
        event.stopPropagation(); // Previene il propagarsi dell'evento

        // Rimuovi il prodotto dalla categoria corrispondente
        if (this.productsByCategory[item.category]) {
            this.productsByCategory[item.category] = this.productsByCategory[item.category]
                .filter(product => product.id !== item.id);

            // Se la categoria è vuota, rimuovila
            if (this.productsByCategory[item.category].length === 0) {
                this.categories = this.categories.filter(cat => cat !== item.category);
                delete this.productsByCategory[item.category];
            }

            // Rimuovi il prodotto anche dall'array _alldata
            this._alldata = this._alldata.filter(product => product.id !== item.id);

            // Aggiorna il datasource
            this.updateDataSource();
        }
    }

// Modifica il metodo saveAllPrices per gestire le eliminazioni
    async saveAllPrices() {
        if (!this.selectedList) return;
        this.saveOrder();
        const db = firebase.firestore();
        const restaurantName = this.dataService.getRistorante();
        const menuRef = db.collection('menu_test').doc(restaurantName);

        try {
            const doc = await menuRef.get();
            if (!doc.exists) return;

            const data = doc.data();
            const items = data.items;

            // Ottieni tutti gli ID dei prodotti attualmente visualizzati
            const currentProductIds = new Set(this._alldata.map(item => item.id));

            // Aggiorna o rimuovi i prezzi per ogni item
            items.forEach((item) => {
                if (!item.priceListPrices) {
                    item.priceListPrices = {};
                }

                // Se il prodotto è ancora nella lista, aggiorna i prezzi
                if (currentProductIds.has(item.id)) {
                    const modifiedItem = this._alldata.find(m => m.id === item.id);
                    if (modifiedItem) {
                        item.priceListPrices[this.selectedList.id] = {
                            counter: {
                                price: Number(modifiedItem.counter_price) || 0,
                                vatId: modifiedItem.counter_vat || '1'
                            },
                            table: {
                                price: Number(modifiedItem.table_price) || 0,
                                vatId: modifiedItem.table_vat || '1'
                            },
                            takeaway: {
                                price: Number(modifiedItem.takeaway_price) || 0,
                                vatId: modifiedItem.takeaway_vat || '1'
                            }
                        };
                    }
                } else {
                    // Se il prodotto non è più nella lista, rimuovi i suoi prezzi per questo listino
                    if (item.priceListPrices[this.selectedList.id]) {
                        delete item.priceListPrices[this.selectedList.id];
                    }
                }
            });

            await menuRef.update({ items });

            this.snackBar.open('Prezzi aggiornati con successo', 'Chiudi', { duration: 2000 });
            this.loadMenuItems();

        } catch (error) {
            console.error('Errore durante l\'aggiornamento dei prezzi:', error);
            this.snackBar.open('Errore durante l\'aggiornamento', 'Chiudi', { duration: 3000 });
        }
    }


    startEditing(list: PriceList | null = null) {
        if (list) {
            this.editingList = { ...list };
        } else {
            this.editingList = {
                id: firebase.firestore().collection('temporary').doc().id,
                name: '',
                settings: {
                    counter: { vatId: '1' },
                    table: { vatId: '1' },
                    takeaway: { vatId: '1' }
                }
            };
        }
        this.isDirty = false;
        this.cdRef.detectChanges();
    }

    async savePriceList() {
        if (!this.editingList?.name) {
            this.snackBar.open('Inserisci un nome per il listino', 'Chiudi', {
                duration: 3000
            });
            return;
        }

        const db = firebase.firestore();
        const restaurantName = this.dataService.getRistorante();
        const priceListsRef = db.collection('price_lists').doc(restaurantName);

        try {
            const doc = await priceListsRef.get();
            let updatedLists: PriceList[] = [];

            if (doc.exists) {
                const existingLists = doc.data()?.priceLists || [];
                if (existingLists.some(l => l.name.toLowerCase() === this.editingList.name.toLowerCase()
                    && l.id !== this.editingList.id)) {
                    this.snackBar.open('Esiste già un listino con questo nome', 'Chiudi', {
                        duration: 3000
                    });
                    return;
                }

                updatedLists = existingLists.map(list =>
                    list.id === this.editingList.id ? this.editingList : list
                );
                if (!existingLists.find(l => l.id === this.editingList.id)) {
                    updatedLists.push(this.editingList);
                }
            } else {
                updatedLists = [this.editingList];
            }

            await priceListsRef.set({ priceLists: updatedLists });
            this.priceLists = updatedLists;
            this.editingList = null;
            this.isDirty = false;

            this.snackBar.open('Listino salvato con successo!', 'Chiudi', {
                duration: 2000
            });

            this.cdRef.detectChanges();
        } catch (error) {
            console.error('Errore durante il salvataggio del listino:', error);
            this.snackBar.open('Errore durante il salvataggio', 'Chiudi', {
                duration: 3000
            });
        }
    }



// Aggiungi questo metodo per identificare se un elemento è un header di categoria
    isCategoryHeader(item: any): boolean {
        return item instanceof Group && item.level === 1;
    }

    onDrop(event: CdkDragDrop<MenuItem[] | string[]>) {
        if (this.isDragDisabled) return;
        const movedItem = this.dataSource.data[event.previousIndex];

        if (this.isCategoryHeader(movedItem)) {
            // Se stiamo spostando un header di categoria
            let categoryIndex = this.categories.indexOf(movedItem.category);
            let targetIndex = 0;

            // Calcoliamo l'indice target nella lista delle categorie
            for (let i = 0; i < event.currentIndex && i < this.dataSource.data.length; i++) {
                const item = this.dataSource.data[i];
                if (this.isCategoryHeader(item)) {
                    targetIndex++;
                }
            }

            // Spostiamo la categoria
            if (categoryIndex !== -1 && categoryIndex !== targetIndex) {
                const category = this.categories[categoryIndex];
                this.categories.splice(categoryIndex, 1);
                this.categories.splice(targetIndex, 0, category);

                // Aggiorniamo il datasource e salviamo
                this.updateDataSource();
            }
        } else {
            // Gestione spostamento prodotti (codice esistente)
            const itemData = movedItem as MenuItem;
            const categoryItems = this.productsByCategory[itemData.category];

            if (categoryItems) {
                const realPreviousIndex = categoryItems.findIndex(i => i.id === itemData.id);
                const realCurrentIndex = this.calculateTargetIndex(event.currentIndex, itemData.category);

                if (realPreviousIndex !== -1 && realPreviousIndex !== realCurrentIndex) {
                    const [removed] = categoryItems.splice(realPreviousIndex, 1);
                    categoryItems.splice(realCurrentIndex, 0, removed);

                    this.updateDataSource();

                }
            }
        }
    }

    // Metodo per calcolare l'indice corretto all'interno di una categoria
    private calculateTargetIndex(dropIndex: number, category: string): number {
        let categoryStart = -1;
        let itemCount = -1;

        for (let i = 0; i < this.dataSource.data.length; i++) {
            const item = this.dataSource.data[i];
            if (this.isCategoryHeader(item) && item.category === category) {
                categoryStart = i;
                itemCount = 0;
            } else if (categoryStart !== -1 && !this.isCategoryHeader(item)) {
                if (i === dropIndex) {
                    return itemCount;
                }
                itemCount++;
            } else if (this.isCategoryHeader(item)) {
                categoryStart = -1;
            }
        }

        return itemCount;
    }

    updateDataSource() {
        const groupedData = [];

        this.categories.forEach(category => {
            const group = new Group();
            group.level = 1;
            group.category = category;
            groupedData.push(group);

            if (this.productsByCategory[category]) {
                this.productsByCategory[category].forEach(item => {
                    groupedData.push(item);
                });
            }
        });

        this.dataSource.data = groupedData;
        this.cdRef.detectChanges();
    }

    async saveOrder() {
        if (!this.selectedList) return;

        const db = firebase.firestore();
        const restaurantName = this.dataService.getRistorante();
        const priceListsRef = db.collection('price_lists').doc(restaurantName);

        try {
            const doc = await priceListsRef.get();
            if (!doc.exists) return;

            // Salviamo l'ordine esatto delle categorie
            const orderCategories = [...this.categories];

            // Raccogliamo tutti i prodotti nell'ordine corretto
            const orderProducts = Object.values(this.productsByCategory)
                .reduce((acc, curr) => [...acc, ...curr], [] as MenuItem[])
                .map(item => item.id);

            // Aggiorniamo il documento
            const data = doc.data();
            const updatedList = {
                ...this.selectedList,
                orderCategories,
                orderProducts
            };

            const updatedLists = data.priceLists.map(list =>
                list.id === this.selectedList.id ? updatedList : list
            );

            await priceListsRef.set({ priceLists: updatedLists });
            this.selectedList = updatedList;


        } catch (error) {
            console.error('Errore durante il salvataggio dell\'ordine:', error);

        }
    }



    async deletePriceList(listId: string) {
        if (!confirm('Sei sicuro di voler eliminare questo listino?')) {
            return;
        }

        const db = firebase.firestore();
        const restaurantName = this.dataService.getRistorante();
        const priceListsRef = db.collection('price_lists').doc(restaurantName);

        try {
            const doc = await priceListsRef.get();
            if (!doc.exists) return;

            const existingLists = doc.data()?.priceLists || [];
            const updatedLists = existingLists.filter(list => list.id !== listId);

            await priceListsRef.set({ priceLists: updatedLists });

            this.priceLists = updatedLists;
            if (this.editingList?.id === listId) {
                this.editingList = null;
            }
            if (this.selectedList?.id === listId) {
                this.selectedList = null;
            }

            this.snackBar.open('Listino eliminato con successo!', 'Chiudi', {
                duration: 2000
            });

            this.cdRef.detectChanges();
        } catch (error) {
            console.error('Errore durante l\'eliminazione del listino:', error);
            this.snackBar.open('Errore durante l\'eliminazione', 'Chiudi', {
                duration: 3000
            });
        }
    }

    cancelEdit() {
        if (this.isDirty) {
            if (confirm('Ci sono modifiche non salvate. Vuoi davvero annullare?')) {
                this.editingList = null;
                this.isDirty = false;
            }
        } else {
            this.editingList = null;
        }
        this.cdRef.detectChanges();
    }

    onFieldChange() {
        this.isDirty = true;
    }

    // Group handling methods
    customFilterPredicate(data: any | Group, filter: string): boolean {
        return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
    }

    getDataRowVisible(data: any): boolean {
        const groupRows = this.dataSource.data.filter(row => {
            if (!(row instanceof Group)) return false;
            let match = true;
            this.groupByColumns.forEach(column => {
                if (!row[column] || !data[column] || row[column] !== data[column]) {
                    match = false;
                }
            });
            return match;
        });

        if (groupRows.length === 0) return true;
        const parent = groupRows[0] as Group;
        return parent.visible && parent.expanded;
    }

    groupHeaderClick(row) {
        row.expanded = !row.expanded;
        this.dataSource.filter = performance.now().toString();
    }

    addGroups(data: any[], groupByColumns: string[]): any[] {
        const rootGroup = new Group();
        rootGroup.expanded = true;
        return this.getSublevel(data, 0, groupByColumns, rootGroup);
    }

    getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
        if (level >= groupByColumns.length) return data;

        const groups = this.uniqueBy(
            data.map(row => {
                const result = new Group();
                result.level = level + 1;
                result.parent = parent;
                for (let i = 0; i <= level; i++) {
                    result[groupByColumns[i]] = row[groupByColumns[i]];
                }
                return result;
            }),
            JSON.stringify
        );

        const currentColumn = groupByColumns[level];
        let subGroups = [];
        groups.forEach(group => {
            const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
            group.totalCounts = rowsInGroup.length;
            const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
            subGroup.unshift(group);
            subGroups = subGroups.concat(subGroup);
        });
        return subGroups;
    }

    uniqueBy(a, key) {
        const seen = {};
        return a.filter((item) => {
            const k = key(item);
            return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        });
    }

    isGroup(index, item): boolean {
        return item.level;
    }
}