import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges, ViewChild
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../router.module';
import {UserDataService} from '../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {AggiuntaPiatto} from "../aggiunta-piatto/aggiunta-piatto";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {CommonService} from "../../shared/services/firestore/common.service";
import { finalize } from 'rxjs/operators';
import * as Papa from 'papaparse';
import {EditMenu} from "../edit-menu/edit-menu";
import {EditCategories} from "../edit-categorie/edit-categories";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}


@Component({
  selector: 'app-menu',
  templateUrl: './menu.html',
  styleUrls: ['./menu.css']
})
  export class Menu implements OnInit{

  availableMenus: string[] = [];  // Array to store available menus
  selectedMenu: string;  // Holds the currently selected menu

  dataSourceDipendenti2= new MatTableDataSource<any | Group>();

  public dataSource = new MatTableDataSource<any | Group>([]);



  @ViewChild(MatPaginator) paginatorDipendenti2: MatPaginator;
  @ViewChild(MatSort) sortDipendenti2: MatSort;


  _alldata: any[];

  columns: any[];
  displayedColumns: string[];

  groupByColumns: string[] = [];



  modalOptions = { backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {} };


  display = 'none';

  closeResult = '';

  temp = [
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      messaggio1: 'Stato Ordine',
      messaggio2: 'In Corso',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    }
  ];

  nome = 'Totale';

  nome2 = 'Mancia per Mike';


  data = '23-01-2021 19.30';

  luogo = 'Rimini ,';

  prezzo = '26.00';

  prezzo2 = '2.60';

  percentuale = 0;

  percentuale2 = 10;

  user;

  ristorante;

  ifCarrello;

  carrelloTmp;

  carrello;

  numeroElementi = 0;

  tot = 0;

  isOver = false;
  selezione = 'menu';
  selectedMenuItem: any = null;
  displayedColumnsDipendenti2: string[] = [
    'title',
    'price',
    'Disponibilità'
  ];

  filterUniqueCategories(menuItems) {
    const seenCategories = new Set();
    return menuItems?.filter(item => {
      if (seenCategories.has(item.category)) {
        return false;
      } else {
        seenCategories.add(item.category);
        return true;
      }
    });
  }

  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog,
    public commonService: CommonService, public dataService: DataServiceGenerico,
    private dataServiceGenerico: DataServiceGenerico, private cdr: ChangeDetectorRef

  ){


    this.columns = [ {
      field: 'title'
    }, {
      field: 'Disponibilità'
    }];

    this.displayedColumns = this.columns.map(column => column.field);

    this.groupByColumns = ['category'];


    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');

  }

  uploadProgress: number = 0;


  // Funzione per gestire il cambiamento dello stato del checkbox del menu
  onMenuChange(row: any) {
    const newMenuValue = !row.menu ; // Menu è stringa "1" o "0"
    this.updateMenuInFirestore(row, newMenuValue);
  }

  selectMenuItem(item: any) {
    this.selectedMenuItem = item;
    this.cdr.detectChanges();
  }

  onItemUpdate(updatedItem: any) {
    if (this.selectedMenuItem) {
      const index = this._alldata.findIndex(item => item.id === updatedItem.id);
      if (index !== -1) {
        this._alldata[index] = updatedItem;
        this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
      }
    }
  }

  onItemDeleted() {
    this.selectedMenuItem = null;
    this.selezione = 'menu';  // Ensure we're on the menu view
    this.cdr.detectChanges(); // Force view update
  }

// Funzione per aggiornare il campo menu su Firebase senza usare l'indice
  updateMenuInFirestore(row: any, newMenuValue: boolean) {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();// Sostituisci con il nome del ristorante corretto
    const menuRef = db.collection('menu_test').doc(restaurantName);

    // Recupera il documento corrente
    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data.items;

        // Trova l'elemento specifico da aggiornare
        const updatedItems = items.map(item => {
          if (item.id === row.id) {
            return { ...item, menu: newMenuValue }; // Aggiorna solo il campo menu
          }
          return item; // Mantieni invariati gli altri elementi
        });

        // Aggiorna l'intero array items con l'elemento modificato
        menuRef.update({
          items: updatedItems
        })
          .then(() => {
            console.log('Menu aggiornato con successo su Firestore!');
            // Aggiorna l'oggetto localmente
            row.menu = newMenuValue;
          })
          .catch((error) => {
            console.error('Errore durante l\'aggiornamento del menu su Firestore: ', error);
          });
      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }




// Funzione per gestire il cambiamento dello stato del checkbox
  onDisponibilitaChange(row: any) {
    const newDisponibilita = !row.disponibilita ;
    this.updateDisponibilitaInFirestore(row, newDisponibilita);
  }

  // Funzione per aggiornare la disponibilità su Firebase senza usare l'indice
  updateDisponibilitaInFirestore(row: any, newDisponibilita: boolean) {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante(); // Sostituisci con il nome del ristorante corretto
    const menuRef = db.collection('menu_test').doc(restaurantName);

    // Recupera il documento corrente
    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data.items;

        // Trova l'elemento specifico da aggiornare
        const updatedItems = items.map(item => {
          if (item.title === row.title && item.price === row.price) {
            return { ...item, disponibilita: newDisponibilita }; // Aggiorna solo il campo Disponibilita
          }
          return item; // Mantieni invariati gli altri elementi
        });

        // Aggiorna l'intero array items con l'elemento modificato
        menuRef.update({
          items: updatedItems
        })
          .then(() => {
            console.log('Disponibilità aggiornata con successo su Firestore!');
            // Aggiorna l'oggetto localmente
            row.disponibilita = newDisponibilita;
          })
          .catch((error) => {
            console.error('Errore durante l\'aggiornamento della disponibilità su Firestore: ', error);
          });
      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }

  // Funzione per esportare i dati del menu in CSV direttamente da Firebase
  exportMenuToCSV() {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante(); // Sostituisci con il nome del ristorante corretto
    const menuRef = db.collection('menu_test').doc(restaurantName);

    // Recupera il documento corrente
    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data.items;

        // Converte i dati in CSV
        const replacer = (key, value) => value === null ? '' : value; // Replacer per null values
        const header = Object.keys(items[0]); // Header con i nomi delle colonne
        const csv = [
          header.join(';'), // Aggiungi l'header
          ...items.map(row => header.map(fieldName => {
            const field = row[fieldName];
            if (Array.isArray(field)) {
              return JSON.stringify(field.map(item => item.name || item).join(', '));
            }
            return JSON.stringify(field, replacer);
          }).join(';'))
        ].join('\r\n');

        // Crea un Blob dal CSV
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        // Crea un link e triggera il download
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'menu_export.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }


  triggerFileInput() {
    document.getElementById('fileInput').click();
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        Papa.parse(csvData as string, {
          header: true,
          delimiter: ';',
          complete: (result) => {
            const processedData = this.processCSVData(result.data);
            this.saveMenuToFirestore(processedData);

          }
        });
      };
      reader.readAsText(file);
    }
  }

  processCSVData(data: any[]) {
    return data.map(item => {
      const processedItem = { ...item };

      if (item.ingredients) {
        processedItem.ingredients = this.processIngredientsOrAllergens(item.ingredients);
      }

      if (item.allergens) {
        processedItem.allergens = this.processIngredientsOrAllergens(item.allergens);
      }
      if (item.variantIds) {
        processedItem.variantIds = this.processArray(item.variantIds);
      }

      if (item.prezziMenu) {
        processedItem.prezziMenu = this.processPrice(item.prezziMenu);
      }

      if (item.priceListPrices) {
        const sanitizedPriceListPrices = item.priceListPrices.replace(/(^“|"$)/g, '');
        processedItem.priceListPrices = JSON.parse(sanitizedPriceListPrices);
      }

      // Convert item.menu and item.single to boolean values
      if (item.menu !== undefined) {
        processedItem.menu = this.convertToBoolean(item.menu);
      }

      if (item.single !== undefined) {
        processedItem.single = this.convertToBoolean(item.single);
      }

      return processedItem;
    });
  }

  convertToBoolean(value: any): boolean {
    if (typeof value === 'string') {
      value = value.trim();
    }
    return value === '1' || value === 1;
  }

  processIngredientsOrAllergens(field: string) {
    const elements = field.split(/[,/]/).map(e => e.trim());
    const counts = elements.reduce((acc, element) => {
      acc[element] = (acc[element] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(counts).map(([name, quantity]) => ({ name, quantity }));
  }
  processArray(field: string) {
    const elements = field.split(/[,/]/).map(e => e.trim());


    return elements;
  }

  processPrice(field: string) {
    // Suddivide il campo in elementi, rimuovendo gli spazi vuoti
    const elements = field.split(/[,/]/).map(e => e.trim());

    // Ritorna direttamente l'array degli elementi
    return elements;
  }



  saveMenuToFirestore(menuItems: any[]) {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante(); // Replace with the actual restaurant name
    const menuRef = db.collection('menu_test').doc(restaurantName);

    // Inizializza un array per contenere gli ID generati
    const updatedMenuItems = [];

    // Itera su ogni elemento del menuItems e crea un documento separato per ciascuno
    menuItems.forEach(item => {
      // Genera un ID univoco automaticamente
      const newDocRef = db.collection('menu_test').doc().collection(restaurantName).doc();

      // Aggiorna l'elemento con il nuovo ID generato
      item.id = newDocRef.id;

      // Aggiungi l'elemento aggiornato all'array
      updatedMenuItems.push(item);

    });

    // Salva l'intero array aggiornato nel documento
    menuRef.set({ items: updatedMenuItems, ristorante: restaurantName })
      .then(() => {
        console.log('Menu successfully uploaded to Firestore with generated IDs!');
      })
      .catch((error) => {
        console.error('Error uploading menu to Firestore: ', error);
      });
  }


  openDialog(): void {
    this.selectedMenuItem = {};
    this.cdr.detectChanges();
  }

  openDialogCategorie(row: any): void {
    this.dialog.open(EditCategories, {
      data: {
        collectionType: 'menu_test'  // O 'magazzino_test' a seconda del caso
      }
    });
  }
  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }
  immagini = {}

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }

  ngOnInit(): void {
    this.immagini = this.dataServiceGenerico.getImmagini();

    // Sottoscrizione ai menu disponibili
    this.dataService.availableMenus$.subscribe(menus => {
      this.availableMenus = menus;
      this.cdr.detectChanges();
    });

    // Sottoscrizione al menu selezionato
    this.dataService.selectedMenu$.subscribe(selectedMenu => {
      this.selectedMenu = selectedMenu;
      if (selectedMenu) {
        this.fetchMenuData(selectedMenu);
      }
      this.cdr.detectChanges();
    });

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Carica prima il menu selezionato da Firebase
          await this.dataService.loadSelectedMenuFromFirebase();

          // Poi carica i menu disponibili
          await this.loadAvailableMenus();

          // Sottoscrizione agli aggiornamenti dell'utente
          this.userService.emitter.subscribe(async () => {
            await this.loadAvailableMenus();
          });

        } catch (error) {
          console.error('Errore durante l\'inizializzazione:', error);
        }
      }
    });
  }

  async loadAvailableMenus() {
    try {
      const db = firebase.firestore();
      const restaurant = this.dataService.getCurrentRestaurant();

      if (!restaurant) {
        console.warn('Nessun ristorante selezionato');
        return;
      }

      const menuSnapshot = await db.collection('menu_test')
          .where('ristorante', '==', restaurant.id)
          .get();

      const menus = menuSnapshot.docs.map(doc => doc.id);

      // Aggiorna i menu disponibili attraverso il service
      this.dataService.updateAvailableMenus(menus);

    } catch (error) {
      console.error('Errore nel caricamento dei menu disponibili:', error);
    }
  }

  onMenuSelect(event: any) {
    const selectedMenu = event.target.value;
    if (selectedMenu && selectedMenu !== this.selectedMenu) {
      this.dataService.setSelectedMenu(selectedMenu)
          .then(() => {
            console.log('Menu selezionato aggiornato con successo');
          })
          .catch(error => {
            console.error('Errore nell\'aggiornamento del menu selezionato:', error);
          });
    }
  }

  menu = [];

  idRistorante = '';

  retriveCarrello(): void {
    console.log("data commonservice getristorante: ", this.dataService.getRistorante());

    this.commonService.getMenuSnapshot(this.dataService.getRistorante()).subscribe(
        (snapshot) => {
          if (snapshot.length > 0) {
            const data = snapshot.map(doc => {
              const docData = doc.payload.doc.data() as { items: any[], ristorante: string };
              return docData;
            });

            if (data[0] !== undefined) {
              console.log("data commonservice: ", data);
              this._alldata = [];
              data[0]?.items.forEach((item) => {
                if (Array.isArray(item.ingredients)) {
                  item.ingredients = item.ingredients.map(ingredient => ingredient.name).join(', ');
                } else {
                  item.ingredients = '';
                }
                if (Array.isArray(item.allergens)) {
                  item.allergens = item.allergens.map(ingredient => ingredient.name).join(', ');
                } else {
                  item.allergens = '';
                }
                this._alldata.push(item);
              });

              console.log('risultato', this._alldata);
              this.menu = this.filterUniqueCategories(data[0]?.items);
              console.log("magazzino: ", this.menu);

              this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
              this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
              this.dataSource.filter = performance.now().toString();

              this.loadMenuData();
              this.cdr.detectChanges();
            }
          }
        },
        error => {
          console.error('Error fetching menu data:', error);
        }
    );
  }




  getAvailableMenus() {
    this.dataService.getAvailableMenus().subscribe(menus => {
      this.availableMenus = menus;

    });
  }

  async loadSelectedMenu() {
    await this.retriveCarrello();
  }

  loadMenuData() {
    const selectedMenu = this.dataService.getRistorante();  // Get the current selected menu name
    this.fetchMenuData(selectedMenu);  // Fetch the menu data from Firebase
  }



  fetchMenuData(menuName: string) {
    const db = firebase.firestore();
    const menuRef = db.collection('menu_test').doc(menuName);

    // Use onSnapshot for real-time updates
    menuRef.onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        const menuItems = data.items || [];

        this._alldata = [];

        menuItems.forEach((item) => {
          if (Array.isArray(item.ingredients)) {
            item.ingredients = item.ingredients.map(ingredient => ingredient.name).join(', ');
          } else {
            item.ingredients = '';
          }

          if (Array.isArray(item.allergens)) {
            item.allergens = item.allergens.map(allergen => allergen.name).join(', ');
          } else {
            item.allergens = '';
          }

          this._alldata.push(item);
        });

        this.applyCategoryGrouping();
        this.cdr.detectChanges(); // Trigger change detection
      } else {
        console.error('Documento menu non trovato.', menuName);
      }
    }, error => {
      console.error('Errore nel recupero dei dati del menu da Firestore:', error);
    });
  }



// Function to apply the existing category grouping logic
  applyCategoryGrouping() {

    this.groupByColumns = ['category'];  // Define the field you want to group by

    // Group the data by categories and apply the filter predicate
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
    this.dataSource.filter = performance.now().toString();  // Trigger the filter
  }
  // Create a new menu
  createNewMenu() {
    const newMenuName = prompt("Inserisci il nome del nuovo menu:");
    if (newMenuName) {
      this.dataService.createMenu(newMenuName).then(() => {
        console.log('Nuovo menu creato:', newMenuName);
        this.getAvailableMenus(); // Refresh the list after creating a new menu
      }).catch(error => {
        console.error('Errore durante la creazione del menu:', error);
      });
    }
  }

  getTabStyle(tab: string) {
    const isSelected = this.selezione === tab;
    return {
      'font-weight': isSelected ? 'bold' : '580',
      'color': isSelected ? 'black' : '#969696',
      'display': 'flex',
      'align-items': 'center',
      'height': 'calc(100% - 2px)',
      'border-bottom': isSelected ? '2px solid black' : '2px solid white',
      'padding': '0px 10px',
      'cursor': 'pointer'
    };
  }

  salvaCategorie()
  {
    console.log("ristorante: ", this.menu)
    let newMenu = {
      menu: this.menu
    }
    console.log("ristorant2: ", newMenu)

    this.commonService.updateDati(this.idRistorante, newMenu)
  }

  salvaMenu()
  {
    console.log("ristorante: ", this.dataSource.data)
    let newDataSource = this.dataSource.data.filter(element => !element.hasOwnProperty('expanded'))
    console.log("ristorant2: ", newDataSource)

    let dataSourceDaSavlvare = [];
    newDataSource.forEach(element => {
      if(dataSourceDaSavlvare.some(elemento => elemento.categoria == element.categoria))
      {
        dataSourceDaSavlvare[dataSourceDaSavlvare.findIndex(elemento => elemento.categoria == element.categoria)].menu.push(element)
      }
      else
      {
        dataSourceDaSavlvare.push(
          {
            categoria: element.categoria,
            menu: [element]
          }
        )
      }
    })

    console.log("datasourcedasalvare: ", dataSourceDaSavlvare)


    let menuDaSalvare = {
      menu: dataSourceDaSavlvare
    }



    this.commonService.updateDati(this.idRistorante, menuDaSalvare)
  }

  calcolaTotale(): void {

    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {

          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);


    });
  }


  calcolaMancia(): number {


    return this.tot / 10;
  }

  calcolaPrezzo(piatto: any): number {

    let prezzoPiatto = piatto.prezzo;

    if (piatto.opzioni) {
      piatto.opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }
    return prezzoPiatto;

  }




  groupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, true);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  checkGroupByColumn(field, add ) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if ( add ) {
        this.groupByColumns.push(field);
      }
    }
  }

  unGroupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, false);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  // below is for grid row grouping
  customFilterPredicate(data: any | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter(
      row => {
        if (!(row instanceof Group)) {
          return false;
        }
        let match = true;
        this.groupByColumns.forEach(column => {
          if (!row[column] || !data[column] || row[column] !== data[column]) {
            match = false;
          }
        });
        return match;
      }
    );

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.dataSource.filter = performance.now().toString();  // bug here need to fix
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map(
        row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }
      ),
      JSON.stringify);

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }

}
