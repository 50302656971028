<div class="dashboard-container">
    <!-- Stats Grid -->
    <div class="stats-grid">
        <div class="stats-section">
            <!-- Totale Ordini -->
            <div class="stat-card">
                <h3 class="stat-title">{{filters.showDeleted ? 'Eliminati: ' : ''}}Scontrini Totali</h3>
                <p class="stat-value">{{stats.totalOrders}}</p>
                <div class="trend-indicator" [class.positive]="stats.percentageChanges.orders >= 0"
                     [class.negative]="stats.percentageChanges.orders < 0">
                    <i class="material-icons">
                        {{stats.percentageChanges.orders >= 0 ? 'trending_up' : 'trending_down'}}
                    </i>
                    <span>{{stats.percentageChanges.orders | number:'1.1-1'}}%</span>
                </div>
            </div>

            <!-- Fatturato -->
            <div class="stat-card">
                <h3 class="stat-title">{{filters.showDeleted ? 'Eliminati: ' : ''}}Fatturato Totale</h3>
                <p class="stat-value">{{formatCurrency(stats.totalRevenue)}}</p>
                <div class="trend-indicator" [class.positive]="stats.percentageChanges.revenue >= 0"
                     [class.negative]="stats.percentageChanges.revenue < 0">
                    <i class="material-icons">
                        {{stats.percentageChanges.revenue >= 0 ? 'trending_up' : 'trending_down'}}
                    </i>
                    <span>{{stats.percentageChanges.revenue | number:'1.1-1'}}%</span>
                </div>
            </div>

            <!-- Scontrino Medio -->
            <div class="stat-card">
                <h3 class="stat-title">{{filters.showDeleted ? 'Eliminati: ' : ''}}Scontrino Medio</h3>
                <p class="stat-value">{{formatCurrency(stats.averageReceipt)}}</p>
                <div class="trend-indicator" [class.positive]="stats.percentageChanges.average >= 0"
                     [class.negative]="stats.percentageChanges.average < 0">
                    <i class="material-icons">
                        {{stats.percentageChanges.average >= 0 ? 'trending_up' : 'trending_down'}}
                    </i>
                    <span>{{stats.percentageChanges.average | number:'1.1-1'}}%</span>
                </div>
            </div>
        </div>
    </div>

    <!-- Filters Section -->
    <div class="filters-section">
        <div class="filters-grid">
            <!-- Date Range Filters -->
            <div class="date-filters">
                <div class="date-range-picker">
                    <button *ngFor="let preset of datePresets"
                            class="date-chip"
                            [class.active]="isActivePreset(preset.value)"
                            (click)="setDatePreset(preset.value)">
                        {{preset.label}}
                    </button>
                    <button class="date-chip custom" (click)="openDateRangePicker()">
                        <i class="material-icons">date_range</i>
                        Periodo Personalizzato
                    </button>
                </div>

                <!-- Date Range Picker Dialog -->
                <mat-form-field *ngIf="showCustomDateRange" class="custom-date-range">
                    <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Data inizio">
                        <input matEndDate formControlName="end" placeholder="Data fine">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>

            <!-- Order Type Filters -->
            <div class="type-filters">
                <button *ngFor="let type of orderTypeFilters"
                        class="type-chip"
                        [class.active]="filters.orderTypes.includes(type.value)"
                        (click)="toggleOrderType(type.value)">
                    <i class="material-icons">{{type.icon}}</i>
                    {{type.label}}
                </button>
                <button class="type-chip"
                        [class.active]="filters.showDeleted"
                        data-type="deleted"
                        (click)="toggleDeletedOrders()">
                    <i class="material-icons">delete</i>
                    Ordini Eliminati
                </button>
            </div>

            <!-- Search and Additional Filters -->
            <div class="search-filters">
                <div class="search-input">
                    <i class="material-icons">search</i>
                    <input type="text"
                           [(ngModel)]="filters.searchTerm"
                           (ngModelChange)="search($event)"
                           placeholder="Cerca prodotti, categorie...">
                </div>

                <!-- Time Range Filter -->
                <div class="time-range">
                    <mat-form-field>
                        <mat-label>Orario</mat-label>
                        <mat-select [formControl]="timeRangeControl">
                            <mat-option value="all">Tutto il giorno</mat-option>
                            <mat-option value="morning">Mattino (6:00-12:00)</mat-option>
                            <mat-option value="lunch">Pranzo (12:00-15:00)</mat-option>
                            <mat-option value="afternoon">Pomeriggio (15:00-19:00)</mat-option>
                            <mat-option value="dinner">Cena (19:00-23:00)</mat-option>
                            <mat-option value="custom">Personalizzato</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <!-- Receipts Section -->
    <div class="receipts-container">
        <!-- Loading Spinner -->
        <div class="loading-overlay" *ngIf="loading">
            <mat-spinner diameter="50"></mat-spinner>
        </div>

        <!-- Error State -->
        <div class="error-state" *ngIf="error">
            <mat-icon color="warn">error_outline</mat-icon>
            <h3>Errore di caricamento</h3>
            <p>Non è stato possibile caricare gli scontrini.</p>
            <button mat-raised-button color="primary" (click)="loadOrders()">Riprova</button>
        </div>

        <!-- Receipts List -->
        <div class="receipts-list" *ngIf="!loading && !error">
            <div class="receipt-card" *ngFor="let order of filteredOrders">
                <div class="receipt-header">
                    <div class="receipt-info">
                        <h3>{{order.id}} - {{order.displayName}}</h3>
                        <span class="timestamp">{{formatDate(order.data)}}</span>
                    </div>
                    <div class="receipt-status" [class.paid]="order.statoPagato === 0">
                        <span>{{formatCurrency(getOrderTotal(order))}}</span>
                    </div>
                </div>



                <div class="receipt-content">
                    <div class="product-list">
                        <div class="product-item" *ngFor="let product of getOrderProducts(order)">
                            <span class="quantity">{{product.quantita}}x</span>
                            <span class="name">{{product.title}}</span>
                            <span class="price">{{formatCurrency(product.prezzo * product.quantita)}} - {{product.vatId == 2 ? '10%' : '22%'}}</span>
                        </div>
                    </div>
                </div>

                <div class="deletion-info" *ngIf="this.filters.showDeleted"
                     style="margin-top: 16px; padding: 12px; background-color: #fee2e2; border-radius: 8px;">
                    <strong style="color: #ef4444;">Motivo eliminazione:</strong>
                    <p style="margin: 8px 0 0; color: #374151;">{{order.motivazione}}</p>
                </div>

                <div class="receipt-footer">
                    <div class="payment-info">
                        <div class="payment-method" *ngIf="order.paymentMethod">
                            <i class="material-icons">
                                {{getPaymentMethodIcon(order.paymentMethod)}}
                            </i>
                            <span>{{getPaymentMethodLabel(order.paymentMethod)}}</span>
                        </div>
                        <div class="order-stats">
                            <span>{{getTotalProducts(order)}} prodotti</span>
                        </div>
                    </div>
                    <button class="view-details" (click)="viewOrderDetails(order)">
                        Dettagli
                        <i class="material-icons">chevron_right</i>
                    </button>
                </div>
            </div>

            <!-- Empty State -->
            <div class="empty-state" *ngIf="filteredOrders.length === 0">
                <mat-icon>receipt_long</mat-icon>
                <h3>Nessuno scontrino trovato</h3>
                <p>Prova a modificare i filtri di ricerca</p>
            </div>
        </div>
    </div>
</div>