<!-- notifiche.html -->
<div class="row" style="height: 95vh; width:calc(60vw); margin-top: 15px; border-radius: 20px; overflow: scroll">
    <div>
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Invia Notifica</div>
        <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <form [formGroup]="notificheForm">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="flex flex-col gap-2 flex-1">
                            <label class="font-bold text-gray-700 text-heading-sm">User ID</label>
                            <input type="text" formControlName="userId" class="form-control">
                        </div>
                    </div>
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="flex flex-col gap-2 flex-1">
                            <label class="font-bold text-gray-700 text-heading-sm">Messaggio</label>
                            <textarea formControlName="message" class="form-control" rows="3"></textarea>
                        </div>
                    </div>
                </div>
            </form>
            <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);"
                 (click)="inviaNotifica()"
                 [style.opacity]="isLoading ? '0.5' : '1'">
                {{ isLoading ? 'Invio...' : 'Invia Notifica' }}
            </div>
        </div>
    </div>
</div>