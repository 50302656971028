<div class="dialog-container">
    <div class="sticky-container">
        <button mat-icon-button class="close-button" aria-label="Chiudi" (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <!-- Scelta iniziale tramite due pulsanti grandi -->
    <div class="opzioni-iniziali" *ngIf="!chiusuraGiornaliera && !annullaScontrino">
        <div class="buttons-row">
            <button mat-raised-button class="square-button" (click)="selezionaChiusuraGiornaliera()">
                <span class="button-text">Chiusura Giornaliera</span>
            </button>
            <button mat-raised-button class="square-button" (click)="selezionaAnnullaScontrino()">
                <span class="button-text">Annulla Scontrino</span>
            </button>
        </div>
        <div class="buttons-row">
            <button mat-raised-button class="square-button " (click)="navigateToUtenti()">
                <span class="button-text">Cambia Account</span>
            </button>
            <button mat-raised-button class="square-button logout" (click)="navigateToImpostazioni()">
                <span class="button-text">Backoffice</span>
            </button>
            <!--button mat-raised-button class="square-button logout" (click)="logout()">
                <span class="button-text">Logout</span>
            </button-->
        </div>
    </div>

    <!-- Conferma per la chiusura giornaliera -->
    <div class="conferma-chiusura" *ngIf="chiusuraGiornaliera">
        <p>Vuoi procedere con la chiusura giornaliera?</p>
        <div class="actions-container">
            <button mat-raised-button class="confirm-button" (click)="confermaChiusuraGiornaliera()">
                <span class="button-text">Conferma</span>
            </button>
            <button mat-raised-button class="cancel-button" (click)="annullaOperazione()">
                <span class="button-text">Annulla</span>
            </button>
        </div>
    </div>

    <!-- Form per annullare lo scontrino -->
    <div class="form-annulla-scontrino" *ngIf="annullaScontrino">
        <form (ngSubmit)="annullaScontrinoSubmit()" class="form-container">
            <mat-form-field>
                <mat-label>Numero Scontrino</mat-label>
                <input matInput [(ngModel)]="nomeScontrino" name="nomeScontrino" required />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Chiusura Giornaliera</mat-label>
                <input matInput [(ngModel)]="chiusuraGiornalieraInput" name="chiusuraGiornaliera" required />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Data (ggmmaaaa)</mat-label>
                <input matInput [(ngModel)]="dataInput" name="data" required pattern="\d{8}" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Seriale</mat-label>
                <input matInput [(ngModel)]="serialeInput" name="seriale" required />
            </mat-form-field>

            <div class="actions-container">
                <button mat-raised-button class="confirm-button" type="submit">
                    <span class="button-text">Annulla Scontrino</span>
                </button>
                <button mat-raised-button class="cancel-button" type="button" (click)="annullaOperazione()">
                    <span class="button-text">Annulla</span>
                </button>
            </div>
        </form>
    </div>
</div>