import {CommonService} from "../firestore/common.service";
import { Injectable } from '@angular/core';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {catchError, map, switchMap} from "rxjs/operators";

@Injectable()
export class DataServiceGenerico {
  private selectedMenuSubject = new BehaviorSubject<string>('');
  selectedMenu$: Observable<string> = this.selectedMenuSubject.asObservable();

  private selectedRistoranteSubject = new BehaviorSubject<string>('');
  selectedRistorante$: Observable<string> = this.selectedRistoranteSubject.asObservable();

  private availableMenusSubject = new BehaviorSubject<string[]>([]);
  availableMenus$: Observable<string[]> = this.availableMenusSubject.asObservable();

  private availableRestaurantsSubject = new BehaviorSubject<any[]>([]);
  availableRestaurants$: Observable<any[]> = this.availableRestaurantsSubject.asObservable();

  private restaurantIdSubject = new BehaviorSubject<string | null>(null);
  restaurantIdReady$ = this.restaurantIdSubject.asObservable();

  private isInitialized = false;
  private initializationPromise: Promise<void> | null = null;


  private currentSelectedRestaurant: any = null;
  private currentGroupId: string | null = null;

  constructor() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.initializeUserData(user.uid);
      }
      this.initializationPromise = this.initializeService();
    });
  }

  private async initializeService(): Promise<void> {
    if (this.isInitialized) return;

    return new Promise<void>((resolve) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          try {
            await this.initializeUserData(user.uid);
            // Aspetta che l'ID del ristorante sia effettivamente impostato
            if (!this.getRistorante1()) {
              await this.loadAvailableRestaurants();
            }
            this.isInitialized = true;
          } catch (error) {
            console.error('Error during initialization:', error);
          }
        }
        unsubscribe();
        resolve();
      });
    });
  }

  private async initializeUserData(userId: string) {
    try {
      const db = firebase.firestore();

      // First find the user's group
      const groupSnapshot = await db
          .collection('gruppi')
          .where('admin', '==', userId)
          .get();

      if (!groupSnapshot.empty) {
        this.currentGroupId = groupSnapshot.docs[0].id;
        const groupData = groupSnapshot.docs[0].data();

        // Load saved selected restaurant if it exists
        if (groupData.selectedRestaurant) {
          const restaurantDoc = await db
              .collection('ristoranti')
              .doc(groupData.selectedRestaurant)
              .get();

          if (restaurantDoc.exists) {
            const restaurantData = {
              id: restaurantDoc.id,
              ...restaurantDoc.data()
            };
            this.currentSelectedRestaurant = restaurantData;
            this.selectedRistoranteSubject.next(restaurantData.id);

            // Se c'è un menu selezionato, caricalo immediatamente
            if (restaurantData['selectedMenu']) {
              this.selectedMenuSubject.next(restaurantData['selectedMenu']);
            } else {
              // Se non c'è un menu selezionato, prova a caricare il primo menu disponibile
              const menuSnapshot = await db.collection('menu_test')
                  .where('ristorante', '==', restaurantData.id)
                  .limit(1)
                  .get();

              if (!menuSnapshot.empty) {
                const firstMenu = menuSnapshot.docs[0];
                this.selectedMenuSubject.next(firstMenu.id);
              }
            }
          }
        }
      }

      await this.loadAvailableRestaurants();

    } catch (error) {
      console.error('Error initializing user data:', error);
    }
  }

  updateAvailableMenus(menus: string[]) {
    this.availableMenusSubject.next(menus);
  }

  getAvailableMenus(): Observable<string[]> {
    return from(this.getCurrentUser()).pipe(
        switchMap(user => {
          if (!user) {
            throw new Error('Utente non autenticato');
          }

          if (!this.currentSelectedRestaurant) {
            throw new Error('Nessun ristorante selezionato');
          }

          const db = firebase.firestore();
          return from(db.collection('menu_test')
              .where('ristorante', '==', this.currentSelectedRestaurant.id)
              .get());
        }),
        map((snapshot: firebase.firestore.QuerySnapshot) => {
          const menuNames: string[] = [];
          snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot) => {
            menuNames.push(doc.id);
          });
          this.availableMenusSubject.next(menuNames); // Aggiorna il BehaviorSubject
          return menuNames;
        }),
        catchError(error => {
          console.error('Errore nel recupero dei menu:', error);
          return of([]);
        })
    );
  }

  async loadSelectedMenuFromFirebase(): Promise<void> {
    try {
      if (!this.currentSelectedRestaurant) {
        console.error('Nessun ristorante selezionato');
        return;
      }

      const db = firebase.firestore();
      const restaurantDoc = await db.collection('ristoranti')
          .doc(this.currentSelectedRestaurant.id)
          .get();

      if (restaurantDoc.exists) {
        const data = restaurantDoc.data();
        if (data && data.selectedMenu) {
          this.selectedMenuSubject.next(data.selectedMenu);
        } else {
          // Se non c'è un menu selezionato, carica il primo menu disponibile
          const menuSnapshot = await db.collection('menu_test')
              .where('ristorante', '==', this.currentSelectedRestaurant.id)
              .limit(1)
              .get();

          if (!menuSnapshot.empty) {
            const firstMenu = menuSnapshot.docs[0];
            await this.setSelectedMenu(firstMenu.id); // Usa setSelectedMenu invece di next diretto
          }
        }

        // Aggiorna la lista dei menu disponibili
        await this.getAvailableMenus().toPromise();
      }
    } catch (error) {
      console.error('Errore nel recupero del menu selezionato:', error);
    }
  }



  private getCurrentUser(): Promise<firebase.User | null> {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  async loadAvailableRestaurants() {
    try {
      const user = await this.getCurrentUser();
      if (!user) return;

      const db = firebase.firestore();
      let restaurants: any[] = [];

      // Cerca nei gruppi
      const groupQuerySnapshot = await db
          .collection('gruppi')
          .where('admin', '==', user.uid)
          .get();

      if (!groupQuerySnapshot.empty) {
        const groupDoc = groupQuerySnapshot.docs[0];
        const groupData = groupDoc.data();

        if (groupData.ristoranti && Array.isArray(groupData.ristoranti)) {
          const restaurantPromises = groupData.ristoranti.map(async (restaurantId) => {
            const restaurantDoc = await db.collection('ristoranti').doc(restaurantId).get();
            if (restaurantDoc.exists) {
              return {
                id: restaurantDoc.id,
                nome: restaurantDoc.data().nome,
                selectedMenu: restaurantDoc.data().selectedMenu,
                ...restaurantDoc.data()
              };
            }
            return null;
          });

          const groupRestaurants = (await Promise.all(restaurantPromises)).filter(r => r !== null);
          restaurants = [...restaurants, ...groupRestaurants];
        }
      }

      // Cerca i ristoranti dove l'utente è admin
      const adminRestaurantsSnapshot = await db
          .collection('ristoranti')
          .where('admin', '==', user.uid)
          .get();

      const adminRestaurants = adminRestaurantsSnapshot.docs.map(doc => ({
        id: doc.id,
        nome: doc.data().nome,
        selectedMenu: doc.data().selectedMenu,
        ...doc.data()
      }));

      restaurants = [...restaurants, ...adminRestaurants];

      // Cerca i ristoranti dove l'utente è nell'organico
      const organicoRestaurantsSnapshot = await db
          .collection('ristoranti')
          .where('utenti', 'array-contains', user.uid)
          .get();

      const organicoRestaurants = organicoRestaurantsSnapshot.docs.map(doc => ({
        id: doc.id,
        nome: doc.data().nome,
        selectedMenu: doc.data().selectedMenu,
        ...doc.data()
      }));

      restaurants = [...restaurants, ...organicoRestaurants];

      // Rimuovi duplicati
      const uniqueRestaurants = Array.from(
          new Map(restaurants.map(item => [item.id, item])).values()
      );

      if (uniqueRestaurants.length > 0) {
        await this.setCurrentRestaurant(uniqueRestaurants[0]);
        // Emetti l'ID del ristorante anche qui
        this.restaurantIdSubject.next(uniqueRestaurants[0].id);
      }

      this.availableRestaurantsSubject.next(uniqueRestaurants);

    } catch (error) {
      console.error('Errore nel caricamento dei ristoranti:', error);
      this.availableRestaurantsSubject.next([]);
      this.restaurantIdSubject.next(null);
    }
  }

  async setCurrentRestaurant(restaurant: any) {
    this.currentSelectedRestaurant = restaurant;
    this.selectedRistoranteSubject.next(restaurant.id);
    this.restaurantIdSubject.next(restaurant.id);  // Aggiungi questa riga

    if (restaurant.selectedMenu) {
      this.selectedMenuSubject.next(restaurant.selectedMenu);
    }

    if (this.currentGroupId) {
      try {
        const db = firebase.firestore();
        await db.collection('gruppi')
            .doc(this.currentGroupId)
            .update({
              selectedRestaurant: restaurant.id
            });
      } catch (error) {
        console.error('Error saving selected restaurant:', error);
      }
    }
  }


  getCurrentRestaurant() {
    return this.currentSelectedRestaurant;
  }


  async setSelectedMenu(menuName: string) {
    try {
      if (!this.currentSelectedRestaurant) {
        throw new Error('Nessun ristorante selezionato');
      }

      const db = firebase.firestore();
      await db.collection('ristoranti')
          .doc(this.currentSelectedRestaurant.id)
          .update({
            selectedMenu: menuName
          });

      this.selectedMenuSubject.next(menuName);
      this.selectedRistoranteSubject.next(menuName);

      this.currentSelectedRestaurant.selectedMenu = menuName;

      console.log('Menu selezionato salvato con successo!');
    } catch (error) {
      console.error('Errore durante il salvataggio del menu selezionato:', error);
      throw error;
    }
  }


  async createMenu(newMenuName: string): Promise<void> {
    try {
      if (!this.currentSelectedRestaurant) {
        throw new Error('Nessun ristorante selezionato');
      }

      const db = firebase.firestore();
      return db.collection('menu_test').doc(newMenuName).set({
        items: [],
        nome_ristorante: newMenuName,
        ristorante: this.currentSelectedRestaurant.id
      });
    } catch (error) {
      console.error('Errore durante la creazione del menu:', error);
      throw error;
    }
  }

  setUser(u) {
    user = u;
  }

  getUser() {
    return user;
  }


  setUserLocale(u) {
    userLocale = u;
  }

  getUserLocale() {
    return userLocale;
  }

  salva() {
    this.camerieriSubject.next(camerieri);
  }

  subtractCameriere(cameriere: any, piatto: any) {
    let filteredCamerieri = camerieri.findIndex(cameriere3 => cameriere3.ordine == cameriere);
    let cameriere2 = camerieri[filteredCamerieri];

    if(cameriere2.hasOwnProperty('carrello')? cameriere2.carrello?.hasOwnProperty(piatto.title.replace(/ /g,'')): false) {
      if(cameriere2.carrello[piatto.title.replace(/ /g,'')].quantita == 1) {
        delete cameriere2.carrello[piatto.title.replace(/ /g,'')];
      } else {
        cameriere2.carrello[piatto.title.replace(/ /g,'')].quantita--;
      }
    }

    camerieri = camerieri.filter(item => !(item.ordine == cameriere2.ordine));
    camerieri.push(cameriere2);
    camerieri = camerieri.sort((a, b) => b.ordine - a.ordine);
    ordine = cameriere2;

    this.OrdineSubject.next(ordine);
    this.camerieriSubject.next(camerieri);
  }

  updateCameriere2(cameriere: any, piatto: any) {
    let filteredCamerieri = camerieri.findIndex(cameriere3 => cameriere3.ordine == cameriere);
    camerieri[filteredCamerieri].carrello = piatto;

    ordine = camerieri[filteredCamerieri];
    camerieri = camerieri.sort((a, b) => b.ordine - a.ordine);

    this.OrdineSubject.next(ordine);
    this.camerieriSubject.next(camerieri);
  }

  updateCameriere(cameriere: any, piatto: any, portata?: any) {
    let filteredCamerieri = camerieri.findIndex(cameriere3 => cameriere3.ordine == cameriere);
    let cameriere2 = camerieri[filteredCamerieri];

    if(cameriere2.hasOwnProperty('carrello')? cameriere2.carrello?.hasOwnProperty(piatto.title.replace(/ /g,'')): false) {
      if (!cameriere2.hasOwnProperty('ordineInserimento')) {
        cameriere2['ordineInserimento'] = [];
        for (let key in cameriere2.carrello) {
          if (cameriere2.carrello.hasOwnProperty(key)) {
            cameriere2['ordineInserimento'].push(key);
          }
        }
      }

      cameriere2.carrello[piatto.title.replace(/ /g,'')].quantita++;
    } else {
      if(!cameriere2.hasOwnProperty('carrello')) {
        let nome = piatto.title.replace(/ /g,'');
        cameriere2['carrello'] = {};
        cameriere2['ordineInserimento'] = [];
        cameriere2['carrello'][nome] = piatto;
        cameriere2.carrello[nome].quantita = 1;
        cameriere2.carrello[nome].price = +cameriere2.carrello[nome].price;
        cameriere2.carrello[nome].prezzo = +cameriere2.carrello[nome].price;
        if(portata == 4) {
          portata = 0;
        }
        cameriere2.carrello[nome].portata = portata;
        cameriere2['ordineInserimento'].push(nome);
      } else {
        let nome = piatto.title.replace(/ /g,'');
        cameriere2['carrello'][nome] = piatto;
        cameriere2.carrello[nome].quantita = 1;
        cameriere2.carrello[nome].price = +cameriere2.carrello[nome].price;
        cameriere2.carrello[nome].prezzo = +cameriere2.carrello[nome].price;
        if(portata == 4) {
          portata = 0;
        }
        cameriere2.carrello[nome].portata = portata;

        if (!cameriere2.hasOwnProperty('ordineInserimento')) {
          cameriere2['ordineInserimento'] = [];
          for (let key in cameriere2.carrello) {
            if (cameriere2.carrello.hasOwnProperty(key)) {
              cameriere2['ordineInserimento'].push(key);
            }
          }
        } else {
          cameriere2['ordineInserimento'].push(nome);
        }
      }
    }

    camerieri = camerieri.filter(item => !(item.ordine == cameriere2.ordine));
    camerieri.push(cameriere2);
    ordine = cameriere2;
    camerieri = camerieri.sort((a, b) => b.ordine - a.ordine);

    this.OrdineSubject.next(ordine);
    this.camerieriSubject.next(camerieri);
  }

  setCamerieri(camerieri2: any) {
    camerieri2.forEach(ordine => {
      if(ordine.hasOwnProperty('carrello')) {
        if (!ordine.hasOwnProperty('ordineInserimento')) {
          ordine['ordineInserimento'] = [];
          for (let key in ordine.carrello) {
            if (ordine.carrello.hasOwnProperty(key)) {
              ordine['ordineInserimento'].push(key);
            }
          }
        }
      }
    });
    camerieri = camerieri2;
    this.camerieriSubject.next(camerieri);
  }

  private camerieriSubject = new BehaviorSubject<any>(0);
  camerieriSubjectItem$ = this.camerieriSubject.asObservable();

  private OrdineSubject = new BehaviorSubject<any>(0);
  OrdineSubjectItem$ = this.OrdineSubject.asObservable();

  getCamerieri() {
    return camerieri.filter(ordine => ordine.hasOwnProperty('fonte')? ordine.fonte == 1: false).filter(ordine => ordine.hasOwnProperty('statoPagato')? ordine.fonte == 0: false);
  }

  setFilterFonte(filterFonteInterno) {
    filterFonte = filterFonteInterno;
  }

  setFilterPagato(filterPagatoInterno) {
    filterPagato = filterPagatoInterno;
  }

  getRistorante(): string {
    return this.selectedMenuSubject.getValue();
  }

  setRistorante(ristorante) {
    this.selectedMenuSubject.next(ristorante);
  }

  async ensureInitialized(): Promise<void> {
    if (this.isInitialized) return;
    if (this.initializationPromise) {
      await this.initializationPromise;
    }
  }

  getRistorante1(): string {
    const id = this.selectedRistoranteSubject.getValue();
    if (!id && this.currentSelectedRestaurant?.id) {
      this.selectedRistoranteSubject.next(this.currentSelectedRestaurant.id);
      this.restaurantIdSubject.next(this.currentSelectedRestaurant.id);
      return this.currentSelectedRestaurant.id;
    }
    return id;
  }

  async getCurrentRestaurantId(): Promise<string> {

    await this.ensureInitialized();
    const id = this.getRistorante1();
    if (!id || id == '') {
      await this.loadAvailableRestaurants();
      return this.getRistorante1();
    }
    return id;
  }

  waitForRestaurantId(): Promise<string> {
    return new Promise((resolve) => {
      if (this.getRistorante1()) {
        resolve(this.getRistorante1());
      } else {
        const subscription = this.restaurantIdReady$.subscribe(id => {
          if (id) {
            subscription.unsubscribe();
            resolve(id);
          }
        });
      }
    });
  }

  setRistorante1(ristorante) {
    this.selectedRistoranteSubject.next(ristorante);
  }

  getAllergeni() {
    return allergeni;
  }

  getRistoranti() {
    return ristoranti;
  }

  setRistoranti(ristoranti2) {
    ristoranti = ristoranti2;
  }

  getGruppi() {
    return gruppi;
  }

  setGruppi(gruppi2) {
    gruppi = gruppi2;
  }

  getCollegamenti() {
    return collegamenti;
  }

  seCollegamenti(gruppi2) {
    collegamenti = gruppi2;
  }

  setImmagini(immagini2) {
    immagini = immagini2;
  }

  getImmagini() {
    return immagini;
  }

  private viewPreferenceDoc(restaurantId: string) {
    return firebase.firestore()
        .collection('restaurants')
        .doc(restaurantId)
        .collection('preferences')
        .doc('view');
  }

  async saveViewPreference(view: number): Promise<void> {
    const restaurantId = this.getRistorante1();
    if (!restaurantId) return;

    try {
      await firebase.firestore()
          .collection('ristoranti')
          .doc(restaurantId)
          .update({
            viewPreference: view
          });
    } catch (error) {
      console.error('Error saving view preference:', error);
    }
  }

  async getViewPreference(): Promise<number> {
    const restaurantId = this.getRistorante1();
    if (!restaurantId) return 0;

    try {
      const doc = await firebase.firestore()
          .collection('ristoranti')
          .doc(restaurantId)
          .get();

      return doc.exists ? doc.data()?.viewPreference ?? 0 : 0;
    } catch (error) {
      console.error('Error getting view preference:', error);
      return 0;
    }
  }



}

let immagini = {};

let user = {};
let userLocale = {};


let camerieri = [];

let ordine = {};

let filterPagato = 'Non Pagato';
let filterFonte = 'Cassa';

let allergeni = [
  "glutine",
  "latticini",
  "crostacei",
  "uova",
  "arachidi",
  "soia",
  "frutta con guscio",
  "sedano",
  "senape",
  "sesamo",
  "lupini",
  "molluschi",
  "Anidride solforosa",
];

let ristoranti = [
  {
    nome: 'Befane',
    piva: '981237983732971',
    email: 'befane@fillapp.it',
    permessi: ['']
  },
  {
    nome: 'Bagno 44',
    piva: '981237983732971',
    email: 'bagno44@fillapp.it',
    permessi: ['']
  },
  {
    nome: 'Bagno 23',
    piva: '981237983732971',
    email: 'bagno23@fillapp.it',
    permessi: ['']
  },
  {
    nome: 'Stazione bologna',
    piva: '981237983732971',
    email: 'Bologna@fillapp.it',
    permessi: ['']
  },
];

let gruppi = [
  {
    nome: 'Centri Commerciali',
    ristoranti: [
      {
        nome: 'Befane',
        piva: '981237983732971',
        email: 'befane@fillapp.it',
        permessi: ['']
      },
    ]
  },
  {
    nome: 'Mare',
    ristoranti: [
      {
        nome: 'Bagno 44',
        piva: '981237983732971',
        email: 'bagno44@fillapp.it',
        permessi: ['']
      },
      {
        nome: 'Bagno 23',
        piva: '981237983732971',
        email: 'bagno23@fillapp.it',
        permessi: ['']
      },
    ]
  },
  {
    nome: 'Stazione',
    ristoranti: [
      {
        nome: 'Stazione bologna',
        piva: '981237983732971',
        email: 'Bologna@fillapp.it',
        permessi: ['']
      },
    ]
  }
];

let collegamenti = [
  {
    nome: 'cannoleria',
    gruppo: [
      {
        nome: 'Centri Commerciali',
        ristoranti: []
      },
    ],
    ristoranti: []
  },
  {
    nome: 'fastfood',
    gruppo: [
      {
        nome: 'Mare',
        ristoranti: []
      },
    ],
    ristoranti: []
  },
  {
    nome: 'pasticceria_1',
    gruppo: [
      {
        nome: 'Stazione',
        ristoranti: []
      }
    ],
    ristoranti: []
  },
  {
    nome: 'pasticceria_2',
    gruppo: [
      ''
    ],
    ristoranti: []
  }
];