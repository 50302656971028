<div class="dashboard-container">
    <!-- Stats Cards Row -->
    <div class="stats-cards">
        <div class="gradient-card teal">
            <div class="card-content">
                <div class="value">{{formatCurrency(totalRevenue)}}</div>
                <div class="label">Fatturato Totale</div>
                <div class="icon-container">
                    <mat-icon>payments</mat-icon>
                </div>
            </div>
        </div>

        <div class="gradient-card blue">
            <div class="card-content">
                <div class="value">{{totalProducts}}</div>
                <div class="label">Prodotti Venduti</div>
                <div class="icon-container">
                    <mat-icon>inventory_2</mat-icon>
                </div>
            </div>
        </div>

        <div class="gradient-card purple">
            <div class="card-content">
                <div class="value">{{bestSeller}}</div>
                <div class="label">Prodotto Più Venduto</div>
                <div class="icon-container">
                    <mat-icon>star</mat-icon>
                </div>
            </div>
        </div>
    </div>

    <!-- Search Section -->
    <div class="search-section">
        <div class="search-container">
            <mat-form-field appearance="outline" class="search-field">
                <mat-label>Cerca prodotto</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Cerca...">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class="category-field">
                <mat-label>Categoria</mat-label>
                <mat-select [formControl]="selectedCategory" (selectionChange)="onCategoryChange()">
                    <mat-option>Tutte le categorie</mat-option>
                    <mat-option *ngFor="let category of categories" [value]="category">
                        {{category}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- Table Section -->

    <div style="flex: 1; overflow: auto;  width: 100%; height: 61vh">
        <table mat-table [dataSource]="dataSource" matSort class="products-table">

            <!-- Image Column -->
            <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let product">
                    <div class="product-image-container">
                        <img [src]="product.image_link" class="product-image"
                             onerror="this.src='assets/placeholder.png'">
                    </div>
                </td>
            </ng-container>

            <!-- Title Column -->
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Prodotto</th>
                <td mat-cell *matCellDef="let product">
                    <div class="product-info">
                        <span class="product-name">{{product.title}}</span>
                    </div>
                </td>
            </ng-container>

            <!-- Category Column -->
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoria</th>
                <td mat-cell *matCellDef="let product">
                    <span class="category-badge" [ngStyle]="getCategoryStyle(product.category)">
                        {{product.category}}
                    </span>
                </td>
            </ng-container>

            <!-- Quantity Column -->
            <ng-container matColumnDef="quantitySold">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Venduti</th>
                <td mat-cell *matCellDef="let product">
                    <span class="quantity-value">{{product.quantitySold}}</span>
                </td>
            </ng-container>

            <!-- Revenue Column -->
            <ng-container matColumnDef="totalRevenue">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fatturato</th>
                <td mat-cell *matCellDef="let product">
                    <span class="revenue-value">{{formatCurrency(product.totalRevenue)}}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <!-- Loading Overlay -->
    <div class="loading-overlay" *ngIf="loading">
        <mat-spinner diameter="50" color="accent"></mat-spinner>
    </div>

    <!-- Error State -->
    <div class="error-state" *ngIf="error">
        <mat-icon color="warn">error_outline</mat-icon>
        <h3>Errore di caricamento</h3>
        <p>Non è stato possibile caricare i dati.</p>
        <button mat-raised-button color="primary" (click)="loadProductStats()">
            Riprova
        </button>
    </div>
</div>