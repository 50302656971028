import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import { Subscription } from 'rxjs';
import {AngularFirestore} from "@angular/fire/firestore";

interface Order {
  id: string;
  number: string;
  total: number;
  status: string;
  date: Date;
  scheduledDate?: any;
  scheduledTime?: string;
  carrelloMagazzino: any[];
  ristorante: string;
  idOrdine: string;
}

@Component({
  selector: 'calendario',
  templateUrl: './calendario.html',
  styleUrls: ['./calendario.css']
})
export class Calendario implements OnInit {
  pendingOrders: Order[] = [];
  scheduledOrders: { [key: string]: { [key: number]: Order[] } } = {};
  timeSlots: string[] = [];
  currentWeek: Date[] = [];
  currentDate: Date = new Date();
  currentRistorante: string = '';
  private ordersSubscription?: Subscription;


  showPopup = false;
  order = {}
  setOrder(order: any)
  {
    this.order = order
  }

  constructor(
      private firestore: AngularFirestore,
      private dataService: DataServiceGenerico
  ) {
    this.generateTimeSlots();
    this.generateCurrentWeek();
    this.initializeScheduledOrders();
  }

  getOrderStatus(selectedOrder: any): string {
    if (selectedOrder.stato === 'confermato') {
      return 'Confermato';
    } else if (selectedOrder.stato === 'confermato_parziale') {
      return 'Confermato Parzialmente';
    } else if (selectedOrder.scheduledTime !== null) {
      return 'In preparazione';
    } else {
      return 'In attesa di conferma';
    }
  }

  ngOnInit() {
    if(this.dataService.getRistorante1() === '') {
      this.dataService.loadSelectedMenuFromFirebase().then((ristorante) => {
        this.currentRistorante = this.dataService.getRistorante1();
        this.loadOrdersForCurrentWeek(this.dataService.getRistorante1());
      });
    } else {
      this.currentRistorante = this.dataService.getRistorante1();
      this.loadOrdersForCurrentWeek(this.currentRistorante);
    }
  }

  ngOnDestroy() {
    if (this.ordersSubscription) {
      this.ordersSubscription.unsubscribe();
    }
  }

  loadOrdersForCurrentWeek(ristorante: string) {
    if (this.ordersSubscription) {
      this.ordersSubscription.unsubscribe();
    }

    const startOfWeek = new Date(this.currentWeek[0]); // Lunedì
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(this.currentWeek[4]); // Venerdì
    endOfWeek.setHours(23, 59, 59, 999);

    this.ordersSubscription = this.firestore.collection('ordini_magazzino_test', ref =>
        ref.where('ristorante', '==', ristorante)
    ).snapshotChanges().subscribe(actions => {
      this.pendingOrders = [];
      this.initializeScheduledOrders();

      actions.forEach(action => {
        const data = action.payload.doc.data() as Order;
        const id = action.payload.doc.id;
        const order = { id, ...data };

        if (order.scheduledDate && order.scheduledTime) {
          const orderDate = order.scheduledDate.toDate();

          // Verifica se l'ordine è schedulato per la settimana corrente
          if (orderDate >= startOfWeek && orderDate <= endOfWeek) {
            // Trova il giorno della settimana corrispondente
            const dayIndex = this.currentWeek.findIndex(date =>
                this.isSameDay(date, orderDate)
            );

            if (dayIndex !== -1) {
              if (!this.scheduledOrders[order.scheduledTime]) {
                this.scheduledOrders[order.scheduledTime] = {};
              }
              if (!this.scheduledOrders[order.scheduledTime][dayIndex]) {
                this.scheduledOrders[order.scheduledTime][dayIndex] = [];
              }
              this.scheduledOrders[order.scheduledTime][dayIndex].push(order);
            }
          }
        } else {
          this.pendingOrders.push(order);
        }
      });
    });
  }
  isSameDay(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
  }

  previousWeek() {
    this.currentDate.setDate(this.currentDate.getDate() - 7);
    this.generateCurrentWeek();
    this.loadOrdersForCurrentWeek(this.currentRistorante);
  }

  nextWeek() {
    this.currentDate.setDate(this.currentDate.getDate() + 7);
    this.generateCurrentWeek();
    this.loadOrdersForCurrentWeek(this.currentRistorante);
  }

  previousMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.generateCurrentWeek();
    this.loadOrdersForCurrentWeek(this.currentRistorante);
  }

  nextMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.generateCurrentWeek();
    this.loadOrdersForCurrentWeek(this.currentRistorante);
  }

  generateTimeSlots() {
    for (let hour = 8; hour <= 18; hour++) {
      this.timeSlots.push(`${hour.toString().padStart(2, '0')}:00`);
    }
  }

  generateCurrentWeek() {
    const curr = new Date(this.currentDate);
    // Trova il lunedì della settimana corrente
    let monday = curr.getDate() - curr.getDay() + 1;
    if (curr.getDay() === 0) {
      monday -= 7; // Aggiustamento se siamo di domenica
    }

    // Genera solo 5 giorni invece di 7 (da lunedì a venerdì)
    this.currentWeek = Array(5).fill(0).map((_, i) => {
      const day = new Date(curr);
      day.setDate(monday + i);
      day.setHours(0, 0, 0, 0);
      return day;
    });
  }

  initializeScheduledOrders() {
    this.scheduledOrders = {};
    this.timeSlots.forEach(slot => {
      this.scheduledOrders[slot] = {};
      Array(5).fill(0).forEach((_, index) => {
        this.scheduledOrders[slot][index] = [];
      });
    });
  }

  getAllDropListIds(): string[] {
    const ids: string[] = ['pending_list'];
    this.timeSlots.forEach((_, timeIndex) => {
      Array(5).fill(0).forEach((_, dayIndex) => {
        ids.push(`slot_${timeIndex}_${dayIndex}`);
      });
    });
    return ids;
  }

  getDropListId(timeIndex: number, dayIndex: number): string {
    return `slot_${timeIndex}_${dayIndex}`;
  }

  async onDrop(event: CdkDragDrop<Order[]>, timeSlot?: string, dayIndex?: number) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      try {
        if (event.container.id === 'pending_list') {
          const order = event.previousContainer.data[event.previousIndex];
          await this.firestore.collection('ordini_magazzino_test').doc(order.id).update({
            scheduledDate: null,
            scheduledTime: null
          });
        } else if (timeSlot && dayIndex !== undefined) {
          const order = event.previousContainer.data[event.previousIndex];
          const scheduledDate = new Date(this.currentWeek[dayIndex]);
          scheduledDate.setHours(parseInt(timeSlot.split(':')[0]), 0, 0, 0);

          await this.firestore.collection('ordini_magazzino_test').doc(order.id).update({
            scheduledDate: scheduledDate,
            scheduledTime: timeSlot
          });
        }
      } catch (error) {
        console.error('Error updating order:', error);
      }
    }
  }

  calcolaTotaleOrdine(ordine: any): string {
    if (!ordine || !ordine.carrelloMagazzino) return "0.00";

    const totale = ordine.carrelloMagazzino.reduce((acc: number, item: any) => {
      const prezzo = parseFloat(item.Prezzo) || 0;
      const quantita = parseInt(item.Quantita) || 0;
      return acc + (prezzo);
    }, 0);

    return totale.toFixed(2);
  }

  getOrdersForSlot(timeSlot: string, dayIndex: number): Order[] {
    return this.scheduledOrders[timeSlot]?.[dayIndex] || [];
  }

  formatDate(date: Date): string {
    return new Intl.DateTimeFormat('it-IT', {
      weekday: 'short',
      day: 'numeric',
      month: 'short'
    }).format(date);
  }

  getCurrentMonthYear(): string {
    return new Intl.DateTimeFormat('it-IT', {
      month: 'long',
      year: 'numeric'
    }).format(this.currentDate);
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return this.isSameDay(date, today);
  }
}