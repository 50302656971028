import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../router.module';
import {UserDataService} from '../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {AggiuntaPiatto} from "../aggiunta-piatto/aggiunta-piatto";
import {IpcService} from "../../shared/services/ipc.service";
import {PosTransactionService} from "../../shared/services/pos-transaction.service";


@Component({
  selector: 'app-home',
  templateUrl: './home.html',
  styleUrls: ['./home.css']
})
  export class Home implements OnInit{


  modalOptions = { backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {} };

  sezioneNovita = 0;

  display = 'none';
  schermata = 0;
  schermataIndex = 1;
  tavoli = [
    {numero: 1, coperti: 2, occupato: 1 , pagato: 'In App',scontrino: [
        {piatto: "Pizza margherita", descrizione: "pomodoro, mozzarella, basilico", quantita: 2, prezzo: 15},
        {piatto: "Coca Cola", descrizione: "", quantita: 2, prezzo: 7}
      ]},
    {numero: 2, coperti: 2, occupato: 1 , pagato: 'In App', scontrino: [
        {piatto: "Spaghetti aglio, olio e peperoncino", descrizione: "aglio, olio, peperoncino", quantita: 2, prezzo: 12},
        {piatto: "Acqua naturale", descrizione: "", quantita: 2, prezzo: 3}
      ]},
    {numero: 3, coperti: 4, occupato: 1 , pagato: 'Non Pagato', scontrino: [
        {piatto: "Lasagne al forno", descrizione: "carne macinata, besciamella, parmigiano", quantita: 4, prezzo: 18},
        {piatto: "Aranciata", descrizione: "", quantita: 4, prezzo: 4}
      ]},
    {numero: 4, coperti: 4, occupato: 1 , pagato: 'In App', scontrino: [
        {piatto: "Penne alla vodka", descrizione: "pomodoro, panna, vodka", quantita: 4, prezzo: 16},
        {piatto: "Birra Moretti", descrizione: "", quantita: 4, prezzo: 5}
      ]},
    {numero: 5, coperti: 4, occupato: 1 , pagato: 'Kiosk', scontrino: [
        {piatto: "Risotto ai frutti di mare", descrizione: "frutti di mare misti, prezzemolo", quantita: 4, prezzo: 20},
        {piatto: "Limonata", descrizione: "", quantita: 4, prezzo: 4}
      ]},
    {numero: 6, coperti: 2, occupato: 1 , pagato: 'In App', scontrino: [
        {piatto: "Insalata caprese", descrizione: "pomodoro, mozzarella, basilico", quantita: 2, prezzo: 10},
        {piatto: "Birra Peroni", descrizione: "", quantita: 2, prezzo: 5}
      ]},
    {numero: 7, coperti: 2, occupato: 0 , pagato: 'Libero', scontrino: []},
    {numero: 8, coperti: 8, occupato: 1 , pagato: 'In App', scontrino: [
        {piatto: "Pizza quattro stagioni", descrizione: "pomodoro, mozzarella, funghi, prosciutto cotto, carciofi, olive", quantita: 1, prezzo: 20},
        {piatto: "Gnocchi alla sorrentina", descrizione: "pomodoro, mozzarella, basilico", quantita: 1, prezzo: 18},
        {piatto: "Tiramisù", descrizione: "savoiardi, mascarpone, caffè, cacao", quantita: 1, prezzo: 14},
        {piatto: "Frittura mista di pesce", descrizione: "calamari, gamberi, triglie", quantita: 1, prezzo: 22},
        {piatto: "Filetto di manzo alla griglia", descrizione: "carne di manzo, contorno di verdure", quantita: 1, prezzo: 26},
        {piatto: "Insalata di mare", descrizione: "frutti di mare misti, verdure", quantita: 1, prezzo: 18},
        {piatto: "Cannoli siciliani", descrizione: "ricotta, cioccolato, scorza d'arancia", quantita: 1, prezzo: 10},
        {piatto: "Spaghetti alle vongole", descrizione: "vongole, aglio, prezzemolo", quantita: 1, prezzo: 22}
      ]},
    {numero: 9, coperti: 8, occupato: 1 , pagato: 'In App', scontrino: [
        {piatto: "Piatto misto di carne alla griglia", descrizione: "costine di maiale, salsicce, pollo", quantita: 1, prezzo: 18},
        {piatto: "Patatine fritte", descrizione: "", quantita: 1, prezzo: 5},
        {piatto: "Insalata verde", descrizione: "insalata, pomodoro, cetriolo", quantita: 1, prezzo: 6},
        {piatto: "Birra artigianale", descrizione: "", quantita: 1, prezzo: 6},
        {piatto: "Risotto ai funghi porcini", descrizione: "funghi porcini, prezzemolo", quantita: 1, prezzo: 16},
        {piatto: "Torta della nonna", descrizione: "crema pasticcera, pinoli", quantita: 1, prezzo: 8},
        {piatto: "Acqua naturale", descrizione: "", quantita: 1, prezzo: 3},
        {piatto: "Caffè espresso", descrizione: "", quantita: 1, prezzo: 2}
      ]},
    {numero: 10, coperti: 4, occupato: 1 , pagato: 'Non Pagato', scontrino: [
        {piatto: "Spaghetti alle vongole", descrizione: "vongole, aglio, prezzemolo", quantita: 1, prezzo: 11},
        {piatto: "Acqua frizzante", descrizione: "", quantita: 1, prezzo: 2},
        {piatto: "Crostini misti", descrizione: "pane, pomodoro, olive, salumi", quantita: 1, prezzo: 8},
        {piatto: "Insalata caprese", descrizione: "pomodoro, mozzarella, basilico", quantita: 1, prezzo: 7}
      ]},
    {numero: 11, coperti: 4, occupato: 0 ,   pagato: 'Libero', scontrino: []},
    {numero: 12, coperti: 2, occupato: 0 , pagato: 'Libero', scontrino: []}
  ];

  tastierino = [7,8,9,4,5,6,1,2,3]
  closeResult = '';
  prezzoPiatto = '0';

  temp = [
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      messaggio1: 'Stato Ordine',
      messaggio2: 'In Corso',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    }
  ];

  nome = 'Totale';

  nome2 = 'Mancia per Mike';


  data = '23-01-2021 19.30';

  luogo = 'Rimini ,';

  prezzo = '26.00';

  prezzo2 = '2.60';

  percentuale = 0;

  percentuale2 = 10;

  user;

  ristorante;

  ifCarrello;

  carrelloTmp;

  carrello;

  numeroElementi = 0;

  tot = 0;

  isOver = false;


  memoLink = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/memo.png?alt=media&token=1a2d9a01-866e-4c0e-84e7-f0672e9355fe';

  scontrini = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a',
      nome: 'Pizza',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhamburger_1f354.png?alt=media&token=8579616f-7a0b-4a25-a9ce-6ca5e013f2d0',
      nome: 'Burger',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpinched-fingers_light-skin-tone_1f90c-1f3fb_1f3fb.png?alt=media&token=2b9874ce-8e1f-4ece-8ab3-ce5492e3972e',
      nome: 'Italiano',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fsushi_1f363.png?alt=media&token=4744cf56-186d-42a8-ad9f-89404b7d0578',
      nome: 'Sushi',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fstuffed-flatbread_1f959.png?alt=media&token=b31a6859-6221-4a19-80c5-572217c3334d',
      nome: 'Poke',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    }

  ];

  ristoranti: { nome: string, descrizione: string, link: string }[] = [
    { nome: 'menu', descrizione: 'Available', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' },
    { nome: 'menu', descrizione: 'Ready', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' },
    { nome: 'menu', descrizione: 'Started', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' }
  ];

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];


  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog, private ipcService: IpcService, private posTransactionService: PosTransactionService
  ){

    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');


  }
  printReceipt(stringa) {
    stringa = this.stampaScontrino(stringa);
    console.log(stringa);
    //this.ipcService.printReceipt(stringa);
  }
  sendTransaction(transactionAmount) {

   // this.posTransactionService.sendTransaction(transactionAmount);
  }
  stampaScontrino(menu: any[]): string {
    const header = 'Qta  Prodotto                     IVA   Prezzo\n';
    const ivaRate = 0.10;
    const maxLength = 28; // Lunghezza massima del campo "Prodotto"
    const spaceBetweenFields = ' '.repeat(2); // Spazio tra i campi

    let totale = 0;

    const scontrino = menu.map(item => {
      const totalePrezzo = item.quantita * item.prezzo;
      totale += totalePrezzo;
      const prodotto = item.piatto.length > maxLength
        ? item.piatto.slice(0, maxLength)
        : item.piatto.padEnd(maxLength); // Piatto allineato a sinistra e di lunghezza fissa
      const quantita = item.quantita.toString().padStart(3); // Quantità allineata a destra
      const prezzo = totalePrezzo.toFixed(2).padStart(6); // Prezzo totale allineato a destra
      const ivaField = (ivaRate * 100).toFixed(0).padStart(3) + '%'; // IVA al 10%

      return `${quantita}${spaceBetweenFields}${prodotto}${spaceBetweenFields}${ivaField}${spaceBetweenFields}${prezzo}`;
    }).join('\n');

    const iva = totale * ivaRate;
    const subtotale = totale - iva;

    const footer = `

      -------------------------------

  * SUBTOTALE${subtotale.toFixed(2).padStart(34)}
  * IVA${iva.toFixed(2).padStart(40)}
  * TOTALE${totale.toFixed(2).padStart(37)}

      -------------------------------

  `;

    return header + scontrino + footer;
  }


  openDialog(): any {
    const modalRef = this.dialog.open(AggiuntaPiatto, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
    });
  }

  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }

  ngOnInit(): void {

    console.log('firebase: ', firebase);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveCarrello();
        this.userService.emitter.subscribe(
          () => {
            this.retriveCarrello();
          }
        );
      } else {
        // No user is signed in.
      }
    });
  }


  retriveCarrello(): void {

    console.log(firebase.auth().currentUser);
    console.log(firebase.auth().currentUser.uid);

    this.userService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
      data => {
        if (data.data() !== undefined) {

          this.user = data.data();

          console.log('menuCard: ', this.carrello);
          console.log('ho trovato2: ', data.data());

          this.carrelloTmp = data.data().carrello;

          console.log(this.carrelloTmp);

          this.calcolaTotale();


        }
      }
    );
  }

  calcolaTotale(): void {

    this.numeroElementi = 0;
    this.tot = 0;

    this.tavoli[this.schermataIndex].scontrino.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);
      console.log("tot nuovo: ", this.tot)
    });
  }

  modificaPrezzp(numerino: any): void {

    if(numerino == 'elimina'){
      this.prezzoPiatto = this.prezzoPiatto.slice(0, -1);
    }else{
      this.prezzoPiatto += numerino.toString();
    }

  }

  cambiaSchermata(schermo: number, index: number): void {

    console.log("ciaociao: ", this.tot)
    this.calcolaTotale()
    this.schermata = schermo;
    this.schermataIndex = index;

  }

  aggiungiPiatto(prezzo: any, index: number): void {
    this.tavoli[index].scontrino.push({piatto: "Piatto personalizzato", descrizione: "", quantita: 1, prezzo: prezzo},)

  }

  calcolaMancia(): number {


    return this.tot / 10;
  }

  calcolaPrezzo(piatto: any): number {

    let prezzoPiatto = piatto.prezzo;

    if (piatto.opzioni) {
      piatto.opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }
    return prezzoPiatto;

  }


}
