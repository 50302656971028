import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-cronologia-inventario',
    templateUrl: './cronologia.component.html',
    styleUrls: ['./cronologia.component.css']
})
export class CronologiaInventarioComponent {
    @Input() item: any;

    get cronologiaInvertita() {
        return this.item?.cronologia ? [...this.item.cronologia].reverse() : [];
    }

    getTagColor(modalita: string): string {
        switch(modalita) {
            case 'aggiunta':
                return '#C4E2BC'; // verde chiaro
            case 'scarico':
                return '#FFE1AD'; // giallo chiaro
            case 'scarti':
                return '#FFB6B6'; // rosso chiaro
            case 'trasferimento':
                return '#B6DBFF'; // blu chiaro
            default:
                return '#E2E2E2'; // grigio chiaro
        }
    }

    getTagTextColor(modalita: string): string {
        switch(modalita) {
            case 'aggiunta':
                return '#2D5A27';
            case 'scarico':
                return '#8B6F3D';
            case 'scarti':
                return '#8B3D3D';
            case 'trasferimento':
                return '#3D5F8B';
            default:
                return '#666666';
        }
    }

    formatDate(date: string): string {
        return new Date(date).toLocaleDateString('it-IT');
    }

    // cronologia-inventario.component.ts
    formatPreviousQuantity(quantitaPrec: string): string {
        try {
            const quantities = JSON.parse(quantitaPrec);
            return Object.values(quantities)
                .filter(qty => qty !== '') // Rimuove i livelli vuoti
                .join(' - ');
        } catch {
            return quantitaPrec; // Fallback se non è un JSON valido
        }
    }
}