import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import firebase from 'firebase/app';
import { Chart } from 'chart.js';

interface RevenueRecord {
    month: number;
    year: number;
    revenue: number;
    commission: number;
    timestamp: firebase.firestore.Timestamp;
}

@Component({
    selector: 'app-revenue-input',
    templateUrl: './revenue-input.component.html',
    styleUrls: ['./revenue-input.component.css']
})
export class RevenueInputComponent implements OnInit, AfterViewInit {
    months = [
        'Gennaio', 'Febbraio', 'Marzo', 'Aprile',
        'Maggio', 'Giugno', 'Luglio', 'Agosto',
        'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
    ];

    @ViewChild('revenueChart') revenueChartCanvas: ElementRef;
    @ViewChild('timelineWrapper') timelineWrapper: ElementRef;

    private chart: any;

    selectedMonth: number;
    currentYear = new Date().getFullYear();
    revenue: string = '';
    commission: number = 0;
    commissionsRate = 0.05;
    isLoading: boolean = false;
    errorMessage: string = '';

    revenueHistory: RevenueRecord[] = [];

    constructor(
        private dataService: DataServiceGenerico,
        private commonService: CommonService,
        private cdr: ChangeDetectorRef
    ) {
        this.selectedMonth = new Date().getMonth();
    }

    ngOnInit() {
        this.loadRevenueHistory();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.initChart();
            this.scrollToCurrentMonth();
        });
    }

    private scrollToCurrentMonth() {
        if (!this.timelineWrapper) return;

        const wrapper = this.timelineWrapper.nativeElement;
        const monthElements = wrapper.children;
        if (this.selectedMonth >= 0 && monthElements[this.selectedMonth]) {
            const monthElement = monthElements[this.selectedMonth];
            const scrollOffset = monthElement.offsetLeft - wrapper.clientWidth / 2 + monthElement.clientWidth / 2;
            wrapper.scrollLeft = scrollOffset;
        }
    }

    private initChart() {
        if (this.chart) {
            this.chart.destroy();
        }

        const ctx = this.revenueChartCanvas.nativeElement.getContext('2d');
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: this.months.map(m => m.substring(0, 3)),
                datasets: [
                    {
                        label: 'Fatturato',
                        data: Array(12).fill(0),
                        backgroundColor: '#3B82F6',
                        order: 2
                    },
                    {
                        type: 'line',
                        label: 'Commissione',
                        data: Array(12).fill(0),
                        borderColor: '#475569',
                        fill: false,
                        order: 1
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function(value) {
                                return '€' + value.toLocaleString();
                            }
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let value = tooltipItem.yLabel;
                            return '€' + value.toLocaleString();
                        }
                    }
                }
            }
        });

        this.updateChart();
    }

    private updateChart() {
        if (!this.chart) {
            this.initChart();
            return;
        }

        const revenueData = Array(12).fill(0);
        const commissionData = Array(12).fill(0);

        this.revenueHistory.forEach(record => {
            if (record.year === this.currentYear) {
                revenueData[record.month] = record.revenue;
                commissionData[record.month] = record.commission;
            }
        });

        this.chart.data.datasets[0].data = revenueData;
        this.chart.data.datasets[1].data = commissionData;
        this.chart.update();
    }

    onMonthSelect(index: number) {
        this.selectedMonth = index;
        const existingData = this.revenueHistory.find(
            h => h.month === index && h.year === this.currentYear
        );
        if (existingData) {
            this.revenue = existingData.revenue.toString();
            this.commission = existingData.commission;
        } else {
            this.revenue = '';
            this.commission = 0;
        }
        this.cdr.detectChanges();
    }

    onRevenueInput(event: any) {
        this.revenue = event.target.value;
        const numericValue = parseFloat(this.revenue.replace(',', '.')) || 0;
        this.commission = this.calculateCommission(numericValue);
        this.cdr.detectChanges();
    }

    private calculateCommission(revenue: number): number {
        return Math.round((revenue * this.commissionsRate) * 100) / 100;
    }

    formatCurrency(value: number): string {
        return new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    }

    async saveRevenue() {
        const numericRevenue = parseFloat(this.revenue.replace(',', '.'));
        if (isNaN(numericRevenue) || numericRevenue <= 0) {
            this.errorMessage = 'Inserire un importo valido';
            return;
        }

        try {
            this.isLoading = true;
            this.errorMessage = '';

            const restaurantId = await this.dataService.getCurrentRestaurantId();
            const db = firebase.firestore();
            const restaurantRef = db.collection('ristoranti').doc(restaurantId);

            const revenueData: RevenueRecord = {
                month: this.selectedMonth,
                year: this.currentYear,
                revenue: numericRevenue,
                commission: this.commission,
                timestamp: firebase.firestore.Timestamp.now()
            };

            const docSnapshot = await restaurantRef.get();
            let fatturatoFranchise = docSnapshot.data()?.fatturatoFranchise || {};

            if (!fatturatoFranchise[this.currentYear]) {
                fatturatoFranchise[this.currentYear] = [];
            }

            // Aggiorna o aggiungi il record per il mese corrente
            const monthIndex = fatturatoFranchise[this.currentYear].findIndex(
                record => record.month === this.selectedMonth
            );

            if (monthIndex !== -1) {
                fatturatoFranchise[this.currentYear][monthIndex] = revenueData;
            } else {
                fatturatoFranchise[this.currentYear].push(revenueData);
            }

            await restaurantRef.update({ fatturatoFranchise });
            await this.loadRevenueHistory();
            this.isLoading = false;
        } catch (error) {
            console.error('Error saving revenue:', error);
            this.errorMessage = 'Errore durante il salvataggio';
            this.isLoading = false;
        }
    }

    private async loadRevenueHistory() {
        try {
            this.isLoading = true;
            const restaurantId = await this.dataService.getCurrentRestaurantId();;
            const doc = await firebase.firestore()
                .collection('ristoranti')
                .doc(restaurantId)
                .get();

            if (doc.exists) {
                const data = doc.data();
                const yearData = data?.fatturatoFranchise?.[this.currentYear] || [];
                this.revenueHistory = yearData;
                this.updateChart();
            }

            this.isLoading = false;
        } catch (error) {
            console.error('Error loading revenue history:', error);
            this.errorMessage = 'Errore durante il caricamento dei dati';
            this.isLoading = false;
        }
    }

    isMonthComplete(monthIndex: number): boolean {
        return this.revenueHistory.some(
            h => h.month === monthIndex && h.year === this.currentYear
        );
    }

    isCurrentMonth(monthIndex: number): boolean {
        return monthIndex === new Date().getMonth();
    }

    ngOnDestroy() {
        if (this.chart) {
            this.chart.destroy();
        }
    }
}