<div class="row" style="height: 95vh; width:calc(60vw); margin-top: 15px; border-radius: 20px; overflow-y: scroll">
    <!-- Sezione Abilita Fidelity -->
    <div style="">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Fidelity</div>
        <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <form [formGroup]="fidelityForm">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="">
                            <label class="container">Ordine Diretto
                                <input type="checkbox" formControlName="ordineDiretto">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);" (click)="saveFidelitySettings()">
                    Salva Modifiche
                </div>
            </form>
        </div>
    </div>

</div>