import { Component, Input } from '@angular/core';

@Component({
  selector: 'tagComponent',
  styles: [`
    .tag {
      display: flex;
      width: max-content;
      height: 30px;
      border-radius: 7px;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-left: 10px;
    }

    .icon {
      width: 12px;
    }

    .text {
      height: 100%;
      display: grid;
      place-items: center;
      font-size: 10px;
      font-weight: 600;
    }

    .tagCassa {
      background-color: rgba(84, 105, 212, 0.1);
    }

    .iconCassa {
      filter: invert(30%) sepia(56%) saturate(4248%) hue-rotate(207deg) brightness(94%) contrast(86%);
    }

    .textCassa {
      color: rgba(84, 105, 212);
    }

    .tagApp {
      background-color: rgba(73, 230, 136, 0.1);
    }

    .iconApp {
      filter: invert(72%) sepia(94%) saturate(482%) hue-rotate(88deg) brightness(97%) contrast(85%);
    }

    .textApp {
      color: rgb(73, 230, 136);
    }

    .tagKiosk {
      background-color: rgba(175, 147, 69, 0.1);
    }

    .iconKiosk {
      filter: invert(53%) sepia(45%) saturate(587%) hue-rotate(344deg) brightness(95%) contrast(95%);
    }

    .textKiosk {
      color: rgb(175, 147, 69);
    }

    .tagJustEat {
      background-color: rgba(255, 128, 0, 0.1);
    }

    .iconJustEat {
      filter: invert(56%) sepia(95%) saturate(1631%) hue-rotate(359deg) brightness(102%) contrast(104%);
    }

    .textJustEat {
      color: #FF8000;
    }

    .tagDeliveroo {
      background-color: rgba(0, 204, 188, 0.1);
    }

    .iconDeliveroo {
      filter: invert(67%) sepia(93%) saturate(380%) hue-rotate(127deg) brightness(95%) contrast(101%);
    }

    .textDeliveroo {
      color: #00ccbc;
    }

    .tagUberEats {
      background-color: rgba(0, 0, 0, 0.1);
    }

    .iconUberEats {
      filter: brightness(0);
    }

    .textUberEats {
      color: #000000;
    }

    .tagGlovo {
      background-color: rgba(255, 194, 68, 0.1);
    }

    .iconGlovo {
      filter: invert(80%) sepia(46%) saturate(638%) hue-rotate(332deg) brightness(101%) contrast(101%);
    }

    .textGlovo {
      color: #FFC244;
    }
  `],
  template: `
 
    <div class="tag tagApp" *ngIf="fonte === 0 || fonte === 'App'">
      <div style="display: grid; place-items: center;">
        <img class="icon iconApp" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ftags%2Ficons8-phone-100.png?alt=media&token=c21a07dc-1c97-42c0-b328-442a684ceb49">
      </div>
      <div class="text textApp" style="margin-left: 5px">
        App
      </div>
    </div>

    <div class="tag tagKiosk" *ngIf="fonte === 1 || fonte === 'Kiosk'">
      <div style="display: grid; place-items: center;">
        <img class="icon iconKiosk" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ftags%2Ficons8-digital-kiosk-96.png?alt=media&token=97ae6a26-f77d-441d-a14b-8e632d903739">
      </div>
      <div class="text textKiosk" style="margin-left: 5px">
        Kiosk
      </div>
    </div>

    <div class="tag tagCassa" *ngIf="fonte === 2 || fonte === 'Cassa'">
      <div style="display: grid; place-items: center;">
        <img class="icon iconCassa" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ftags%2Ficons8-cash-register-100.png?alt=media&token=35d7a1c5-889c-4d1f-ae02-c041354fe0a6">
      </div>
      <div class="text textCassa" style="margin-left: 5px">
        Cassa
      </div>
    </div>

    <div class="tag tagJustEat" *ngIf="fonte === 'Just Eat'">
      
      <div class="text textJustEat" style="margin-left: 5px">
        Just Eat
      </div>
    </div>

    <div class="tag tagDeliveroo" *ngIf="fonte === 'Deliveroo'">
      
      <div class="text textDeliveroo" style="margin-left: 5px">
        Deliveroo
      </div>
    </div>

    <div class="tag tagUberEats" *ngIf="fonte === 'Uber Eats'">
      
      <div class="text textUberEats" style="margin-left: 5px">
        Uber Eats
      </div>
    </div>

    <div class="tag tagGlovo" *ngIf="fonte === 'Glovo'">
      
      <div class="text textGlovo" style="margin-left: 5px">
        Glovo
      </div>
    </div>
  `
})
export class tagComponent {
  @Input() fonte: string | number;
}