<div class="dashboard-container">
    <div class="metrics-grid">
        <!-- Notifications Card -->
        <div class="metric-card notifications-card">
            <div class="metric-header">
                <h3>Notifiche</h3>
            </div>
            <div class="notifications-list">
                <div class="notification-item"
                     *ngFor="let notification of latestNotifications"
                     [class.expanded]="notification.isExpanded"
                     (click)="toggleNotification(notification)">
                    <div class="notification-header">
                        <div class="notification-icon" [ngClass]="notification.tipologiaNotifica">
                            <i class="material-icons">
                                {{getNotificationIcon(notification.tipologiaNotifica)}}
                            </i>
                        </div>
                        <div class="notification-content">
                            <p class="notification-text">{{notification.testoNotifica}}</p>
                            <span class="notification-date">{{notification.formattedDate | date:'dd/MM/yyyy HH:mm'}}</span>

                        </div>
                        <i class="material-icons expand-icon">
                            {{notification.isExpanded ? 'expand_less' : 'expand_more'}}
                        </i>
                    </div>
                    <div class="notification-details" [class.expanded]="notification.isExpanded">
                        <div class="details-row">
                            <span class="detail-label">Categoria:</span>
                            <span>{{notification.tipologiaNotifica | titlecase}}</span>
                        </div>
                        <div class="details-row">
                            <span class="detail-label">Data:</span>
                            <span>{{notification.formattedDate | date:'dd/MM/yyyy HH:mm'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Revenue Chart Card -->
        <div class="metric-card chart-card">
            <div class="metric-header">
                <div class="header-content">
                    <h3>Fatturato {{getPreviousMonthName()}} {{currentYear}}</h3>
                    <div class="revenue-total">
                        <span class="value">{{getLastMonthRevenue() | number:'1.0-0'}}€</span>
                        <span class="trend" [class.positive]="getLastMonthGrowth() > 0">
        <i class="material-icons">{{getLastMonthGrowth() > 0 ? 'trending_up' : 'trending_down'}}</i>
                            {{getLastMonthGrowth() | number:'1.1-1'}}%
    </span>
                    </div>
                </div>
            </div>
            <div class="metric-content">
                <div class="chart-container">
                    <canvas #revenueChart></canvas>
                </div>
            </div>
        </div>
    </div>

    <!-- Orders Overview -->
    <!-- Orders Section in dashboard.html -->
    <div class="orders-section">
        <div class="orders-header">
            <h3>Ordini Recenti</h3>
        </div>
        <div class="orders-table">
            <table>
                <thead>
                <tr>
                    <th>ID Ordine</th>
                    <th>Data</th>
                    <th>Stato</th>
                    <th>Totale</th>
                    <th>Azioni</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let order of ordiniMagazzino">
                    <td>{{order.idOrdine}}</td>
                    <td>{{order.data?.toDate() | date:'dd/MM/yyyy HH:mm'}}</td>
                    <td>
                        <span class="status-badge" [ngClass]="{
                            'status-completed': order.stato === 'confermato',
                            'status-partial': order.stato === 'confermato_parziale',
                            'status-pending': !order.stato || order.stato === 'in_attesa'
                        }">
                            {{getOrderStatus(order.stato)}}
                        </span>
                    </td>
                    <td>{{calcolaTotaleOrdine(order)}}€</td>
                    <td>
                        <button class="action-btn" (click)="openOrderDetail(order)">
                            <i class="material-icons">visibility</i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>