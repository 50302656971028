import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import firebase from 'firebase/app';
import { DataServiceGenerico } from "../../../../shared/services/data-service/data.service";

@Component({
    selector: 'app-add-workstation-dialog',
    template: `
        <div class="dialog-container">
            <div class="sticky-container">
                <div class="save-button">
                    <img src="assets/icons/save-icon.png"
                         style="width: 30px; aspect-ratio: 1 / 1;"
                         title="Salva Postazione"
                         (click)="onSubmit()">
                </div>
                <div class="close-button">
                    <img src="assets/icons/close-icon.png"
                         style="width: 30px; aspect-ratio: 1 / 1;"
                         title="Chiudi"
                         (click)="onCancel()">
                </div>
            </div>

            <div style="height: 80vh; max-width: 500px;">
                <form [formGroup]="workstationForm">
                    <fieldset class="custom-fieldset">
                        <legend>Nome Postazione</legend>
                        <input placeholder="Nome" class="custom-input" formControlName="name">
                    </fieldset>

                    <fieldset class="custom-fieldset">
                        <legend>Tipologia</legend>
                        <mat-select class="custom-input" formControlName="type">
                            <mat-option value="cassa">Cassa</mat-option>
                            <mat-option value="kiosk">Kiosk</mat-option>
                        </mat-select>
                    </fieldset>

                    <fieldset class="custom-fieldset">
                        <legend>Ristorante</legend>
                        <mat-select class="custom-input" formControlName="restaurantId" (selectionChange)="onRestaurantChange($event)">
                            <mat-option *ngFor="let restaurant of restaurants" [value]="restaurant.id">
                                {{restaurant.nome}}
                            </mat-option>
                        </mat-select>
                    </fieldset>

                    <fieldset class="custom-fieldset" *ngIf="workstationForm.get('restaurantId').value">
                        <legend>Listino</legend>
                        <mat-select class="custom-input" formControlName="priceListId">
                            <mat-option *ngFor="let list of priceLists" [value]="list.id">
                                {{list.name}}
                            </mat-option>
                        </mat-select>
                    </fieldset>
                </form>
            </div>
        </div>
    `,
    styles: [`
        .dialog-container {
            position: relative;
        }
        .sticky-container {
            position: sticky;
            top: 0;
            right: 0;
            z-index: 1000;
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-end;
            cursor: pointer;
        }
        .close-button {
            width: 30px;
            aspect-ratio: 1 / 1;
            margin-top: -15px;
            margin-right: -15px;
        }
        .save-button {
            width: 30px;
            margin-top: -15px;
        }
        .custom-fieldset {
            margin-bottom: 10px;
            border-radius: 20px;
            height: auto;
            border: 2px solid rgba(222,214,214,0.57);
            display: flex;
            flex-direction: column;
            transition: border-color 0.3s ease;
        }
        .custom-fieldset legend {
            font-weight: 600;
            font-size: 16px;
        }
        .custom-input {
            border: none;
            outline: none;
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
            transition: all 0.3s ease;
        }
        .custom-fieldset:focus-within {
            border-color: #000;
        }
        .custom-input:focus {
            border: none;
            outline: none;
        }
    `]
})
export class AddWorkstationDialogComponent implements OnInit {
    workstationForm: FormGroup;
    restaurants: any[] = [];
    priceLists: any[] = [];
    isEdit = false;

    constructor(
        private dialogRef: MatDialogRef<AddWorkstationDialogComponent>,
        private fb: FormBuilder,
        private dataService: DataServiceGenerico,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.workstationForm = this.fb.group({
            name: ['', Validators.required],
            type: ['', Validators.required],
            restaurantId: ['', Validators.required],
            priceListId: ['', Validators.required]
        });

        this.isEdit = !!data;
    }

    ngOnInit() {
        this.dataService.availableRestaurants$.subscribe(restaurants => {
            this.restaurants = restaurants;
            if (this.isEdit && this.data) {
                this.workstationForm.patchValue({
                    name: this.data.name,
                    type: this.data.type || '',  // Add default empty string if type is missing
                    restaurantId: this.data.restaurantId,
                });
                if (this.data.restaurantId) {
                    this.onRestaurantChange({ value: this.data.restaurantId });
                }
            }
        });
    }

    async onRestaurantChange(event: any) {
        const restaurantId = event.value;
        if (restaurantId) {
            const restaurant = this.restaurants.find(r => r.id === restaurantId);
            if (restaurant) {
                await this.loadPriceLists(restaurantId);
                if (this.isEdit) {
                    this.workstationForm.patchValue({
                        priceListId: this.data.priceListId
                    });
                }
            }
        }
    }

    private async loadPriceLists(restaurantId: string) {
        const db = firebase.firestore();
        try {
            const doc = await db.collection('price_lists').doc(restaurantId).get();
            if (doc.exists) {
                const data = doc.data();
                this.priceLists = data.priceLists || [];

                // Set priceListId after loading priceLists if in edit mode
                if (this.isEdit && this.data && this.data.priceListId) {
                    this.workstationForm.patchValue({
                        priceListId: this.data.priceListId
                    });
                }
            }
        } catch (error) {
            console.error('Error loading price lists:', error);
            this.priceLists = [];
        }
    }

    onSubmit(): void {
        if (this.workstationForm.valid) {
            this.dialogRef.close(this.workstationForm.value);
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}