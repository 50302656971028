import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import { NotificationService } from "../stampanti/notificationService/notification.service";
import {EditSliderTotemComponent} from "./popup/edit-slider-totem";

interface SliderImage {
  url: string;
  name: string;
  duration: number;
  isClickable?: boolean;
  isDiscount?: boolean;
  linkedItemId?: string;
  discountId?: string;
}

@Component({
  selector: 'slider-totem',
  templateUrl: './slider-totem.html',
  styleUrls: ['./slider-totem.css']
})
export class SliderTotem implements OnInit {
  displayedColumns: string[] = ['nome', 'durata', 'tipo', 'preview', 'edit'];
  images: SliderImage[] = [];
  uploadProgress: { [key: number]: number } = {};
  isLoading = false;

  constructor(
      private dialog: MatDialog,
      private dataServiceGenerico: DataServiceGenerico,
      private notificationService: NotificationService
  ) {}

  async ngOnInit() {
    await this.loadImages();
    this.setupRealtimeUpdates();
  }

  private setupRealtimeUpdates() {
    const restaurantId = this.dataServiceGenerico.getRistorante1();
    if (restaurantId) {
      const db = firebase.firestore();
      db.collection('ristoranti').doc(restaurantId)
          .onSnapshot((doc) => {
            const data = doc.data();
            if (data?.sliderTotem) {
              this.images = data.sliderTotem;
            }
          }, (error) => {
            console.error('Error in slider totem snapshot:', error);
          });
    }
  }

  async loadImages() {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        console.error('No restaurant ID found');
        return;
      }

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();

      if (data?.sliderTotem) {
        this.images = data.sliderTotem as SliderImage[];
      }
    } catch (error) {
      console.error('Error loading images:', error);
    }
  }

  getTipoText(image: SliderImage): string {
    if (!image.isClickable) return 'Non cliccabile';
    return image.isDiscount ? 'Sconto' : 'Prodotto';
  }

  addImage() {
    const dialogRef = this.dialog.open(EditSliderTotemComponent, {
      width: '600px',
      data: null
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.delete) {
          await this.deleteImage(result.url);
          return;
        }

        try {
          const restaurantId = this.dataServiceGenerico.getRistorante1();
          if (!restaurantId) throw new Error('No restaurant ID found');

          const db = firebase.firestore();
          const doc = await db.collection('ristoranti').doc(restaurantId).get();
          const data = doc.data();
          const currentImages = data?.sliderTotem || [];

          currentImages.push(result);

          await db.collection('ristoranti').doc(restaurantId).update({
            sliderTotem: currentImages
          });

          this.notificationService.show('Immagine aggiunta con successo', 'success');
        } catch (error) {
          console.error('Error adding image:', error);
          this.notificationService.show('Errore durante l\'aggiunta dell\'immagine', 'error');
        }
      }
    });
  }

  editImage(image: SliderImage) {
    const dialogRef = this.dialog.open(EditSliderTotemComponent, {
      width: '600px',
      data: image
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.delete) {
          await this.deleteImage(image.url);
          return;
        }

        try {
          const restaurantId = this.dataServiceGenerico.getRistorante1();
          if (!restaurantId) throw new Error('No restaurant ID found');

          const db = firebase.firestore();
          const doc = await db.collection('ristoranti').doc(restaurantId).get();
          const data = doc.data();
          let currentImages = data?.sliderTotem || [];

          const index = currentImages.findIndex(img => img.url === image.url);
          if (index !== -1) {
            currentImages[index] = result;

            await db.collection('ristoranti').doc(restaurantId).update({
              sliderTotem: currentImages
            });

            this.notificationService.show('Immagine modificata con successo', 'success');
          }
        } catch (error) {
          console.error('Error updating image:', error);
          this.notificationService.show('Errore durante la modifica dell\'immagine', 'error');
        }
      }
    });
  }

  async deleteImage(imageUrl: string) {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) throw new Error('No restaurant ID found');

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();
      let currentImages = data?.sliderTotem || [];

      currentImages = currentImages.filter(img => img.url !== imageUrl);

      await db.collection('ristoranti').doc(restaurantId).update({
        sliderTotem: currentImages
      });

      try {
        const storage = firebase.storage();
        const imageRef = storage.refFromURL(imageUrl);
        await imageRef.delete();
      } catch (error) {
        console.error('Error deleting image from storage:', error);
      }

      this.notificationService.show('Immagine eliminata con successo', 'success');
    } catch (error) {
      console.error('Error deleting image:', error);
      this.notificationService.show('Errore durante l\'eliminazione dell\'immagine', 'error');
    }
  }
}