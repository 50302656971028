import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges, ViewChild
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../../router.module';
import {UserDataService} from '../../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {AggiuntaPiatto} from "../../aggiunta-piatto/aggiunta-piatto";
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {CommonService} from "../../../shared/services/firestore/common.service";
import * as Chart from "chart.js";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";



@Component({
  selector: 'report-prodotti',
  templateUrl: './report-prodotti.html',
  styleUrls: ['./report-prodotti.css']
})

export class ReportProdotti implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<any>([]);
  _alldata: any[] = [];
  columns: any[] = [{
    field: 'title'
  }, {
    field: 'category'
  }, {
    field: 'ingredients'
  }, {
    field: 'price'
  }, {
    field: 'quantityInStock'
  }, {
    field: 'reorderPoint'
  }, {
    field: 'actualStock'
  }, {
    field: 'variationPercentage'
  }, {
    field: 'variationCost'
  }, {
    field: 'productRevenue'
  }, {
    field: 'inventoryValue'
  }, {
    field: ' '
  }];
  displayedColumns: string[] = this.columns.map(column => column.field);
  groupByColumns: string[] = ['category', 'ingredients'];

  @ViewChild(MatSort) sort: MatSort;

  public chart: any;
  public pieChart: any = {};
  idPieChart: string;
  idChart: string;
  filterValue = '';

  constructor() {
    let current = new Date();
    let timestamp = current.getTime();
    let psw = timestamp.toString();
    this.idPieChart = 'pie' + psw;
    this.idChart = 'chart' + psw;
  }

  ngOnInit(): void {
    this.initializeData();
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
  }

  initializeData(): void {
    // ... (il tuo codice di inizializzazione dei dati rimane lo stesso)

    this._alldata = [
      {
        title: "Cheesecake ai Frutti di Bosco",
        price: 2.5,
        category: "Dolci",
        ingredients: "Ricotta, zucchero, scorza d'arancia candita",
        quantityInStock: 50,
        reorderPoint: 10,
        actualStock: 48
      },
      {
        title: "Millefoglie alla Crema",
        price: 2.8,
        category: "Dolci",
        ingredients: "Ricotta, zucchero, cioccolato fondente",
        quantityInStock: 40,
        reorderPoint: 8,
        actualStock: 37
      },
      {
        title: "Torta della Nonna",
        price: 3.0,
        category: "Dolci",
        ingredients: "Ricotta, zucchero, pistacchio",
        quantityInStock: 30,
        reorderPoint: 5,
        actualStock: 28
      },
      {
        title: "Gelato al Pistacchio",
        price: 2.8,
        category: "Dolci",
        ingredients: "Ricotta, zucchero, crema di nocciola",
        quantityInStock: 60,
        reorderPoint: 15,
        actualStock: 55
      },
      {
        title: "Pasticcini alla Crema",
        price: 1.2,
        category: "Dolci Assortiti",
        ingredients: "Pasta frolla, crema pasticcera",
        quantityInStock: 100,
        reorderPoint: 20,
        actualStock: 95
      },
      {
        title: "Pasticcini alla Frutta",
        price: 1.3,
        category: "Dolci Assortiti",
        ingredients: "Pasta frolla, crema pasticcera, frutta fresca",
        quantityInStock: 80,
        reorderPoint: 15,
        actualStock: 75
      },
      {
        title: "Acqua Naturale",
        price: 1.0,
        category: "Bevande Fredde",
        ingredients: "Acqua",
        quantityInStock: 200,
        reorderPoint: 50,
        actualStock: 180
      },
      {
        title: "Succo d'Arancia",
        price: 2.0,
        category: "Bevande Fredde",
        ingredients: "Succo d'arancia",
        quantityInStock: 100,
        reorderPoint: 20,
        actualStock: 90
      },
      {
        title: "Caffè Espresso",
        price: 1.2,
        category: "Bevande Calde",
        ingredients: "Caffè",
        quantityInStock: 500,
        reorderPoint: 100,
        actualStock: 450
      },
      {
        title: "Cappuccino",
        price: 1.5,
        category: "Bevande Calde",
        ingredients: "Caffè, latte",
        quantityInStock: 300,
        reorderPoint: 50,
        actualStock: 280
      },
      {
        title: "Panino Prosciutto e Formaggio",
        price: 3.5,
        category: "Snack Salati",
        ingredients: "Pane, prosciutto cotto, formaggio",
        quantityInStock: 50,
        reorderPoint: 10,
        actualStock: 45
      },
      {
        title: "Focaccia al Rosmarino",
        price: 2.0,
        category: "Snack Salati",
        ingredients: "Focaccia, rosmarino, olio",
        quantityInStock: 70,
        reorderPoint: 15,
        actualStock: 65
      }
    ];

    this._alldata.forEach(product => {
      product['variationUnits'] = product['actualStock'] - product['quantityInStock'];
      product['variationCost'] = product['variationUnits'] * product['price'];
      product['productRevenue'] = ((product['quantityInStock'] - product['actualStock']) * product['price']).toFixed(2);
      product['inventoryValue'] = (product['actualStock'] * product['price']).toFixed(2);
      product['variationPercentage'] = ((product['variationCost']*100) / +product['productRevenue']).toFixed(3) == 'NaN' ? '0.000' : ((product['variationCost']*100) / +product['productRevenue']).toFixed(3);
    });

    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.createChart0();
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {
    if (data.category === 'Risultati') {
      return true;
    }

    if (!(data instanceof Group) && (!isNaN(parseFloat(filter)))) {
      return !data.hasOwnProperty('visible') || data.visible;
    }
    if (!(data instanceof Group) && (isNaN(parseFloat(filter)))) {
      return this.filtraRicerca(data, filter);
    }
    if ((data instanceof Group) && this.filterValue === '') {
      return true;
    }
    if ((data instanceof Group) && this.filterValue !== '') {
      const hasVisibleChild = this.dataSource.filteredData.some(item => {
        if (!(item instanceof Group)) {
          return (item.category === data.category && item.ingredients === data.ingredients);
        } else {
          return false;
        }
      });
      data.visible = hasVisibleChild;
      return hasVisibleChild;
    }
    return false;
  }

  filtraRicerca(data: any, filter: string): boolean {
    const searchStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
      return currentTerm + (data[key] ? data[key].toString().toLowerCase() : '');
    }, '');
    return searchStr.indexOf(filter.toLowerCase()) !== -1;
  }

  applyFilter(filterValue: string): void {
    this.filterValue = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  groupHeaderClick(row: Group) {
    row.expanded = !row.expanded;
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  isChildOf(child: Group, parent: Group): boolean {
    let isChild = false;
    let currentParent = child.parent;
    while (currentParent) {
      if (currentParent === parent) {
        isChild = true;
        break;
      }
      currentParent = currentParent.parent;
    }
    return isChild;
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
        data.map(row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          result.expanded = true;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }),
        JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level !== undefined && item.level !== null;
  }

  createChart0() {
    this.pieChart = new Chart(this.idPieChart, {
      type: 'doughnut',
      data: {
        labels: ['Dolci', 'Dolci Assortiti', 'Bevande Fredde', 'Bevande Calde', 'Snack Salati'],
        datasets: [
          {
            label: 'My First Dataset',
            data: [4, 2, 2, 2, 2],
            backgroundColor: [
              '#F40027',
              '#000000',
              '#f1ebeb',
              '#666666',
              '#999999'
            ],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: true,
          position: 'bottom'
        },
        title: {
          display: true,
          text: 'Distribuzione Categorie Prodotti'
        },
        cutoutPercentage: 75,
      }
    });

    this.chart = new Chart(this.idChart, {
      type: 'bar',
      data: {
        labels: this._alldata.map(item => item.title),
        datasets: [
          {
            label: 'Quantità in Stock',
            data: this._alldata.map(item => item.quantityInStock),
            backgroundColor: '#F40027',
          },
          {
            label: 'Stock Attuale',
            data: this._alldata.map(item => item.actualStock),
            backgroundColor: '#000000',
          }
        ]
      },
      options: {
        responsive: true,
        legend: {
          display: true,
          position: 'bottom'
        },
        title: {
          display: true,
          text: 'Panoramica Inventario Prodotti'
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
              color: "rgb(0,0,0)",
            }
          }],
          yAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              drawOnChartArea: false,
              color: "rgb(0,0,0)"
            }
          }]
        }
      }
    });
  }
  // Metodi aggiuntivi per calcoli specifici dell'inventario
  getTotalInventoryValue(): number {
    return this._alldata.reduce((total, item) => total + parseFloat(item.inventoryValue), 0);
  }

  getLowStockItems(): any[] {
    return this._alldata.filter(item => item.actualStock <= item.reorderPoint);
  }

  getTopSellingItems(): any[] {
    return this._alldata
        .sort((a, b) => (b.quantityInStock - b.actualStock) - (a.quantityInStock - a.actualStock))
        .slice(0, 5);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter(row => {
      if (!(row instanceof Group)) {
        return false;
      }
      let match = true;
      this.groupByColumns.forEach(column => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }


}

class Group {
  level: number = 0;
  parent: Group | null = null;
  expanded: boolean = true;
  totalCounts: number = 0;
  [key: string]: any;
}