<div class="row" style="height: 95vh; width:calc(60vw); margin-top: 15px; border-radius: 20px; overflow-y: scroll">
    <div style="">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Ristoranti</div>
        <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
                <div style="width: 27%; margin-right: 1%; margin-left: 1%; margin-top: 10px">
                    <categorieCard class="col-12 col-md-12 menuItem"
                                   style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                   [nome]="'Aggiungi Ristorante'"
                                   altezza="50"
                                   [link]="'assets/icons/icons8-add-90.png'"
                                   (click)="openAddRestaurantDialog()">
                    </categorieCard>
                </div>
            </div>

            <div class='table-responsive' style="margin-top: 20px; margin-left: 1%; width: 98%; overflow-y: scroll; border-radius: 20px; border: 1px solid #e3e2e2;">
                <table mat-table id="tabellaRistoranti" matSortActive="nome" [dataSource]="ristoranti" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">
                    <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef> Nome </th>
                        <td mat-cell *matCellDef="let user"> {{user.nome}} </td>
                    </ng-container>

                    <ng-container matColumnDef="piva">
                        <th mat-header-cell *matHeaderCellDef> P.Iva </th>
                        <td mat-cell *matCellDef="let user"> {{user.piva}} </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef> Email </th>
                        <td mat-cell *matCellDef="let user"> {{user.email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="permessi">
                        <th mat-header-cell *matHeaderCellDef> Permessi </th>
                        <td mat-cell *matCellDef="let user">
                            <mat-select placeholder="Seleziona Permesso"
                                        multiple
                                        class="custom-input"
                                        [(ngModel)]="user.permessi"
                                        (ngModelChange)="updateRestaurantPermissions(user)">
                                <mat-option *ngFor="let permesso of permessi" [value]="permesso">
                                    {{permesso}}
                                </mat-option>
                            </mat-select>
                        </td>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsRistoranti; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsRistoranti;"></mat-row>
                </table>
            </div>
        </div>
    </div>

    <div style="">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Gruppi</div>
        <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
                <div style="width: 27%; margin-right: 1%; margin-left: 1%; margin-top: 10px">
                    <categorieCard class="col-12 col-md-12 menuItem"
                                   style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                   [nome]="'Aggiungi Gruppo'"
                                   altezza="50"
                                   [link]="'assets/icons/icons8-add-90.png'"
                                   (click)="openAddGroupDialog()">
                    </categorieCard>
                </div>
            </div>

            <div class='table-responsive' style="margin-top: 20px; margin-left: 1%; width: 98%; overflow-y: scroll; border-radius: 20px; border: 1px solid #e3e2e2;">
                <table mat-table id="tabellaDipendenti2" matSortActive="nome" [dataSource]="gruppi" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">
                    <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef> Nome </th>
                        <td mat-cell *matCellDef="let group"> {{group.nomeGruppo}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ristoranti">
                        <th mat-header-cell *matHeaderCellDef> Ristoranti </th>
                        <td mat-cell *matCellDef="let group">
                            <mat-select placeholder="Seleziona Ristoranti"
                                        multiple
                                        class="custom-input"
                                        [(ngModel)]="group.ristoranti"
                                        (selectionChange)="updateGroupRestaurants(group)"
                                        [compareWith]="compareObjects">
                                <mat-option *ngFor="let ristorante of ristoranti" [value]="ristorante">
                                    {{ristorante.nome}}
                                </mat-option>
                            </mat-select>
                        </td>
                    </ng-container>


                    <mat-header-row *matHeaderRowDef="displayedColumnsGruppi; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsGruppi;"></mat-row>
                </table>
            </div>
        </div>
    </div>

    <!-- Add after the gruppi section -->
    <div style="margin-bottom: 100px">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Postazioni</div>
        <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
                <div style="width: 27%; margin-right: 1%; margin-left: 1%; margin-top: 10px">
                    <categorieCard class="col-12 col-md-12 menuItem"
                                   style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                   [nome]="'Aggiungi Postazione'"
                                   altezza="50"
                                   [link]="'assets/icons/icons8-add-90.png'"
                                   (click)="openAddWorkstationDialog()">
                    </categorieCard>
                </div>
            </div>

            <div class='table-responsive' style="margin-top: 20px; margin-left: 1%; width: 98%; overflow-y: scroll; border-radius: 20px; border: 1px solid #e3e2e2;">
                <table mat-table [dataSource]="workstations" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">
                    <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef> Nome </th>
                        <td mat-cell *matCellDef="let workstation"> {{workstation.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef> Tipo </th>
                        <td mat-cell *matCellDef="let workstation"> {{workstation.type === 'cassa' ? 'Cassa' : 'Kiosk'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ristorante">
                        <th mat-header-cell *matHeaderCellDef> Ristorante </th>
                        <td mat-cell *matCellDef="let workstation">
                            {{getRistoranteNome(workstation.restaurantId)}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="listino">
                        <th mat-header-cell *matHeaderCellDef> Listino </th>
                        <td mat-cell *matCellDef="let workstation"> {{workstation.priceListId}} </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Azioni </th>
                        <td mat-cell *matCellDef="let workstation">
                            <button mat-icon-button (click)="editWorkstation(workstation)" title="Modifica">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deleteWorkstation(workstation)" title="Elimina">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsWorkstations; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsWorkstations;"></mat-row>
                </table>
            </div>
        </div>
    </div>


</div>