import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'notifiche-dashboard-card',
  styles: [`


    .card-scontrino {
      border-radius: 20px;
      border: solid 0px #0000000D;
      border: 1px solid #e3e2e2;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      /*
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 1px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

       */
    }

    /* On mouse-over, add a deeper shadow */
    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    /* Add some padding inside the card container */



    /* Demo styles */

    ul {
      list-style: none;
      padding: 0;
    }

    b{
      text-align: right;
      font-size: 15px;
      vertical-align: 90%;
      margin-bottom: 0px;
      margin-top: 0;
    }

    p{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }

    p.due{
      margin-top: 0px !important;
      font-size: 15px;
      vertical-align: 0px;
      padding-bottom: 0px;
      font-weight: lighter;
    }

    p.tre{
      margin-top: 0px !important;
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-weight: lighter;
    }

    .containerScontrino {
      padding-bottom: 10px;
      padding-top: 15px;
      padding-left: 5px;
      height: 100px;
      border-radius: 0px;
      horiz-align: left;
      float: left;
    }

    .center-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 400px;

    }

    h4{
      margin-bottom: 0px;
    }

    .right {
      float: right;
      position: static;
    }

    .left {
      float: left;
    }

    .header {
      display: inline-block;
      width: 100%;
      background: white;
    }
    .playerOne {
      float: right;
    }
    .playerTwo {
      float: left;
    }

  `],
  template: `

    <div class="header card-scontrino" style="margin-top: 10px" [style.border-left]="selected? '3px solid #E55933': ''" >
      <div class="containerScontrino playerTwo" style="width: 100%">
        <div
            style="border-top-right-radius: 20px; border-top-left-radius: 20px; float: left; display: flex; align-items: initial; justify-content: left; margin-top: 10px; clear: left; width: 100%">
          <div
              style="top: 20px !important; margin-left: 10px !important; max-height: 1000px !important; padding-bottom: 10px !important; padding-left: 5px !important;">
            Data: <span style="font-weight: bold">{{ this.data }}</span>
          </div>
          <div
              style="top: 20px !important; margin-right: 20px !important; margin-left: auto !important; max-height: 1000px !important; padding-bottom: 10px !important; padding-left: 5px !important;">
            <span style="font-weight: bold">Tipologia: {{ this.tipologiaNotifica }}</span>
          </div>
        </div>
        <div
            style="border-top-right-radius: 20px; border-top-left-radius: 20px; float: left; display: flex; align-items: initial; justify-content: left; margin-top: 10px; clear: left; width: 100%">
          <div
              style="top: 20px !important; margin-left: 10px !important; max-height: 1000px !important; padding-bottom: 10px !important; padding-left: 5px !important;">
            <span style="font-weight: bold">{{ this.testoNotifica }}</span>
          </div>
        </div>
      </div>
    </div>

  `
})

export class NotificheDashboardCardComponent {
  @Input() data;
  @Input() testoNotifica;
  @Input() tipologiaNotifica;
  @Input() selected;


  constructor() {}
}
