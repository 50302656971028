<div class="display-container">
    <!-- Slider quando non c'è un ordine attivo -->
    <div class="slider-container" [class.with-order]="currentOrder?.items?.length > 0">
        <img [src]="currentImage"
             alt="Slider image"
             class="slider-image"
             *ngIf="currentImage">
    </div>

    <!-- Dettagli ordine quando presenti -->
    <div class="order-container" *ngIf="currentOrder?.items?.length > 0">
        <div class="order-header">
            <h1>Il tuo ordine</h1>
        </div>

        <div class="order-items" #orderItemsContainer>
            <div class="order-item" *ngFor="let item of currentOrder.items">
                <div class="item-quantity">{{(item.quantity? item.quantity: 1)}}x</div>
                <div class="item-details">
                    <div class="item-name">{{item.name}}</div>
                    <div class="item-variants" *ngIf="item.variants?.length">
            <span *ngFor="let variant of item.variants">
              {{variant.name}}
            </span>
                    </div>
                </div>
                <div class="item-price">€{{(item.price * (item.quantity? item.quantity: 1)).toFixed(2)}}</div>
            </div>
        </div>

        <div class="order-footer">
            <div class="total-line"></div>
            <div class="total">
                <span>Totale</span>
                <span class="total-amount">€{{calcolaTotale().toFixed(2)}}</span>
            </div>
        </div>
    </div>
</div>