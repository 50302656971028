<div class="row">
    <div class="column">
        <!-- Header con tabs -->
        <div class="header-container">
            <div class="tabs-section">
                <div [ngStyle]="getTabStyle('Magazzino')" (click)="selezione = 'Magazzino'">
                    Magazzino
                </div>
                <div [ngStyle]="getTabStyle('Inventario')" (click)="selezione = 'Inventario'">
                    Inventario
                </div>
                <div [ngStyle]="getTabStyle('Categorie')" (click)="selezione = 'Categorie'">
                    Categorie
                </div>
                <div [ngStyle]="getTabStyle('Fornitori')" (click)="selezione = 'Fornitori'">
                    Fornitori
                </div>
                <div [ngStyle]="getTabStyle('esporta')"

                >

                    <img src="../../../assets/icons/icons8-import-90.png" style=" aspect-ratio: 1; width: 22px; margin-right: 5px; cursor: pointer">
                    <p  style="color: rgb(54 191 163) !important; cursor: pointer" >Importa</p>
                </div>
                <div [ngStyle]="getTabStyle('esporta')"

                >
                    <img src="../../../assets/icons/icons8-export-90.png" style=" aspect-ratio: 1; width: 22px; margin-right: 5px; cursor: pointer">
                    <p  style="color: rgb(52 152 219) !important; cursor: pointer" >Esporta</p>
                </div>
                <input type="file" id="fileInput" style="display: none" >

            </div>

        </div>



        <!-- Content container -->
        <div class="main-container" *ngIf="selezione == 'Magazzino'">
            <!-- Table container -->
            <div class="table-container" [class.with-detail]="selectedItem">
                <div class="table-wrapper">
                    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <!-- Column definitions -->
                        <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">


                            <mat-header-cell *matHeaderCellDef [ngStyle]="{'justify-content': column.field == 'Disponibilità' ? 'flex-end' : 'inherit'}">
                                <div *ngIf="column.field != 'Quantità'">
                                    {{ column.field }}
                                </div>
                                <div *ngIf="column.field === 'Quantità'" style="display: flex; align-items: center; margin: 0px">
                                    <categorieCard class="col-12 col-md-12 menuItem" style="width: 100%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                                   [nome]="'Aggiungi Prodotto'"
                                                   altezza="45"
                                                   [link]=" 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7' "
                                                   [bg]="'#FF0000B3'"
                                                   (click)="aggiungiProdotto()">
                                    </categorieCard>
                                </div>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row" (click)="selectItem(row)">
                                <ng-container [ngSwitch]="column.field">
                                    <span *ngSwitchCase="'Quantità'">{{row.Quantita}} {{row.UM}}</span>
                                    <span *ngSwitchCase="'Prezzo'">€{{row.Prezzo}}</span>
                                    <span *ngSwitchDefault>{{row[column.field]}}</span>
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <!-- Group header -->
                        <ng-container matColumnDef="groupHeader">
                            <mat-cell colspan="999" *matCellDef="let group">
                                <strong>{{group[groupByColumns[group.level-1]]}}</strong>
                            </mat-cell>
                        </ng-container>

                        <!-- Rows -->
                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                                 [class.selected]="selectedItem?.id === row.id">
                        </mat-row>
                        <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
                                 (click)="groupHeaderClick(row)">
                        </mat-row>
                    </mat-table>
                </div>
            </div>

            <!-- Detail section -->
            <div class="detail-container" *ngIf="selectedItem">
                <edit-magazzino
                        [item]="selectedItem"
                        (itemUpdated)="onItemUpdate($event)"
                        (itemDeleted)="onItemDeleted()">
                </edit-magazzino>
            </div>
        </div  >


        <div class="" *ngIf="selezione == 'Inventario'">
            <!-- Table container -->
            <app-inventory></app-inventory>
        </div  >

        <div        style="
                    background-color: white;
                    border: 1px solid rgb(227 226 226);
                    border-radius: 25px;
                    padding: 10px;
                    height: 85vh;
                    overflow-y: auto;"
             *ngIf="selezione == 'Categorie'">
            <!-- Table container -->
            <edit-categories [collectionType]="'magazzino_test'"></edit-categories>
        </div  >

    </div>
</div>