import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import firebase from 'firebase/app';

interface ProductStats {
    id: string;
    title: string;
    category: string;
    image_link: string;
    quantitySold: number;
    totalRevenue: number;
}

@Component({
    selector: 'app-product-statistics',
    templateUrl: './product-statistics.component.html',
    styleUrls: ['./product-statistics.component.css']
})
export class ProductStatisticsComponent implements OnInit, AfterViewInit {
    @ViewChild('salesChart') salesChartCanvas: ElementRef;
    @ViewChild('categoryChart') categoryChartCanvas: ElementRef;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    displayedColumns: string[] = ['image', 'title', 'category', 'quantitySold', 'totalRevenue'];
    dataSource: MatTableDataSource<ProductStats>;

    selectedCategory = new FormControl('');
    categories: string[] = [];
    categoryColors: { [key: string]: { background: string, color: string } } = {};

    totalRevenue: number = 0;
    totalProducts: number = 0;
    bestSeller: string = '';

    loading = true;
    error = false;

    constructor(
        private dataService: DataServiceGenerico,
        private commonService: CommonService
    ) {
        this.dataSource = new MatTableDataSource<ProductStats>();
    }

    ngOnInit(): void {
        this.initializeData();
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.setupSortingBehavior();
    }

    private setupSortingBehavior(): void {
        this.dataSource.sortingDataAccessor = (item: ProductStats, property: string) => {
            switch (property) {
                case 'title': return item.title.toLowerCase();
                case 'category': return item.category.toLowerCase();
                case 'quantitySold': return item.quantitySold;
                case 'totalRevenue': return item.totalRevenue;
                default: return item[property];
            }
        };
    }

    private initializeData(): void {
        this.loadProductStats();
    }

    private generateCategoryColors(categories: string[]): { [key: string]: { background: string, color: string } } {
        const categoryColors: { [key: string]: { background: string, color: string } } = {};
        const hueStep = 360 / categories.length;

        categories.forEach((category, index) => {
            const hue = index * hueStep;
            categoryColors[category] = {
                background: `hsla(${hue}, 85%, 95%, 0.8)`,
                color: `hsl(${hue}, 85%, 35%)`
            };
        });

        return categoryColors;
    }

    private async loadProductStats(): Promise<void> {
        try {
            this.loading = true;

            const sicurezza = await this.dataService.getCurrentRestaurantId();
            const restaurantId = await this.dataService.getRistorante();
            const orders = await this.getOrders(restaurantId);
            const stats = this.processOrders(orders);

            this.dataSource.data = stats;
            this.categories = [...new Set(stats.map(item => item.category))];
            this.categoryColors = this.generateCategoryColors(this.categories);
            this.calculateTotals(stats);

            this.loading = false;
        } catch (error) {
            console.error('Error loading statistics:', error);
            this.error = true;
            this.loading = false;
        }
    }

    private async getOrders(restaurantId: string): Promise<any[]> {
        const ordersSnapshot = await firebase.firestore()
            .collection('ordini')
            .where('ristorante', '==', restaurantId)
            .where('statoPagato', '==', 0)
            .get();

        return ordersSnapshot.docs.map(doc => doc.data());
    }

    private processOrders(orders: any[]): ProductStats[] {
        const statsMap = new Map<string, ProductStats>();

        orders.forEach(order => {
            if (!order.carrello) return;

            Object.values(order.carrello).forEach((item: any) => {
                const price = Number(item.prezzo) || 0;
                const quantity = Number(item.quantita) || 0;

                if (!statsMap.has(item.id)) {
                    statsMap.set(item.id, {
                        id: item.id,
                        title: item.title,
                        category: item.category || 'Non categorizzato',
                        image_link: item.image_link || '',
                        quantitySold: 0,
                        totalRevenue: 0
                    });
                }

                const stats = statsMap.get(item.id);
                stats.quantitySold += quantity;
                stats.totalRevenue += (price * quantity);
            });
        });

        return Array.from(statsMap.values());
    }

    private calculateTotals(stats: ProductStats[]): void {
        this.totalRevenue = stats.reduce((sum, item) => sum + item.totalRevenue, 0);
        this.totalProducts = stats.reduce((sum, item) => sum + item.quantitySold, 0);

        const bestSeller = stats.reduce((best, current) =>
                current.quantitySold > (best?.quantitySold || 0) ? current : best
            , null);

        this.bestSeller = bestSeller?.title || '';
    }

    getCategoryStyle(category: string): object {
        return this.categoryColors[category] || {
            background: 'rgba(0, 0, 0, 0.1)',
            color: '#666'
        };
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    onCategoryChange(): void {
        this.dataSource.filterPredicate = (data: ProductStats, filter: string) => {
            return !filter || data.category === filter;
        };
        this.dataSource.filter = this.selectedCategory.value;
    }

    formatCurrency(value: number): string {
        return new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);
    }

    retry(): void {
        this.loadProductStats();
    }
}