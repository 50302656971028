import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges, ViewChild
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../../router.module';
import {UserDataService} from '../../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {AggiuntaPiatto} from "../../aggiunta-piatto/aggiunta-piatto";
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {CommonService} from "../../../shared/services/firestore/common.service";
import * as Chart from "chart.js";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";



@Component({
  selector: 'report-inventario',
  templateUrl: './report-inventario.html',
  styleUrls: ['./report-inventario.css']
})

export class ReportInventario implements OnInit, AfterViewInit {

  dataSource = new MatTableDataSource<any>([]);
  _alldata: any[] = [];
  columns: any[] = [{
    field: 'nome'
  }, {
    field: 'UM'
  }, {
    field: 'prezzo'
  }, {
    field: 'quantitaPrima'
  }, {
    field: 'finaleTeorica'
  }, {
    field: 'finaleAttuale'
  }, {
    field: 'variazionePercentuale'
  }, {
    field: 'variazioneCost'
  }, {
    field: 'spesaProdotto'
  }, {
    field: 'valoreMagazzino'
  }, {
    field: ' '
  }];
  displayedColumns: string[] = this.columns.map(column => column.field);
  groupByColumns: string[] = ['categoria'];
  totali = {
    'Food cost': '20.000,00€',
    'Costo del lavoro': '10.000,00€',
    'Costi generali': '3.500,00€'
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(    private dataServiceGenerico: DataServiceGenerico
  ) {
    let current = new Date();
    let timestamp = current.getTime();
    let psw = timestamp.toString();
    this.idPieChart = 'pie' + psw;
    this.idChart = 'chart' + psw;
  }
  immagini = {}

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }

  ngOnInit(): void {

    this.immagini = this.dataServiceGenerico.getImmagini()
    this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);

    this._alldata = [
      {
      nome: "LEGNETTI",
      prezzo: 0.004,
      UM: "PZ",
      categoria: "CONSUMABILI",
      quantitaPrima: 286,
      finaleTeorica: 26,
      finaleAttuale: 26
    },
      {
        nome: "COPPETTE CHIARE",
        prezzo: 0.033,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 1349,
        finaleTeorica: 1064,
        finaleAttuale: 1064
      },
      {
        nome: "TUBETTI DI CARTA",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 1297,
        finaleTeorica: 124,
        finaleAttuale: 123
      },
      {
        nome: "LAME DI LEGNO",
        prezzo: 0.016,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 1323,
        finaleTeorica: 203,
        finaleAttuale: 203
      },
      {
        nome: "PALETTE DI LEGNO",
        prezzo: 0.022,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 495,
        finaleTeorica: 171,
        finaleAttuale: 170
      },
      {
        nome: "MINI CUCCHIAIO PRO",
        prezzo: 0.01,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 1180,
        finaleTeorica: 1148,
        finaleAttuale: 1147
      },
      {
        nome: "RETINE HENNY PENNY",
        prezzo: 0.449,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 349,
        finaleTeorica: 336,
        finaleAttuale: 336
      },
      {
        nome: "PUNTE DI LEGNO",
        prezzo: 0.02,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 1762,
        finaleTeorica: 762,
        finaleAttuale: 762
      },
      {
        nome: "CONTENITORE TRASPARENTE PRO",
        prezzo: 0.06,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 1912,
        finaleTeorica: 1220,
        finaleAttuale: 1218
      },
      {
        nome: "SUPPORTO PER BICCHIERI",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 1549,
        finaleTeorica: 741,
        finaleAttuale: 741
      },
      {
        nome: "BORSA DI PLASTICA X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 493,
        finaleTeorica: 90,
        finaleAttuale: 90
      },
      {
        nome: "PEZZI IGIENICI UMIDI",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 109,
        finaleTeorica: 47,
        finaleAttuale: 47
      },
      {
        nome: "BORSE BIO IBE",
        prezzo: 0.052,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 454,
        finaleTeorica: 288,
        finaleAttuale: 287
      },
      {
        nome: "BORSE BIO IBE",
        prezzo: 0.107,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 802,
        finaleTeorica: 566,
        finaleAttuale: 566
      },
      {
        nome: "CARTA TORK",
        prezzo: 0.004,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 833,
        finaleTeorica: 342,
        finaleAttuale: 342
      },
      {
        nome: "CARTA TORK",
        prezzo: 0.004,
        UM: "PZ",
        categoria: "CONSUMABILI",
        quantitaPrima: 1525,
        finaleTeorica: 1261,
        finaleAttuale: 1262
      },
      {
        nome: "FAGIOLI BBQ",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "CONTORNO",
        quantitaPrima: 156,
        finaleTeorica: 29,
        finaleAttuale: 28
      },
      {
        nome: "PURE' DI PATATE",
        prezzo: 2.106,
        UM: "KG",
        categoria: "CONTORNO",
        quantitaPrima: 1065,
        finaleTeorica: 122,
        finaleAttuale: 121
      },
      {
        nome: "FIOCCHI DI FORMAGGIO",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "CONTORNO",
        quantitaPrima: 882,
        finaleTeorica: 193,
        finaleAttuale: 192
      },
      {
        nome: "SNACK DI MOZZARELLA",
        prezzo: 0.123,
        UM: "PZ",
        categoria: "CONTORNO",
        quantitaPrima: 1230,
        finaleTeorica: 848,
        finaleAttuale: 848
      },
      {
        nome: "ANELLI DI CIPOLLA",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "CONTORNO",
        quantitaPrima: 322,
        finaleTeorica: 41,
        finaleAttuale: 40
      },
      {
        nome: "CORN COB",
        prezzo: 0.237,
        UM: "PZ",
        categoria: "CONTORNO",
        quantitaPrima: 1262,
        finaleTeorica: 1258,
        finaleAttuale: 1256
      },
      {
        nome: "CORN COB",
        prezzo: 0.238,
        UM: "PZ",
        categoria: "CONTORNO",
        quantitaPrima: 317,
        finaleTeorica: 254,
        finaleAttuale: 253
      },
      {
        nome: "PATATE SOFFIATE",
        prezzo: 0.023,
        UM: "PZ",
        categoria: "CONTORNO",
        quantitaPrima: 111,
        finaleTeorica: 89,
        finaleAttuale: 89
      },
      {
        nome: "TORTA AL CIOCCOLATO",
        prezzo: 16.1,
        UM: "PZ",
        categoria: "DESSERT",
        quantitaPrima: 850,
        finaleTeorica: 791,
        finaleAttuale: 791
      },
      {
        nome: "BISCOTTI AL CIOCCOLATO",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "DESSERT",
        quantitaPrima: 518,
        finaleTeorica: 400,
        finaleAttuale: 399
      },
      {
        nome: "CONO DOLCE X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "DESSERT",
        quantitaPrima: 1556,
        finaleTeorica: 729,
        finaleAttuale: 727
      },
      {
        nome: "CROCCANTINI MANDORLE",
        prezzo: 0.0,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 797,
        finaleTeorica: 528,
        finaleAttuale: 528
      },
      {
        nome: "CROCCANTINI WAFER MIX",
        prezzo: 0.0,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 1928,
        finaleTeorica: 882,
        finaleAttuale: 881
      },
      {
        nome: "KIT ISCREAM HALLOWEEN",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "DESSERT",
        quantitaPrima: 1324,
        finaleTeorica: 949,
        finaleAttuale: 948
      },
      {
        nome: "KIT ISCREAM HALLOWEEN",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "DESSERT",
        quantitaPrima: 989,
        finaleTeorica: 474,
        finaleAttuale: 473
      },
      {
        nome: "N. Topping Cioccolato",
        prezzo: 6.632,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 1530,
        finaleTeorica: 251,
        finaleAttuale: 251
      },
      {
        nome: "N.CROCCANTE KITKAT",
        prezzo: 11.624,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 178,
        finaleTeorica: 68,
        finaleAttuale: 67
      },
      {
        nome: "N.CROCCANTE SMRT Mini",
        prezzo: 9.869,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 1363,
        finaleTeorica: 107,
        finaleAttuale: 107
      },
      {
        nome: "N.Crumbles Granules",
        prezzo: 0.0,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 1992,
        finaleTeorica: 1025,
        finaleAttuale: 1025
      },
      {
        nome: "N.GALAK Crema Pistacchio",
        prezzo: 14.47,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 926,
        finaleTeorica: 302,
        finaleAttuale: 302
      },
      {
        nome: "N.Granella Wafer",
        prezzo: 0.0,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 101,
        finaleTeorica: 45,
        finaleAttuale: 45
      },
      {
        nome: "N.KITKAT Salsa Cremosa",
        prezzo: 13.561,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 1755,
        finaleTeorica: 1596,
        finaleAttuale: 1593
      },
      {
        nome: "N.Topping Caramello",
        prezzo: 4.322,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 1673,
        finaleTeorica: 1022,
        finaleAttuale: 1022
      },
      {
        nome: "NUTELLA BOTTLE",
        prezzo: 6.295,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 67,
        finaleTeorica: 7,
        finaleAttuale: 6
      },
      {
        nome: "TOPPING CARAMELLO",
        prezzo: 0.0,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 1671,
        finaleTeorica: 1298,
        finaleAttuale: 1297
      },
      {
        nome: "TOPPING CIOCCO-NOCCIOLA",
        prezzo: 0.0,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 775,
        finaleTeorica: 744,
        finaleAttuale: 744
      },
      {
        nome: "TOPPING FRAGOLA EXTRA",
        prezzo: 7.145,
        UM: "KG",
        categoria: "DESSERT",
        quantitaPrima: 1276,
        finaleTeorica: 1225,
        finaleAttuale: 1225
      },
      {
        nome: "TORTA MARENGO",
        prezzo: 6.638,
        UM: "PZ",
        categoria: "DESSERT",
        quantitaPrima: 520,
        finaleTeorica: 390,
        finaleAttuale: 389
      },
      {
        nome: "TORTA MARENGO",
        prezzo: 11.73,
        UM: "PZ",
        categoria: "DESSERT",
        quantitaPrima: 1498,
        finaleTeorica: 490,
        finaleAttuale: 490
      },
      {
        nome: "TORTA NOCCIOLA",
        prezzo: 10.65,
        UM: "PZ",
        categoria: "DESSERT",
        quantitaPrima: 356,
        finaleTeorica: 224,
        finaleAttuale: 223
      },
      {
        nome: "TORTA RED VELVET",
        prezzo: 26.2,
        UM: "PZ",
        categoria: "DESSERT",
        quantitaPrima: 1673,
        finaleTeorica: 1249,
        finaleAttuale: 1248
      },
      {
        nome: "TORTA YOGURT E FRUTTI DI",
        prezzo: 10.761,
        UM: "PZ",
        categoria: "DESSERT",
        quantitaPrima: 1290,
        finaleTeorica: 827,
        finaleAttuale: 826
      },
      {
        nome: "CHEDDAR SLICES",
        prezzo: 0.061,
        UM: "PZ",
        categoria: "FORMAGGI",
        quantitaPrima: 823,
        finaleTeorica: 213,
        finaleAttuale: 213
      },
      {
        nome: "BACON (",
        prezzo: 17.424,
        UM: "KG",
        categoria: "INGREDIENTI",
        quantitaPrima: 1523,
        finaleTeorica: 951,
        finaleAttuale: 949
      },
      {
        nome: "BACON FLAKES",
        prezzo: 0.0,
        UM: "KG",
        categoria: "INGREDIENTI",
        quantitaPrima: 620,
        finaleTeorica: 44,
        finaleAttuale: 43
      },
      {
        nome: "BUN BRIOCHE",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "INGREDIENTI",
        quantitaPrima: 969,
        finaleTeorica: 670,
        finaleAttuale: 670
      },
      {
        nome: "CRISPY ONIONS",
        prezzo: 0.0,
        UM: "KG",
        categoria: "INGREDIENTI",
        quantitaPrima: 1384,
        finaleTeorica: 546,
        finaleAttuale: 545
      },
      {
        nome: "GLAZED BUN",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "INGREDIENTI",
        quantitaPrima: 1971,
        finaleTeorica: 1744,
        finaleAttuale: 1741
      },
      {
        nome: "INSALATA ICEBERG",
        prezzo: 3.834,
        UM: "KG",
        categoria: "INGREDIENTI",
        quantitaPrima: 958,
        finaleTeorica: 772,
        finaleAttuale: 772
      },
      {
        nome: "INSALATA LIMONETTA",
        prezzo: 0.0,
        UM: "KG",
        categoria: "INGREDIENTI",
        quantitaPrima: 1761,
        finaleTeorica: 1029,
        finaleAttuale: 1028
      },
      {
        nome: "Insalata mix",
        prezzo: 0.0,
        UM: "KG",
        categoria: "INGREDIENTI",
        quantitaPrima: 1720,
        finaleTeorica: 716,
        finaleAttuale: 715
      },
      {
        nome: "MAIS",
        prezzo: 2.675,
        UM: "KG",
        categoria: "INGREDIENTI",
        quantitaPrima: 1686,
        finaleTeorica: 653,
        finaleAttuale: 652
      },
      {
        nome: "N.MIX Stuzzicante",
        prezzo: 5.133,
        UM: "KG",
        categoria: "INGREDIENTI",
        quantitaPrima: 1032,
        finaleTeorica: 520,
        finaleAttuale: 520
      },
      {
        nome: "N.TORT.WRAP DEEP",
        prezzo: 0.155,
        UM: "PZ",
        categoria: "INGREDIENTI",
        quantitaPrima: 799,
        finaleTeorica: 643,
        finaleAttuale: 642
      },
      {
        nome: "N.TORTILLA",
        prezzo: 0.139,
        UM: "PZ",
        categoria: "INGREDIENTI",
        quantitaPrima: 929,
        finaleTeorica: 194,
        finaleAttuale: 193
      },
      {
        nome: "Pomodorini Ciliegina",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "INGREDIENTI",
        quantitaPrima: 1984,
        finaleTeorica: 93,
        finaleAttuale: 92
      },
      {
        nome: "Pomodoro a fette",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "INGREDIENTI",
        quantitaPrima: 723,
        finaleTeorica: 1,
        finaleAttuale: 1
      },
      {
        nome: "POMODORO INTERO",
        prezzo: 4.497,
        UM: "KG",
        categoria: "INGREDIENTI",
        quantitaPrima: 1084,
        finaleTeorica: 771,
        finaleAttuale: 769
      },
      {
        nome: "SALE PATATINE X",
        prezzo: 0.987,
        UM: "PZ",
        categoria: "INGREDIENTI",
        quantitaPrima: 1167,
        finaleTeorica: 846,
        finaleAttuale: 845
      },
      {
        nome: "BAVAGLIO  AIRSPUN",
        prezzo: 0.27,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 855,
        finaleTeorica: 560,
        finaleAttuale: 560
      },
      {
        nome: "BICCHIERE",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1226,
        finaleTeorica: 21,
        finaleAttuale: 21
      },
      {
        nome: "BICCHIERE",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 502,
        finaleTeorica: 436,
        finaleAttuale: 436
      },
      {
        nome: "BICCHIERE KREAMBALL",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1909,
        finaleTeorica: 1626,
        finaleAttuale: 1625
      },
      {
        nome: "BICCHIERE MEDIO CAFFE X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 520,
        finaleTeorica: 406,
        finaleAttuale: 405
      },
      {
        nome: "BICCHIERE PICCOLO CAFFE",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1409,
        finaleTeorica: 1036,
        finaleAttuale: 1036
      },
      {
        nome: "BICCHIERE SUNDAE",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 511,
        finaleTeorica: 250,
        finaleAttuale: 249
      },
      {
        nome: "BIOCIDA POVI IODINE",
        prezzo: 3.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 767,
        finaleTeorica: 206,
        finaleAttuale: 206
      },
      {
        nome: "BOLLINI COLORATI NERI",
        prezzo: 0.004,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 55,
        finaleTeorica: 32,
        finaleAttuale: 32
      },
      {
        nome: "BOLLINI COLORATI ROSSI",
        prezzo: 0.004,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1155,
        finaleTeorica: 663,
        finaleAttuale: 662
      },
      {
        nome: "BOLLINI COLORATI VERDI",
        prezzo: 0.004,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1895,
        finaleTeorica: 1021,
        finaleAttuale: 1020
      },
      {
        nome: "BUCKET",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1796,
        finaleTeorica: 568,
        finaleAttuale: 567
      },
      {
        nome: "BUCKET",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 397,
        finaleTeorica: 253,
        finaleAttuale: 252
      },
      {
        nome: "BUCKET",
        prezzo: 0.201,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 510,
        finaleTeorica: 338,
        finaleAttuale: 338
      },
      {
        nome: "BUCKET",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 348,
        finaleTeorica: 255,
        finaleAttuale: 254
      },
      {
        nome: "BUCKET X",
        prezzo: 0.108,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1308,
        finaleTeorica: 1221,
        finaleAttuale: 1222
      },
      {
        nome: "BUSTA DA ASPORTO",
        prezzo: 0.018,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1126,
        finaleTeorica: 710,
        finaleAttuale: 710
      },
      {
        nome: "BUSTA DA ASPORTO",
        prezzo: 0.032,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 880,
        finaleTeorica: 851,
        finaleAttuale: 849
      },
      {
        nome: "BUSTE CON MANICO",
        prezzo: 0.091,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1180,
        finaleTeorica: 581,
        finaleAttuale: 581
      },
      {
        nome: "CIOTOLA INSALATA GRANDE",
        prezzo: 0.158,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 181,
        finaleTeorica: 68,
        finaleAttuale: 68
      },
      {
        nome: "COPERCHIO BICCHIERI X",
        prezzo: 0.013,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 222,
        finaleTeorica: 193,
        finaleAttuale: 193
      },
      {
        nome: "COPERCHIO BUCKET X",
        prezzo: 0.033,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1126,
        finaleTeorica: 454,
        finaleAttuale: 453
      },
      {
        nome: "COPERCHIO CAFFE MEDIO X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1140,
        finaleTeorica: 459,
        finaleAttuale: 459
      },
      {
        nome: "COPERCHIO CAFFE' PICCOLO",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 953,
        finaleTeorica: 841,
        finaleAttuale: 840
      },
      {
        nome: "COPERCHIO CIOTOLE",
        prezzo: 0.082,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 880,
        finaleTeorica: 537,
        finaleAttuale: 536
      },
      {
        nome: "COPERCHIO KREAMBALL",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1710,
        finaleTeorica: 361,
        finaleAttuale: 360
      },
      {
        nome: "COPERCHIO LARGE POT X",
        prezzo: 0.022,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1522,
        finaleTeorica: 1459,
        finaleAttuale: 1458
      },
      {
        nome: "COPERCHIO SMALL POT X",
        prezzo: 0.013,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1228,
        finaleTeorica: 953,
        finaleAttuale: 953
      },
      {
        nome: "COPERCHIO SUNDAE",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1832,
        finaleTeorica: 291,
        finaleAttuale: 290
      },
      {
        nome: "DIPPING BAR X",
        prezzo: 0.036,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1036,
        finaleTeorica: 186,
        finaleAttuale: 185
      },
      {
        nome: "FILMOP STRIPE MOP",
        prezzo: 7.5,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 330,
        finaleTeorica: 246,
        finaleAttuale: 245
      },
      {
        nome: "FLAT CHICKEN BAG X",
        prezzo: 0.01,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 759,
        finaleTeorica: 661,
        finaleAttuale: 661
      },
      {
        nome: "GARZA STERILE",
        prezzo: 0.25,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 645,
        finaleTeorica: 431,
        finaleAttuale: 431
      },
      {
        nome: "GARZA STERILE FORMATO",
        prezzo: 0.12,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 313,
        finaleTeorica: 306,
        finaleAttuale: 306
      },
      {
        nome: "GUANT REFLEXX T",
        prezzo: 0.042,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 2000,
        finaleTeorica: 146,
        finaleAttuale: 145
      },
      {
        nome: "GUANTI NITR.SOFT SPOL M",
        prezzo: 0.029,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1436,
        finaleTeorica: 80,
        finaleAttuale: 79
      },
      {
        nome: "GUANTI NITR.SOFT SPOL M",
        prezzo: 0.029,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1213,
        finaleTeorica: 166,
        finaleAttuale: 165
      },
      {
        nome: "GUANTI NITR.SOFT SPOL M",
        prezzo: 0.029,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1939,
        finaleTeorica: 57,
        finaleAttuale: 57
      },
      {
        nome: "GUANTI NITR.SOFT SPOL M",
        prezzo: 0.029,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1815,
        finaleTeorica: 377,
        finaleAttuale: 377
      },
      {
        nome: "KIT COMPLEANNO ",
        prezzo: 161.82,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 159,
        finaleTeorica: 144,
        finaleAttuale: 144
      },
      {
        nome: "LARGE POT X",
        prezzo: 0.044,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1272,
        finaleTeorica: 1248,
        finaleAttuale: 1246
      },
      {
        nome: "MATITA",
        prezzo: 2.1,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 706,
        finaleTeorica: 250,
        finaleAttuale: 250
      },
      {
        nome: "MEAL BOX X",
        prezzo: 0.178,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 604,
        finaleTeorica: 582,
        finaleAttuale: 581
      },
      {
        nome: "MEDIUM CHICKEN BOX -",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1938,
        finaleTeorica: 1327,
        finaleAttuale: 1327
      },
      {
        nome: "N.BICCHIERE",
        prezzo: 0.042,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 818,
        finaleTeorica: 114,
        finaleAttuale: 114
      },
      {
        nome: "N.BICCHIERE",
        prezzo: 0.049,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 965,
        finaleTeorica: 182,
        finaleAttuale: 181
      },
      {
        nome: "N.BICCHIERE CROUSTI",
        prezzo: 0.073,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1076,
        finaleTeorica: 412,
        finaleAttuale: 411
      },
      {
        nome: "N.BICCHIERE MINI",
        prezzo: 0.058,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1235,
        finaleTeorica: 267,
        finaleAttuale: 266
      },
      {
        nome: "N.BICCHIERE SUNDAE",
        prezzo: 0.067,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1441,
        finaleTeorica: 459,
        finaleAttuale: 458
      },
      {
        nome: "N.BIRRA PERONI",
        prezzo: 0.61,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1895,
        finaleTeorica: 120,
        finaleAttuale: 120
      },
      {
        nome: "N.PALLET PLAST.",
        prezzo: 51.73,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 812,
        finaleTeorica: 744,
        finaleAttuale: 744
      },
      {
        nome: "N.PALLET PLAST.NERO",
        prezzo: 10.925,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1052,
        finaleTeorica: 583,
        finaleAttuale: 583
      },
      {
        nome: "N.POR.BICCHIE.",
        prezzo: 0.047,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1765,
        finaleTeorica: 94,
        finaleAttuale: 94
      },
      {
        nome: "N.SACCH.PATATINE MEDIO",
        prezzo: 0.005,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1957,
        finaleTeorica: 226,
        finaleAttuale: 226
      },
      {
        nome: "N.STICKERS ROSSI E VERDI",
        prezzo: 0.007,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1043,
        finaleTeorica: 926,
        finaleAttuale: 927
      },
      {
        nome: "N.STICKERSVEGGIE",
        prezzo: 0.013,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 244,
        finaleTeorica: 77,
        finaleAttuale: 76
      },
      {
        nome: "N.WRAP BURGER",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 642,
        finaleTeorica: 52,
        finaleAttuale: 51
      },
      {
        nome: "N.WRAP CLASSIC BURGER",
        prezzo: 0.018,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 319,
        finaleTeorica: 76,
        finaleAttuale: 76
      },
      {
        nome: "N.WRAP PREMIUM BURGER",
        prezzo: 0.018,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1397,
        finaleTeorica: 308,
        finaleAttuale: 308
      },
      {
        nome: "N.WRAP VALUE BURGER",
        prezzo: 0.014,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1477,
        finaleTeorica: 75,
        finaleAttuale: 75
      },
      {
        nome: "OPEN BUCKET",
        prezzo: 0.077,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 418,
        finaleTeorica: 285,
        finaleAttuale: 285
      },
      {
        nome: "PACK DOUBLE DOWN x",
        prezzo: 0.115,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 821,
        finaleTeorica: 543,
        finaleAttuale: 543
      },
      {
        nome: "PACK KENTUCKY FRIES",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 645,
        finaleTeorica: 540,
        finaleAttuale: 540
      },
      {
        nome: "POPCORN CHICKEN GRANDE",
        prezzo: 0.07,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1133,
        finaleTeorica: 970,
        finaleAttuale: 969
      },
      {
        nome: "POPCORN CHICKEN MEDIO X",
        prezzo: 0.062,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 834,
        finaleTeorica: 35,
        finaleAttuale: 35
      },
      {
        nome: "PROFUMATORE ",
        prezzo: 0.83,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1030,
        finaleTeorica: 793,
        finaleAttuale: 793
      },
      {
        nome: "PROFUMATORE ",
        prezzo: 0.83,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1406,
        finaleTeorica: 1047,
        finaleAttuale: 1046
      },
      {
        nome: "SACCHETTO GELO GRANDE M",
        prezzo: 0.002,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1837,
        finaleTeorica: 1718,
        finaleAttuale: 1717
      },
      {
        nome: "SACCHETTO GELO M",
        prezzo: 0.001,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 331,
        finaleTeorica: 204,
        finaleAttuale: 204
      },
      {
        nome: "SACCHETTO GELO M",
        prezzo: 0.001,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 943,
        finaleTeorica: 257,
        finaleAttuale: 257
      },
      {
        nome: "SACCHETTO GHIACCIO",
        prezzo: 0.7,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1176,
        finaleTeorica: 219,
        finaleAttuale: 218
      },
      {
        nome: "SACCHETTO GRANDE POLLO",
        prezzo: 0.026,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1894,
        finaleTeorica: 1403,
        finaleAttuale: 1404
      },
      {
        nome: "SACCHETTO PANNOCCHIA",
        prezzo: 0.006,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 225,
        finaleTeorica: 176,
        finaleAttuale: 176
      },
      {
        nome: "Sacchetto pasticceria",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1786,
        finaleTeorica: 789,
        finaleAttuale: 789
      },
      {
        nome: "SACCHETTO PATATINE",
        prezzo: 0.006,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1369,
        finaleTeorica: 1286,
        finaleAttuale: 1284
      },
      {
        nome: "SACCHETTO PATATINE",
        prezzo: 0.006,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1444,
        finaleTeorica: 1186,
        finaleAttuale: 1185
      },
      {
        nome: "SACCHETTO PICCOLO",
        prezzo: 0.008,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 914,
        finaleTeorica: 262,
        finaleAttuale: 261
      },
      {
        nome: "SALVIETTINE IGIENIZZANTI",
        prezzo: 0.28,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 342,
        finaleTeorica: 184,
        finaleAttuale: 184
      },
      {
        nome: "SMALL POT X",
        prezzo: 0.026,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 476,
        finaleTeorica: 74,
        finaleAttuale: 73
      },
      {
        nome: "SNACKBOX X",
        prezzo: 0.102,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1432,
        finaleTeorica: 1026,
        finaleAttuale: 1027
      },
      {
        nome: "TOVAGLIETTE  PROMO",
        prezzo: 0.013,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 338,
        finaleTeorica: 207,
        finaleAttuale: 207
      },
      {
        nome: "TOVAGLIETTE  X",
        prezzo: 0.013,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1222,
        finaleTeorica: 838,
        finaleAttuale: 838
      },
      {
        nome: "TOVAGLIETTE PROMO X",
        prezzo: 0.01,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1949,
        finaleTeorica: 738,
        finaleAttuale: 739
      },
      {
        nome: "TOWER CLAMSHELL",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 770,
        finaleTeorica: 140,
        finaleAttuale: 139
      },
      {
        nome: "WRAP BURGER GENERICO",
        prezzo: 0.023,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1684,
        finaleTeorica: 420,
        finaleAttuale: 419
      },
      {
        nome: "WRAP KRUNCH",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1949,
        finaleTeorica: 592,
        finaleAttuale: 591
      },
      {
        nome: "WRAP SMALL X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 115,
        finaleTeorica: 68,
        finaleAttuale: 68
      },
      {
        nome: "WRAP ZINGER X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PACKAGING",
        quantitaPrima: 1529,
        finaleTeorica: 1254,
        finaleAttuale: 1252
      },
      {
        nome: "FARINA IMPANATURA POLLO",
        prezzo: 0.852,
        UM: "KG",
        categoria: "PANE",
        quantitaPrima: 1575,
        finaleTeorica: 942,
        finaleAttuale: 941
      },
      {
        nome: "N.PREMIUM BRIOCHE BUN",
        prezzo: 0.221,
        UM: "PZ",
        categoria: "PANE",
        quantitaPrima: 1203,
        finaleTeorica: 27,
        finaleAttuale: 27
      },
      {
        nome: "N.SESAME BUN",
        prezzo: 0.196,
        UM: "PZ",
        categoria: "PANE",
        quantitaPrima: 436,
        finaleTeorica: 103,
        finaleAttuale: 103
      },
      {
        nome: "N.VALUE BUN",
        prezzo: 0.128,
        UM: "PZ",
        categoria: "PANE",
        quantitaPrima: 1175,
        finaleTeorica: 1124,
        finaleAttuale: 1123
      },
      {
        nome: "N.WRAP LARGE",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "PANE",
        quantitaPrima: 1721,
        finaleTeorica: 585,
        finaleAttuale: 585
      },
      {
        nome: "PANINO KRUNCHY",
        prezzo: 0.115,
        UM: "PZ",
        categoria: "PANE",
        quantitaPrima: 717,
        finaleTeorica: 594,
        finaleAttuale: 594
      },
      {
        nome: "N.PATATINE",
        prezzo: 0.0,
        UM: "KG",
        categoria: "PATATE",
        quantitaPrima: 1804,
        finaleTeorica: 1318,
        finaleAttuale: 1318
      },
      {
        nome: "PATATINE",
        prezzo: 1.716,
        UM: "KG",
        categoria: "PATATE",
        quantitaPrima: 607,
        finaleTeorica: 569,
        finaleAttuale: 569
      },
      {
        nome: "COB PREMARINATO",
        prezzo: 0.517,
        UM: "PZ",
        categoria: "POLLO",
        quantitaPrima: 1419,
        finaleTeorica: 1229,
        finaleAttuale: 1227
      },
      {
        nome: "FILETTI ORIGINAL PM",
        prezzo: 0.517,
        UM: "PZ",
        categoria: "POLLO",
        quantitaPrima: 614,
        finaleTeorica: 462,
        finaleAttuale: 462
      },
      {
        nome: "FILETTI ZINGER PM",
        prezzo: 0.519,
        UM: "PZ",
        categoria: "POLLO",
        quantitaPrima: 1030,
        finaleTeorica: 828,
        finaleAttuale: 828
      },
      {
        nome: "N.FIL ORIGIN.",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "POLLO",
        quantitaPrima: 105,
        finaleTeorica: 90,
        finaleAttuale: 89
      },
      {
        nome: "N.HOTWINGS PREMAR.",
        prezzo: 0.137,
        UM: "PZ",
        categoria: "POLLO",
        quantitaPrima: 198,
        finaleTeorica: 38,
        finaleAttuale: 37
      },
      {
        nome: "N.NUGGETS",
        prezzo: 0.09,
        UM: "PZ",
        categoria: "POLLO",
        quantitaPrima: 352,
        finaleTeorica: 7,
        finaleAttuale: 6
      },
      {
        nome: "N.POPCORN CHICKEN",
        prezzo: 0.032,
        UM: "PZ",
        categoria: "POLLO",
        quantitaPrima: 1982,
        finaleTeorica: 823,
        finaleAttuale: 822
      },
      {
        nome: "STRIPS PREMARINATI",
        prezzo: 0.228,
        UM: "PZ",
        categoria: "POLLO",
        quantitaPrima: 1726,
        finaleTeorica: 839,
        finaleAttuale: 840
      },
      {
        nome: "TENDER ORIGINAL",
        prezzo: 0.278,
        UM: "PZ",
        categoria: "POLLO",
        quantitaPrima: 1724,
        finaleTeorica: 1596,
        finaleAttuale: 1596
      },
      {
        nome: "GLENN SAUCE",
        prezzo: 0.22,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 484,
        finaleTeorica: 276,
        finaleAttuale: 275
      },
      {
        nome: "GRAVY MIX X",
        prezzo: 5.016,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 440,
        finaleTeorica: 277,
        finaleAttuale: 277
      },
      {
        nome: "IMOR BREADING",
        prezzo: 8.877,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 140,
        finaleTeorica: 97,
        finaleAttuale: 97
      },
      {
        nome: "IMOR SALE",
        prezzo: 1.411,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 681,
        finaleTeorica: 6,
        finaleAttuale: 5
      },
      {
        nome: "KETCHUP SQUEEZE",
        prezzo: 1.551,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 609,
        finaleTeorica: 240,
        finaleAttuale: 240
      },
      {
        nome: "MAIO BURGER X",
        prezzo: 0.0,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 925,
        finaleTeorica: 864,
        finaleAttuale: 865
      },
      {
        nome: "MAIO BURGER X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1762,
        finaleTeorica: 650,
        finaleAttuale: 649
      },
      {
        nome: "MAIO PEPATA X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1902,
        finaleTeorica: 928,
        finaleAttuale: 927
      },
      {
        nome: "MAIO PEPATA X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 649,
        finaleTeorica: 15,
        finaleAttuale: 15
      },
      {
        nome: "MIX PANATURA HOT & SPICY",
        prezzo: 3.461,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1068,
        finaleTeorica: 982,
        finaleAttuale: 981
      },
      {
        nome: "N.CETRIOLINI",
        prezzo: 5.407,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1378,
        finaleTeorica: 953,
        finaleAttuale: 951
      },
      {
        nome: "N.CHEESE SAUCE BOTTLE",
        prezzo: 5.292,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 439,
        finaleTeorica: 397,
        finaleAttuale: 396
      },
      {
        nome: "N.OR BREADING MIX",
        prezzo: 4.996,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 166,
        finaleTeorica: 154,
        finaleAttuale: 153
      },
      {
        nome: "N.OR BREADING MIX",
        prezzo: 4.998,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1819,
        finaleTeorica: 1276,
        finaleAttuale: 1277
      },
      {
        nome: "N.ORIGINAL SAUCE",
        prezzo: 4.129,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 531,
        finaleTeorica: 431,
        finaleAttuale: 430
      },
      {
        nome: "N.SALSA DIPS HONEY",
        prezzo: 0.07,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 893,
        finaleTeorica: 612,
        finaleAttuale: 611
      },
      {
        nome: "N.SALSA DIPS SPICY",
        prezzo: 0.072,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 407,
        finaleTeorica: 217,
        finaleAttuale: 216
      },
      {
        nome: "N.SALSA SMOCKY BBQ",
        prezzo: 0.06,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 550,
        finaleTeorica: 208,
        finaleAttuale: 207
      },
      {
        nome: "NEW MIX UOVO E LATTE",
        prezzo: 10.0,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 515,
        finaleTeorica: 374,
        finaleAttuale: 374
      },
      {
        nome: "OLIVE OIL WITH BALSAMIC",
        prezzo: 0.18,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 495,
        finaleTeorica: 27,
        finaleAttuale: 26
      },
      {
        nome: "OLIVE OIL WITH VINEGAR X",
        prezzo: 0.175,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1217,
        finaleTeorica: 1021,
        finaleAttuale: 1021
      },
      {
        nome: "OLIVE OIL X",
        prezzo: 0.2,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 653,
        finaleTeorica: 371,
        finaleAttuale: 371
      },
      {
        nome: "SALE MONODOSI X",
        prezzo: 0.002,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 941,
        finaleTeorica: 761,
        finaleAttuale: 760
      },
      {
        nome: "SALSA CHEDDAR",
        prezzo: 0.0,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1361,
        finaleTeorica: 992,
        finaleAttuale: 990
      },
      {
        nome: "SALSA DIPS",
        prezzo: 0.064,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 938,
        finaleTeorica: 720,
        finaleAttuale: 721
      },
      {
        nome: "SALSA DIPS CREAMY CURRY",
        prezzo: 0.062,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1599,
        finaleTeorica: 1059,
        finaleAttuale: 1059
      },
      {
        nome: "SALSA DIPS FRESH PEPPER X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1341,
        finaleTeorica: 1115,
        finaleAttuale: 1115
      },
      {
        nome: "SALSA DIPS GARLIC X",
        prezzo: 0.129,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 554,
        finaleTeorica: 381,
        finaleAttuale: 381
      },
      {
        nome: "SALSA DIPS KETCHUP",
        prezzo: 0.065,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 984,
        finaleTeorica: 777,
        finaleAttuale: 776
      },
      {
        nome: "SALSA DIPS MAYO",
        prezzo: 0.076,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1934,
        finaleTeorica: 346,
        finaleAttuale: 346
      },
      {
        nome: "SALSA DIPS ORIGINAL X",
        prezzo: 0.076,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1006,
        finaleTeorica: 405,
        finaleAttuale: 404
      },
      {
        nome: "SALSA DIPS SWEET",
        prezzo: 0.061,
        UM: "PZ",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 669,
        finaleTeorica: 86,
        finaleAttuale: 85
      },
      {
        nome: "SALSA THE BOSS",
        prezzo: 0.0,
        UM: "KG",
        categoria: "SALSE E CONDIMENTI.",
        quantitaPrima: 1342,
        finaleTeorica: 203,
        finaleAttuale: 203
      },
      {
        nome: "TUBETTI DI CARTA PEPSI",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "ALTRO",
        quantitaPrima: 1463,
        finaleTeorica: 846,
        finaleAttuale: 844
      },
      {
        nome: "N.TUBETTI DI CARTA",
        prezzo: 0.009,
        UM: "PZ",
        categoria: "ALTRO",
        quantitaPrima: 454,
        finaleTeorica: 392,
        finaleAttuale: 392
      },
      {
        nome: "N.TRANSPALLET ",
        prezzo: 274.0,
        UM: "PZ",
        categoria: "ALTRO",
        quantitaPrima: 1264,
        finaleTeorica: 8,
        finaleAttuale: 7
      },
      {
        nome: "N.VEGGIE TENDER",
        prezzo: 0.305,
        UM: "PZ",
        categoria: "ALTRO",
        quantitaPrima: 1238,
        finaleTeorica: 324,
        finaleAttuale: 324
      },
      {
        nome: "Nothing Ordered  -",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "ALTRO",
        quantitaPrima: 1537,
        finaleTeorica: 848,
        finaleAttuale: 846
      },
      {
        nome: "SALE ADDOLCITORE",
        prezzo: 0.644,
        UM: "KG",
        categoria: "ALTRO",
        quantitaPrima: 801,
        finaleTeorica: 324,
        finaleAttuale: 323
      },
      {
        nome: "SALE ADDOLCITORE IN",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "ALTRO",
        quantitaPrima: 1037,
        finaleTeorica: 44,
        finaleAttuale: 43
      },
      {
        nome: "ACQUA FRIZZANTE SAN AQ",
        prezzo: 0.144,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 951,
        finaleTeorica: 511,
        finaleAttuale: 511
      },
      {
        nome: "ACQUA NATURALE AQ",
        prezzo: 0.139,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1149,
        finaleTeorica: 660,
        finaleAttuale: 660
      },
      {
        nome: "ARANCIATA LATTINA",
        prezzo: 0.342,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1184,
        finaleTeorica: 873,
        finaleAttuale: 873
      },
      {
        nome: "BIRRA",
        prezzo: 0.643,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 845,
        finaleTeorica: 787,
        finaleAttuale: 787
      },
      {
        nome: "BIRRA LATTINA",
        prezzo: 0.609,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1182,
        finaleTeorica: 1050,
        finaleAttuale: 1048
      },
      {
        nome: "N.RED BULL ENERGY DRINK",
        prezzo: 0.992,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 574,
        finaleTeorica: 526,
        finaleAttuale: 526
      },
      {
        nome: "N.RED BULL SUGAR FREE",
        prezzo: 0.992,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 467,
        finaleTeorica: 151,
        finaleAttuale: 151
      },
      {
        nome: "PEPSI MAX ZERO LATTINA",
        prezzo: 0.342,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1173,
        finaleTeorica: 85,
        finaleAttuale: 85
      },
      {
        nome: "PEPSI REGULAR LATTINA",
        prezzo: 0.342,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1493,
        finaleTeorica: 852,
        finaleAttuale: 851
      },
      {
        nome: "SEVEN UP LATTINA",
        prezzo: 0.342,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 621,
        finaleTeorica: 174,
        finaleAttuale: 174
      },
      {
        nome: "N.Caramelised Almond granules",
        prezzo: 0.0,
        UM: "KG",
        categoria: "BEVANDE",
        quantitaPrima: 148,
        finaleTeorica: 40,
        finaleAttuale: 40
      },
      {
        nome: "N.Croccante Mix",
        prezzo: 0.0,
        UM: "KG",
        categoria: "BEVANDE",
        quantitaPrima: 963,
        finaleTeorica: 176,
        finaleAttuale: 176
      },
      {
        nome: "N.ETICHET.DELIVERY ",
        prezzo: 0.007,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 977,
        finaleTeorica: 227,
        finaleAttuale: 226
      },
      {
        nome: "Rotolo aspatura",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 849,
        finaleTeorica: 50,
        finaleAttuale: 49
      },
      {
        nome: "ROTOLO CARTA TERMICA",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1957,
        finaleTeorica: 90,
        finaleAttuale: 90
      },
      {
        nome: "ROTOLO CARTA TERMICA",
        prezzo: 1.251,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1141,
        finaleTeorica: 1135,
        finaleAttuale: 1133
      },
      {
        nome: "ROTOLO POS X",
        prezzo: 0.231,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 832,
        finaleTeorica: 211,
        finaleAttuale: 210
      },
      {
        nome: "CARTA IGIENICA BAULETTO M",
        prezzo: 0.169,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1395,
        finaleTeorica: 1277,
        finaleAttuale: 1277
      },
      {
        nome: "DISTRIBUTORI TOVAGLIOLI",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1258,
        finaleTeorica: 530,
        finaleAttuale: 530
      },
      {
        nome: "FILTRI FRYMASTER",
        prezzo: 0.323,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1827,
        finaleTeorica: 197,
        finaleAttuale: 197
      },
      {
        nome: "FILTRI FRYMASTER POLLO X",
        prezzo: 0.349,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1033,
        finaleTeorica: 372,
        finaleAttuale: 372
      },
      {
        nome: "FILTRI PER FRIGGITRICE",
        prezzo: 2.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 209,
        finaleTeorica: 58,
        finaleAttuale: 58
      },
      {
        nome: "FILTRI PER FRIGGITRICI A",
        prezzo: 0.8,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 193,
        finaleTeorica: 43,
        finaleAttuale: 42
      },
      {
        nome: "FUSTINO PASTIGLIE",
        prezzo: 0.68,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 223,
        finaleTeorica: 217,
        finaleAttuale: 217
      },
      {
        nome: "FUSTINO PASTIGLIE",
        prezzo: 0.56,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1916,
        finaleTeorica: 141,
        finaleAttuale: 141
      },
      {
        nome: "PELLICOLA MT.",
        prezzo: 6.7,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 581,
        finaleTeorica: 81,
        finaleAttuale: 80
      },
      {
        nome: "Piastra nervatura Teflon",
        prezzo: 51.36,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 977,
        finaleTeorica: 11,
        finaleAttuale: 10
      },
      {
        nome: "VASSOIO ROSSO X",
        prezzo: 1.89,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1508,
        finaleTeorica: 173,
        finaleAttuale: 173
      },
      {
        nome: "ABRASIVO INOX GR.",
        prezzo: 1.45,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1991,
        finaleTeorica: 665,
        finaleAttuale: 664
      },
      {
        nome: "ACTIGEL",
        prezzo: 5.285,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 82,
        finaleTeorica: 44,
        finaleAttuale: 43
      },
      {
        nome: "ALU HANDLE",
        prezzo: 22.99,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 117,
        finaleTeorica: 35,
        finaleAttuale: 34
      },
      {
        nome: "ALU HANDLE",
        prezzo: 22.99,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1015,
        finaleTeorica: 826,
        finaleAttuale: 826
      },
      {
        nome: "ALU HANDLE",
        prezzo: 22.99,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 242,
        finaleTeorica: 169,
        finaleAttuale: 168
      },
      {
        nome: "ALU HANDLE",
        prezzo: 22.99,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1196,
        finaleTeorica: 690,
        finaleAttuale: 690
      },
      {
        nome: "APPENDITUTTO",
        prezzo: 16.2,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 872,
        finaleTeorica: 2,
        finaleAttuale: 2
      },
      {
        nome: "AROMIST AIR FRESHENING QSR",
        prezzo: 10.87,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1884,
        finaleTeorica: 605,
        finaleAttuale: 605
      },
      {
        nome: "AROMIST DISPENSER QSR",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 654,
        finaleTeorica: 432,
        finaleAttuale: 431
      },
      {
        nome: "ASCIUGAMANO PIEGATO A V M",
        prezzo: 21.43,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1541,
        finaleTeorica: 1171,
        finaleAttuale: 1171
      },
      {
        nome: "BICCHIERE PP",
        prezzo: 0.016,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 532,
        finaleTeorica: 230,
        finaleAttuale: 229
      },
      {
        nome: "Bioshield Beverage Tower Drain QSR",
        prezzo: 3.833,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1503,
        finaleTeorica: 1081,
        finaleAttuale: 1081
      },
      {
        nome: "BOBINA L",
        prezzo: 16.43,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 877,
        finaleTeorica: 576,
        finaleAttuale: 576
      },
      {
        nome: "BOBINA L",
        prezzo: 9.573,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1043,
        finaleTeorica: 423,
        finaleAttuale: 423
      },
      {
        nome: "BOBINA MULTIUSO",
        prezzo: 6.035,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1536,
        finaleTeorica: 534,
        finaleAttuale: 534
      },
      {
        nome: "BOBINA MULTIUSO SMART M",
        prezzo: 4.62,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1550,
        finaleTeorica: 6,
        finaleAttuale: 6
      },
      {
        nome: "BROOM HD",
        prezzo: 33.22,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 594,
        finaleTeorica: 141,
        finaleAttuale: 140
      },
      {
        nome: "BROOM ST",
        prezzo: 20.04,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1418,
        finaleTeorica: 701,
        finaleAttuale: 700
      },
      {
        nome: "BROOM ST",
        prezzo: 20.04,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 528,
        finaleTeorica: 279,
        finaleAttuale: 279
      },
      {
        nome: "BROOM ST",
        prezzo: 20.04,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 791,
        finaleTeorica: 616,
        finaleAttuale: 616
      },
      {
        nome: "C.IGIENICA MAXI JUMBO M",
        prezzo: 3.43,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1498,
        finaleTeorica: 38,
        finaleAttuale: 37
      },
      {
        nome: "CAPITAL NCSE",
        prezzo: 28.49,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1887,
        finaleTeorica: 1643,
        finaleAttuale: 1645
      },
      {
        nome: "CARRELLO FRED LT.",
        prezzo: 71.3,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 954,
        finaleTeorica: 43,
        finaleAttuale: 42
      },
      {
        nome: "CARTA FORNO",
        prezzo: 0.144,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1226,
        finaleTeorica: 152,
        finaleAttuale: 151
      },
      {
        nome: "CARTA FORNO IN FOGLI M",
        prezzo: 0.041,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1371,
        finaleTeorica: 310,
        finaleAttuale: 309
      },
      {
        nome: "CARTA IGIENICA",
        prezzo: 0.446,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 397,
        finaleTeorica: 215,
        finaleAttuale: 215
      },
      {
        nome: "CARTA IGIENICA MAXI M",
        prezzo: 3.412,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 327,
        finaleTeorica: 214,
        finaleAttuale: 214
      },
      {
        nome: "CARTA IGIENICA MAXI M",
        prezzo: 2.45,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1559,
        finaleTeorica: 323,
        finaleAttuale: 322
      },
      {
        nome: "CHLORINE TEST STRIPS HE",
        prezzo: 4.355,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1300,
        finaleTeorica: 1042,
        finaleAttuale: 1042
      },
      {
        nome: "Clax Essence",
        prezzo: 2.688,
        UM: "KG",
        categoria: "BEVANDE",
        quantitaPrima: 1804,
        finaleTeorica: 1158,
        finaleAttuale: 1157
      },
      {
        nome: "Clax Pura",
        prezzo: 45.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 134,
        finaleTeorica: 88,
        finaleAttuale: 88
      },
      {
        nome: "CLAX TABS",
        prezzo: 0.19,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1279,
        finaleTeorica: 370,
        finaleAttuale: 370
      },
      {
        nome: "CLAX TABS",
        prezzo: 0.519,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 131,
        finaleTeorica: 94,
        finaleAttuale: 93
      },
      {
        nome: "Clear Dry Classic HE",
        prezzo: 35.655,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1557,
        finaleTeorica: 1282,
        finaleAttuale: 1283
      },
      {
        nome: "COPPIA MORSETTI X",
        prezzo: 3.8,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 445,
        finaleTeorica: 86,
        finaleAttuale: 85
      },
      {
        nome: "COPRIBARBA TNT COLORE M",
        prezzo: 0.096,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 795,
        finaleTeorica: 565,
        finaleAttuale: 564
      },
      {
        nome: "CUFFIA TNT BIANCA M",
        prezzo: 0.104,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1174,
        finaleTeorica: 548,
        finaleAttuale: 548
      },
      {
        nome: "CUFFIA TNT BIANCA MIS. L PCD",
        prezzo: 0.016,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 610,
        finaleTeorica: 605,
        finaleAttuale: 605
      },
      {
        nome: "CUFFIA TNT BIANCA MIS.L. - PCD",
        prezzo: 0.03,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 542,
        finaleTeorica: 375,
        finaleAttuale: 374
      },
      {
        nome: "Dazzle Cleaner & Polish",
        prezzo: 2.758,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1109,
        finaleTeorica: 941,
        finaleAttuale: 940
      },
      {
        nome: "DESGUARD",
        prezzo: 16.38,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1116,
        finaleTeorica: 779,
        finaleAttuale: 778
      },
      {
        nome: "DI Quat Chek Test Kit",
        prezzo: 10.37,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 858,
        finaleTeorica: 800,
        finaleAttuale: 799
      },
      {
        nome: "DISPENSER \"LEVITA\"",
        prezzo: 21.2,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1448,
        finaleTeorica: 1311,
        finaleAttuale: 1309
      },
      {
        nome: "DISPENSER AQUARIUS ROLL M",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1632,
        finaleTeorica: 1524,
        finaleAttuale: 1523
      },
      {
        nome: "DISPENSER AQUARIUS ROLL M",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1375,
        finaleTeorica: 1300,
        finaleAttuale: 1298
      },
      {
        nome: "DISPENSER CON SENSORE X M",
        prezzo: 51.75,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1725,
        finaleTeorica: 1536,
        finaleAttuale: 1534
      },
      {
        nome: "DISPENSER MAGRIS CARTA M",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1176,
        finaleTeorica: 855,
        finaleAttuale: 856
      },
      {
        nome: "DISPENSER MAGRIS CARTA M",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1362,
        finaleTeorica: 354,
        finaleAttuale: 353
      },
      {
        nome: "DISPENSER MAGRIS PER M",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 487,
        finaleTeorica: 279,
        finaleAttuale: 279
      },
      {
        nome: "DISPENSER PURELL LTX-",
        prezzo: 53.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1254,
        finaleTeorica: 163,
        finaleAttuale: 162
      },
      {
        nome: "Distribuore rotolo aspatura",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1759,
        finaleTeorica: 1744,
        finaleAttuale: 1742
      },
      {
        nome: "DISTRIBUTORE BIANCO FIAUJOL PZ",
        prezzo: 169.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 961,
        finaleTeorica: 312,
        finaleAttuale: 311
      },
      {
        nome: "DISTRIBUTORE MAGRIS M",
        prezzo: 29.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 574,
        finaleTeorica: 78,
        finaleAttuale: 78
      },
      {
        nome: "DUSTPAN SET B&S -",
        prezzo: 47.04,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1862,
        finaleTeorica: 883,
        finaleAttuale: 882
      },
      {
        nome: "ECOLAB HI-TEMP PAD QSR",
        prezzo: 2.23,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1379,
        finaleTeorica: 900,
        finaleAttuale: 900
      },
      {
        nome: "ECOLAB HI-TEMP TOOL",
        prezzo: 38.85,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 560,
        finaleTeorica: 445,
        finaleAttuale: 445
      },
      {
        nome: "EPICARE",
        prezzo: 6.58,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 666,
        finaleTeorica: 404,
        finaleAttuale: 404
      },
      {
        nome: "EPICARE",
        prezzo: 33.6,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1742,
        finaleTeorica: 1278,
        finaleAttuale: 1276
      },
      {
        nome: "Esteem Dry All Special",
        prezzo: 35.655,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1555,
        finaleTeorica: 339,
        finaleAttuale: 338
      },
      {
        nome: "Esteem Special Warewashing QSR",
        prezzo: 16.2,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 379,
        finaleTeorica: 227,
        finaleAttuale: 227
      },
      {
        nome: "FIBRA FORTE S.B.",
        prezzo: 1.54,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1350,
        finaleTeorica: 50,
        finaleAttuale: 49
      },
      {
        nome: "FIOCCO MOP MICROFIBRA M",
        prezzo: 7.07,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1258,
        finaleTeorica: 474,
        finaleAttuale: 473
      },
      {
        nome: "Flaconi D",
        prezzo: 1.728,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 560,
        finaleTeorica: 368,
        finaleAttuale: 367
      },
      {
        nome: "Flaconi Neutri",
        prezzo: 1.167,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1112,
        finaleTeorica: 648,
        finaleAttuale: 648
      },
      {
        nome: "FLACONI SERIGRAFATI E HE",
        prezzo: 3.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 567,
        finaleTeorica: 269,
        finaleAttuale: 269
      },
      {
        nome: "FLOOR SQUEEGEE",
        prezzo: 23.76,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 433,
        finaleTeorica: 416,
        finaleAttuale: 415
      },
      {
        nome: "FLOOR SQUEEGEE",
        prezzo: 23.76,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 85,
        finaleTeorica: 38,
        finaleAttuale: 37
      },
      {
        nome: "FLOOR SQUEEGEE",
        prezzo: 23.76,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 908,
        finaleTeorica: 804,
        finaleAttuale: 803
      },
      {
        nome: "FS REPLACEMENT M",
        prezzo: 11.88,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1848,
        finaleTeorica: 549,
        finaleAttuale: 549
      },
      {
        nome: "GEL DISINFETTANTE MANI GJ",
        prezzo: 17.333,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 726,
        finaleTeorica: 233,
        finaleAttuale: 232
      },
      {
        nome: "Good Sense Crusair Refill",
        prezzo: 2.125,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1502,
        finaleTeorica: 187,
        finaleAttuale: 186
      },
      {
        nome: "GOOD SENSE FRESH",
        prezzo: 3.318,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 597,
        finaleTeorica: 364,
        finaleAttuale: 363
      },
      {
        nome: "GREMBIULE MOMOUSO M",
        prezzo: 0.05,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 349,
        finaleTeorica: 55,
        finaleAttuale: 55
      },
      {
        nome: "GREMBIULE MONOUSO BLU M",
        prezzo: 0.156,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 801,
        finaleTeorica: 569,
        finaleAttuale: 569
      },
      {
        nome: "GREMBIULE MONOUSO BLU M",
        prezzo: 0.07,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 331,
        finaleTeorica: 107,
        finaleAttuale: 106
      },
      {
        nome: "GSS dispenser",
        prezzo: 6.54,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1137,
        finaleTeorica: 967,
        finaleAttuale: 967
      },
      {
        nome: "GUANTI BICOLORE MIS.L M",
        prezzo: 0.125,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1962,
        finaleTeorica: 1271,
        finaleAttuale: 1270
      },
      {
        nome: "GUANTI BICOLORE MIS.M M",
        prezzo: 0.125,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1048,
        finaleTeorica: 799,
        finaleAttuale: 797
      },
      {
        nome: "GUANTI BICOLORE MIS.S M",
        prezzo: 0.125,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1638,
        finaleTeorica: 66,
        finaleAttuale: 65
      },
      {
        nome: "GUANTI BICOLORE MIS.XL M",
        prezzo: 0.125,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 747,
        finaleTeorica: 31,
        finaleAttuale: 30
      },
      {
        nome: "GUANTI BLU X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 290,
        finaleTeorica: 113,
        finaleAttuale: 112
      },
      {
        nome: "GUANTI IN NITRILE SENZA M",
        prezzo: 0.038,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1347,
        finaleTeorica: 291,
        finaleAttuale: 290
      },
      {
        nome: "GUANTI IN NITRILE SENZA M",
        prezzo: 0.075,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1878,
        finaleTeorica: 943,
        finaleAttuale: 942
      },
      {
        nome: "GUANTI IN NITRILE SENZA M",
        prezzo: 0.038,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1191,
        finaleTeorica: 537,
        finaleAttuale: 536
      },
      {
        nome: "GUANTI IN NITRILE SENZA M",
        prezzo: 0.075,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 126,
        finaleTeorica: 25,
        finaleAttuale: 25
      },
      {
        nome: "GUANTI IN TPE BLU TAGLIA",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 449,
        finaleTeorica: 423,
        finaleAttuale: 423
      },
      {
        nome: "GUANTI IN TPE BLU TAGLIA",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1404,
        finaleTeorica: 1392,
        finaleAttuale: 1391
      },
      {
        nome: "GUANTI IN VINILE BLU",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 720,
        finaleTeorica: 118,
        finaleAttuale: 117
      },
      {
        nome: "GUANTI IN VINILE BLU",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 192,
        finaleTeorica: 51,
        finaleAttuale: 50
      },
      {
        nome: "GUANTI IN VINILE SENZA M",
        prezzo: 0.045,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 253,
        finaleTeorica: 151,
        finaleAttuale: 151
      },
      {
        nome: "GUANTI IN VINILE SENZA M",
        prezzo: 0.045,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 892,
        finaleTeorica: 511,
        finaleAttuale: 511
      },
      {
        nome: "GUANTI IN VINILE SENZA M",
        prezzo: 0.045,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1733,
        finaleTeorica: 116,
        finaleAttuale: 116
      },
      {
        nome: "GUANTI IN VINILE SENZA M",
        prezzo: 0.045,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1485,
        finaleTeorica: 726,
        finaleAttuale: 725
      },
      {
        nome: "GUANTI NITRILE LEG. MIS.L M",
        prezzo: 1.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1781,
        finaleTeorica: 1290,
        finaleAttuale: 1290
      },
      {
        nome: "GUANTI NITRILE LEG. MIS.M M",
        prezzo: 1.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1372,
        finaleTeorica: 533,
        finaleAttuale: 532
      },
      {
        nome: "GUANTI NITRILE LEG. MIS.S M",
        prezzo: 2.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 58,
        finaleTeorica: 44,
        finaleAttuale: 43
      },
      {
        nome: "GUANTI NITRILE LEG. M",
        prezzo: 1.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1835,
        finaleTeorica: 261,
        finaleAttuale: 261
      },
      {
        nome: "GUANTI NITRILE LEG. M",
        prezzo: 1.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1776,
        finaleTeorica: 971,
        finaleAttuale: 972
      },
      {
        nome: "GUANTI POLIETILENE ICHDPE PZ",
        prezzo: 0.55,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 844,
        finaleTeorica: 835,
        finaleAttuale: 836
      },
      {
        nome: "GUANTI UNIVERSAL M",
        prezzo: 1.2,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1921,
        finaleTeorica: 1902,
        finaleAttuale: 1904
      },
      {
        nome: "GUANTI UNIVERSAL M",
        prezzo: 1.2,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 551,
        finaleTeorica: 154,
        finaleAttuale: 153
      },
      {
        nome: "GUANTI UNIVERSAL M",
        prezzo: 1.2,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 757,
        finaleTeorica: 231,
        finaleAttuale: 230
      },
      {
        nome: "GUANTI UNIVERSAL M",
        prezzo: 1.2,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 645,
        finaleTeorica: 171,
        finaleAttuale: 170
      },
      {
        nome: "HERO GEL NF LT.",
        prezzo: 26.47,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1596,
        finaleTeorica: 467,
        finaleAttuale: 466
      },
      {
        nome: "HI-LO DECK SCRUB",
        prezzo: 17.3,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 531,
        finaleTeorica: 137,
        finaleAttuale: 136
      },
      {
        nome: "HI-LO DECK SCRUB",
        prezzo: 18.675,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1414,
        finaleTeorica: 519,
        finaleAttuale: 518
      },
      {
        nome: "HI-LO DECK SCRUB",
        prezzo: 17.3,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1419,
        finaleTeorica: 907,
        finaleAttuale: 906
      },
      {
        nome: "Hydrion Micro Chlorine",
        prezzo: 10.374,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1702,
        finaleTeorica: 742,
        finaleAttuale: 741
      },
      {
        nome: "HYGI GEL IGIENIZZANTE ARCOHY PZ",
        prezzo: 37.9,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1673,
        finaleTeorica: 1279,
        finaleAttuale: 1279
      },
      {
        nome: "HYGI OXY IGIENIZZANTE ARCOHY PZ",
        prezzo: 37.9,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 451,
        finaleTeorica: 324,
        finaleAttuale: 324
      },
      {
        nome: "Jon Activ",
        prezzo: 12.39,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 872,
        finaleTeorica: 842,
        finaleAttuale: 841
      },
      {
        nome: "Kay Bun Toaster Release Agent QSR",
        prezzo: 7.875,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1117,
        finaleTeorica: 247,
        finaleAttuale: 246
      },
      {
        nome: "Kay Delimer QSR",
        prezzo: 0.445,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1208,
        finaleTeorica: 112,
        finaleAttuale: 112
      },
      {
        nome: "Kay Easy Wash Plus QSR",
        prezzo: 0.511,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1290,
        finaleTeorica: 1097,
        finaleAttuale: 1095
      },
      {
        nome: "Kay Grill Cleaning Pad Holder",
        prezzo: 15.09,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1318,
        finaleTeorica: 323,
        finaleAttuale: 323
      },
      {
        nome: "Kay Grill Cleaning PASTIGLIE",
        prezzo: 1.422,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 158,
        finaleTeorica: 117,
        finaleAttuale: 117
      },
      {
        nome: "Kay Heavy Duty Degreaser QSR",
        prezzo: 6.5,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 226,
        finaleTeorica: 175,
        finaleAttuale: 175
      },
      {
        nome: "KAY LOTION HANDWASH QRS",
        prezzo: 9.363,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1862,
        finaleTeorica: 1068,
        finaleAttuale: 1066
      },
      {
        nome: "Kay Multi Surface & Glass QSR",
        prezzo: 8.21,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 261,
        finaleTeorica: 245,
        finaleAttuale: 245
      },
      {
        nome: "Kay No-Thaw FreezerCooler QSR",
        prezzo: 33.625,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 465,
        finaleTeorica: 443,
        finaleAttuale: 443
      },
      {
        nome: "KAY RESTROOM CLEANER QSR",
        prezzo: 7.417,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1293,
        finaleTeorica: 1223,
        finaleAttuale: 1221
      },
      {
        nome: "Kay Sink Sanitizer QSR",
        prezzo: 0.191,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1106,
        finaleTeorica: 707,
        finaleAttuale: 706
      },
      {
        nome: "Kay SolidSense Floorcare A&B QSR",
        prezzo: 18.773,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 207,
        finaleTeorica: 102,
        finaleAttuale: 102
      },
      {
        nome: "KAY SPECIALTY CLEANER & QSR",
        prezzo: 3.645,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 762,
        finaleTeorica: 227,
        finaleAttuale: 226
      },
      {
        nome: "KAY SUPER CONTACT QSR",
        prezzo: 7.875,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 476,
        finaleTeorica: 262,
        finaleAttuale: 261
      },
      {
        nome: "Kay-",
        prezzo: 0.187,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 661,
        finaleTeorica: 165,
        finaleAttuale: 164
      },
      {
        nome: "KIT BOTTIGLIE D",
        prezzo: 6.755,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 421,
        finaleTeorica: 143,
        finaleAttuale: 143
      },
      {
        nome: "Lancia schiumogena per D",
        prezzo: 11.59,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 600,
        finaleTeorica: 77,
        finaleAttuale: 76
      },
      {
        nome: "Lancia schiumogena per Suma",
        prezzo: 29.89,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 327,
        finaleTeorica: 147,
        finaleAttuale: 146
      },
      {
        nome: "MANICO ALLUMINIO M",
        prezzo: 3.79,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 308,
        finaleTeorica: 204,
        finaleAttuale: 203
      },
      {
        nome: "MANICO ALLUMINIO M",
        prezzo: 3.85,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1910,
        finaleTeorica: 749,
        finaleAttuale: 748
      },
      {
        nome: "MASCHERINA AZZURRA M",
        prezzo: 0.13,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 798,
        finaleTeorica: 566,
        finaleAttuale: 566
      },
      {
        nome: "MASCHERINA FILTR.FFP",
        prezzo: 0.7,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1197,
        finaleTeorica: 1093,
        finaleAttuale: 1091
      },
      {
        nome: "MASCHERINA IN CARTA PCN",
        prezzo: 0.014,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 679,
        finaleTeorica: 332,
        finaleAttuale: 332
      },
      {
        nome: "MASCHERINE CHIRURGICHE",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1335,
        finaleTeorica: 743,
        finaleAttuale: 743
      },
      {
        nome: "NAIL BRUSH R -",
        prezzo: 4.47,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1965,
        finaleTeorica: 903,
        finaleAttuale: 902
      },
      {
        nome: "PANNO MICROTUFF PLUS M",
        prezzo: 3.102,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1328,
        finaleTeorica: 447,
        finaleAttuale: 446
      },
      {
        nome: "PANNO MICROTUFF PLUS M",
        prezzo: 3.102,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1207,
        finaleTeorica: 965,
        finaleAttuale: 965
      },
      {
        nome: "PANNO MICROTUFF PLUS M",
        prezzo: 3.102,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1915,
        finaleTeorica: 1305,
        finaleAttuale: 1303
      },
      {
        nome: "PANNO MICROTUFF PLUS M",
        prezzo: 3.102,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 132,
        finaleTeorica: 2,
        finaleAttuale: 2
      },
      {
        nome: "PANNO SPEEDY MICRO- M",
        prezzo: 4.8,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1251,
        finaleTeorica: 832,
        finaleAttuale: 831
      },
      {
        nome: "PIANTANA MAGRIS X M",
        prezzo: 42.5,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1192,
        finaleTeorica: 82,
        finaleAttuale: 82
      },
      {
        nome: "PINZA PLASTICA GIGANTE M",
        prezzo: 1.94,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 229,
        finaleTeorica: 110,
        finaleAttuale: 110
      },
      {
        nome: "PORTAROTOLO M",
        prezzo: 35.35,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 619,
        finaleTeorica: 126,
        finaleAttuale: 125
      },
      {
        nome: "Portatampone Pad Holder with",
        prezzo: 11.045,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 817,
        finaleTeorica: 602,
        finaleAttuale: 601
      },
      {
        nome: "PUREHAND EL",
        prezzo: 3.29,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1298,
        finaleTeorica: 917,
        finaleAttuale: 915
      },
      {
        nome: "QUAT TEST STRIPS QSR",
        prezzo: 6.49,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 773,
        finaleTeorica: 54,
        finaleAttuale: 53
      },
      {
        nome: "RED ROUND HAND SCRUB M",
        prezzo: 14.66,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 799,
        finaleTeorica: 571,
        finaleAttuale: 571
      },
      {
        nome: "RIC.FRANGIA SPEEDY BLU M",
        prezzo: 4.8,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 265,
        finaleTeorica: 73,
        finaleAttuale: 72
      },
      {
        nome: "RIC.FRANGIA SPEEDY M",
        prezzo: 4.8,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 700,
        finaleTeorica: 598,
        finaleAttuale: 597
      },
      {
        nome: "RIC.FRANGIA SPEEDY ROSSO M",
        prezzo: 4.8,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1240,
        finaleTeorica: 448,
        finaleAttuale: 448
      },
      {
        nome: "RIC.FRANGIA SPEEDY M",
        prezzo: 4.8,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1282,
        finaleTeorica: 353,
        finaleAttuale: 352
      },
      {
        nome: "RICAMBIO SPEEDY M",
        prezzo: 4.8,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 261,
        finaleTeorica: 16,
        finaleAttuale: 15
      },
      {
        nome: "Room Care R",
        prezzo: 3.337,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1161,
        finaleTeorica: 1013,
        finaleAttuale: 1014
      },
      {
        nome: "SACCHETTI X PALLINE M",
        prezzo: 0.113,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1115,
        finaleTeorica: 201,
        finaleAttuale: 200
      },
      {
        nome: "SACCHI BIANCHI",
        prezzo: 0.154,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 175,
        finaleTeorica: 157,
        finaleAttuale: 157
      },
      {
        nome: "SACCHI GIALLI",
        prezzo: 0.008,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 547,
        finaleTeorica: 22,
        finaleAttuale: 21
      },
      {
        nome: "SACCHI GIALLI TRASP M",
        prezzo: 0.164,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 888,
        finaleTeorica: 414,
        finaleAttuale: 413
      },
      {
        nome: "SACCHI IMMONDIZIA",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 704,
        finaleTeorica: 692,
        finaleAttuale: 692
      },
      {
        nome: "SACCHI IMMONDIZIA",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 522,
        finaleTeorica: 114,
        finaleAttuale: 113
      },
      {
        nome: "SACCHI MATER-BI",
        prezzo: 0.26,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1399,
        finaleTeorica: 1264,
        finaleAttuale: 1264
      },
      {
        nome: "SACCHI MATER-BI",
        prezzo: 0.397,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 981,
        finaleTeorica: 407,
        finaleAttuale: 407
      },
      {
        nome: "SACCHI MATER-BI M",
        prezzo: 0.55,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 952,
        finaleTeorica: 122,
        finaleAttuale: 122
      },
      {
        nome: "SACCHI NERI",
        prezzo: 0.023,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 510,
        finaleTeorica: 375,
        finaleAttuale: 374
      },
      {
        nome: "SACCHI NERI",
        prezzo: 0.106,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1512,
        finaleTeorica: 1090,
        finaleAttuale: 1090
      },
      {
        nome: "SACCHI NERI",
        prezzo: 0.208,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1883,
        finaleTeorica: 1758,
        finaleAttuale: 1758
      },
      {
        nome: "SACCHI NERI",
        prezzo: 0.252,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1407,
        finaleTeorica: 947,
        finaleAttuale: 947
      },
      {
        nome: "Sacchi spazzatura",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1574,
        finaleTeorica: 342,
        finaleAttuale: 341
      },
      {
        nome: "SACCHI TRASPARENTI",
        prezzo: 0.03,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 925,
        finaleTeorica: 255,
        finaleAttuale: 255
      },
      {
        nome: "SACCHI TRASPARENTI M",
        prezzo: 0.099,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 376,
        finaleTeorica: 109,
        finaleAttuale: 108
      },
      {
        nome: "SACCHI TRASPARENTI M",
        prezzo: 0.095,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1748,
        finaleTeorica: 847,
        finaleAttuale: 846
      },
      {
        nome: "SACCHI TRASPARENTI M",
        prezzo: 0.241,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 855,
        finaleTeorica: 835,
        finaleAttuale: 835
      },
      {
        nome: "SACCHI VERDI",
        prezzo: 0.007,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 405,
        finaleTeorica: 195,
        finaleAttuale: 195
      },
      {
        nome: "SIZZLE PLUS QSR",
        prezzo: 9.04,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1664,
        finaleTeorica: 1212,
        finaleAttuale: 1212
      },
      {
        nome: "SN SANIGEL",
        prezzo: 37.9,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 286,
        finaleTeorica: 217,
        finaleAttuale: 217
      },
      {
        nome: "Soft care Des E H",
        prezzo: 18.63,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1552,
        finaleTeorica: 862,
        finaleAttuale: 862
      },
      {
        nome: "Soft Care Plus H",
        prezzo: 11.58,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 288,
        finaleTeorica: 112,
        finaleAttuale: 111
      },
      {
        nome: "Soft Care Star H",
        prezzo: 21.95,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1350,
        finaleTeorica: 706,
        finaleAttuale: 706
      },
      {
        nome: "SPRAYER PRESSURE",
        prezzo: 131.5,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 891,
        finaleTeorica: 107,
        finaleAttuale: 106
      },
      {
        nome: "STRIZZATORE REGULAR M",
        prezzo: 31.8,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1920,
        finaleTeorica: 127,
        finaleAttuale: 127
      },
      {
        nome: "Suma Anti-Stick AS",
        prezzo: 5.123,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1663,
        finaleTeorica: 196,
        finaleAttuale: 195
      },
      {
        nome: "Suma Break Up D",
        prezzo: 18.63,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1755,
        finaleTeorica: 1366,
        finaleAttuale: 1365
      },
      {
        nome: "Suma D",
        prezzo: 14.5,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1526,
        finaleTeorica: 458,
        finaleAttuale: 458
      },
      {
        nome: "Suma Degreaser Pur-Eco D",
        prezzo: 28.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1755,
        finaleTeorica: 150,
        finaleAttuale: 149
      },
      {
        nome: "Suma Delime EP D",
        prezzo: 0.583,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1250,
        finaleTeorica: 122,
        finaleAttuale: 122
      },
      {
        nome: "Suma Freeze D",
        prezzo: 31.631,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 463,
        finaleTeorica: 393,
        finaleAttuale: 393
      },
      {
        nome: "Suma Inox D",
        prezzo: 5.014,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 186,
        finaleTeorica: 75,
        finaleAttuale: 75
      },
      {
        nome: "Suma Jade Pur-Eco L",
        prezzo: 27.67,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 607,
        finaleTeorica: 445,
        finaleAttuale: 445
      },
      {
        nome: "SUMA MAX D",
        prezzo: 9.645,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1936,
        finaleTeorica: 860,
        finaleAttuale: 860
      },
      {
        nome: "Suma Multipurpose Cl.D",
        prezzo: 19.92,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1682,
        finaleTeorica: 901,
        finaleAttuale: 900
      },
      {
        nome: "Suma Nova Pur-Eco L",
        prezzo: 7.2,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1481,
        finaleTeorica: 60,
        finaleAttuale: 60
      },
      {
        nome: "Suma Rinse A",
        prezzo: 14.525,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 596,
        finaleTeorica: 429,
        finaleAttuale: 428
      },
      {
        nome: "Suma Star D",
        prezzo: 10.972,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 214,
        finaleTeorica: 36,
        finaleAttuale: 35
      },
      {
        nome: "Suma Tab D",
        prezzo: 0.041,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 352,
        finaleTeorica: 37,
        finaleAttuale: 36
      },
      {
        nome: "SURE Antibac Hand WashF.",
        prezzo: 11.41,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 631,
        finaleTeorica: 267,
        finaleAttuale: 266
      },
      {
        nome: "Tampone High Temp Sandwich F",
        prezzo: 1.892,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 74,
        finaleTeorica: 70,
        finaleAttuale: 69
      },
      {
        nome: "TASKI Jontec",
        prezzo: 63.26,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1272,
        finaleTeorica: 872,
        finaleAttuale: 872
      },
      {
        nome: "Taski Jontec Forward conc DVFL",
        prezzo: 7.472,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 875,
        finaleTeorica: 714,
        finaleAttuale: 714
      },
      {
        nome: "TELAIO SPEEDY M",
        prezzo: 13.1,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 777,
        finaleTeorica: 125,
        finaleAttuale: 124
      },
      {
        nome: "Tork Xpressnap Dispenser da",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1151,
        finaleTeorica: 1066,
        finaleAttuale: 1066
      },
      {
        nome: "Tork Xpressnap Dispenser da",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1265,
        finaleTeorica: 979,
        finaleAttuale: 978
      },
      {
        nome: "Tork Xpressnap Dispenser da",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1949,
        finaleTeorica: 608,
        finaleAttuale: 607
      },
      {
        nome: "Tork Xpressnap Tovagliolo",
        prezzo: 0.005,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 999,
        finaleTeorica: 878,
        finaleAttuale: 876
      },
      {
        nome: "TRUMP XL SPECIAL",
        prezzo: 8.047,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 317,
        finaleTeorica: 298,
        finaleAttuale: 298
      },
      {
        nome: "VASSOIO GJ",
        prezzo: 2.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 149,
        finaleTeorica: 18,
        finaleAttuale: 17
      },
      {
        nome: "VK HI-LO DECK BRUSH G - M",
        prezzo: 25.88,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 962,
        finaleTeorica: 148,
        finaleAttuale: 147
      },
      {
        nome: "VK WALL BRACKET GRIP M",
        prezzo: 24.77,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1050,
        finaleTeorica: 626,
        finaleAttuale: 626
      },
      {
        nome: "VK WALL BRACKET GRIP M",
        prezzo: 24.77,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1695,
        finaleTeorica: 1400,
        finaleAttuale: 1399
      },
      {
        nome: "WALL BRACKET",
        prezzo: 8.34,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 929,
        finaleTeorica: 471,
        finaleAttuale: 470
      },
      {
        nome: "WALL BRACKET",
        prezzo: 8.34,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1042,
        finaleTeorica: 765,
        finaleAttuale: 765
      },
      {
        nome: "WALL BRACKET",
        prezzo: 8.34,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1549,
        finaleTeorica: 1004,
        finaleAttuale: 1002
      },
      {
        nome: "WALL BRACKET",
        prezzo: 8.34,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 835,
        finaleTeorica: 415,
        finaleAttuale: 415
      },
      {
        nome: "WALL BRACKET",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 164,
        finaleTeorica: 109,
        finaleAttuale: 109
      },
      {
        nome: "WALL BRACKET",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 208,
        finaleTeorica: 96,
        finaleAttuale: 95
      },
      {
        nome: "WALL BRACKET RUBBER M",
        prezzo: 22.98,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1626,
        finaleTeorica: 1522,
        finaleAttuale: 1521
      },
      {
        nome: "WYPALL L",
        prezzo: 8.333,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1455,
        finaleTeorica: 1020,
        finaleAttuale: 1019
      },
      {
        nome: "WYPALL L",
        prezzo: 8.333,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1369,
        finaleTeorica: 1302,
        finaleAttuale: 1300
      },
      {
        nome: "BADGES ROSSI X",
        prezzo: 0.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1013,
        finaleTeorica: 42,
        finaleAttuale: 42
      },
      {
        nome: " Black Bonded Fleece Jacket KFE",
        prezzo: 17.61,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 492,
        finaleTeorica: 312,
        finaleAttuale: 311
      },
      {
        nome: " Ladies Black Jeans KFE",
        prezzo: 11.67,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 411,
        finaleTeorica: 393,
        finaleAttuale: 393
      },
      {
        nome: " Ladies LS Hero Chicken T- KFE",
        prezzo: 6.22,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 197,
        finaleTeorica: 69,
        finaleAttuale: 69
      },
      {
        nome: " Ladies SS Hero Chicken T- KFE",
        prezzo: 5.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1106,
        finaleTeorica: 812,
        finaleAttuale: 810
      },
      {
        nome: " Manager Blouse KFE",
        prezzo: 10.37,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 915,
        finaleTeorica: 764,
        finaleAttuale: 764
      },
      {
        nome: " Manager Shirt KFE",
        prezzo: 10.39,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1695,
        finaleTeorica: 593,
        finaleAttuale: 592
      },
      {
        nome: " Mens Black Jeans KFE",
        prezzo: 11.81,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1219,
        finaleTeorica: 33,
        finaleAttuale: 33
      },
      {
        nome: " Mens LS Hero Chicken T- KFE",
        prezzo: 6.27,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 313,
        finaleTeorica: 109,
        finaleAttuale: 108
      },
      {
        nome: " Mens SS Hero Chicken T- KFE",
        prezzo: 5.05,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 945,
        finaleTeorica: 642,
        finaleAttuale: 642
      },
      {
        nome: "E Black Baseball Cap KFE",
        prezzo: 2.65,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1725,
        finaleTeorica: 710,
        finaleAttuale: 710
      },
      {
        nome: "E Chefs Black Jacket KFE",
        prezzo: 12.83,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 266,
        finaleTeorica: 69,
        finaleAttuale: 68
      },
      {
        nome: "E Chefs Check Trouser KFE",
        prezzo: 10.59,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1988,
        finaleTeorica: 1433,
        finaleAttuale: 1431
      },
      {
        nome: "E Denim Apron KFE",
        prezzo: 6.95,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 778,
        finaleTeorica: 136,
        finaleAttuale: 135
      },
      {
        nome: "E Host Shirt KFE",
        prezzo: 17.39,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 758,
        finaleTeorica: 318,
        finaleAttuale: 318
      },
      {
        nome: "E Hostess Blouse KFE",
        prezzo: 17.37,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1075,
        finaleTeorica: 808,
        finaleAttuale: 807
      },
      {
        nome: "ELadies SS Zebra Chicken KFE",
        prezzo: 5.0,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 996,
        finaleTeorica: 104,
        finaleAttuale: 104
      },
      {
        nome: "EMens SS Zebra Chicken T- KFE",
        prezzo: 5.05,
        UM: "PZ",
        categoria: "BEVANDE",
        quantitaPrima: 1686,
        finaleTeorica: 484,
        finaleAttuale: 483
      }
    ]

    this._alldata.forEach(piatto => {
      piatto['variazioneUnits'] = piatto['finaleAttuale'] - piatto['finaleTeorica']
      piatto['variazioneCost'] = piatto['variazioneUnits'] * piatto['prezzo']

      piatto['spesaProdotto'] = ((piatto['quantitaPrima'] - piatto['finaleAttuale']) * piatto['prezzo']).toFixed(2)

      piatto['valoreMagazzino'] = ((piatto['finaleAttuale']) * piatto['prezzo']).toFixed(2)


      piatto['variazionePercentuale'] = ((piatto['variazioneCost']*100)/ +piatto['spesaProdotto']).toFixed(3) == 'NaN'? '0,000': ((piatto['variazioneCost']*100)/ +piatto['spesaProdotto']).toFixed(3)

    })

    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);

    console.log("datasource: ", this.dataSource)
  }

  stampRow(row)
  {

    console.log("row: ", this.dataSource.filteredData.filter(data => data.categoria == row).filter(data => !data.hasOwnProperty('parent')))
  }

  sumCostoProdotto(row)
  {
    let initialValue = 0;

    if(row == 'Risultati')
    {
      if (this.filterValue == '') {
        return (this.dataSource.data.filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.spesaProdotto,
          initialValue,
        ).toFixed(2)).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`)
      } else {
        return (this.dataSource.filteredData.filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.spesaProdotto,
          initialValue,
        ).toFixed(2)).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`)
      }
    }
    else {
      if (this.filterValue == '') {
        return this.dataSource.data.filter(data => data.categoria == row).filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.spesaProdotto,
          initialValue,
        ).toFixed(2).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`);
      } else {
        return this.dataSource.filteredData.filter(data => data.categoria == row).filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.spesaProdotto,
          initialValue,
        ).toFixed(2).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`);
      }
    }
  }

  sumVariazioneProdotto(row)
  {
    let initialValue = 0;

    console.log("filterValue: ", this.filterValue)
    if(row == 'Risultati')
    {
      if (this.filterValue == '') {
        return (this.dataSource.data.filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.variazioneCost,
          initialValue,
        ).toFixed(2)).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`)
      } else {
        return (this.dataSource.filteredData.filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.variazioneCost,
          initialValue,
        ).toFixed(2)).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`)
      }
    }
    else {


      if (this.filterValue == '') {
        return this.dataSource.data.filter(data => data.categoria == row).filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.variazioneCost,
          initialValue,
        ).toFixed(2).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`);
      } else {
        return this.dataSource.filteredData.filter(data => data.categoria == row).filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.variazioneCost,
          initialValue,
        ).toFixed(2).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`);
      }
    }
  }


  sumValoreMagazzino(row)
  {
    let initialValue = 0;

    if(row == 'Risultati')
    {
      if (this.filterValue == '') {
        return (this.dataSource.data.filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.valoreMagazzino,
          initialValue,
        ).toFixed(2)).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`)
      } else {
        return (this.dataSource.filteredData.filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.valoreMagazzino,
          initialValue,
        ).toFixed(2)).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`)
      }
    }
    else {
      if (this.filterValue == '') {
        return this.dataSource.data.filter(data => data.categoria == row).filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.valoreMagazzino,
          initialValue,
        ).toFixed(2).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`);
      } else {
        return this.dataSource.filteredData.filter(data => data.categoria == row).filter(data => !data.hasOwnProperty('parent')).reduce(
          (accumulator, currentValue) => accumulator + +currentValue.valoreMagazzino,
          initialValue,
        ).toFixed(2).replace('.', ',')
          .replace(/\d(?=(?:(?:\d{3})+),)/g, match => `${ match }.`);
      }
    }
  }

  ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
    this.createChart0();
  }

  filterValue = ''
  applyFilter(filterValue: string): void {
    this.filterValue = filterValue
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {

    if(data.categoria == 'Risultati')
    {
      return true;
    }
    // @ts-ignore
    if(!(data instanceof Group) && (!isNaN(parseFloat(filter)) && !isNaN(filter - 0)))
    {
      if(!data.hasOwnProperty('visible'))
      {
        return true;
      }
      else {
        return data.visible
      }
    }
    // @ts-ignore
    if(!(data instanceof Group) && (isNaN(parseFloat(filter)) && isNaN(filter - 0)))
    {
      return this.filtraRicerca(data, filter)
    }



    // @ts-ignore
    if ((data instanceof Group) && this.filterValue == '') {
      return true;
    }
    // @ts-ignore
    if ((data instanceof Group) && this.filterValue !== '') {
      //console.log("datasource: ", this.dataSource)
      const hasVisibleChild = this.dataSource.filteredData.some(item => {
        if(!(item instanceof Group))
        {
          console.log("item: ", item)
          return (item.categoria == data.categoria)
        }
        else {
          return false;
        }
      });
      //console.log("data: ", data)
      data.visible = hasVisibleChild;
      //console.log("data: ", data)

      console.log("data: ", data)
      return hasVisibleChild;
    }


  }

  filtraRicerca(data: any, filter: any)
  {
    const searchStr = Object.keys(data).reduce((currentTerm, key) => {
      return currentTerm + (data[key] ? data[key].toString().toLowerCase() : '');
    }, '');
    return searchStr.indexOf(filter) !== -1;
  }

  groupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, true);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  unGroupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, false);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  checkGroupByColumn(field, add) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if (add) {
        this.groupByColumns.push(field);
      }
    }
  }

  groupHeaderClick(row: Group) {
    row.expanded = !row.expanded;

    // Nasconde o mostra le righe figlie basandosi sullo stato del gruppo
    this.dataSource.data.forEach(item => {
      //let parent = (item.categoria == row.categoria) && item.hasOwnProperty('parent')
      if (item == row) {
        //item.visible = row.expanded;
        if (item instanceof Group) {
          this.toggleChildGroupVisibility(item);
        }
      }
    });

    this.dataSource.filter = performance.now().toString();
  }

  toggleChildGroupVisibility(group: Group) {
    this.dataSource.data.forEach(item => {
      if (item.categoria === group.categoria) {
        if (item instanceof Group) {
        }else
        {
          item.visible = group.expanded;
        }
      }
    });
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    const result = this.getSublevel(data, 0, groupByColumns, rootGroup);
    // Imposta la visibilità iniziale solo per i gruppi principali
    result.forEach(item => {
      if (item instanceof Group && item.level === 1) {
        item.visible = true;
      }
    });
    let primaCategoria = new Group()
    primaCategoria.categoria= 'Risultati';
    primaCategoria.expanded =  false;
      primaCategoria.totalCounts= 0;
      primaCategoria.visible= true;

    result.unshift(primaCategoria)
    console.log("result: ", result)
    return result;
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map(row => {
        const result = new Group();
        result.level = level + 1;
        result.parent = parent;
        result.expanded = parent.expanded;
        result.visible = false; // Nascondi i gruppi figlio per default
        for (let i = 0; i <= level; i++) {
          result[groupByColumns[i]] = row[groupByColumns[i]];
        }
        return result;
      }),
      JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index: number, item: any): boolean {
    return item instanceof Group;
  }




  public chart: any;
  public pieChart: any = {};

  dataGrafici = {
    labels: ['Food Cost', 'Costo del lavoro', 'Costi generali'],
    datasets: [
      {
        label: 'My First Dataset',
        data: [20, 10, 3.5],
        backgroundColor: [
          '#F40027',
          '#000000',
          '#f1ebeb',
        ],
        hoverOffset: 4,
      },
    ],
  };

  config = {
    type: 'bar',
    data: [],
    options: {
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      }
    }
  };


  idPieChart = '';
  idChart = '';
  createChart0() {




    console.log("create chart")
    this.pieChart = new Chart(this.idPieChart, {
      type: 'doughnut',
      data: this.dataGrafici,
      options: {
        legend: {
          display: true,
          position: 'bottom'
        },
        cutoutPercentage: 75,
      }
    });

    this.chart = new Chart (this.idChart, {
      type: 'bar',
      data: this.dataStacked,
      options: {
        legend: {
          display: true,
          position: 'bottom'
        },
        responsive: true,
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
              color: "rgb(0,0,0)",
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              drawOnChartArea: false,
              color: "rgb(0,0,0)"
            }
          }]
        }
      }
    });
  }


  dataStacked = {
    labels: ['Food Cost', 'Costo del lavoro', 'Costi generali'],
    datasets: [
      {
        label: 'Consumo cibo',
        data: [18000, 0, 0],
        backgroundColor: '#F40027',
      },
      {
        label: 'Sprechi',
        data: [2000, 0, 0],
        backgroundColor: '#FF6666',
      },
      {
        label: 'Stipendi',
        data: [0, 7000, 0],
        backgroundColor: '#000000',
      },
      {
        label: 'Pasti dipendenti',
        data: [0, 500, 0],
        backgroundColor: '#CCCCCC',
      },
      {
        label: 'Contributi',
        data: [0, 2500, 0],
        backgroundColor: '#333333',
      },
      {
        label: 'Affitto',
        data: [0, 0, 3500],
        backgroundColor: '#f1ebeb',
      },
    ]
  }


}

class Group {
  level: number = 0;
  parent: Group | null = null;
  expanded: boolean = true;
  visible: boolean = true;
  totalCounts: number = 0;
  [key: string]: any;
  constructor() {
    this.level = 0;
    this.parent = null;
    this.expanded = true;
    this.visible = false; // Per default i gruppi sono nascosti
    this.totalCounts = 0;
  }
}
