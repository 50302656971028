import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import { NotificationService } from "../stampanti/notificationService/notification.service";
import {EditSliderImageComponent} from "./edit-slider-images/edit-slider-images";

interface SliderImage {
  url: string;
  name: string;
  duration: number;
}

@Component({
  selector: 'slider-images',
  templateUrl: './slider-images.html',
  styleUrls: ['./slider-images.css']
})
export class SliderImages implements OnInit {
  displayedColumns: string[] = ['nome', 'durata', 'preview', 'edit'];
  images: SliderImage[] = [];

  constructor(
      private dialog: MatDialog,
      private dataServiceGenerico: DataServiceGenerico,
      private notificationService: NotificationService
  ) {}

  async ngOnInit() {
    await this.loadImages();
    this.setupRealtimeUpdates();
  }

  private setupRealtimeUpdates() {
    const restaurantId = this.dataServiceGenerico.getRistorante1();
    if (restaurantId) {
      const db = firebase.firestore();
      db.collection('ristoranti').doc(restaurantId)
          .onSnapshot((doc) => {
            const data = doc.data();
            if (data?.sliderImages) {
              this.images = data.sliderImages;
            }
          }, (error) => {
            console.error('Error in slider images snapshot:', error);
          });
    }
  }

  async loadImages() {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        console.error('No restaurant ID found');
        return;
      }

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();

      if (data?.sliderImages) {
        this.images = data.sliderImages;
      }
    } catch (error) {
      console.error('Error loading images:', error);
    }
  }

  addImage() {
    const dialogRef = this.dialog.open(EditSliderImageComponent, {
      width: '600px',
      data: null
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          const restaurantId = this.dataServiceGenerico.getRistorante1();
          if (!restaurantId) throw new Error('No restaurant ID found');

          const db = firebase.firestore();
          const doc = await db.collection('ristoranti').doc(restaurantId).get();
          const data = doc.data();
          const currentImages = data?.sliderImages || [];

          currentImages.push(result);

          await db.collection('ristoranti').doc(restaurantId).update({
            sliderImages: currentImages
          });

          this.notificationService.show('Immagine aggiunta con successo', 'success');
        } catch (error) {
          console.error('Error adding image:', error);
          this.notificationService.show('Errore durante l\'aggiunta dell\'immagine', 'error');
        }
      }
    });
  }

  editImage(image: SliderImage) {
    const dialogRef = this.dialog.open(EditSliderImageComponent, {
      width: '600px',
      data: image
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.delete) {
          await this.deleteImage(image.url);
          return;
        }

        try {
          const restaurantId = this.dataServiceGenerico.getRistorante1();
          if (!restaurantId) throw new Error('No restaurant ID found');

          const db = firebase.firestore();
          const doc = await db.collection('ristoranti').doc(restaurantId).get();
          const data = doc.data();
          let currentImages = data?.sliderImages || [];

          const index = currentImages.findIndex(img => img.url === image.url);
          if (index !== -1) {
            currentImages[index] = result;

            await db.collection('ristoranti').doc(restaurantId).update({
              sliderImages: currentImages
            });

            this.notificationService.show('Immagine modificata con successo', 'success');
          }
        } catch (error) {
          console.error('Error updating image:', error);
          this.notificationService.show('Errore durante la modifica dell\'immagine', 'error');
        }
      }
    });
  }

  async deleteImage(imageUrl: string) {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) throw new Error('No restaurant ID found');

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();
      let currentImages = data?.sliderImages || [];

      currentImages = currentImages.filter(img => img.url !== imageUrl);

      await db.collection('ristoranti').doc(restaurantId).update({
        sliderImages: currentImages
      });

      // Delete from storage
      try {
        const storage = firebase.storage();
        const imageRef = storage.refFromURL(imageUrl);
        await imageRef.delete();
      } catch (error) {
        console.error('Error deleting image from storage:', error);
      }

      this.notificationService.show('Immagine eliminata con successo', 'success');
    } catch (error) {
      console.error('Error deleting image:', error);
      this.notificationService.show('Errore durante l\'eliminazione dell\'immagine', 'error');
    }
  }
}