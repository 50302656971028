<div class="inventory-container">
    <!-- Header with tabs selector -->
    <div class="operation-selector">
        <div class="tab-selector">
            <div class="slider"
                 [class.active]="operationMode === 'aggiunta'"
                 (click)="onOperationModeChange('aggiunta')">
                Carico
            </div>
            <div class="slider"
                 [class.active]="operationMode === 'scarico'"
                 (click)="onOperationModeChange('scarico')">
                Scarico
            </div>
            <div class="slider"
                 [class.active]="operationMode === 'scarti'"
                 (click)="onOperationModeChange('scarti')">
                Scarti
            </div>
            <div class="slider"
                 [class.active]="operationMode === 'trasferimento'"
                 (click)="onOperationModeChange('trasferimento')">
                Trasferimento
            </div>
        </div>

        <categorieCard
                class="col-12 col-md-12 menuItem"
                style="width: 17.5%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                [nome]="'Salva Modifiche'"
                altezza="55"
                [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e'"
                [bg]="'#33bfa3'"
                (click)="saveChanges()">
        </categorieCard>
    </div>

    <!-- Error message -->
    <div class="error-message" *ngIf="errorMessage">
        {{ errorMessage }}
    </div>

    <!-- Table -->
    <!-- inventory.component.html -->
    <div class="content-container" [class.with-history]="selectedItemForHistory">
        <!-- Tabella esistente -->
        <div class="table-section" [class.with-history]="selectedItemForHistory">
            <div class="table-responsive">
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Group Header Column -->
            <ng-container matColumnDef="groupHeader">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let group">
                    <strong>{{group[groupByColumns[group.level-1]]}}</strong>
                </mat-cell>
            </ng-container>

            <!-- Title Column -->
            <ng-container matColumnDef="Titolo">
                <mat-header-cell *matHeaderCellDef>Prodotto</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <ng-container *ngIf="!isGroup(null, row) && !row.isEditRow">{{ row.Titolo }}</ng-container>
                </mat-cell>
            </ng-container>

            <!-- Livello1 Column -->
            <ng-container matColumnDef="livello1">
                <mat-header-cell *matHeaderCellDef>Livello 1</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <ng-container *ngIf="!isGroup(null, row) && row.lvl">
                        <span *ngIf="!row.isEditRow">{{ row.quantityTotals?.livello1 }}</span>
                        <div *ngIf="row.isEditRow && row.lvl[0]" class="quantity-input-container">
                            <input type="number"
                                   [ngModel]="row.newQuantities && row.newQuantities[0]"
                                   (ngModelChange)="onQuantityChange(row, 0, $event)"
                                   class="quantity-input"
                                   [placeholder]="row.lvl[0].um">
                        </div>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Livello2 Column -->
            <ng-container matColumnDef="livello2">
                <mat-header-cell *matHeaderCellDef>Livello 2</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <ng-container *ngIf="!isGroup(null, row) && row.lvl && row.maxLvl >= 2">
                        <span *ngIf="!row.isEditRow">{{ row.quantityTotals?.livello2 }}</span>
                        <div *ngIf="row.isEditRow && row.lvl[1]" class="quantity-input-container">
                            <input type="number"
                                   [ngModel]="row.newQuantities && row.newQuantities[1]"
                                   (ngModelChange)="onQuantityChange(row, 1, $event)"
                                   class="quantity-input"
                                   [placeholder]="row.lvl[1].um">
                        </div>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Livello3 Column -->
            <ng-container matColumnDef="livello3">
                <mat-header-cell *matHeaderCellDef>Livello 3</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <ng-container *ngIf="!isGroup(null, row) && row.lvl && row.maxLvl >= 3">
                        <span *ngIf="!row.isEditRow">{{ row.quantityTotals?.livello3 }}</span>
                        <div *ngIf="row.isEditRow && row.lvl[2]" class="quantity-input-container">
                            <input type="number"
                                   [ngModel]="row.newQuantities && row.newQuantities[2]"
                                   (ngModelChange)="onQuantityChange(row, 2, $event)"
                                   class="quantity-input"
                                   [placeholder]="row.lvl[2].um">
                        </div>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Livello4 Column -->
            <ng-container matColumnDef="livello4">
                <mat-header-cell *matHeaderCellDef>Livello 4</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <ng-container *ngIf="!isGroup(null, row) && row.lvl && row.maxLvl >= 4">
                        <span *ngIf="!row.isEditRow">{{ row.quantityTotals?.livello4 }}</span>
                        <div *ngIf="row.isEditRow && row.lvl[3]" class="quantity-input-container">
                            <input type="number"
                                   [ngModel]="row.newQuantities && row.newQuantities[3]"
                                   (ngModelChange)="onQuantityChange(row, 3, $event)"
                                   class="quantity-input"
                                   [placeholder]="row.lvl[3].um">
                        </div>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Row Definitions -->
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"
                     [class.edit-row]="row.isEditRow"
                     [class.view-row]="!row.isEditRow"
                     (click)="!row.isEditRow && !isGroup(null, row) && showHistory(row)">
            </mat-row>
            <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
                     (click)="groupHeaderClick(row)"
                     class="group-row">
            </mat-row>
        </mat-table>
    </div>
        </div>

        <!-- Cronologia -->
        <div class="history-section" *ngIf="selectedItemForHistory">
            <app-cronologia-inventario [item]="selectedItemForHistory"></app-cronologia-inventario>
        </div>
    </div>
</div>