<div class="row" style="height: 95vh; width:calc(60vw); margin-top: 15px; border-radius: 20px; overflow: scroll">

    <!-- Sezione Generali -->
    <div>
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Generali</div>
        <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <form [formGroup]="generaliForm">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                            <label class="font-bold text-gray-700 text-heading-sm">Nome</label>
                            <input type="text" formControlName="nome" class="form-control">
                            <div class="error-message" *ngIf="generaliForm.get('nome')?.invalid && generaliForm.get('nome')?.touched">
                                {{ getErrorMessage(generaliForm, 'nome') }}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);" (click)="salvaGenerali()">
                {{ isLoading ? 'Salvataggio...' : 'Salva Modifiche' }}
            </div>
        </div>
    </div>

    <!-- Sezione Dati Aziendali -->
    <div>
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Dati Aziendali</div>
        <div style="width: 90%; height: 550px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <form [formGroup]="datiAziendaliForm">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <!-- Prima riga -->
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                            <label class="font-bold text-gray-700 text-heading-sm">Ragione sociale</label>
                            <input type="text" formControlName="ragioneSociale" class="form-control">
                            <div class="error-message" *ngIf="datiAziendaliForm.get('ragioneSociale')?.invalid && datiAziendaliForm.get('ragioneSociale')?.touched">
                                {{ getErrorMessage(datiAziendaliForm, 'ragioneSociale') }}
                            </div>
                        </div>
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                            <label class="font-bold text-gray-700 text-heading-sm">P.iva</label>
                            <input type="text" formControlName="piva" class="form-control">
                            <div class="error-message" *ngIf="datiAziendaliForm.get('piva')?.invalid && datiAziendaliForm.get('piva')?.touched">
                                {{ getErrorMessage(datiAziendaliForm, 'piva') }}
                            </div>
                        </div>
                    </div>

                    <!-- Seconda riga -->
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                            <label class="font-bold text-gray-700 text-heading-sm">SDI (solo per aziende italiane)</label>
                            <input type="text" formControlName="sdi" class="form-control">
                            <div class="error-message" *ngIf="datiAziendaliForm.get('sdi')?.invalid && datiAziendaliForm.get('sdi')?.touched">
                                {{ getErrorMessage(datiAziendaliForm, 'sdi') }}
                            </div>
                        </div>
                    </div>

                    <!-- Terza riga - Indirizzo con autocomplete -->
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 100%">
                            <label class="font-bold text-gray-700 text-heading-sm">Indirizzo</label>
                            <input
                                    id="indirizzo"
                                    type="text"
                                    formControlName="indirizzo"
                                    class="form-control"
                                    placeholder="Inizia a digitare l'indirizzo...">
                            <div class="error-message" *ngIf="datiAziendaliForm.get('indirizzo')?.invalid && datiAziendaliForm.get('indirizzo')?.touched">
                                {{ getErrorMessage(datiAziendaliForm, 'indirizzo') }}
                            </div>
                        </div>
                    </div>

                    <!-- Quarta riga -->
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 30%">
                            <label class="font-bold text-gray-700 text-heading-sm">CAP</label>
                            <input type="text" formControlName="cap" class="form-control" readonly>
                            <div class="error-message" *ngIf="datiAziendaliForm.get('cap')?.invalid && datiAziendaliForm.get('cap')?.touched">
                                {{ getErrorMessage(datiAziendaliForm, 'cap') }}
                            </div>
                        </div>
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 35%">
                            <label class="font-bold text-gray-700 text-heading-sm">Città</label>
                            <input type="text" formControlName="citta" class="form-control" readonly>
                            <div class="error-message" *ngIf="datiAziendaliForm.get('citta')?.invalid && datiAziendaliForm.get('citta')?.touched">
                                {{ getErrorMessage(datiAziendaliForm, 'citta') }}
                            </div>
                        </div>
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 35%">
                            <label class="font-bold text-gray-700 text-heading-sm">Provincia</label>
                            <input type="text" formControlName="provincia" class="form-control" readonly>
                            <div class="error-message" *ngIf="datiAziendaliForm.get('provincia')?.invalid && datiAziendaliForm.get('provincia')?.touched">
                                {{ getErrorMessage(datiAziendaliForm, 'provincia') }}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);" (click)="salvaDatiAziendali()">
                {{ isLoading ? 'Salvataggio...' : 'Salva Modifiche' }}
            </div>
        </div>
    </div>

    <!-- Sezione Dati Opzionali -->
    <div>
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Dati Opzionali</div>
        <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <form [formGroup]="datiOpzionaliForm">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                            <label class="font-bold text-gray-700 text-heading-sm">Sito web</label>
                            <input type="text" formControlName="sitoWeb" class="form-control">
                            <div class="error-message" *ngIf="datiOpzionaliForm.get('sitoWeb')?.invalid && datiOpzionaliForm.get('sitoWeb')?.touched">
                                {{ getErrorMessage(datiOpzionaliForm, 'sitoWeb') }}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);" (click)="salvaDatiOpzionali()">
                {{ isLoading ? 'Salvataggio...' : 'Salva Modifiche' }}
            </div>
        </div>
    </div>

    <!-- Sezione Immagini -->
    <div style="padding-bottom: 100px">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Immagini</div>
        <div style="width: 90%; height: auto; min-height: 400px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <form [formGroup]="immaginiForm">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <!-- Logo Section -->
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                            <label class="font-bold text-gray-700 text-heading-sm">Logo del ristorante</label>
                            <input
                                    type="file"
                                    accept="image/*"
                                    (change)="onFileSelected($event, 'logo')"
                                    class="form-control">

                            <div class="preview-container" *ngIf="immaginiForm.get('logo')?.value || immaginiForm.get('logoFile')?.value">
                                <img
                                        [src]="(immaginiForm.get('logoFile')?.value ? (immaginiForm.get('logoFile')?.value | objectURL) : immaginiForm.get('logo')?.value | objectURL) | async"
                                        class="image-preview"
                                        alt="Logo preview">
                            </div>

                            <div *ngIf="uploadProgress.logo > 0" class="progress-bar">
                                <div class="progress" [style.width.%]="uploadProgress.logo"></div>
                            </div>
                        </div>

                        <!-- Immagine Copertina Section -->
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                            <label class="font-bold text-gray-700 text-heading-sm">Immagine di copertina</label>
                            <input
                                    type="file"
                                    accept="image/*"
                                    (change)="onFileSelected($event, 'copertina')"
                                    class="form-control">

                            <div class="preview-container" *ngIf="immaginiForm.get('immagineCopertina')?.value || immaginiForm.get('copertinaFile')?.value">
                                <img
                                        [src]="(immaginiForm.get('copertinaFile')?.value ? (immaginiForm.get('copertinaFile')?.value | objectURL) : immaginiForm.get('immagineCopertina')?.value | objectURL) | async"
                                        class="image-preview"
                                        alt="Copertina preview">
                            </div>

                            <div *ngIf="uploadProgress.copertina > 0" class="progress-bar">
                                <div class="progress" [style.width.%]="uploadProgress.copertina"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);" (click)="salvaImmagini()">
                    {{ isLoading ? 'Salvataggio...' : 'Salva Modifiche' }}
                </div>
            </form>
        </div>
    </div>

</div>