import { Injectable } from '@angular/core';
import {catchError, map} from "rxjs/operators";
import {from, Observable, of} from "rxjs";
import {ipcRenderer} from "electron";
import {Router} from "@angular/router";
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class IpcService {
  private configData: any = null;
  constructor(private router: Router) {this.loadConfig(); }

  printReceipt(stringa, cassetto, tipostampante = 'epson') {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      console.log('asdf rch', tipostampante);
      ipcRenderer.send('print-receipt', stringa, cassetto, tipostampante);
    }
  }

  PrintFiscalReceipt(xmlString: string, tipoStampante: string): void {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      console.log('print-fiscal-receipt', xmlString, tipoStampante);
      ipcRenderer.send('print-fiscal-receipt', xmlString, tipoStampante);  // Send XML string to main process
    }
  }

  private readonly IMAGE_NAMES = [
    'icons8-back-arrow-96.png',
    'icons8-drawer-100.png',
    'icons8-loyalty-90.png',
    'icons8-delete-96.png',
    'icons8-cash-100.png',
    'search.png',
    'icons8-add-90.png',
    'icons8-minus-90.png',
    'icons8-radio-button-empty-96.png',
    'icons8-radio-button-96.png',
    'icons8-heart-100 (1).png'
  ];


  /*
  getImagePath(url: string): Observable<string> {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      try {
        const fileName = this.getFileNameFromUrl(url);
        return ipcRenderer.invoke('get-image-path', fileName).pipe(
            map(relativePath => `file:///menuImages/${relativePath}`),
            catchError(() => of('ciaociao'))
        );
      }catch {
      }
    }
    return of(url);
  }

   */


  async getImagePath2(url: string): Promise<string> {
    if (window && window.require) {

      const { ipcRenderer } = window.require('electron');
      try {
        const fileName = this.getFileNameFromUrl(url);
        if (this.IMAGE_NAMES.includes(fileName)) {
          console.log("filename: ", fileName)
          return ipcRenderer.invoke('get-local-image-path', fileName).pipe(
              map(localPath => `menuImages/${localPath}`),
              catchError(() => of(url))) // Fallback to original URL if local file not found
        }
        else
        {
          const localPath = await ipcRenderer.invoke('get-image-path', url);
          if (localPath.startsWith('file://')) {
            // Rimuovi eventuali spazi extra e assicurati che il percorso sia formattato correttamente
            return encodeURI(localPath.trim());
          } else {
            // Se non inizia con 'file://', aggiungilo e formatta correttamente
            return encodeURI(`menuImages/${localPath.trim()}`);
          }
        }
      } catch (error) {
        console.error('Errore nel recupero del percorso dell\'immagine:', error);
        return url; // Fallback all'URL originale in caso di errore
      }
    } else {
      return url; // Se non è un'app Electron, usa l'URL originale
    }
  }

  getImagePath(url: string): Promise<string> {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      const fileName = this.getFileNameFromUrl(url);
      return ipcRenderer.invoke('get-image-path', url).pipe(
          map(localPath => localPath),
          catchError(() => from(['']))
      );
    }
    //return url;
  }

  async getLocalImage(imagePath: string): Promise<string> {
    if (window && window.require) {
      try {
        const { ipcRenderer } = window.require('electron');
        //const fileName = this.getFileNameFromUrl(imagePath);
        const base64Image = await ipcRenderer.invoke('GET_LOCAL_IMAGE', imagePath);
        return `data:image/jpeg;base64,${base64Image}`;
      } catch (error) {
        console.error('Errore nel recuperare l\'immagine locale:', error);
        return imagePath;

        //throw error;
      }
    } else {
      console.warn('Questa funzione è disponibile solo nell\'app Electron');
      return imagePath;
    }
  }

  /*
  async getLocalImage2(imagePath: string): Promise<string> {
    if (window && window.require) {
      try {
        const { ipcRenderer } = window.require('electron');
        //const fileName = this.getFileNameFromUrl(imagePath);
        const base64Image = await ipcRenderer.invoke('GET_LOCAL_IMAGE2', imagePath);
        return `data:image/jpeg;base64,${base64Image}`;
      } catch (error) {
        console.error('Errore nel recuperare l\'immagine locale:', error);
        throw error;
      }
    } else {
      console.log("imagePath: ", imagePath)
      //console.warn('Questa funzione è disponibile solo nell\'app Electron');
      return imagePath;
    }
  }

   */

  private getFileNameFromUrl(url: string): string {
    console.log('url: ', url)
    const parts = url.split('/');
    return parts[parts.length - 1].split('?')[0];
  }

  async getSelectedPriceList(): Promise<string> {
    const defaultPriceList = 'Kns7iKRRBf9GGqfAYNYl';

    if (window && window.require) {
      try {
        const { ipcRenderer } = window.require('electron');
        const priceList = await ipcRenderer.invoke('get-selected-pricelist');
        return priceList;
      } catch (error) {
        console.warn('Errore nel recupero del price list da Electron:', error);
        return defaultPriceList;
      }
    }
    return defaultPriceList;
  }

  saveSelectedPriceList(priceListId: string): void {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.send('save-selected-pricelist', priceListId);
    }
  }

  async loadConfig(): Promise<void> {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      try {
        this.configData = await ipcRenderer.invoke('load-config');

        // Check if we're in cassa-ordini but config is set to cucina
        const workstationName = this.configData.workstationName?.toLowerCase();
        if (workstationName?.includes('cucina')) {
          const currentPath = window.location.pathname;
          if (currentPath.includes('cassa-ordini') || currentPath.includes('cassa/order') || currentPath.includes('')) {
            this.router.navigateByUrl('cucina#nonav');
          }
        }
      } catch (error) {
        console.error('Error loading config:', error);
      }
    }
  }

  getConfig() {
    return this.configData;
  }



  updateSecondaryDisplay(orderData: any) {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');

      const formattedOrder = {
        items: this.formatOrderItems(orderData?.carrello || {}),
        total: this.calculateTotal(orderData?.carrello || {}),
        orderNumber: orderData?.ordine || '',
        displayName: orderData?.displayName || '',
        status: orderData?.statoPagato
      };

      ipcRenderer.send('update-customer-display', formattedOrder);
    }
  }

  /**
   * Formatta gli elementi del carrello per il display secondario
   */
  private formatOrderItems(carrello: any) {
    return Object.entries(carrello).map(([key, item]: [string, any]) => ({
      name: item.title,
      quantity: item.quantita,
      price: item.prezzo || item.price,
      variants: item.variants || [],
      notes: item.note,
      category: item.category
    }));
  }

  /**
   * Calcola il totale dell'ordine
   */
  private calculateTotal(carrello: any): number {
    return <number>Object.values(carrello).reduce((total: number, item: any) => {
      const price = item.prezzo || item.price || 0;
      return total + (price * item.quantita);
    }, 0);
  }

  /**
   * Apre il display secondario su un altro schermo
   */
  openSecondaryDisplay() {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.send('open-secondary-display');
    }
  }

  /**
   * Chiude il display secondario
   */
  closeSecondaryDisplay() {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.send('close-secondary-display');
    }
  }



}
