<div class="revenue-container">
    <div class="months-timeline">
        <div class="timeline-wrapper" #timelineWrapper>
            <div class="month-item"
                 *ngFor="let month of months; let i = index"
                 [class.selected]="selectedMonth === i"
                 [class.current]="isCurrentMonth(i)"
                 (click)="onMonthSelect(i)">
                <span>{{ month }}</span>
                <div class="status-indicator">
                    <i class="material-icons status-icon"
                       [class.complete]="isMonthComplete(i)"
                       [class.pending]="!isMonthComplete(i)"
                       [class.selected]="selectedMonth === i">
                        {{ isMonthComplete(i) ? 'check_circle' : 'schedule' }}
                    </i>
                </div>
            </div>
        </div>
    </div>

    <div class="main-content">
        <div class="revenue-card">
            <div class="card-header">
                <h2>Fatturato {{ months[selectedMonth] }} {{ currentYear }}</h2>
            </div>

            <div class="input-section">
                <div class="amount-input">
                    <span class="currency-symbol">€</span>
                    <input type="text"
                           [(ngModel)]="revenue"
                           (input)="onRevenueInput($event)"
                           placeholder="0,00">
                </div>

                <div class="commission-preview" *ngIf="revenue">
                    <div class="commission-label">Commissione stimata (5%)</div>
                    <div class="commission-amount">{{ formatCurrency(commission) }}</div>
                </div>
            </div>

            <div class="action-buttons">
                <button class="save-button"
                        (click)="saveRevenue()"
                        [disabled]="!revenue">
                    Inserisci fatturato
                </button>
            </div>
        </div>

        <div class="history-card">
            <div class="card-header">
                <h2>Storico ultimi 12 mesi</h2>
            </div>

            <div class="chart-container">
                <canvas #revenueChart></canvas>
            </div>
        </div>
    </div>
</div>