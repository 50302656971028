import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import { Router } from '@angular/router';
import { OnChanges, SimpleChanges } from '@angular/core';
import { DataServiceGenerico } from '../../shared/services/data-service/data.service';
import 'hammerjs';
import {MatDialog} from "@angular/material/dialog";
import {DeleteOrderDialogComponent} from "../delete-order-dialog/delete-order-dialog.component";

@Component({
  selector: 'menuDetailCard-bar',
  styles: [`
    .menu-item-container {
      background: white;
      border-radius: 12px;
      padding: 10px;
      margin: 8px 0;
    }

    .content-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .quantity-badge {
      min-width: 32px;
      height: 32px;
      border-radius: 16px;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: #333;
      position: relative;
      transition: color 0.3s ease;
    }

    .quantity-badge.verde {
      color: #4CAF50;
    }

    .quantity-badge.rosso {
      color: #F44336;
    }

    .strikethrough {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      height: 2px;
      background: #ff4444;
      transform: rotate(-45deg);
    }

    .product-image {
      width: 48px;
      height: 48px;
      border-radius: 8px;
      object-fit: cover;
    }

    .product-details {
      flex: 1;
      min-width: 0;
    }

    .product-name {
      font-size: 16px;
      font-weight: 500;
      color: #333;
      margin-bottom: 4px;
    }

    .product-category {
      font-size: 14px;
      color: #666;
    }

    .variant-tag {
      display: inline-block;
      padding: 4px 8px;
      background: rgba(229, 89, 51, 0.1);
      color: rgb(229, 89, 51);
      border-radius: 12px;
      font-size: 12px;
      margin: 4px 4px 4px 0;
    }

    .modifier-tag {
      padding: 2px 6px;
      border-radius: 4px;
      font-size: 12px;
      margin: 2px 0;
    }

    .addition-tag {
      background: rgba(76, 175, 80, 0.1);
      color: #4CAF50;
    }

    .removal-tag {
      background: rgba(244, 67, 54, 0.1);
      color: #F44336;
    }

    .note-tag {
      background: rgba(33, 150, 243, 0.1);
      color: #2196F3;
    }

    .price {
      font-weight: 600;
      color: #333;
      min-width: 70px;
      text-align: right;
    }

    .actions {
      display: flex;
      gap: 8px;
    }

    .action-button {
      width: 36px;
      height: 36px;
      border-radius: 18px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .decrease-button {
      background: #f5f5f5;
    }

    .increase-button {
      background: #f5f5f5;
    }

    .delete-button {
      background: #ff4444;
    }

    .action-icon {
      width: 20px;
      height: 20px;
    }

    .delete-action {
      filter: brightness(0) invert(1);
    }

    .border-bottom {
      border-bottom: 1px solid rgba(181,181,181,0.3);
      margin: 0 15px;
    }
  `],
  template: `
    <div class="menu-item-container"
         (swipeleft)="handleSwipe('left')"
         (swiperight)="handleSwipe('right')"
         (press)="onLongPress()"
         (pressup)="onPressEnd()">

      <div class="content-wrapper">
        <div class="quantity-badge" [class.verde]="isGreen" [class.rosso]="isRed">
          <ng-container *ngIf="isPaidQuantity !== undefined">
            <span>{{quantita}}</span>
            <div class="strikethrough"></div>
          </ng-container>
          <span>{{isPaidQuantity !== undefined ? (quantita - isPaidQuantity) : quantita}}x</span>
        </div>

        <img [src]="link"
             [alt]="nome"
             class="product-image"
             onerror="this.src='https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FFillApp%20solo%20logo%20grigio.png?alt=media&token=6105b54c-35c8-4c16-818c-2cb5548e9f94'">

        <div class="product-details">
          <div class="product-name">{{nome}}</div>
          <div class="product-category">{{ingredienti}}</div>

          <div *ngIf="variants?.length" class="variants">
            <span *ngFor="let variant of variants" class="variant-tag">
              {{variant.variantTitle}}: {{variant.name}}
              <span *ngIf="variant.priceModifier > 0">+{{variant.priceModifier.toFixed(2)}}€</span>
            </span>
          </div>

          <div *ngIf="aggiunte?.length" class="modifier-tag addition-tag">
            Aggiunte: {{aggiunte.join(', ')}}
          </div>

          <div *ngIf="rimozioni?.length" class="modifier-tag removal-tag">
            Rimozioni: {{rimozioni.join(', ')}}
          </div>

          <div *ngIf="nota" class="modifier-tag note-tag">
            Nota: {{nota}}
          </div>
        </div>

        <div class="price">
          {{(prezzo * quantita).toFixed(2)}}€
        </div>

        <div class="actions">
          <button class="action-button decrease-button"
                  (click)="rimuoviProdotto()"
                  *ngIf="pagato !== 0">
            <img class="action-icon" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ficons8-subtract-90.png?alt=media&token=ec1e6b52-9bb4-41df-aa96-34630f028e1c">
          </button>

          <button class="action-button increase-button"
                  (click)="aggiungiProdotto()"
                  *ngIf="pagato !== 0">
            <img class="action-icon" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ficons8-plus-math-90.png?alt=media&token=1dbe70c3-63ee-40d3-a6ba-bc006710ac5e">
          </button>

          <button class="action-button delete-button"
                  (click)="onDelete($event)"
                  *ngIf="showDeleteButton">
            <img class="action-icon delete-action" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-delete-96.png?alt=media&token=a5eaf571-9519-474b-8d33-853cbe4a9e43">
          </button>

          <button class="action-button delete-button"
                  (click)="checkLastProduct.emit(true)"
                  *ngIf="true">
            <img class="action-icon delete-action" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-delete-96.png?alt=media&token=a5eaf571-9519-474b-8d33-853cbe4a9e43">
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!last" class="border-bottom"></div>
  `
})
export class MenuDetailCardBarComponent implements OnChanges {

  @Input() id: string;
  @Input() nome: string;
  @Input() ingredienti: string;
  @Input() link: string;
  @Input() prezzo: number;
  @Input() quantita: number;
  @Input() allergene: string;
  @Input() piatto: any;
  @Input() user: any;
  @Input() last: boolean;
  @Input() first: boolean;
  @Input() pagato: number;
  @Input() carrello: boolean;
  @Input() lenght: number;
  @Input() aggiunte: string[];
  @Input() rimozioni: string[];
  @Input() nota: string;
  @Input() variants: any[];
  @Input() isPaidQuantity: number;
  @Output() openVariants = new EventEmitter<void>();
  @Input() hasRequiredVariants: boolean = false;
  @Output() quantitaOut = new EventEmitter<number>();
  @Output() deleteItem = new EventEmitter<void>();
  @Output() checkLastProduct = new EventEmitter<boolean>();


  showDeleteButton = false;
  allergia = false;
  allergeni = '';
  isSwipe = false;


  isGreen = false;
  isRed = false;

  constructor(
      private router: Router,
      public dataServiceGenerico: DataServiceGenerico,
      private dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.allergia = false;
    this.allergeni = '';

    if (this.piatto !== undefined) {
      if (this.piatto.hasOwnProperty('ingredienti')) {
        if (this.piatto.ingredienti) {
          this.piatto.ingredienti.forEach(ingrediente => {
            ingrediente.allergeni.forEach(allergeneIngrediente => {
              this.user.allergeni.forEach(allergeneUser => {
                if (allergeneIngrediente === allergeneUser) {
                  this.allergia = true;
                  this.allergeni += ' ' + allergeneIngrediente;
                }
              });
            });
          });
        }
      }
    }

    if (this.piatto !== undefined) {
      if (this.piatto.hasOwnProperty('ingredienti')) {
        if (this.piatto.opzioni) {
          this.piatto.opzioni.forEach(opzione => {
            opzione.allergeni.forEach(allergeneOpzione => {
              this.user.allergeni.forEach(allergeneUser => {
                if (allergeneUser === allergeneOpzione) {
                  this.allergia = true;
                  this.allergeni += ' ' + allergeneOpzione;
                }
              });
            });
          });
        }
      }
    }
  }

  handleSwipe(direction: string) {
    this.isSwipe = true;
    if(this.pagato !== 0) {
      if (direction === 'left') {
        this.rimuoviProdotto();
      } else if (direction === 'right') {
        this.aggiungiProdotto();
      }
    }
    setTimeout(() => this.isSwipe = false, 500);
  }

  onLongPress() {
    if(this.pagato !== 0) {
      this.showDeleteButton = true;
    }
  }

  onPressEnd() {
    setTimeout(() => {
      this.showDeleteButton = false;
    }, 5000); // il bottone sparisce dopo 2 secondi
  }

  onDelete(event: Event) {
    event.stopPropagation();
    this.checkLastProduct.emit(true);  // true = rimozione completa
    this.showDeleteButton = false;
  }

  aggiungiProdotto() {
    if(this.pagato !== 0) {
      this.triggerColorChange('green');
      this.quantita = this.quantita + 1;
      this.quantitaOut.emit(this.quantita);
    }
  }




  rimuoviProdotto() {
    if(this.pagato !== 0) {
      this.triggerColorChange('red');
      if (this.quantita === 1) {
        this.checkLastProduct.emit(false);  // false = rimozione singola
      } else {
        this.quantita = this.quantita - 1;
        this.quantitaOut.emit(this.quantita);
      }
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (this.isSwipe) return;

    if (event.target instanceof Element) {
      const isButton = event.target.closest('.rimuovi-button') ||
          event.target.closest('.aggiungi-button') ||
          event.target.closest('.delete-button');
      if (!isButton && this.hasRequiredVariants) {
        this.openVariants.emit();
      }
    }
  }

  private triggerColorChange(color: 'green' | 'red') {
    if (color === 'green') {
      this.isGreen = true;
    } else {
      this.isRed = true;
    }

    setTimeout(() => {
      this.isGreen = false;
      this.isRed = false;
    }, 700);
  }

  clicco(): void {
    console.log('ciaociaociaociao', this.nome);
  }

  logout(): void {
    this.router.navigateByUrl('login');
  }
}