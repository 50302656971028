// level-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-level-dialog',
    template: `
    <h2 mat-dialog-title>Modifica Livello</h2>
    <mat-dialog-content>
      <div class="level-options">
        <div 
          *ngFor="let level of levels" 
          class="level-option"
          [class.selected]="selectedLevel === level.level"
          (click)="selectedLevel = level.level">
          <div class="level-badge" [ngStyle]="getLevelStyle(level.level)">
            Livello {{level.level}}
          </div>
          <div class="points-info">
            {{level.points}} punti
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="onCancel()">Annulla</button>
      <button mat-raised-button color="primary" (click)="onConfirm()">Conferma</button>
    </mat-dialog-actions>
  `,
    styles: [`
    .level-options {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 16px 0;
    }
    .level-option {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 12px;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.2s;
    }
    .level-option:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    .level-option.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }
    .level-badge {
      padding: 6px 12px;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;
    }
    .points-info {
      color: rgba(0, 0, 0, 0.6);
    }
  `]
})
export class LevelDialogComponent {
    levels = [
        { level: 0, points: 0 },
        { level: 1, points: 200 },
        { level: 2, points: 400 },
        { level: 3, points: 800 },
        { level: 4, points: 1200 },
        { level: 5, points: 2000 }
    ];

    selectedLevel: number;

    constructor(
        public dialogRef: MatDialogRef<LevelDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { currentLevel: number }
    ) {
        this.selectedLevel = data.currentLevel;
    }

    getLevelStyle(level: number): object {
        const colors = {
            0: { background: 'rgba(156, 163, 175, 0.1)', color: '#6B7280' },
            1: { background: 'rgba(59, 130, 246, 0.1)', color: '#3B82F6' },
            2: { background: 'rgba(16, 185, 129, 0.1)', color: '#10B981' },
            3: { background: 'rgba(245, 158, 11, 0.1)', color: '#F59E0B' },
            4: { background: 'rgba(237, 88, 14, 0.1)', color: '#ED580E' },
            5: { background: 'rgba(239, 68, 68, 0.1)', color: '#EF4444' }
        };

        return colors[level] || colors[0];
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onConfirm(): void {
        const selectedLevelPoints = this.levels.find(l => l.level === this.selectedLevel)?.points || 0;
        this.dialogRef.close({ level: this.selectedLevel, points: selectedLevelPoints });
    }
}