import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { UserDataService } from '../../../shared/services/firestore/userData.service';
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import { NotificationService } from "../stampanti/notificationService/notification.service";
import { environment } from "../../../../environments/environment";

interface RistoranteData {
  nome: string;
  ragioneSociale: string;
  piva: string;
  sdi: string;
  indirizzo: string;
  cap: string;
  citta: string;
  provincia: string;
  sitoWeb?: string;
  logo?: string;
  immagineCopertina?: string;
  location?: {
    geohash: string;
    geopoint: firebase.firestore.GeoPoint;
  };
}

declare var google: any;

@Component({
  selector: 'ristorante',
  templateUrl: './ristorante.html',
  styleUrls: ['./ristorante.css']
})
export class Ristorante implements OnInit {
  generaliForm: FormGroup;
  datiAziendaliForm: FormGroup;
  datiOpzionaliForm: FormGroup;
  immaginiForm: FormGroup;
  autocomplete: any;
  isLoading = false;
  uploadProgress = {
    logo: 0,
    copertina: 0
  };

  constructor(
      private router: Router,
      private userService: UserDataService,
      private dataServiceGenerico: DataServiceGenerico,
      private notificationService: NotificationService,
      private fb: FormBuilder
  ) {
    this.initializeForms();
  }

  async ngOnInit() {
    if (this.dataServiceGenerico.getRistorante1() === '') {
      await this.dataServiceGenerico.loadSelectedMenuFromFirebase();
      await this.loadRistoranteData();
    } else {
      await this.loadRistoranteData();
    }
    this.loadGoogleMapsScript();
  }

  private initializeForms() {
    this.generaliForm = this.fb.group({
      nome: ['', Validators.required]
    });

    this.datiAziendaliForm = this.fb.group({
      ragioneSociale: ['', Validators.required],
      piva: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
      sdi: ['', [Validators.minLength(7), Validators.maxLength(7)]],
      indirizzo: ['', Validators.required],
      cap: ['', [Validators.required, Validators.pattern('^[0-9]{5}$')]],
      citta: ['', Validators.required],
      provincia: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      lat: [''],
      lng: ['']
    });

    this.datiOpzionaliForm = this.fb.group({
      sitoWeb: ['', Validators.pattern('https?://.+')]
    });

    this.immaginiForm = this.fb.group({
      logo: [''],
      logoFile: [null],
      immagineCopertina: [''],
      copertinaFile: [null]
    });
  }

  private async loadRistoranteData() {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        console.error('No restaurant ID found');
        return;
      }

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data() as RistoranteData;

      if (data) {
        this.generaliForm.patchValue({
          nome: data.nome
        });

        this.datiAziendaliForm.patchValue({
          ragioneSociale: data.ragioneSociale,
          piva: data.piva,
          sdi: data.sdi,
          indirizzo: data.indirizzo,
          cap: data.cap,
          citta: data.citta,
          provincia: data.provincia
        });

        if (data.location?.geopoint) {
          this.datiAziendaliForm.patchValue({
            lat: data.location.geopoint.latitude,
            lng: data.location.geopoint.longitude
          });
        }

        this.datiOpzionaliForm.patchValue({
          sitoWeb: data.sitoWeb
        });

        this.immaginiForm.patchValue({
          logo: data.logo,
          immagineCopertina: data.immagineCopertina
        });
      }
    } catch (error) {
      console.error('Error loading restaurant data:', error);
      this.notificationService.show('Errore durante il caricamento dei dati', 'error');
    }
  }

  private loadGoogleMapsScript() {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.initAutocomplete();
    };
    document.head.appendChild(script);
  }

  private initAutocomplete() {
    const input = document.getElementById('indirizzo') as HTMLInputElement;
    this.autocomplete = new google.maps.places.Autocomplete(input, {
      componentRestrictions: { country: 'it' },
      fields: ['address_components', 'geometry', 'formatted_address']
    });

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      if (place.geometry) {
        let cap = '';
        let citta = '';
        let provincia = '';
        let indirizzo = '';

        place.address_components.forEach(component => {
          const type = component.types[0];
          if (type === 'postal_code') {
            cap = component.long_name;
          } else if (type === 'locality') {
            citta = component.long_name;
          } else if (type === 'administrative_area_level_2') {
            provincia = component.short_name;
          } else if (type === 'route') {
            indirizzo = component.long_name;
          } else if (type === 'street_number') {
            indirizzo += ' ' + component.long_name;
          }
        });

        this.datiAziendaliForm.patchValue({
          indirizzo: indirizzo,
          cap: cap,
          citta: citta,
          provincia: provincia,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        });
      }
    });
  }

  private generateGeoHash(lat: number, lng: number): string {
    const BASE32 = '0123456789bcdefghjkmnpqrstuvwxyz';
    let geohash = '';
    let minLat = -90;
    let maxLat = 90;
    let minLng = -180;
    let maxLng = 180;
    let bit = 0;
    let ch = 0;

    while (geohash.length < 9) {
      if (bit % 2 === 0) {
        const mid = (minLng + maxLng) / 2;
        if (lng > mid) {
          ch = (ch << 1) + 1;
          minLng = mid;
        } else {
          ch = (ch << 1) + 0;
          maxLng = mid;
        }
      } else {
        const mid = (minLat + maxLat) / 2;
        if (lat > mid) {
          ch = (ch << 1) + 1;
          minLat = mid;
        } else {
          ch = (ch << 1) + 0;
          maxLat = mid;
        }
      }

      bit++;
      if (bit === 5) {
        geohash += BASE32.charAt(ch);
        bit = 0;
        ch = 0;
      }
    }
    return geohash;
  }

  async onFileSelected(event: any, type: 'logo' | 'copertina') {
    const file = event.target.files[0];
    if (file) {
      this.immaginiForm.patchValue({
        [`${type}File`]: file,
        [type]: ''
      });
      this.immaginiForm.markAsDirty();
    }
  }

  private async uploadImage(file: File, type: 'logo' | 'copertina'): Promise<string> {
    const restaurantId = this.dataServiceGenerico.getRistorante1();
    if (!restaurantId || !file) throw new Error('Missing restaurant ID or file');

    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(`restaurants/${restaurantId}/${type}/${file.name}`);

    const uploadTask = imageRef.put(file);

    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
          (snapshot) => {
            this.uploadProgress[type] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => reject(error),
          async () => {
            const downloadUrl = await imageRef.getDownloadURL();
            resolve(downloadUrl);
          }
      );
    });
  }

  async salvaGenerali() {
    if (this.generaliForm.invalid) {
      Object.keys(this.generaliForm.controls).forEach(key => {
        const control = this.generaliForm.get(key);
        if (control?.invalid) {
          control.markAsTouched();
        }
      });
      return;
    }

    this.isLoading = true;
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        throw new Error('No restaurant ID found');
      }

      const db = firebase.firestore();
      await db.collection('ristoranti').doc(restaurantId).update({
        ...this.generaliForm.value,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });

      this.notificationService.show('Dati generali salvati con successo', 'success');
    } catch (error) {
      console.error('Error saving general data:', error);
      this.notificationService.show('Errore durante il salvataggio dei dati generali', 'error');
    } finally {
      this.isLoading = false;
    }
  }

  async salvaDatiAziendali() {
    if (this.datiAziendaliForm.invalid) {
      Object.keys(this.datiAziendaliForm.controls).forEach(key => {
        const control = this.datiAziendaliForm.get(key);
        if (control?.invalid) {
          control.markAsTouched();
        }
      });
      return;
    }

    this.isLoading = true;
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        throw new Error('No restaurant ID found');
      }

      const formValue = this.datiAziendaliForm.value;
      const lat = parseFloat(formValue.lat);
      const lng = parseFloat(formValue.lng);

      if (isNaN(lat) || isNaN(lng)) {
        throw new Error('Coordinate non valide');
      }

      const geohash = this.generateGeoHash(lat, lng);
      const indirizzoCompleto = `${formValue.indirizzo}, ${formValue.citta}, ${formValue.provincia}, ${formValue.cap}`;

      const db = firebase.firestore();
      const geopoint = new firebase.firestore.GeoPoint(lat, lng);

      const updateData = {
        ragioneSociale: formValue.ragioneSociale,
        piva: formValue.piva,
        sdi: formValue.sdi,
        indirizzo: formValue.indirizzo,
        cap: formValue.cap,
        citta: formValue.citta,
        provincia: formValue.provincia,
        location: {
          geohash: geohash,
          geopoint: geopoint
        },
        indirizzoCompleto: indirizzoCompleto,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      };

      await db.collection('ristoranti').doc(restaurantId).update(updateData);

      this.notificationService.show('Dati aziendali salvati con successo', 'success');
    } catch (error) {
      console.error('Error saving business data:', error);
      this.notificationService.show('Errore durante il salvataggio dei dati aziendali', 'error');
    } finally {
      this.isLoading = false;
    }
  }

  async salvaDatiOpzionali() {
    if (this.datiOpzionaliForm.invalid) {
      Object.keys(this.datiOpzionaliForm.controls).forEach(key => {
        const control = this.datiOpzionaliForm.get(key);
        if (control?.invalid) {
          control.markAsTouched();
        }
      });
      return;
    }

    this.isLoading = true;
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        throw new Error('No restaurant ID found');
      }

      const db = firebase.firestore();
      await db.collection('ristoranti').doc(restaurantId).update({
        ...this.datiOpzionaliForm.value,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });

      this.notificationService.show('Dati opzionali salvati con successo', 'success');
    } catch (error) {
      console.error('Error saving optional data:', error);
      this.notificationService.show('Errore durante il salvataggio dei dati opzionali', 'error');
    } finally {
      this.isLoading = false;
    }
  }

  async salvaImmagini() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) throw new Error('No restaurant ID found');

      const updateData: Partial<RistoranteData> = {};
      const logoFile = this.immaginiForm.get('logoFile')?.value;
      const copertinaFile = this.immaginiForm.get('copertinaFile')?.value;

      if (logoFile) {
        updateData.logo = await this.uploadImage(logoFile, 'logo');
      }
      if (copertinaFile) {
        updateData.immagineCopertina = await this.uploadImage(copertinaFile, 'copertina');
      }

      if (Object.keys(updateData).length > 0) {
        const db = firebase.firestore();
        await db.collection('ristoranti').doc(restaurantId).update({
          ...updateData,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });

        this.immaginiForm.patchValue({
          logoFile: null,
          copertinaFile: null
        });

        this.notificationService.show('Immagini salvate con successo', 'success');
      }
    } catch (error) {
      console.error('Error saving images:', error);
      this.notificationService.show('Errore durante il salvataggio delle immagini', 'error');
    } finally {
      this.isLoading = false;
      this.uploadProgress = { logo: 0, copertina: 0 };
    }
  }

  getErrorMessage(formGroup: FormGroup, controlName: string): string {
    const control = formGroup.get(controlName);
    if (control?.hasError('required')) {
      return 'Questo campo è obbligatorio';
    }
    if (control?.hasError('pattern')) {
      if (controlName === 'cap') {
        return 'Il CAP deve essere composto da 5 numeri';
      }
      if (controlName === 'sitoWeb') {
        return 'Inserire un URL valido (deve iniziare con http:// o https://)';
      }
    }
    if (control?.hasError('minlength')) {
      if (controlName === 'piva') {
        return 'La partita IVA deve essere di 11 caratteri';
      }
      if (controlName === 'sdi') {
        return 'Il codice SDI deve essere di 7 caratteri';
      }
      if (controlName === 'provincia') {
        return 'La provincia deve essere di 2 caratteri';
      }
    }
    if (control?.hasError('maxlength')) {
      if (controlName === 'piva') {
        return 'La partita IVA deve essere di 11 caratteri';
      }
      if (controlName === 'sdi') {
        return 'Il codice SDI deve essere di 7 caratteri';
      }
      if (controlName === 'provincia') {
        return 'La provincia deve essere di 2 caratteri';
      }
    }
    return '';
  }
}