import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase';
declare var window: any;

interface SliderImage {
  url: string;
  duration?: number;
}

interface OrderData {
  items: Array<{
    name: string;
    quantity: number;
    price: number;
    variants?: Array<any>;
    notes?: string;
    category?: string;
  }>;
  total: number;
  orderNumber: string;
  displayName: string;
  status: number;
}

@Component({
  selector: 'app-schermo-secondario',
  templateUrl: './schermo-secondario.html',
  styleUrls: ['./schermo-secondario.css']
})
export class SchermoSecondarioComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('orderItemsContainer') orderItemsContainer: ElementRef;

  currentImage: string = '';
  sliderImages: SliderImage[] = [];
  currentIndex: number = 0;
  sliderTimeout: any;
  currentOrder: OrderData | null = null;
  scrollInterval: any;
  isScrolling: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.loadSliderImages();

    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');

      ipcRenderer.on('customer-display-update', (_, data: OrderData) => {
        console.log('Ricevuto aggiornamento ordine:', data);
        this.updateDisplay(data);
      });

      ipcRenderer.on('navigate-to', (_, route: string) => {
        console.log('Navigazione richiesta verso:', route);
        this.router.navigate([route], { fragment: 'nonav' });
      });
    }
  }

  ngAfterViewInit() {
    this.initializeScroll();
  }

  private initializeScroll() {
    if (this.orderItemsContainer) {
      const container = this.orderItemsContainer.nativeElement;

      const observer = new MutationObserver(() => {
        this.checkScrollNeeded();
      });

      observer.observe(container, {
        childList: true,
        subtree: true
      });

      this.checkScrollNeeded();
    }
  }

  private checkScrollNeeded() {
    if (!this.orderItemsContainer) return;

    const container = this.orderItemsContainer.nativeElement;
    const needsScroll = container.scrollHeight > container.clientHeight;

    if (needsScroll && !this.isScrolling) {
      this.startAutoScroll();
    } else if (!needsScroll && this.isScrolling) {
      this.stopAutoScroll();
    }
  }

  private startAutoScroll() {
    if (this.scrollInterval) return;

    const container = this.orderItemsContainer.nativeElement;
    let scrollTop = 0;
    const scrollSpeed = 0.5;

    this.isScrolling = true;
    this.scrollInterval = setInterval(() => {
      scrollTop += scrollSpeed;
      if (scrollTop >= container.scrollHeight - container.clientHeight) {
        scrollTop = 0;
      }
      container.scrollTop = scrollTop;
    }, 20);
  }

  private stopAutoScroll() {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
    }
    this.isScrolling = false;
  }

  private loadSliderImages() {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      const restaurantId = 'cannoleria';

      firebase.firestore().collection('ristoranti').doc(restaurantId).get()
          .then(doc => {
            if (doc.exists) {
              this.sliderImages = doc.data()?.sliderImages || [];
              if (this.sliderImages.length > 0) {
                this.updateCurrentImage(0);
                this.startSlider();
              }
            }
          })
          .catch(error => {
            console.error('Errore nel caricamento delle immagini:', error);
          });
    }
  }

  private updateCurrentImage(index: number) {
    this.currentImage = this.sliderImages[index].url;
    this.currentIndex = index;
  }

  private startSlider() {
    if (this.sliderImages.length <= 1) return;

    const showNextImage = () => {
      this.currentIndex = (this.currentIndex + 1) % this.sliderImages.length;
      this.updateCurrentImage(this.currentIndex);
      this.sliderTimeout = setTimeout(showNextImage, 5000);
    };

    this.sliderTimeout = setTimeout(showNextImage, 5000);
  }

  private updateDisplay(orderData: OrderData) {
    console.log('Aggiornamento display con dati:', orderData);
    this.currentOrder = orderData;

    const sliderContainer = document.querySelector('.slider-container');
    if (sliderContainer) {
      sliderContainer.classList.toggle('with-order', !!orderData);
    }

    setTimeout(() => {
      this.checkScrollNeeded();
    }, 100);
  }

  calcolaTotale(): number {
    if (!this.currentOrder?.items) return 0;
    return this.currentOrder.items.reduce((acc, item) =>
        acc + (item.price * (item.quantity? item.quantity: 1)), 0);
  }

  ngOnDestroy() {
    if (this.sliderTimeout) {
      clearTimeout(this.sliderTimeout);
    }
    this.stopAutoScroll();
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.removeAllListeners('customer-display-update');
      ipcRenderer.removeAllListeners('navigate-to');
    }
  }
}