import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent} from "../ConfirmDialogData/confirmDialogData.component";
import { interval, Subscription } from 'rxjs';
import { CommonService } from '../../shared/services/firestore/common.service';
import { DataServiceGenerico } from '../../shared/services/data-service/data.service';
import firebase from 'firebase/app';

interface Order {
  id: string;
  ordine: string;
  data: string;
  tavolo?: string;
  selectedPriceListType: 'counter' | 'table' | 'takeaway';
  nota?: string;
  carrello: {
    [key: string]: {
      title: string;
      quantita: number;
      variants?: Array<{
        name: string;
        variantTitle: string;
      }>;
      selectedPrintDestination?: string;
      category?: string;
    };
  };
  ordineInserimento: string[];
  statoPagato: number;
  bloccato?: boolean;
  timestampEvasione?: string;
  fonte: number;
}

@Component({
  selector: 'app-schermo-cucina',
  templateUrl: './schermo-cucina.component.html',
  styleUrls: ['./schermo-cucina.component.css']
})
export class SchermoCucina implements OnInit, OnDestroy {
  currentTime: Date = new Date();
  ordiniCucina: Order[] = [];
  ordiniEvasi: Order[] = [];
  ordiniOriginali: Order[] = [];
  tempoMedioEvasione: string = '0:00';
  mostraTutti: boolean = false;
  showFilters: boolean = false;

  printDestinations: string[] = [];
  uniqueCategories: string[] = [];
  selectedDestinazioni: string[] = [];
  selectedCategories: string[] = [];

  private subscriptions: Subscription[] = [];
  private restaurantId: string = '';

  constructor(
      private commonService: CommonService,
      private dataServiceGenerico: DataServiceGenerico,
      private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // Aggiorna l'ora ogni secondo
    this.subscriptions.push(
        interval(1000).subscribe(() => {
          this.currentTime = new Date();
        })
    );

    this.initializeKitchenDisplay();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private async initializeKitchenDisplay(): Promise<void> {
    try {
      await this.dataServiceGenerico.loadSelectedMenuFromFirebase();

      setTimeout(async () => {
        this.restaurantId = this.dataServiceGenerico.getRistorante1() ||
            this.dataServiceGenerico.getCurrentRestaurant()?.id;

        if (!this.restaurantId) {
          throw new Error('Restaurant ID not available');
        }

        await this.loadPrintDestinationsAndCategories();
        this.setupOrdersListener();
      }, 1000);
    } catch (error) {
      console.error('Error initializing kitchen display:', error);
    }
  }

  private async loadPrintDestinationsAndCategories(): Promise<void> {
    try {
      const menuDoc = await firebase.firestore()
          .collection('menu_test')
          .doc(this.restaurantId)
          .get();

      if (menuDoc.exists) {
        const items = menuDoc.data()?.items || [];

        // Extract print destinations
        const destinationsSet = new Set<string>();
        items.forEach(item => {
          if (item.selectedPrintDestination) {
            destinationsSet.add(item.selectedPrintDestination);
          }
        });
        this.printDestinations = Array.from(destinationsSet);

        // Extract categories
        const categoriesSet = new Set<string>();
        items.forEach(item => {
          if (item.category?.trim()) {
            categoriesSet.add(item.category);
          }
        });
        this.uniqueCategories = Array.from(categoriesSet);
      }
    } catch (error) {
      console.error('Error loading menu data:', error);
      // Set default values if loading fails
      this.printDestinations = ['cucina', 'bar', 'pizzeria'];
      this.uniqueCategories = ['Primi', 'Secondi', 'Contorni'];
    }
  }

  private setupOrdersListener(): void {
    const today = new Date().setHours(0, 0, 0, 0);

    // Remove existing order subscription if any
    if (this.subscriptions.length > 1) {
      this.subscriptions[1].unsubscribe();
      this.subscriptions.pop();
    }

    this.subscriptions.push(
        this.commonService.getOrdiniSnapshot(
            this.restaurantId,
            this.mostraTutti ? 3 : 0
        ).subscribe(ordini => {
          if (ordini) {
            // Filter and sort orders
            const filteredOrders = ordini
                .filter(ordine => {
                  const orderDate = new Date(parseInt(ordine.data)).setHours(0, 0, 0, 0);
                  return orderDate === today;
                })
                .sort((a, b) => {
                  if (!this.mostraTutti) {
                    return parseInt(a.data) - parseInt(b.data);
                  }
                  return parseInt(b.data) - parseInt(a.data);
                });

            this.ordiniOriginali = [...filteredOrders];
            this.ordiniCucina = [...filteredOrders];

            this.ordiniEvasi = ordini.filter(ordine =>
                ordine.statoPagato === 3 &&
                new Date(parseInt(ordine.data)).setHours(0, 0, 0, 0) === today
            );

            this.calcolaTempoMedioEvasione();
            this.applyFilters();
          }
        })
    );
  }

  getColonneOrdinate(): Order[][] {
    const NUM_COLONNE = 6;
    const ORDERS_PER_COLUMN = Math.floor((window.innerHeight - 200) / 250); // 250px è l'altezza stimata di un ordine
    const colonne: Order[][] = Array.from({ length: NUM_COLONNE }, () => []);

    let currentColumn = 0;
    this.ordiniCucina.forEach(ordine => {
      if (colonne[currentColumn].length >= ORDERS_PER_COLUMN) {
        currentColumn++;
        if (currentColumn >= NUM_COLONNE) {
          currentColumn = NUM_COLONNE - 1; // Se non c'è più spazio, accoda all'ultima colonna
        }
      }
      colonne[currentColumn].push(ordine);
    });

    return colonne;
  }

  async completeOrder(ordine: Order): Promise<void> {
    if (this.mostraTutti && ordine.statoPagato === 3) {
      // Dialog per ripristinare l'ordine
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Ripristinare questo ordine?',
          confirmText: 'Ripristina',
          cancelText: 'Annulla',
          isRestore: true
        }
      });

      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          try {
            const updatedOrder = {
              ...ordine,
              consegnato: false,
              statoPagato: 0,
              timestampEvasione: null
            };
            await this.commonService.updateOrdine(ordine.id, updatedOrder);
          } catch (error) {
            console.error('Error restoring order:', error);
          }
        }
      });
    } else if (ordine.statoPagato !== 3) {
      // Dialog per evadere l'ordine
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Evadere questo ordine?',
          confirmText: 'Evadi',
          cancelText: 'Annulla',
          isRestore: false
        }
      });

      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          try {
            const updatedOrder = {
              ...ordine,
              consegnato: true,
              statoPagato: 3,
              timestampEvasione: new Date().getTime().toString()
            };
            await this.commonService.updateOrdine(ordine.id, updatedOrder);
          } catch (error) {
            console.error('Error completing order:', error);
          }
        }
      });
    }
  }

  getOrderTypeLabel(type: string): string {
    switch (type) {
      case 'counter': return 'Banco';
      case 'table': return 'Tavolo';
      case 'takeaway': return 'Asporto';
      default: return 'N/D';
    }
  }

  toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  toggleVisualizzazione(): void {
    this.mostraTutti = !this.mostraTutti;
    this.setupOrdersListener();
  }

  toggleDestinazione(destinazione: string): void {
    const index = this.selectedDestinazioni.indexOf(destinazione);
    if (index === -1) {
      this.selectedDestinazioni.push(destinazione);
    } else {
      this.selectedDestinazioni.splice(index, 1);
    }
    this.applyFilters();
  }

  toggleCategory(category: string): void {
    const index = this.selectedCategories.indexOf(category);
    if (index === -1) {
      this.selectedCategories.push(category);
    } else {
      this.selectedCategories.splice(index, 1);
    }
    this.applyFilters();
  }

  hasActiveFilters(): boolean {
    return this.selectedDestinazioni.length > 0 || this.selectedCategories.length > 0;
  }

  clearFilters(): void {
    this.selectedDestinazioni = [];
    this.selectedCategories = [];
    this.ordiniCucina = [...this.ordiniOriginali];
  }

  applyFilters(): void {
    let filteredOrders = [...this.ordiniOriginali];

    if (this.selectedDestinazioni.length > 0) {
      filteredOrders = filteredOrders.filter(ordine =>
          ordine.ordineInserimento.some(prodottoId =>
              this.selectedDestinazioni.includes(ordine.carrello[prodottoId].selectedPrintDestination)
          )
      );
    }

    if (this.selectedCategories.length > 0) {
      filteredOrders = filteredOrders.filter(ordine =>
          ordine.ordineInserimento.some(prodottoId =>
              this.selectedCategories.includes(ordine.carrello[prodottoId].category)
          )
      );
    }

    this.ordiniCucina = filteredOrders;
  }

  calcolaTempoMedioEvasione(): void {
    if (this.ordiniEvasi.length === 0) {
      this.tempoMedioEvasione = '0:00';
      return;
    }

    let totalMinutes = 0;
    let ordiniValidi = 0;

    this.ordiniEvasi.forEach(ordine => {
      if (ordine.data && ordine.timestampEvasione) {
        const timestampCreazione = parseInt(ordine.data);
        const timestampEvasione = parseInt(ordine.timestampEvasione);
        const differenzaMinuti = Math.floor((timestampEvasione - timestampCreazione) / (1000 * 60));

        if (differenzaMinuti > 0) {
          totalMinutes += differenzaMinuti;
          ordiniValidi++;
        }
      }
    });

    if (ordiniValidi > 0) {
      const mediaMinuti = Math.floor(totalMinutes / ordiniValidi);
      const ore = Math.floor(mediaMinuti / 60);
      const minuti = mediaMinuti % 60;
      this.tempoMedioEvasione = `${ore}:${minuti.toString().padStart(2, '0')}`;
    }
  }

  getOrderTime(timestamp: string): string {
    if (!timestamp) return '0:00';

    const orderTime = new Date(parseInt(timestamp));
    const now = new Date();
    const diffMinutes = Math.floor((now.getTime() - orderTime.getTime()) / (1000 * 60));

    const hours = Math.floor(diffMinutes / 60);
    const minutes = diffMinutes % 60;

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }

  getCompletionPercentage(): number {
    const total = this.ordiniCucina.length + this.ordiniEvasi.length;
    if (total === 0) return 0;
    return Math.round((this.ordiniEvasi.length / total) * 100);
  }

  getElapsedTime(): string {
    return this.tempoMedioEvasione;
  }

}