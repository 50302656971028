<div class="row">
    <div class="header-container">
        <div class="title">Gestione Stampanti</div>
    </div>

    <div *ngFor="let workstation of workstations" class="card-container">
        <div style="padding: 20px;">
            <h3>{{workstation.name}}</h3>

            <div class="printer-section" *ngFor="let printer of getWorkstationPrinters(workstation); let i = index">
                <div class="printer-header">
                    <div class="printer-inputs">
                        <div class="input-container">
                            <input
                                    [class.error]="isPrinterNameInvalid(workstation.id, i)"
                                    [(ngModel)]="printer.name"
                                    placeholder="Nome Stampante"
                                    [readonly]="printer.name === 'fiscale'">
                            <div class="error-message" *ngIf="isPrinterNameInvalid(workstation.id, i)">
                                Nome stampante obbligatorio
                            </div>
                        </div>

                        <div class="input-container">
                            <input
                                    [class.error]="isPrinterIpInvalid(workstation.id, i)"
                                    [(ngModel)]="printer.ipAddress"
                                    placeholder="Indirizzo IP">
                            <div class="error-message" *ngIf="isPrinterIpInvalid(workstation.id, i)">
                                Indirizzo IP non valido
                            </div>
                        </div>
                    </div>

                    <button
                            *ngIf="printer.name !== 'fiscale'"
                            class="remove-printer-button"
                            (click)="removePrinter(workstation, i)">
                        ×
                    </button>
                </div>
            </div>

            <button class="add-printer-button" (click)="addPrinter(workstation)" type="button">
                Aggiungi Stampante
            </button>
        </div>
    </div>

    <div class="save-button-container">
        <button (click)="savePrinters()" [disabled]="isLoading || !isValid()">
            {{ isLoading ? 'Salvataggio...' : 'Salva Modifiche' }}
        </button>
    </div>
</div>