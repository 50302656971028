import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import {DataServiceGenerico} from "../../../../shared/services/data-service/data.service";
import {NotificationService} from "../../stampanti/notificationService/notification.service";

interface MenuItem {
    id: string;
    title: string;
}

@Component({
    selector: 'edit-slider-totem',
    templateUrl: './edit-slider-totem.html',
    styleUrls: ['./edit-slider-totem.css']
})
export class EditSliderTotemComponent implements OnInit {
    imageForm: FormGroup;
    isLoading = false;
    uploadProgress = 0;
    currentImageFile: File | null = null;
    menuItems: MenuItem[] = [];
    discounts: any[] = [];
    previewUrl: string = '';

    constructor(
        private dialogRef: MatDialogRef<EditSliderTotemComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private dataServiceGenerico: DataServiceGenerico,
        private notificationService: NotificationService
    ) {
        this.initForm();
    }

    private initForm(): void {
        this.imageForm = this.fb.group({
            name: ['', Validators.required],
            duration: [5, [Validators.required, Validators.min(1), Validators.max(60)]],
            url: [''],
            isClickable: [false],
            isDiscount: [false],
            linkedItemId: [''],
            discountId: ['']
        });

        // Add listeners for checkboxes
        this.imageForm.get('isClickable')?.valueChanges.subscribe(isClickable => {
            if (!isClickable) {
                this.imageForm.patchValue({
                    isDiscount: false,
                    linkedItemId: '',
                    discountId: ''
                });
            }
        });

        this.imageForm.get('isDiscount')?.valueChanges.subscribe(isDiscount => {
            if (!isDiscount) {
                this.imageForm.patchValue({
                    discountId: ''
                });
            } else {
                this.imageForm.patchValue({
                    linkedItemId: ''
                });
            }
        });
    }

    async ngOnInit() {
        await Promise.all([
            this.loadMenuItems(),
            this.loadDiscounts()
        ]);

        if (this.data) {
            this.imageForm.patchValue(this.data);
            this.previewUrl = this.data.url;
        }
    }

    async loadMenuItems() {
        try {
            const restaurantId = this.dataServiceGenerico.getRistorante1();
            if (!restaurantId) return;

            const db = firebase.firestore();
            const doc = await db.collection('menu_test').doc(restaurantId).get();
            const data = doc.data();

            if (data?.items) {
                this.menuItems = data.items.map((item: any) => ({
                    id: item.id,
                    title: item.title
                }));
            }
        } catch (error) {
            console.error('Error loading menu items:', error);
        }
    }

    async loadDiscounts() {
        try {
            const restaurantId = this.dataServiceGenerico.getRistorante1();
            if (!restaurantId) return;

            const db = firebase.firestore();
            const snapshot = await db.collection('sconti')
                .where('ristoranteId', '==', restaurantId)
                .get();

            this.discounts = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
        } catch (error) {
            console.error('Error loading discounts:', error);
        }
    }

    async onFileSelected(event: any) {
        const file = event.target.files[0];
        if (file) {
            this.currentImageFile = file;
            this.imageForm.patchValue({ url: '' });

            // Create preview URL
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.previewUrl = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    async uploadImage(file: File): Promise<string> {
        const restaurantId = this.dataServiceGenerico.getRistorante1();
        if (!restaurantId || !file) throw new Error('Missing restaurant ID or file');

        const storage = firebase.storage();
        const storageRef = storage.ref();
        const imageRef = storageRef.child(`restaurants/${restaurantId}/slider/${file.name}`);

        const uploadTask = imageRef.put(file);

        return new Promise((resolve, reject) => {
            uploadTask.on('state_changed',
                (snapshot) => {
                    this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => reject(error),
                async () => {
                    const downloadUrl = await imageRef.getDownloadURL();
                    resolve(downloadUrl);
                }
            );
        });
    }

    async saveImage() {
        if (this.imageForm.invalid) {
            this.notificationService.show('Compila tutti i campi richiesti', 'error');
            return;
        }

        this.isLoading = true;
        try {
            const formValue = this.imageForm.value;
            let imageUrl = formValue.url;

            if (this.currentImageFile) {
                imageUrl = await this.uploadImage(this.currentImageFile);
            }

            if (!imageUrl) {
                throw new Error('Missing image URL');
            }

            const imageData = {
                ...formValue,
                url: imageUrl
            };

            this.dialogRef.close(imageData);
        } catch (error) {
            console.error('Error saving image:', error);
            this.notificationService.show('Errore durante il salvataggio dell\'immagine', 'error');
        } finally {
            this.isLoading = false;
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    async deleteImage() {
        if (confirm('Sei sicuro di voler eliminare questa immagine?')) {
            this.dialogRef.close({ delete: true, url: this.data.url });
        }
    }

    getErrorMessage(controlName: string): string {
        const control = this.imageForm.get(controlName);
        if (control?.hasError('required')) {
            return 'Questo campo è obbligatorio';
        }
        if (control?.hasError('min') || control?.hasError('max')) {
            return 'La durata deve essere compresa tra 1 e 60 secondi';
        }
        return '';
    }
}