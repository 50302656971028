import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import firebase from 'firebase/app';
import { Chart } from 'chart.js';

interface OrderStatus {
  id: string;
  status: 'processing' | 'shipped' | 'delivered' | 'pending';
  date: Date;
  destination: string;
  totalAmount: number;
}

interface RevenueData {
  month: number;
  year: number;
  revenue: number;
  commission: number;
  timestamp: firebase.firestore.Timestamp;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.css']
})

export class Dashboard implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('revenueChart') revenueChartCanvas: ElementRef;
  private chart: any;
  notifiche: any[] = [];
  latestNotifications: any[] = [];
  recentOrders: OrderStatus[] = [];
  revenueHistory: RevenueData[] = [];
  currentYear = new Date().getFullYear();
  revenueGrowth: number = 0;
  totalAnnualRevenue: number = 0;

  metrics = {
    monthlyRevenue: 0,
    revenueGrowth: 0,
    pendingOrders: 0,
    completedOrders: 0,
    totalOrders: 0
  };

  months = [
    'Gennaio', 'Febbraio', 'Marzo', 'Aprile',
    'Maggio', 'Giugno', 'Luglio', 'Agosto',
    'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
  ];

  ordiniMagazzino: any[] = [];

  private unsubscribeNotifiche: (() => void) | undefined;
  private unsubscribeOrdini: (() => void) | undefined;

  constructor(
      private dataService: DataServiceGenerico,
      private commonService: CommonService,
      private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initializeData();

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initChart();
      this.fetchRevenueData();
    });
  }

  toggleNotification(notification: any) {
    notification.isExpanded = !notification.isExpanded;
  }

  getNotificationIcon(type: string): string {
    switch(type) {
      case 'alert': return 'warning';
      case 'info': return 'info';
      default: return 'notifications';
    }
  }

  getPreviousMonthName(): string {
    const currentMonth = new Date().getMonth();
    const previousMonth = currentMonth - 1 < 0 ? 11 : currentMonth - 1;
    console.log('asdf test');
    return this.months[previousMonth];
  }

  getLastMonthRevenue(): number {
    const currentMonth = new Date().getMonth();
    const lastMonth = currentMonth - 1 < 0 ? 11 : currentMonth - 1;
    const lastMonthYear = currentMonth - 1 < 0 ? this.currentYear - 1 : this.currentYear;

    const lastMonthData = this.revenueHistory.find(record =>
        record.month === lastMonth && record.year === lastMonthYear
    );

    return lastMonthData?.revenue || 0;
  }

  getLastMonthGrowth(): number {
    const currentMonth = new Date().getMonth();
    const lastMonth = currentMonth - 1 < 0 ? 11 : currentMonth - 1;
    const lastMonthYear = currentMonth - 1 < 0 ? this.currentYear - 1 : this.currentYear;

    const twoMonthsAgo = lastMonth - 1 < 0 ? 11 : lastMonth - 1;
    const twoMonthsAgoYear = lastMonth - 1 < 0 ? lastMonthYear - 1 : lastMonthYear;

    const lastMonthRevenue = this.getLastMonthRevenue();
    const twoMonthsAgoData = this.revenueHistory.find(record =>
        record.month === twoMonthsAgo && record.year === twoMonthsAgoYear
    );

    const twoMonthsAgoRevenue = twoMonthsAgoData?.revenue || 0;
    return twoMonthsAgoRevenue > 0
        ? ((lastMonthRevenue - twoMonthsAgoRevenue) / twoMonthsAgoRevenue) * 100
        : 0;
  }

  async fetchRevenueData() {
    try {
      const restaurantId = await this.dataService.getCurrentRestaurantId();
      const db = firebase.firestore();
      const doc = await db.collection('ristoranti')
          .doc(restaurantId)
          .get();

      if (doc.exists) {
        const data = doc.data();
        const fatturatoData = data?.fatturatoFranchise || {};
        const yearData = fatturatoData[this.currentYear] || [];
        this.revenueHistory = yearData;

        // Prendiamo il mese precedente a quello corrente
        const currentMonth = new Date().getMonth() ; // 0-11
        const lastMonth = currentMonth - 1 < 0 ? 11 : currentMonth - 1;
        const lastMonthYear = currentMonth - 1 < 0 ? this.currentYear - 1 : this.currentYear;

        // Cerca il record del mese precedente
        const lastMonthData = yearData.find(record =>
            record.month === lastMonth && record.year === lastMonthYear
        );

        // Imposta il fatturato del mese precedente
        this.metrics.monthlyRevenue = lastMonthData?.revenue || 0;

        // Prendiamo due mesi prima per il calcolo della crescita
        const twoMonthsAgo = lastMonth - 1 < 0 ? 11 : lastMonth - 1;
        const twoMonthsAgoYear = lastMonth - 1 < 0 ? lastMonthYear - 1 : lastMonthYear;

        const twoMonthsAgoData = yearData.find(record =>
            record.month === twoMonthsAgo && record.year === twoMonthsAgoYear
        );

        // Calcola la crescita percentuale
        const twoMonthsAgoRevenue = twoMonthsAgoData?.revenue || 0;
        this.metrics.revenueGrowth = twoMonthsAgoRevenue > 0
            ? ((this.metrics.monthlyRevenue - twoMonthsAgoRevenue) / twoMonthsAgoRevenue) * 100
            : 0;

        this.updateChart();
      }
    } catch (error) {
      console.error('Error loading revenue data:', error);
    }
  }

  private updateChart() {
    if (!this.chart) return;

    const monthlyData = Array(12).fill(0);

    // Ordina i dati per mese
    const sortedData = [...this.revenueHistory].sort((a, b) => a.month - b.month);

    // Popola i dati del grafico
    sortedData.forEach(record => {
      if (record.year === this.currentYear) {
        monthlyData[record.month] = record.revenue;
      }
    });

    this.chart.data.datasets[0].data = monthlyData;
    this.chart.update();
  }

  private initChart() {
    if (this.chart) {
      this.chart.destroy();
    }

    const ctx = this.revenueChartCanvas?.nativeElement.getContext('2d');
    if (!ctx) return;

    // Gradiente in stile design di riferimento (azzurro chiaro)
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(188, 232, 241, 0.5)');  // Azzurro chiaro più opaco in alto
    gradient.addColorStop(0.5, 'rgba(188, 232, 241, 0.3)'); // Azzurro chiaro più trasparente a metà
    gradient.addColorStop(1, 'rgba(188, 232, 241, 0)');    // Trasparente in basso

    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.months.map(month => month.substring(0, 3)),
        datasets: [{
          label: 'Fatturato',
          data: Array(12).fill(0),
          backgroundColor: gradient,
          borderColor: '#78CEDD', // Azzurro più chiaro per la linea
          borderWidth: 2,
          fill: true,
          tension: 0.4,
          pointRadius: 0, // Nascondiamo i punti normali
          pointHoverRadius: 6,
          pointHoverBackgroundColor: '#FFFFFF',
          pointHoverBorderColor: '#78CEDD',
          pointHoverBorderWidth: 2,
          // Aggiungiamo un punto visibile solo per il valore attuale
          pointBackgroundColor: (context) => {
            const index = context.dataIndex;
            const value = context.dataset.data[index];
            return index === new Date().getMonth() ? '#FFFFFF' : 'transparent';
          },
          pointBorderColor: (context) => {
            const index = context.dataIndex;
            return index === new Date().getMonth() ? '#78CEDD' : 'transparent';
          },
          pointBorderWidth: 2,

        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: '#94A3B8',
              padding: 10,
              fontSize: 12
            }
          }],
          yAxes: [{
            gridLines: {
              borderDash: [8, 4],
              color: 'rgba(148, 163, 184, 0.1)',
              drawBorder: false
            },
            ticks: {
              beginAtZero: true,
              fontColor: '#94A3B8',
              padding: 10,
              fontSize: 12,
              maxTicksLimit: 5,
              callback: (value) => '€' + value.toLocaleString()
            }
          }]
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false,
          backgroundColor: '#FFFFFF',
          titleFontColor: '#1E293B',
          bodyFontColor: '#1E293B',
          borderColor: '#E2E8F0',
          borderWidth: 1,
          titleFontFamily: "'Inter', sans-serif",
          bodyFontFamily: "'Inter', sans-serif",
          titleFontSize: 13,
          bodyFontSize: 12,
          titleMarginBottom: 10,
          xPadding: 12,
          yPadding: 12,
          displayColors: false,
          callbacks: {
            label: (tooltipItem) => {
              const value = tooltipItem.yLabel as number;
              return '€' + value.toLocaleString();
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: false
        },
        elements: {
          line: {
            tension: 0.4
          }
        }
      }
    });
  }


  private async initializeData(): Promise<void> {
    try {
      if (await this.dataService.getCurrentRestaurantId() === '') {
        await this.dataService.loadAvailableRestaurants();
      }
      await this.fetchNotifiche();
      await this.fetchOrders();
      await this.calculateMetrics();
    } catch (error) {
      console.error('Error initializing dashboard:', error);
    }
  }

  private async fetchNotifiche(): Promise<void> {
    const restaurantId = await this.dataService.getCurrentRestaurantId();
    const db = firebase.firestore();

    this.unsubscribeNotifiche = db.collection('notifications')
        .where('restaurantId', '==', restaurantId)
        .orderBy('date', 'desc')
        .limit(5)
        .onSnapshot(snapshot => {
          this.latestNotifications = [];
          snapshot.forEach(doc => {
            const data = doc.data();
            this.latestNotifications.push({
              id: doc.id,
              ...data,
              formattedDate: this.formatDate(data.date),
              isExpanded: false // Aggiungiamo questa proprietà per gestire l'espansione
            });
          });
          this.cdRef.detectChanges();
        });
  }

  async fetchOrders() {
    const db = firebase.firestore();
    const ordiniRef = db.collection('ordini_magazzino_test');
    const restaurantId = await this.dataService.getCurrentRestaurantId();

    if (!restaurantId) {
      console.error('ID ristorante non trovato');
      return;
    }

    ordiniRef.where('ristorante', '==', restaurantId)
        .orderBy('data', 'desc')
        .limit(5) // Prendiamo solo gli ultimi 5 ordini per la dashboard
        .onSnapshot(querySnapshot => {
          this.ordiniMagazzino = [];
          querySnapshot.forEach(doc => {
            const ordine = {
              id: doc.id,
              ...doc.data()
            };
            this.ordiniMagazzino.push(ordine);
          });
          this.cdRef.detectChanges();
        });
  }

// Metodi helper
  calcolaTotaleOrdine(ordine: any): string {
    if (!ordine || !ordine.carrelloMagazzino) return "0.00";

    const totale = ordine.carrelloMagazzino.reduce((acc: number, item: any) => {
      const prezzo = parseFloat(item.Prezzo) || 0;
      const quantita = parseInt(item.Quantita) || 0;
      return acc + prezzo;
    }, 0);

    return totale.toFixed(2);
  }

  getOrderStatus(stato: string): string {
    switch(stato?.toLowerCase()) {
      case 'confermato':
        return 'Completato';
      case 'confermato_parziale':
        return 'Parzialmente Completato';
      case 'in_attesa':
        return 'In Attesa';
      default:
        return 'In Preparazione';
    }
  }
  getOrdersByStatus(status: string): number {
    return this.recentOrders.filter(order => order.status === status).length;
  }

  private async calculateMetrics(): Promise<void> {
    const restaurantId = await this.dataService.getCurrentRestaurantId();
    const db = firebase.firestore();

    try {
      const revenueDoc = await db
          .collection('ristoranti')
          .doc(restaurantId)
          .get();

      const fatturatoData = revenueDoc.data()?.fatturatoFranchise || {};
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const yearData = fatturatoData[currentYear] || [];

      // Calcola fatturato mensile
      const monthlyRevenue = yearData
          .filter(record => record.month === currentMonth)
          .reduce((sum, record) => sum + record.revenue, 0);

      // Calcola crescita
      const lastMonthRevenue = yearData
          .filter(record => record.month === (currentMonth - 1))
          .reduce((sum, record) => sum + record.revenue, 0);

      const revenueGrowth = lastMonthRevenue === 0 ? 0 :
          ((monthlyRevenue - lastMonthRevenue) / lastMonthRevenue) * 100;

      this.metrics = {
        monthlyRevenue,
        revenueGrowth,
        pendingOrders: this.getOrdersByStatus('pending'),
        completedOrders: this.getOrdersByStatus('delivered'),
        totalOrders: this.recentOrders.length
      };

      this.updateChart();
    } catch (error) {
      console.error('Error calculating metrics:', error);
    }
  }





  private formatDate(date: any): Date {
    if (!date) return new Date();

    // Se è un timestamp di Firestore
    if (date.toDate) {
      return date.toDate();
    }

    // Se è una stringa o altra data
    return new Date(date);
  }

  ngOnDestroy(): void {
    if (this.unsubscribeNotifiche) {
      this.unsubscribeNotifiche();
    }
    if (this.unsubscribeOrdini) {
      this.unsubscribeOrdini();
    }
    if (this.chart) {
      this.chart.destroy();
    }
  }
}