import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import {DataServiceGenerico} from "../../../../shared/services/data-service/data.service";
import {NotificationService} from "../../stampanti/notificationService/notification.service";

@Component({
    selector: 'edit-slider-image',
    templateUrl: './edit-slider-images.html',
    styleUrls: ['./edit-slider-images.css']
})
export class EditSliderImageComponent implements OnInit {
    imageForm: FormGroup;
    isLoading = false;
    uploadProgress = 0;
    currentImageFile: File | null = null;
    previewUrl: string = '';

    constructor(
        private dialogRef: MatDialogRef<EditSliderImageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private dataServiceGenerico: DataServiceGenerico,
        private notificationService: NotificationService
    ) {
        this.initForm();
    }

    private initForm(): void {
        this.imageForm = this.fb.group({
            name: ['', Validators.required],
            duration: [5, [Validators.required, Validators.min(1), Validators.max(60)]],
            url: [''],
            file: [null]
        });
    }

    async ngOnInit() {
        if (this.data) {
            this.imageForm.patchValue({
                name: this.data.name,
                duration: this.data.duration,
                url: this.data.url
            });
            this.previewUrl = this.data.url;
        }
    }

    async onFileSelected(event: any) {
        const file = event.target.files[0];
        if (file) {
            this.currentImageFile = file;
            this.imageForm.patchValue({
                name: file.name,
                url: ''
            });

            // Create preview URL
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.previewUrl = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    async uploadImage(file: File): Promise<string> {
        const restaurantId = this.dataServiceGenerico.getRistorante1();
        if (!restaurantId || !file) throw new Error('Missing restaurant ID or file');

        const storage = firebase.storage();
        const storageRef = storage.ref();
        const imageRef = storageRef.child(`restaurants/${restaurantId}/slider/${file.name}`);

        const uploadTask = imageRef.put(file);

        return new Promise((resolve, reject) => {
            uploadTask.on('state_changed',
                (snapshot) => {
                    this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => reject(error),
                async () => {
                    const downloadUrl = await imageRef.getDownloadURL();
                    resolve(downloadUrl);
                }
            );
        });
    }

    async saveImage() {
        if (this.imageForm.invalid) {
            this.notificationService.show('Compila tutti i campi richiesti', 'error');
            return;
        }

        this.isLoading = true;
        try {
            const formValue = this.imageForm.value;
            let imageUrl = formValue.url;

            if (this.currentImageFile) {
                imageUrl = await this.uploadImage(this.currentImageFile);
            }

            if (!imageUrl) {
                throw new Error('Missing image URL');
            }

            const imageData = {
                name: formValue.name,
                duration: formValue.duration,
                url: imageUrl
            };

            this.dialogRef.close(imageData);
        } catch (error) {
            console.error('Error saving image:', error);
            this.notificationService.show('Errore durante il salvataggio dell\'immagine', 'error');
        } finally {
            this.isLoading = false;
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    async deleteImage() {
        if (confirm('Sei sicuro di voler eliminare questa immagine?')) {
            this.dialogRef.close({ delete: true, url: this.data.url });
        }
    }
}