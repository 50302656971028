// dialog-buoni-pasto.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'dialog-buoni-pasto',
    template: `
    <div class="dialog-container">
      <div class="header">
        <span class="title">Inserisci Buoni Pasto</span>
        <div class="close-button" (click)="dialogRef.close()">✕</div>
      </div>

      <div class="input-container">
        <div class="input-field" (click)="activeInput = 'quantity'" [class.active]="activeInput === 'quantity'">
          <div class="label">Quantità</div>
          <div class="value">{{quantity || '0'}}</div>
        </div>

        <div class="input-field" (click)="activeInput = 'value'" [class.active]="activeInput === 'value'">
          <div class="label">Valore (€)</div>
          <div class="value">{{value || '0'}}</div>
        </div>
      </div>

      <div class="numpad">
        <div class="numpad-grid">
          <button *ngFor="let num of numbers" (click)="handleNumberClick(num)">{{num}}</button>
          <button (click)="handleDelete()">←</button>
          <button class="confirm" (click)="handleConfirm()">OK</button>
        </div>
      </div>

      <div class="total">
        <div class="label">Totale</div>
        <div class="value">€{{calculateTotal()}}</div>
      </div>
    </div>
  `,
    styles: [`
    .dialog-container {
      padding: 20px;
      max-width: 400px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
    }

    .close-button {
      cursor: pointer;
      padding: 8px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .close-button:hover {
      background-color: rgba(0,0,0,0.1);
    }

    .input-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 20px;
    }

    .input-field {
      padding: 15px;
      border: 1px solid #e3e2e2;
      border-radius: 10px;
      cursor: pointer;
    }

    .input-field.active {
      border-color: #007bff;
    }

    .input-field .label {
      font-size: 14px;
      color: #666;
    }

    .input-field .value {
      font-size: 24px;
      font-weight: bold;
      margin-top: 5px;
    }

    .numpad-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
    }

    .numpad button {
      padding: 15px;
      font-size: 20px;
      font-weight: bold;
      border: none;
      border-radius: 10px;
      background-color: #f5f5f5;
      cursor: pointer;
    }

    .numpad button:hover {
      background-color: #e0e0e0;
    }

    .numpad button.confirm {
      background-color: #007bff;
      color: white;
    }

    .numpad button.confirm:hover {
      background-color: #0056b3;
    }

    .total {
      margin-top: 20px;
      padding: 15px;
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    .total .label {
      font-size: 14px;
      color: #666;
    }

    .total .value {
      font-size: 24px;
      font-weight: bold;
      margin-top: 5px;
    }
  `]
})
export class DialogBuoniPasto {
    quantity: string = '';
    value: string = '';
    activeInput: 'quantity' | 'value' = 'quantity';
    numbers: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

    constructor(
        public dialogRef: MatDialogRef<DialogBuoniPasto>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    handleNumberClick(number: number) {
        if (this.activeInput === 'quantity') {
            this.quantity += number.toString();
        } else {
            this.value += number.toString();
        }
    }

    handleDelete() {
        if (this.activeInput === 'quantity') {
            this.quantity = this.quantity.slice(0, -1);
        } else {
            this.value = this.value.slice(0, -1);
        }
    }

    handleConfirm() {
        if (this.quantity && this.value) {
            const result = {
                quantity: parseInt(this.quantity),
                value: parseFloat(this.value),
                totalValue: parseInt(this.quantity) * parseFloat(this.value)
            };
            this.dialogRef.close(result);
        }
    }

    calculateTotal(): string {
        const total = (parseInt(this.quantity || '0') * parseFloat(this.value || '0')).toFixed(2);
        return total;
    }
}